import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { createIntraMerchantTransfer } from "../financialActions";

import { asyncValidateAndGetMerchantName } from "../../../app/common/validation/asyncValidate";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  transfer_to_account: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    }),
    hasLengthBetween(
      4,
      8
    )({
      message: "Must be 4 or 8 digits",
    })
  )(),
  transfer_amount: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    })
  )(),
  transfer_reason: composeValidators(customIsRequired)(),

  withdrawal_date: composeValidators(customIsRequired)(),
});

const merchantTransferForm = ({
  initialValues,
  handleSubmit,
  createIntraMerchantTransfer,
  error,
}) => {
  const { account, merchantName } = initialValues;
  let [step, setStep] = useState(1);
  let [transferToMerchantName, setTransferToMerchantName] = useState(null);
  let [transferToMerchantAccount, setTransferToMerchantAccount] =
    useState(null);
  let [transferAmount, setTransferAmount] = useState(0);
  let [transferReason, setTransferReason] = useState(null);

  const onFormSubmit = async (values) => {
    console.log("values", values);

    if (step === 1) {
      console.log("Step 1");
      let validatedMerchantName = await asyncValidateAndGetMerchantName(values);

      if (validatedMerchantName.isValidAccount) {
        setTransferToMerchantName(validatedMerchantName.merchantName);
        setTransferToMerchantAccount(validatedMerchantName.merchantAccount);
        setTransferAmount(values.transfer_amount);
        setTransferReason(values.transfer_reason);
        setStep(2);
      } else {
        console.log("Merchant Not found");
      }
    } else if (step === 2) {
      console.log("Step 2");
      console.log("Step submitting");
      await createIntraMerchantTransfer({
        values,
      });

      setTransferToMerchantName(null);
      setTransferToMerchantAccount(null);
      // setStep(1);
    }
  };

  const clearFormSubmit = async () => {
    console.log("clearFormSubmit");
    if (step === 1) {
      return;
    } else if (step === 2) {
      console.log("Step 2");
      console.log("Step submitting");
      setTransferToMerchantName(null);
      setTransferToMerchantAccount(null);
      setStep(1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {step === 1 ? (
        <>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Transfer From
              </div>
              <strong>
                {account} - {merchantName}
              </strong>
            </div>
            <div className={"col-sm-4"}>
              <Field
                label="Transfer To Account"
                name="transfer_to_account"
                component={TextInput}
                placeholder="Transfer To"
                className={"form-control"}
                required="true"
                type="text"
                aria-required="true"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                label="Transfer Amount"
                name="transfer_amount"
                component={TextInput}
                type="text"
                className={"form-control"}
                placeholder="Amount"
                required="required"
              />
            </div>
          </div>

          <br />
          <div className={"row"} style={{ lineHeight: "3.8rem" }}>
            <div className={"col-md-12"}>
              <Field
                label="Transfer Reason"
                name="transfer_reason"
                component={TextArea}
                type="text"
                className={"form-control"}
                placeholder="Reason for Transfer"
                required="required"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Transfer From
              </div>
              <strong>
                {account} - {merchantName}
              </strong>
            </div>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Transfer To
              </div>
              <strong>
                {transferToMerchantAccount} - {transferToMerchantName}
              </strong>
            </div>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Transfer Amount
              </div>
              <strong>{transferAmount}</strong>
            </div>
          </div>

          <br />
          <div className={"row"} style={{ lineHeight: "3.8rem" }}>
            <div className={"col-md-12"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Transfer Reason
              </div>
              <strong>{transferReason}</strong>
            </div>
          </div>
        </>
      )}
      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      <button className={"btn btn-primary btn-cons m-t-10"}>
        {step === 1 ? "Confirm Details" : "Merchant Transfer"}
      </button>
      {step === 2 && (
        <button
          onClick={() => clearFormSubmit()}
          className={"btn btn-danger btn-cons m-t-10"}
        >
          Cancel
        </button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    account: state.modals.modalProps.account,
    merchantName: state.modals.modalProps.merchantName,
    transactionCategory: "Debit",
    transactionType: "Transfer",
    transferType: "Intra Merchant Transfer",
    isTransactionCharged: false,
  },
  enableReinitialize: true,
});

const actions = {
  createIntraMerchantTransfer,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "intraMerchantTransferForm", validate })(
    merchantTransferForm
  )
);
