import React, { Component } from "react";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { combineValidators, isRequired } from "revalidate";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { amendDeviceContract } from "../../inventoryActions";
import SelectInput from "../../../../app/common/form/SelectInput";
import TextArea from "../../../../app/common/form/TextArea";

import styles from "./AmendDeviceContractForm.module.css";

const validate = combineValidators({
  device: isRequired("device"),
  devicePlan: isRequired("devicePlan"),
  sim: isRequired("sim"),
});

class AmendDeviceContractForm extends Component {
  render() {
    const { amendDeviceContract, initialValues, loading, handleSubmit, error } =
      this.props;

    const { deviceContract } = initialValues;

    console.log("deviceContract", deviceContract);
    const onFormSubmit = (values) => {
      amendDeviceContract(values);
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <br />

        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Current Contract: ${deviceContract.plan}`}
            </h5>
          </div>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Remaining Period: ${deviceContract.plan}`}
            </h5>
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Amount Paid: ${deviceContract.amountPaid}`}
            </h5>
          </div>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Amount Outstanding: ${deviceContract.amountOutstanding}`}
            </h5>
          </div>
        </div>
        <br />

        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <Field
              name="devicePlan"
              options={this.props.devicePaymentPlans}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              component={SelectInput}
              placeholder="Select Device Payment Plan..."
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          {/* <div className={"col-md-6"}>
            <h4 style={{ marginTop: 0 }}>
              {`Account Balance: ${moneyFormat(
                this.props.merchantBalance && this.props.merchantBalance.balance
              )}`}
            </h4>
          </div> */}
        </div>
        <br />
        <div className={"row"} style={{ lineHeight: "3.8rem" }}>
          <div className={"col-md-12"}>
            <Field
              label="Change Reason"
              name="change_reason"
              component={TextArea}
              type="text"
              className={"form-control"}
              placeholder="Reason for contract change"
              required="required"
            />
          </div>
        </div>

        {/* <div className={"row clearfix m-t-30"}>
          <div className={"col-md-6"}>
            <Field
              name="device"
              options={this.props.devices}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              component={SelectInput}
              placeholder="Select Device..."
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>

          <div className={"col-lg-6"} style={{ paddingRight: "7px" }}></div>
        </div> */}
        <br />
        {error && <label className={"error semi-bold"}>{error}</label>}
        <button className={styles.btn} type="submit" disabled={loading}>
          {loading ? (
            <Spinner
              style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
            />
          ) : (
            <span>Amend Contract</span>
          )}
        </button>
      </form>
    );
  }
}

const query = () => {
  return [
    {
      collection: "devicePaymentPlans",
      storeAs: "devicePaymentPlans",
      orderBy: ["orderingNumber", "asc"],
    },
  ];
};

const mapStateToProps = (state) => ({
  devicePaymentPlans:
    state.firestore.ordered.devicePaymentPlans &&
    state.firestore.ordered.devicePaymentPlans.map((item) => {
      return {
        label: `${item.plan}`,
        value: item,
      };
    }),

  initialValues: {
    deviceContract: state.modals.modalProps.deviceContract,
    outletName: state.modals.modalProps.outletName,
    group: state.modals.modalProps.group,
    merchantName: state.modals.modalProps.merchantName,
  },
  loading: state.async.loading,
});

const actions = {
  amendDeviceContract,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "amendDeviceContractForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(AmendDeviceContractForm);
