import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";

let startingBalanceCalc = (transactions) => {
  // let total = 0;
  let arr = [];

  let result = transactions.reduce((total, transaction) => {
    // let transaction = doc.data();

    if (!arr.includes(transaction.account)) {
      arr.push(transaction.account);
      total = total + transaction.balance;
      return total;
    } else {
      total = total + 0;
      return total;
    }
  }, 0);

  console.log("result", result);
  return result;
};

let closingBalanceCalc = (balances) => {
  // let total = 0;
  let arr = [];

  let balancesCopy = [...balances];
  let transactions = balancesCopy.reverse();

  let result = transactions.reduce((total, transaction) => {
    if (!arr.includes(transaction.account)) {
      arr.push(transaction.account);
      total = total + transaction.balance;
      return total;
    } else {
      total = total + 0;
      return total;
    }
  }, 0);

  return result;
};

const financialRecords = ({
  sales,
  deviceFees,
  salesTotal,
  merchantCommissionTotal,
  deposits,
  commissionCredited,
  reversalsTotal,
  transfersTotal,
  transfersList,
  merchant,
  withdrawalsTotal,
  startingBalance,
  closingBalance,
}) => {
  let financialRecords = (
    <Fragment>
      <LoadingComponent inverted={true} />
    </Fragment>
  );

  parseFloat(startingBalance) !== NaN
    ? (financialRecords = (
        <table className={classes.MerchantTable}>
          <thead>
            <tr style={{ backgroundColor: "#eee" }}>
              <th style={{ padding: "1rem" }} scope="col">
                Type
              </th>
              <th style={{ padding: "1rem" }} scope="col">
                Amount
              </th>

              {/* <th scope="col">Sales Value2</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>
                <strong>Starting Balance</strong>
              </td>
              <td>
                <strong>{formatMoney(startingBalance)}</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Deposits </td>
              <td>{formatMoney(deposits)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Commission Credited</td>
              <td>{formatMoney(commissionCredited)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Reversals</td>
              <td>{formatMoney(reversalsTotal)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}>
                <strong>Sales</strong>
              </td>
            </tr>
            {sales.map((report, index) => {
              return (
                <tr className={classes.MerchantRow} key={index}>
                  <td>
                    {report.saleType} - {report.provider}
                  </td>
                  <td>{formatMoney(report.transactionAmount)}</td>
                </tr>
              );
            })}
            <tr className={classes.MerchantRow}>
              <td>
                <strong>Total Sales</strong>
              </td>
              <td>
                <strong>{formatMoney(salesTotal)}</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Withdrawals</td>
              <td>{formatMoney(withdrawalsTotal)}</td>
            </tr>

            {deviceFees &&
              deviceFees.map((item, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>{item.transactionType}</td>
                    <td>{`(${formatMoney(item.transactionAmount)})`}</td>
                  </tr>
                );
              })}

            {transfersList.length > 0 ? (
              <>
                <tr className={classes.MerchantRow}>
                  <td>Transfers In</td>
                  <td>
                    {transfersTotal >= 0
                      ? formatMoney(
                          transfersList
                            .filter(
                              (item) => item.transferToAccount === merchant.id
                            )
                            .map((doc) => {
                              return doc;
                            })
                            .reduce(function (total, doc) {
                              total += parseFloat(doc.transactionAmount)
                                ? parseFloat(doc.transactionAmount)
                                : 0;

                              return total;
                            }, 0)
                        )
                      : `(${formatMoney(
                          transfersList
                            .filter(
                              (item) => item.transferToAccount  === merchant.id
                            )
                            .map((doc) => {
                              return doc;
                            })
                            .reduce(function (total, doc) {
                              total += parseFloat(doc.transactionAmount)
                                ? parseFloat(doc.transactionAmount)
                                : 0;

                              return total;
                            }, 0)
                        )})`}
                  </td>
                </tr>
                <tr className={classes.MerchantRow}>
                  <td>Transfers Out</td>
                  <td>
                    {transfersTotal >= 0
                      ? formatMoney(
                          transfersList
                            .filter(
                              (item) => item.transferFromAccount === merchant.id
                            )
                            .map((doc) => {
                              return doc;
                            })
                            .reduce(function (total, doc) {
                              total += parseFloat(doc.transactionAmount)
                                ? parseFloat(doc.transactionAmount)
                                : 0;

                              return total;
                            }, 0)
                        )
                      : `(${formatMoney(
                          transfersList
                            .filter(
                              (item) => item.transferFromAccount === merchant.id
                            )
                            .map((doc) => {
                              return doc;
                            })
                            .reduce(function (total, doc) {
                              total += parseFloat(doc.transactionAmount)
                                ? parseFloat(doc.transactionAmount)
                                : 0;

                              return total;
                            }, 0)
                        )})`}
                  </td>
                </tr>
              </>
            ) : null}

            {/* {transfersList.length > 0 ? 
<tr className={classes.MerchantRow}>
              <td>Transfers </td>
              <td>{
              
              transfersTotal >= 0 ?
              
              formatMoney(transfersTotal) : `(${formatMoney(transfersTotal)})`
              
              
              }</td>
            </tr> : null

            } */}

            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td style={{ padding: "1rem", background: "#efefef" }}>
                <strong>Closing Balance</strong>
              </td>
              <td style={{ padding: "1rem", background: "#efefef" }}>
                <strong>{formatMoney(closingBalance)}</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}>
                <strong>Commissions</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Commission earned by Merchants</td>
              <td>{formatMoney(merchantCommissionTotal)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
          </tbody>
        </table>
      ))
    : (financialRecords = (
        <h3>There are no financial transactions for selected period.</h3>
      ));

  return (
    <div className={classes.MerchantTableWrapper}>
      <div className={classes.TableWrapper}>{financialRecords}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startingBalance: state.financials.startingBalance,
  closingBalance: state.financials.closingBalance,
  deposits: state.financials.depositsTotal,
  reversalsTotal: state.financials.reversalsTotal,
  withdrawals: state.financials.withdrawalsList,
  withdrawalsTotal: state.financials.withdrawalsTotal,
  transfersList: state.financials.transfersList
    ? state.financials.transfersList
    : [],
  transfersTotal: state.financials.transfersTotal,
  sales: state.financials.totalsByProvider,
  salesTotal: state.financials.salesTotal,
  financials: state.financials.summary,
  deviceFees: state.financials.deviceFees,
  commissionCredited: state.financials.creditedCommissionListTotal,
  merchantCommissionTotal: state.financials.earnedCommissionTotal,
  merchant:
    state.firestore.ordered.merchant && state.firestore.ordered.merchant[0],
});

export default connect(mapStateToProps)(financialRecords);
