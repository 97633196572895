import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import MerchantFinancialsSpinner from "../financials/merchantFinancialsSpinner";

const actions = {
  closeModal
};

const spinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <MerchantFinancialsSpinner/>
    </TransparentModal>
  );
};

export default connect(
  null,
  actions
)(spinnerModal);



