//import liraries
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Outlet from "./Outlet/Outlet";

import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import classes from "./Outlets.module.css";

const merchantOutlets = props => {
  const { outlets } = props;
  let attachedPaginateActiveClasses = [classes.paginate_button, classes.active];

  let attachedPaginateSimpleNumbersClasses = [
    classes.dataTables_paginate,
    classes.paging_simple_numbers
  ];

  let attachedPaginateNextClasses = [classes.paginate_button, classes.next];
  let outletList = "Spinner.....";

  if (outlets) {
    console.log("outlets", outlets);
    console.log("props.outlets", props.outlets);

    let numberOfRecords = props.outlets.length;
    outletList =
      props.outlets &&
      Object.values(props.outlets).map(outlet => {
        console.log("outlet", Object.values(outlet));
        return (
          <tr className={classes.MerchantRow} key={outlet.id}>
            <td>
              <Link to={`/merchants/${props.account}/outlet/${outlet.id}`}>
                {outlet.name}
              </Link>
            </td>
            <td>{outlet.id}</td>
            <td>{outlet.type}</td>

            <td>{outlet.contact.telephone}</td>
            <td>{outlet.city}</td>
            <td>
              {format(
                outlet.dateCreated && outlet.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {outletList && outletList.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>Merchant does not have any configured users.</h3>
            </div>
          ) : (
            <div className={classes.TableWrapper}>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Account</th>
                    <th scope="col">Type</th>
                    <th className={"hide-s"} scope="col">
                      Telephone
                    </th>
                    <th className={"hide-s"} scope="col">
                      City
                    </th>
                    <th className={"hide-s"} scope="col">
                      Date Created
                    </th>
                  </tr>
                </thead>
                <tbody>{outletList}</tbody>
              </table>
            </div>
          )}
        </div>
      </Fragment>
    );
  } else {
    return <LoadingComponent inverted={true} />;
  }
};

export default merchantOutlets;

// export default compose(
//   firestoreConnect(props => [
//     {
//       collection: "merchants",
//       storeAs: "outlets",
//       doc: props.account,
//       subcollections: [{ collection: "outlets" }]
//     }
//   ]),
//   connect(({ firestore: { ordered } }, props) => ({
//     outlets: ordered.outlets
//   }))
// )(merchantOutlets);
