import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./index.module.css";
import Transactions from "./Transactions";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    const { match } = this.props;

    let transactions = <Transactions account={match.params.account} />;

    const { merchant, outlets, profile } = this.props;

    const userRole = profile.role;
    const userType = profile.userType;

    const merchantsLink = `/merchants`;
    const selectedMerchantLink = `/merchants/${match.params.account}`;
    const transactionsReportLink = `/merchants/${match.params.account}/reports`;

    return (
      <Fragment>
        <div className={classes.Container}>
          {/* <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Transactions</span>

              <br />
              <span className={classes.ByLine}>
                You are in the Transactions panel
              </span>
            </div>
          </div> */}

          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={merchantsLink}>Home</Link>
                {" - "}
                <Link to={selectedMerchantLink}>{merchant[0].name}</Link>
                {" - "}
                <Link to={transactionsReportLink}>Reports</Link> {" - "}{" "}
                Transactions
              </span>
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Reports panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Merchant Transactions</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>{transactions}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  merchant: state.firestore.ordered.merchant,
  outlets: state.firestore.ordered.outlets,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(Index);

// export default Index;
