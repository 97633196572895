import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./MerchantListItem.module.css";

const merchantListItem = props => {
  let { merchant } = props;

  return (
    <Fragment>
      <tr className={classes.MemberRow}>
        <td>
          <span className={classes.MemberName}>
            <Link to={`/merchants/${merchant.account}`}> {merchant.name}</Link>
          </span>
        </td>
        <td>{merchant.account}</td>
        <td>{merchant.contact.telephone}</td>
        <td>{merchant.group}</td>
      </tr>
    </Fragment>
  );
};

export default merchantListItem;
