import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import classes from "./details.module.css";
import Tabs from "../../../../../../app/UI/Tabs/Tabs";
const dom = require("xmldom").DOMParser;

const detail = (props) => {
  let { transaction } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  let chunkPin = (pin) => {
    if (/[a-zA-Z]/.test(pin)) {
      return pin;
    } else {
      let pinArray = pin.match(/.{1,4}/g);

      let chunkedPin = "";

      pinArray.forEach((element) => {
        chunkedPin = chunkedPin + ` ${element}`;
      });

      return chunkedPin;
    }
  };

  const transactionDetail = (transaction) => {
    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Electricity"
    ) {
      const response = transaction.response;
      // let position = response.search("stsCipher") + 10;

      // let end = position + 20;
      // let result = response.substring(position, end);
      console.log("trans", transaction);

      console.log("response", response);
      let ststToken;
      let changeToken1;
      let changeToken2;
      let xmlDoc = new dom().parseFromString(transaction.response, "text/xml");
      let creditVendResp =
        transaction.response !== undefined &&
        xmlDoc.getElementsByTagName("creditVendResp")[0] !== undefined
          ? xmlDoc.getElementsByTagName("creditVendResp")[0]
          : undefined;

      let faultResponse =
        transaction.response !== undefined &&
        xmlDoc.getElementsByTagName("XMLVendFaultResp")[0] !== undefined
          ? xmlDoc.getElementsByTagName("XMLVendFaultResp")[0]
          : undefined;

      let lastAdviceResponse =
        transaction.response !== undefined &&
        xmlDoc.getElementsByTagName("adviceResp")[0] !== undefined
          ? xmlDoc.getElementsByTagName("adviceResp")[0]
          : undefined;
      if (creditVendResp) {
        let creditVendReceipt =
          creditVendResp.getElementsByTagName("creditVendReceipt")[0];
        let transactions =
          creditVendResp.getElementsByTagName("transactions")[0];

        let txs = transactions.getElementsByTagName("tx");

        let creditVendTx;
        let creditVendTxIndex;

        for (var i = 0; i < txs.length; i++) {
          let tx = txs[i];

          if (tx.getAttribute("xsi:type") === "CreditVendTx") {
            if (tx.hasAttribute("receiptNo")) {
              creditVendTx = tx;
              creditVendTxIndex = i + 1;
            }
          }
        }
        let stsCipherElement =
          creditVendReceipt.getElementsByTagName(`q3:stsCipher`)[0];
        let stsCiphertoken1Element =
          creditVendReceipt.getElementsByTagName(`q1:stsCipher`)[0];

        let stsCiphertoken2Element =
          creditVendReceipt.getElementsByTagName(`q2:stsCipher`)[0];

        ststToken = stsCipherElement
          ? stsCipherElement.firstChild.data
          : undefined;
        changeToken1 = stsCiphertoken1Element
          ? stsCiphertoken1Element.firstChild.data
          : undefined;
        changeToken2 = stsCiphertoken2Element
          ? stsCiphertoken2Element.firstChild.data
          : undefined;
      }

      if (faultResponse) {
        let stsCipherElement =
          faultResponse.getElementsByTagName(`operatorMsg`)[0];

        ststToken = stsCipherElement
          ? stsCipherElement.firstChild.data
          : undefined;
      }
      if (lastAdviceResponse) {
        let stsCipherElements = lastAdviceResponse.getElementsByTagName(
          `stsCipher`
        )
          ? lastAdviceResponse.getElementsByTagName(`stsCipher`)[0]
          : lastAdviceResponse.getElementsByTagName(`operatorMsg`)[0];
        // console.log(
        //   "tid lastadvice",
        //   lastAdviceResponse.getElementsByTagName(`stsCipher`)
        // );

        if (lastAdviceResponse.getElementsByTagName(`stsCipher`)[1]) {
          changeToken1 = lastAdviceResponse.getElementsByTagName(`stsCipher`)[1]
            .firstChild.data
            ? lastAdviceResponse.getElementsByTagName(`stsCipher`)[1].firstChild
                .data
            : undefined;
          changeToken2 = lastAdviceResponse.getElementsByTagName(`stsCipher`)[2]
            .firstChild.data
            ? lastAdviceResponse.getElementsByTagName(`stsCipher`)[2].firstChild
                .data
            : undefined;
          ststToken = lastAdviceResponse.getElementsByTagName(`stsCipher`)[0]
            ? lastAdviceResponse.getElementsByTagName(`stsCipher`)[0].firstChild
                .data
            : undefined;
        } else {
          ststToken = stsCipherElements
            ? stsCipherElements.firstChild.data
            : undefined;
        }
      }

      if (transaction.response == undefined) {
        ststToken = "Run last advice to resolve transaction result";
      }

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Recharge Number
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {ststToken !== undefined
                  ? chunkPin(ststToken)
                  : changeToken1 !== undefined
                  ? chunkPin(changeToken1)
                  : ""}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Key Change Token 1
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {changeToken1 && ststToken ? chunkPin(changeToken1) : ""}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Key Change Token 2
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {changeToken1 && ststToken ? chunkPin(changeToken2) : ""}
              </h5>
            </div>
          </div>
        </>
      );
    }

    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Airtime" &&
      transaction.transactionStatus === "Successful"
    ) {
      const response = transaction.response;

      let pin = response.voucherPinNumber;
      let serialNo = response.voucherSerialNumber
        ? response.voucherSerialNumber
        : 0;
      let reference = response.transaction_reference
        ? response.transaction_reference
        : "None";

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Recharge Number
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {pin}
              </h5>
            </div>
          </div>

          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Serial Number
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {serialNo}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Seller Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {reference}
              </h5>
            </div>
          </div>
        </>
      );
    }
    console.log("transaction.response", transaction.response);
    if (
      transaction.transactionType === "Sale" &&
      transaction.transactionStatus !== "Successful" &&
      transaction.response &&
      !!transaction.response.transaction_reference
    ) {
      const response = transaction.response
        ? transaction.response
        : { response_message: "An error occured." };
      console.log("transaction.response", transaction.response);

      let name = `${response.customer_name} ${response.customer_surname}`;
      let confirmation_number = response.confirmation_number
        ? response.confirmation_number
        : 0;
      let vendor_reference = response.providerReference
        ? response.providerReference
        : "None";
      let reference = response.transaction_reference
        ? response.transaction_reference
        : "None";

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Customer Name
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {name}
              </h5>
            </div>
          </div>

          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Vendor Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {vendor_reference}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Seller Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {reference}
              </h5>
            </div>
          </div>
        </>
      );
    }
    if (
      transaction.transactionType === "Sale" &&
      transaction.transactionStatus !== "Successful"
    ) {
      const response = transaction.response
        ? transaction.response
        : { response_message: "An error occured." };
      console.log("transaction.response", transaction.response);

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Failure Reason
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {response.response_message}
              </h5>
            </div>
          </div>
        </>
      );
    }

    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Payments" &&
      transaction.saleTypeDetail === "DSTV Payment"
    ) {
      console.log("transaction.response", transaction.response);

      const response = transaction.response;

      let name = `${response.customer_name} ${response.customer_surname}`;
      let confirmation_number = response.confirmation_number
        ? response.confirmation_number
        : 0;
      let vendor_reference = response.providerReference
        ? response.providerReference
        : "None";
      let reference = response.transaction_reference
        ? response.transaction_reference
        : "None";

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Customer Name
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {name}
              </h5>
            </div>
          </div>

          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Vendor Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {vendor_reference}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Seller Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {reference}
              </h5>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        {/* <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Redeem Winning Meter</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            {transaction.isRedeemed === true ? (
              <table className={classes.FuneralSchemeTable}>
                <thead>
                  <tr>
                    <th scope="col">Meter</th>

                    <th className={"hide-s"} scope="col">
                      Amount
                    </th>
                    <th className={"hide-s"} scope="col">
                      Recharge Code{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.MemberRow}>
                    <td>{transaction.meterNumber}</td>
                    <td>{transaction.transactionAmount}</td>
                    <td>
                      <span>{transaction.voucher}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>
                <button
                  className={classes.AddUserBtn}
                  onClick={() => {
                    handleClick();
                  }}
                  disabled={loading}
                  style={{width:'21rem'}}
                >
                 

                  {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <span>Redeem Winning Voucher</span>
                )}


                </button>
              </div>
            )}
          </div>
        </div> */}

        <div className={"card card-default"}>
          {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Users</div>
                  </div> */}
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"card card-transparent"}>
                <div className={"card-body"}>
                  <Tabs>
                    <div label="Transaction Details">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          <div className={"row p-t-10"}>
                            <div className={"col-lg-4"}>
                              <label
                                className={
                                  "control-label all-caps small no-margin hint-text semi-bold"
                                }
                              >
                                Account
                              </label>
                              <h5 className={"no-margin"}>
                                {transaction.account}
                              </h5>
                            </div>
                            <div className={"col-lg-4"}>
                              <div>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Name
                                </label>
                                <h5 className={"no-margin"}>
                                  {transaction.merchantName}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-lg-4"}>
                              <div
                                className={classes.FieldWrapper}
                                onClick={() => this.handleFieldClick("Group")}
                              >
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Outlet
                                </label>
                                <h5 className={"no-margin"}>
                                  {transaction.outletName}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className={"row p-t-10"}>
                            <div className={"col-lg-4"}>
                              <label
                                className={
                                  "control-label all-caps small no-margin hint-text semi-bold"
                                }
                              >
                                Channel
                              </label>
                              <h5 className={"no-margin"}>
                                {transaction.channel
                                  ? transaction.channel
                                  : "PrepaidPlus"}

                                {transaction.deviceContractTransactionType ===
                                "Installment"
                                  ? transaction.deviceContract
                                  : " "}
                              </h5>
                            </div>
                            <div className={"col-lg-4"}>
                              <div>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Created By
                                </label>
                                <h5 className={"no-margin"}>
                                  {transaction.createdBy
                                    ? transaction.createdBy
                                    : "PrepaidPlus"}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className={"row p-t-30"}>
                            {transactionDetail(transaction)}
                          </div>

                          <div className={"wizard-footer padding-20"}>
                            <p
                              className={"small hint-text pull-left no-margin"}
                            />
                            <div className={"pull-right"} />
                            <div className={"clearfix"} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div label="">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          Nothing here...
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {};

export default connect(mapStateToProps, actions)(detail);
