//import liraries
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import classes from "./index.module.css";
import Tabs from "../../app/UI/Tabs/Tabs";
import MerchantList from "./Lists/Merchant";
import DeviceList from "./Lists/Devices";
import CommissionList from "./Lists/Commission";
import CreditList from "./Lists/Credit";
import PettyCash from "./Lists/PettyCash";
import DepositsList from "./Lists/Deposits";
import WaybillList from "./Lists/Courier";
import { firebaseConnect } from "react-redux-firebase";
// create a component
const index = ({ profile }) => {
  const pettyCashApprover = profile.isPettyCashApprover;
  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Authorisations</span>

            <br />
            <span className={classes.ByLine}>
              You are in the Authorisations panel.
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h3>Outstanding Authorisations</h3>
                    </div>
                  </header>

                  <div className={"row"}>
                    <div className={"card card-transparent"}>
                      <div className={"card-body"}>
                        <Tabs>
                          <div label={`Merchants`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Merchants</h4>
                              </div>
                            </header>
                            <MerchantList />
                          </div>
                          <div label="Devices">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Devices</h4>
                              </div>
                            </header>
                            <DeviceList />
                          </div>
                          <div label={`Commissions`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Commissions</h4>
                              </div>
                            </header>
                            <CommissionList />
                          </div>
                          <div label={`Credit`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Credit</h4>
                              </div>
                            </header>
                            <CreditList />
                          </div>
                          {pettyCashApprover ? (
                            <div label={`Petty Cash`}>
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>Petty Cash</h4>
                                </div>
                              </header>
                              <PettyCash />
                            </div>
                          ) : null}

                          <div label={`Deposits`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Deposits</h4>
                              </div>
                            </header>
                            <DepositsList />
                          </div>

                          <div label={`Waybills`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Courier Waybills</h4>
                              </div>
                            </header>
                            <WaybillList />
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
});

const actions = {};

export default connect(mapStateToProps, actions)(firebaseConnect()(index));
