import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import firebase from "../../../../../app/config/firebase";
import { openModal } from "../../../../modals/modalActions";

class Documents extends Component {
  state = {
    loadingInitial: true,
    agreementUrl: "",
    omangUrl: "",
    affidavitUrl: "",
    showModal: false,
    uploading: false,
  };
  kl0;

  componentDidMount() {
    // Retrieve URLs from Cloud Storage
    this.retrieveDocumentUrls();
  }

  retrieveDocumentUrls = async () => {
    const agreementRef = firebase
      .storage()
      .ref()
      .child(
        `${this.props.merchant.account}/${this.props.merchant.account}-Contract.pdf`
      );
    const omangRef = firebase
      .storage()
      .ref()
      .child(
        `${this.props.merchant.account}/${this.props.merchant.account}-Omang.pdf`
      );

    const cipaRef = firebase
      .storage()
      .ref()
      .child(
        `${this.props.merchant.account}/${this.props.merchant.account}-Cipa.pdf`
      );

    const affidavitRef = firebase
      .storage()
      .ref()
      .child(
        `${this.props.merchant.account}/${this.props.merchant.account}-Affidavit.pdf`
      );

    const otherRef = firebase
      .storage()
      .ref()
      .child(
        `${this.props.merchant.account}/${this.props.merchant.account}-Other.pdf`
      );

    try {
      const agreementUrl = await agreementRef.getDownloadURL();

      this.setState({
        agreementUrl,
      });
    } catch (error) {
      var storageRef = firebase
        .storage()
        .ref(`${this.props.merchant.account}/merchantcontract/`);
      let list = await storageRef.listAll();

      if (list.items != 0) {
        for (let index = 0; index < list.items.length; index++) {
          const element = list.items[index];
          let url = await element.getDownloadURL();
          this.setState({
            agreementUrl: url,
          });
        }
      } else {
        this.setState({
          agreementUrl: "#",
        });
      }
    }

    try {
      const omangUrl = await omangRef.getDownloadURL();

      this.setState({
        omangUrl,
      });
    } catch (error) {
      try {
        const omangUrl = await cipaRef.getDownloadURL();

        this.setState({
          omangUrl,
        });
      } catch (error) {
        this.setState({
          omangUrl: "#",
        });
      }
    }

    try {
      const affidavitUrl = await affidavitRef.getDownloadURL();

      this.setState({
        affidavitUrl,
        loadingInitial: false,
      });
    } catch (error) {
      try {
        const affidavitUrl = await otherRef.getDownloadURL();

        this.setState({
          affidavitUrl,
          loadingInitial: false,
        });
      } catch (error) {
        this.setState({
          affidavitUrl: "#",
          loadingInitial: false,
        });
      }
    }
  };

  handleChange = async (event, type) => {
    const _type = event.target.id;
    const file = event.target.files[0];
    console.log(file);
    const { merchant } = this.props;
    const storageRef = firebase.storage().ref();
    const filePath = `${merchant.account}/${merchant.account}-${_type}.pdf`;
    console.log(filePath);
    const fileRef = storageRef.child(filePath);

    this.setState({ loadingInitial: true });

    try {
      await fileRef.put(file);
      const downloadURL = await fileRef.getDownloadURL();

      if (_type == "Affidavit") {
        this.setState({
          affidavitUrl: downloadURL,
          loadingInitial: false,
        });
      } else if (_type == "Omang") {
        this.setState({
          omangUrl: downloadURL,
          loadingInitial: false,
        });
      } else if (_type == "Contract") {
        this.setState({
          agreementUrl: downloadURL,
          loadingInitial: false,
        });
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
      this.setState({ uploading: false });
    }
  };

  render() {
    let attachedClasses = ["form-group-default"];
    const { loadingInitial, agreementUrl, omangUrl, affidavitUrl } = this.state;

    if (loadingInitial) {
      return <p>Loading...</p>;
    }

    return (
      <Fragment>
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <h4>
              Agreement{" "}
              {agreementUrl != "#" ? (
                <a
                  href={agreementUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              ) : null}
              <p>
                <input
                  type="file"
                  accept="application/pdf"
                  name="Contract"
                  id="Contract"
                  className="form-control"
                  required
                  style={{ marginTop: 10, paddingTop: 7, maxWidth: "160px" }}
                  onChange={this.handleChange}
                />{" "}
              </p>
            </h4>
          </div>
          <div className={"col-sm-4"}>
            <h4>
              Omang / Registration{" "}
              {omangUrl != "#" ? (
                <a href={omangUrl} target="_blank" rel="noopener noreferrer">
                  View
                </a>
              ) : null}
              <p>
                <input
                  type="file"
                  accept="application/pdf"
                  name="Omang"
                  id="Omang"
                  className="form-control"
                  required
                  style={{ marginTop: 10, paddingTop: 7, maxWidth: "160px" }}
                  onChange={this.handleChange}
                />
              </p>
            </h4>
          </div>
          <div className={"col-sm-4"}>
            <h4>
              Affidavit / Other{" "}
              {affidavitUrl != "#" ? (
                <a
                  href={affidavitUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View
                </a>
              ) : null}
              <p>
                <input
                  type="file"
                  accept="application/pdf"
                  name="Affidavit"
                  id="Affidavit"
                  className="form-control"
                  required
                  style={{ marginTop: 10, paddingTop: 7, maxWidth: "160px" }}
                  onChange={this.handleChange}
                />
              </p>
            </h4>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.async.loading,
});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(Documents);
