import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";

import {
  
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween
} from "revalidate";
import styles from "./SetRateActive.module.css";
import { setCommissionRateActive } from "../../../settings/settingsActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthBetween(4, 4)({
      message: "Must be 4 numbers"
    })
  )()
});

class SetRateActive extends Component {
  render() {
    const {
      setCommissionRateActive,
      handleSubmit,loading,
      closePanel,
      rateId,
      outletId,
      account
    } = this.props;

    const onFormSubmit = values => {
      setCommissionRateActive(rateId);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Outlet Id: <span className={"semi-bold"}>{outletId}</span>
              </h5>
            
              <br />

              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">


                    <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span>Set Rate Active</span>} 
                
              </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  setCommissionRateActive
};

const mapStateToProps = state => ({
  loading: state.async.loading,
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(SetRateActive)
);
