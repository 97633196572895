import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import classes from "./details.module.css";
import { reconcilePayment } from "../../wucActions";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

//write out invalid records onto an excel
const detail = (props) => {
  let {
    transaction,
    profile,
    reconcilePayment,

    loading,
  } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  let handleReconciliationClick = () => {
    reconcilePayment({
      id: transaction.id,
      reconciledBy: profile.displayName,
    });
  };

  // let handleReverseReconciliationClick = () => {
  //   reversePaymentReconciliation({
  //     id: transaction.id,
  //     reconciledBy: profile.displayName,
  //   });
  // };

  let exportWUCInfo = (transaction) => {
    const fileInfo = {
      bankKey: "287867",
      bankAccountNumber: "62309207109",
      bankStatementNumber: "00001",
      effectiveValueDate: "15.02.23",
      filler: "",
      transactionNarrative: "Catchet PMT123456",
      transactionCode: "7207",
      filler1: "",
      filler2: "",
      contract: transaction.customer.utilContractNo,
      transactionAmount: `${transaction.transactionAmount}+`,
      filler3: "",
      filler4: "",
      transactionPostingDate: "15.02.23",
      filler5: "",
      filler6: "",
      filler7: "",
      filler8: "",
      filler9: "",
      filler10: "",
    };

    // 287867;62309207109;00001;15.02.23;;Catchet PMT123456;7207;;;123456;500.00+;;;15.02.23;;;;;;;

    let now = new Date();

    const fileData = JSON.stringify(Object.values(fileInfo));
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "user-info.txt";
    link.href = url;
    link.click();
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Additional Details</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            <table className={classes.FuneralSchemeTable}>
              <thead>
                <tr>
                  {/* <th scope="col">Customer No</th>
                  <th className={"hide-s"} scope="col">
                    Util Contract No
                  </th> */}
                  <th className={"hide-s"} scope="col">
                    Cellphone
                  </th>
                  <th className={"hide-s"} scope="col">
                    Plot Number
                  </th>
                  <th className={"hide-s"} scope="col">
                    Transaction Narrative
                  </th>
                  <th className={"hide-s"} scope="col">
                    File Name
                  </th>
                  <th scope="col">Payment</th>
                  <th scope="col">Fee </th>
                  <th scope="col">Total</th>
                </tr>
              </thead>

              <tbody>
                <tr className={classes.MemberRow}>
                  {/* <td>{transaction.customer.customerNumber}</td>
                  <td>{transaction.customer.contractNumber}</td> */}
                  <td>{transaction.customer.cellPhone}</td>

                  <td>{transaction.customer.customerPlot}</td>
                  <td>
                    <span>{transaction.transactionNarrative}</span>
                  </td>
                  <td>
                    <span>{transaction.uploadedFileName}</span>
                  </td>

                  <td>{moneyFormat(transaction.paymentAmount)} </td>
                  <td>{moneyFormat(transaction.transactionFee)}</td>
                  <td>{moneyFormat(transaction.transactionAmount)}</td>

                  {/* <td>{moneyFormat(transaction.transactionAmount)}</td> */}
                  {/* <th className={"hide-s"} scope="col">
                  {transaction.customer.customerPlot}
                  </th> */}
                  {/* <td>
                    {transaction.wucFileUploadStatus === "Pending" ? (
                      <button
                        className={classes.AddUserBtn}
                        onClick={() => {
                          handleReconciliationClick();
                        }}
                        disabled={loading}
                        style={{ width: "21rem" }}
                      >
                        {loading ? (
                          <Spinner
                            style={{
                              width: "20px",
                              height: "20px",
                              verticalAlign: "middle",
                            }}
                          />
                        ) : (
                          <span>Upload WUC TXT File</span>
                        )}
                      </button>
                    ) : (
                      <div
                        className={classes.ToolsDataExportIconWrapper}
                        onClick={() => exportWUCInfo(transaction)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                          />
                        </svg>
                      </div>
                    )}
                  </td> */}
                </tr>
                {/* <>
                  <tr className={classes.MemberRow}>
                    <td colSpan={5}>
                      <div>
                        {transaction.wucFileUploadStatus === "Pending" ? (
                          <button
                            className={classes.AddUserBtn}
                            onClick={() => {
                              handleReconciliationClick();
                            }}
                            disabled={loading}
                            style={{ width: "21rem" }}
                          >
                            {loading ? (
                              <Spinner
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  verticalAlign: "middle",
                                }}
                              />
                            ) : (
                              <span>Upload WUC TXT File</span>
                            )}
                          </button>
                        ) : (
                          <div
                            className={classes.ToolsDataExportIconWrapper}
                            onClick={() => exportWUCInfo(transaction)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                </>{" "}
                * */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  reconcilePayment,
};

export default connect(mapStateToProps, actions)(detail);
