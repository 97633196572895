import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import styles from "./ReviewMerchant.module.css";

let reviewMerchant = (props) => {
  const { loading, handleSubmit, previousPage } = props;

  return (
    <form onSubmit={handleSubmit(props.onFormSubmit)}>
      <p className={"large-text semi-bold m-t-0"} style={{ color: "#909090" }}>
        <strong>Merchant Details</strong>
      </p>
      <div className={"row clearfix"}>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant Name</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantName}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant ID </p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantId}</h6>
        </div>

        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant City</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantCity}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant Surburb</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantSuburb}</h6>
        </div>
      </div>
      <br />
      <div className={"row clearfix"}>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Name</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchant_contact_name}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Email</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchant_contact_email}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Tel.</p>
          <h6 className={"semi-bold m-t-0"}>
            {props.merchant_contact_telephone}
          </h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant Group</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantGroup}</h6>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant Agreement</p>
          <h6 className={"semi-bold m-t-0"}>{props.fileName}</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Merchant Registration</p>
          <h6 className={"semi-bold m-t-0"}>{props.omangFileName}</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Other documents</p>
          <h6 className={"semi-bold m-t-0"}>{props.affidavitFileName}</h6>
        </div>
      </div>
      <hr />

      <div className={"row clearfix m-t-5"}>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>beMobile</p>
          <h6 className={"semi-bold m-t-0"}>{props.airtime_bemobile}%</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Mascom</p>
          <h6 className={"semi-bold m-t-0"}>{props.airtime_mascom}%</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Orange</p>
          <h6 className={"semi-bold m-t-0"}>{props.airtime_orange}%</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Electricity</p>
          <h6 className={"semi-bold m-t-0"}>{props.electricity_bpc}%</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Commission Frequency</p>
          <h6 className={"semi-bold m-t-0"}>
            {props.commission_frequency.value}
          </h6>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>BPC</p>
          <h6 className={"semi-bold m-t-0"}>{props.bill_BPC}%</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>DSTV</p>
          <h6 className={"semi-bold m-t-0"}>{props.bill_DSTV}%</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>WUC</p>
          <h6 className={"semi-bold m-t-0"}>P{props.bill_WUC}</h6>
        </div>
        <div className={"col-sm-2 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>BTCL</p>
          <h6 className={"semi-bold m-t-0"}>{props.bill_BTCL}%</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Botswana Life</p>
          <h6 className={"semi-bold m-t-0"}>
            {props.collections_botswanalife}%
          </h6>
        </div>
      </div>
      <hr />

      <div className={"row clearfix m-t-5"}>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Outlet Name</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantName}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Outlet Type</p>
          <h6 className={"semi-bold m-t-0"}>Informal Trader</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Outlet City</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantCity}</h6>
        </div>
        <div className={"col-sm-3 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Outlet Surburb</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchantSuburb}</h6>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Name</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchant_contact_name}</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Email</p>
          <h6 className={"semi-bold m-t-0"}>{props.merchant_contact_email}</h6>
        </div>
        <div className={"col-sm-4 col-sm-height sm-no-padding"}>
          <p className={"small no-margin"}>Contact Tel.</p>
          <h6 className={"semi-bold m-t-0"}>
            {props.merchant_contact_telephone}
          </h6>
        </div>
      </div>

      <br />
      <div
        className={"p-t-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix"}
      >
        <ul className={"pager wizard no-style"}>
          <li className="next">
            {/* <button
              className={"btn btn-primary btn-cons pull-right"}
              type="submit"
            >
              <span>Submit</span>
            </button> */}

            <button className={styles.btn} type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>Submit</span>
              )}
            </button>
          </li>
          <li className={"previous"}>
            <button
              className={"btn btn-default btn-cons pull-right"}
              type="button"
              onClick={previousPage}
            >
              <span>Previous</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
});

reviewMerchant = compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "merchantForm", //                 <------ same form name
    destroyOnUnmount: false, //        <------ preserve form data
    forceUnregisterOnUnmount: true,
  })
)(reviewMerchant);

const selector = formValueSelector("merchantForm"); // <-- same as form name
reviewMerchant = connect((state) =>
  selector(
    state,
    "merchantName",
    "merchantId",
    "merchantAccount",
    "merchantGroup",
    "merchantClientId",
    "merchantSellerId",
    "merchantUserId",
    "merchantCity",
    "merchantSuburb",
    "merchant_contact_name",
    "merchant_contact_email",
    "merchant_contact_telephone",
    "merchant_outlet_outletId",
    "merchant_outlet_name",
    "merchant_outlet_type",
    "merchant_outlet_city",
    "merchant_outlet_suburb",
    "merchant_outlet_contact_name",
    "merchant_outlet_contact_email",
    "merchant_outlet_contact_telephone",
    "airtime_bemobile",
    "airtime_mascom",
    "airtime_orange",
    "electricity_bpc",
    "bill_BPC",
    "bill_DSTV",
    "bill_WUC",
    "bill_BTCL",
    "collections_botswanalife",
    "commission_frequency"
  )
)(reviewMerchant);

export default reviewMerchant;
