import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import toMomentObject from "../../app/common/util/dateFormat";
import {
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
} from "./reconsActions";
import { openModal } from "../modals/modalActions";

import classes from "./index.module.css";
import LoadingComponent from "../../app/layout/LoadingComponent";
import Transactions from "./transactions";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    let transactions = <Transactions />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Recons</span>

              <br />
              <span className={classes.ByLine}>
                You are in the <strong>Recons</strong> panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Recons</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>{transactions}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Index;
