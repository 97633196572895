import { createReducer } from "../../app/common/util/reducerUtil";
import { FETCH_TRANSACTIONS } from "./transactionsConstants";
import { FETCH_TRANSACTION_SUMMARIES } from "./transactionsConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  // console.log('payload',payload)

  return payload.transactions;
};


export const getTransactionsSummaryForDashboard = (state, payload) => {
  // console.log('payload',payload)

  return payload.transactions;
};

export default createReducer(initialState, {
  [FETCH_TRANSACTIONS]: getTransactionsForDashboard,
  [FETCH_TRANSACTION_SUMMARIES]: getTransactionsSummaryForDashboard
});


