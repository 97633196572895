import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";

import classes from "./Toolbar.module.css";
import SignedInMenu from "../menus/SignedInMenu";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as Hamburger } from "../../../assets/hamburger.svg";
import SignedOutMenu from "../menus/SignedOutMenu";

// const actions = {
//   openModal
// }

const mapState = state => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile
});

class Toolbar extends Component {
  // state = {
  //   authenticated: false
  // };

  handleSignIn = () => {
    this.setState({
      authenticated: true
    });
  };

  handleSignOut = () => {
    this.setState({
      authenticated: false
    });
  };

  handleSignOut = () => {
    this.props.firebase.logout();
    this.props.history.push("/");
  };

  render() {
    const { auth, profile } = this.props;
    const authenticated = auth.isLoaded && !auth.isEmpty;
    const logo = () => {
      return (
        <Link to="/" className={classes.AppLogo}>
          DATA
          <span className={classes.Bold}>MANAGER</span>
        </Link>
      );
    };

    // const { authenticated } = this.state;
    return (
      <header className={classes.Toolbar} role="banner">
        <section className={classes.ToolbarLogoSection}>
          <div className={classes.ToolbarHamburgerWrapper}>
            <Hamburger />
          </div>
          <Link to="/">
            <Logo className={classes.Logo} />
          </Link>
        </section>
        {/* 
        <section className={classes.Notifications}>
          <div className={classes.NotificationsWrapper}>
            <span className={classes.TimeContainer}>
              <span className={classes.Time}>
                <span className={classes.TimeHours}>19</span>
                <span className={classes.TimeSemicolon}>:</span>
                <span className={classes.TimeMinutes}>40</span>
                <span className={classes.TimeAmPm}>Thurday</span> 
              <span className={classes.TimemanRightSide}>
                <span className={classes.TimemanInfo} id="timeman-info">
                  <span
                    className={classes.TimemanTasks}
                    id="timeman-tasks"
                    style={{ display: "block" }}
                  >
                    0
                  </span>
                </span>
                <span
                  className={classes.TimemanTaskTime}
                  id="timeman-task-time"
                  style={{ display: "none" }}
                >
                  <i />
                  <span id="timeman-task-timer" />
                </span>
              </span>  </span>
            
              <span className={classes.TimemanBackground} />
           
            </span>
          </div>
        </section> */}

        <section className={classes.UserControls}>
          {authenticated ? (
            <SignedInMenu
              auth={auth}
              profile={profile}
              signOut={this.handleSignOut}
            />
          ) : (
            <SignedOutMenu signIn={this.handleSignIn} />
          )}
        </section>
      </header>
    );
  }
}

export default withRouter(withFirebase(connect(mapState)(Toolbar)));
