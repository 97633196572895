import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import RegisterWUCUserForm from "../auth/RegisterWUC/RegisterWUCUserForm";

const actions = {
  closeModal,
};

const registerWUCUserModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create WUC User" onClose={closeModal}>
      <RegisterWUCUserForm />
    </Modal>
  );
};

export default connect(null, actions)(registerWUCUserModal);
