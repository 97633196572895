import React, { useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  matchesPattern,
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { createAdjustmentDeposit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

// const isANumber = matchesPattern(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)(
//   "adjustment_amount"
// );

const validate = combineValidators({
  adjustment_amount: composeValidators(
    customIsRequired,
    matchesPattern(/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/)({
      message: "Must be a number",
    })
  )(),
  adjustment_reason: composeValidators(customIsRequired)(),
});

const merchantAdjustmentForm = ({
  initialValues,
  handleSubmit,
  createAdjustmentDeposit,
  error,
}) => {
  const { account, merchantName } = initialValues;
  let [step, setStep] = useState(1);

  let [transferAmount, setAdjustmentAmount] = useState(0);
  let [transferReason, setAdjustmentReason] = useState(null);

  const onFormSubmit = async (values) => {
    console.log("values", values);

    if (step === 1) {
      setAdjustmentAmount(values.adjustment_amount);
      setAdjustmentReason(values.adjustment_reason);
      setStep(2);
    } else if (step === 2) {
      console.log("Step 2");
      console.log("Step submitting");
      await createAdjustmentDeposit({
        values,
      });

      // setStep(1);
    }
  };

  const clearFormSubmit = async () => {
    if (step === 1) {
      return;
    } else if (step === 2) {
      setStep(1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {step === 1 ? (
        <>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Merchant
              </div>
              <strong>
                {account} - {merchantName}
              </strong>
            </div>

            <div className={"col-sm-4"}>
              <Field
                label="Adjustment Amount"
                name="adjustment_amount"
                component={TextInput}
                type="text"
                className={"form-control"}
                placeholder="Amount"
                required="required"
              />
            </div>
          </div>

          <br />
          <div className={"row"} style={{ lineHeight: "3.8rem" }}>
            <div className={"col-md-12"}>
              <Field
                label="Adjustment Reason"
                name="adjustment_reason"
                component={TextArea}
                type="text"
                className={"form-control"}
                placeholder="Reason for Adjustment"
                required="required"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Merchant
              </div>
              <strong>
                {account} - {merchantName}
              </strong>
            </div>

            <div className={"col-sm-4"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Adjustment Amount
              </div>
              <strong>{transferAmount}</strong>
            </div>
          </div>

          <br />
          <div className={"row"} style={{ lineHeight: "3.8rem" }}>
            <div className={"col-md-12"}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Adjustment Reason
              </div>
              <strong>{transferReason}</strong>
            </div>
          </div>
        </>
      )}
      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      <button className={"btn btn-primary btn-cons m-t-10"}>
        {step === 1 ? "Confirm Details" : "Merchant Adjustment"}
      </button>
      {step === 2 && (
        <button
          onClick={() => clearFormSubmit()}
          className={"btn btn-danger btn-cons m-t-10"}
        >
          Cancel
        </button>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    account: state.modals.modalProps.account,
    merchantName: state.modals.modalProps.merchantName,
    transactionCategory: "Credit",
    transactionType: "Deposit",
    depositType: "Merchant Balance Adjustment",
    isTransactionCharged: false,
  },
  enableReinitialize: true,
});

const actions = {
  createAdjustmentDeposit,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "adjustmentDepositForm", validate })(merchantAdjustmentForm)
);
