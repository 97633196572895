import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";

import classes from "./index.module.css";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

import { uploadWUCTXTFile } from "../../wucActions";
// import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";

import Detail from "./detail";

const wucListItem = (props) => {
  let { transaction, profile } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  let exportWUCInfo = (transaction) => {
    // effectiveValueDate = format(transaction.transactionDateTime.toDate(),"YYYY-MM-DD  HH:mm:ss");

    const fileInfo = {
      bankKey: "287867",
      bankAccountNumber: "62309207109",
      bankStatementNumber: "00001",
      effectiveValueDate: format(
        transaction.transactionDateTime.toDate(),
        "DD.MM.YY"
      ),
      filler: "",
      transactionNarrative: transaction.transactionNarrative,
      transactionCode: "7207",
      filler1: "",
      filler2: "",
      contract: transaction.customer.contractNumber,
      transactionAmount: `${transaction.transactionAmount}+`,
      filler3: "",
      filler4: "",
      transactionPostingDate: format(
        transaction.transactionDateTime.toDate(),
        "DD.MM.YY"
      ),
      filler5: "",
      filler6: "",
      filler7: "",
      filler8: "",
      filler9: "",
      filler10: "",
    };

    const fileData = Object.values(fileInfo).join(";");

    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${format(
      transaction.transactionDateTime.toDate(),
      "DD.MM.YY.HH.mm.ss"
    )}-${transaction.transactionNarrative.slice(-6)}.txt`;
    link.href = url;
    link.click();
  };

  let uploadWUCTXTFileClick = () => {
    uploadWUCTXTFile({
      id: transaction.id,
      reconciledBy: profile.displayName,
    });
  };

  return (
    <Fragment>
      {/* <tr className={classes.MemberRow} onClick={() => handleClick()}> */}
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>{transaction.clientSaleId}</td>
        <td>{transaction.customer.customerNumber}</td>
        <td>{transaction.customer.contractNumber}</td>
        <td>{transaction.customer.firstName}</td>
        <td>{transaction.customer.lastName}</td>
        <td>{moneyFormat(transaction.paymentAmount)}</td>
        {/* <td>{moneyFormat(transaction.transactionFee)}</td>
        <td>{moneyFormat(transaction.transactionAmount)}</td> */}

        {/* <td>
          <div
            className={classes.ToolsDataExportIconWrapper}
            onClick={() => exportWUCInfo(transaction)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
              />
            </svg>
          </div>
        </td> */}
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="7">
            <Detail transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

// export default wucListItem;

const mapStateToProps = (state) => ({
  loading: state.async.loading,

  profile: state.firebase.profile,
});

const actions = {
  uploadWUCTXTFile,
};

export default connect(mapStateToProps, actions)(wucListItem);
