import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import Tabs from "../../../../app/UI/Tabs/Tabs";
import { createNewExcelFileWithTabs } from "../../../../app/excel/excel";
import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";

import { getDepositsByDepositDate } from "../../financialActions";

class Index extends Component {
  state = {
    openAdditionalInfoPanel: false,
    selectedDeposit: null,
  };

  handleDepositClick = (deposit) => {
    this.setState({
      openAdditionalInfoPanel: true,
      selectedDeposit: null,
    });
  };

  handleCloseClick = () => {
    this.setState({
      activateMerchantPanelOpen: false,
      selectedMerchant: null,
    });
  };

  handleSalesReport = (deposits, depositsTotal) => {
    let reportData = [];

    reportData = deposits.map((record) => {
      return [
        {
          value: record.saleType,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } },
          },
        },
        {
          value: record.provider,
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } },
          },
        },
        {
          value: formatMoney(record.transactionAmount),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } },
          },
        },
      ];
    });

    reportData.push([
      {
        value: "",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
        },
      },
      {
        value: "Total",
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "00FFFFFF" } },
        },
      },
      {
        value: formatMoney(depositsTotal),
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "920202" } },
          font: { sz: "12", bold: true, color: { rgb: "FFFFFF" } },
        },
      },
    ]);

    let reportDataSet = [
      {
        columns: ["Type", "Provider", "Amount"],
        data: reportData,
      },
    ];

    return reportDataSet;
  };

  render() {
    let {
      depositsList,
      depositsTotal,
      depositsByDepositDateList,
      depositsByDepositDateTotal,
      initialValues,
      //  reportStartDate,
      //  reportEndDate,
    } = this.props;

    // let start_Date={startDate}

    console.log("depositsList", depositsList);
    console.log("depositsByDepositDateList", depositsByDepositDateList);
    console.log("start date", initialValues);

    const deposits =
      depositsList &&
      Object.values(depositsList).map((deposit, index) => {
        return (
          <tr className={classes.MerchantRow} key={index}>
            <td>{deposit.createdBy}</td>
            <td>
              {format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}
            </td>
            <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
            <td>{deposit.bank}</td>
            <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
            <td>{formatMoney(deposit.transactionAmount)}</td>
          </tr>
        );
      });

    // const fnbDepositsByDepositDateList =
    // depositsByDepositDateList &&
    // Object.values(depositsByDepositDateList)
    //   .filter((i) => i.bank === "First National Bank")
    //   .map((deposit, index) => {

    //   return  deposit;

    //   });

    //       const absaDepositsByDepositDateList =
    //       depositsByDepositDateList &&
    //       Object.values(depositsByDepositDateList)
    //         .filter((i) => i.bank === "ABSA")
    //         .map((deposit, index) => {
    //           return deposit;
    //         });

    // console.log(fnbDepositsByDepositDateList);

    const fnbDepositsByDepositDate =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "First National Bank")
        .map((deposit, index) => {
          return (
            <tr className={classes.MerchantRow} key={index}>
              <td>{deposit.createdBy}</td>
              <td>
                {format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}
              </td>
              <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
              <td>{deposit.bank}</td>
              <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
              <td>{formatMoney(deposit.transactionAmount)}</td>
            </tr>
          );
        });
    const stanbicDepositsByDepositDate =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "Stanbic Bank")
        .map((deposit, index) => {
          return (
            <tr className={classes.MerchantRow} key={index}>
              <td>{deposit.createdBy}</td>
              <td>
                {format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}
              </td>
              <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
              <td>{deposit.bank}</td>
              <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
              <td>{formatMoney(deposit.transactionAmount)}</td>
            </tr>
          );
        });

    let generateDepositsTotalsCell = (
      worksheet,
      columnLetter,
      totalDataRows
    ) => {
      const firstDataRow = 9;
      const lastDataRow = firstDataRow + totalDataRows - 1;

      const firstCellReference = `${columnLetter}${firstDataRow}`;
      const lastCellReference = `${columnLetter}${lastDataRow}`;
      const sumRange = `${firstCellReference}:${lastCellReference}`;

      return {
        formula: `SUM(${sumRange})`,
      };
    };

    const fnbDepositsByDepositDateTotal =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "First National Bank")
        .map((deposit, index) => {
          return deposit.transactionAmount;
        })
        .reduce((total, amount) => {
          return total + amount;
        }, 0);

    const stanbicDepositsByDepositDateTotal =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "Stanbic Bank")
        .map((deposit, index) => {
          return deposit.transactionAmount;
        })
        .reduce((total, amount) => {
          return total + amount;
        }, 0);

    const absaDepositsByDepositDate =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "ABSA")
        .map((deposit, index) => {
          return (
            <tr className={classes.MerchantRow} key={index}>
              <td>{deposit.createdBy}</td>
              <td>
                {format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}
              </td>
              <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
              <td>{deposit.bank}</td>
              <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
              <td>{formatMoney(deposit.transactionAmount)}</td>
            </tr>
          );
        });

    const absaDepositsByDepositDateTotal =
      depositsByDepositDateList &&
      Object.values(depositsByDepositDateList)
        .filter((i) => i.bank === "ABSA")
        .map((deposit, index) => {
          return deposit.transactionAmount;
        })
        .reduce((total, amount) => {
          return total + amount;
        }, 0);

    const FNBdepositsListSheet = async (
      depositsByDepositDateList,
      workbook,
      logo
    ) => {
      // let merchants = [];

      const fnbDepositsByDepositDateList =
        depositsByDepositDateList &&
        Object.values(depositsByDepositDateList)
          .filter((i) => i.bank === "First National Bank")
          .map((deposit, index) => {
            return deposit;
          });

      let worksheet = workbook.addWorksheet("FNBB Deposits By Deposit Date", {
        properties: {
          showGridLines: false,
        },
        pageSetup: {
          paperSize: 9,
          fitToPage: true,
          orientation: "portrait",
        },
        views: [
          {
            state: "frozen",
            xSplit: 7,
            activeCell: "A1",
            showGridLines: false,
          },
        ],
      });

      worksheet.mergeCells("A1", "G2");

      worksheet.mergeCells("A3", "G3");

      worksheet.mergeCells("A4", "G4");
      worksheet.getCell("A4").value = `FNBB Deposits Transaction List`;
      worksheet.getRow(4).font = {
        name: "Calibri",
        family: 4,
        size: 23,
        bold: true,
      };

      worksheet.mergeCells("A5", "G5");

      let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

      worksheet.mergeCells("A6", "G6");

      worksheet.getCell("A6").value = {
        richText: [
          {
            font: {
              size: 12,
              color: {
                argb: "FF000000",
              },
              name: "Verdana",
              family: 4,
              scheme: "minor",
            },
            text: "Date generated: ",
          },
          {
            font: {
              size: 12,
              color: {
                argb: "FF000000",
              },
              bold: true,
              name: "Verdana",
              family: 4,
              scheme: "minor",
            },
            text: `${now}`,
          },
        ],
      };

      // insert an image over B2:D6
      worksheet.addImage(logo, {
        tl: {
          col: 0.25,
          row: 0.25,
        },
        ext: {
          width: 150,
          height: 32,
        },
      });

      // adjust pageSetup settings afterwards
      worksheet.pageSetup.margins = {
        left: 0.7,
        right: 0.7,
        top: 0.75,
        bottom: 0.75,
        header: 0.3,
        footer: 0.3,
      };

      let headers = worksheet.getRow(8);
      headers.height = 25;
      headers.values = [
        "Transaction Date-Time",
        "Account",
        "Bank",
        "Date Of Deposit",
        "Amount",
      ];
      headers.alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      headers.eachCell((cell) => {
        cell.font = {
          name: "Calibri",
          family: 4,
          size: 15,
          color: {
            argb: "FFFFFFFF",
          },
          bold: true,
        };
        cell.border = {
          bottom: {
            style: "thick",
          },
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF002060",
          },
        };
      });

      worksheet.columns = [
        {
          key: "transactionDateTime",
          width: 35,
        },
        {
          key: "account",
          width: 25,
        },

        {
          key: "bank",
          width: 20,
        },
        {
          key: "dateOfDeposit",
          width: 30,
        },
        {
          key: "transactionAmount",
          width: 18,
        },
      ];

      fnbDepositsByDepositDateList.forEach((doc, index) => {
        worksheet
          .addRow({
            transactionDateTime: format(
              doc.transactionDateTime,
              "YYYY-MM-DD HH:mm:ss"
            ),
            account: doc.account + " - " + doc.merchantName,
            bank: doc.bank,
            dateOfDeposit: format(doc.dateOfDeposit, "YYYY-MM-DD"),
            transactionAmount: parseFloat(doc.transactionAmount),
            depositsByDepositDateTotal: depositsByDepositDateTotal,
          })
          .commit();

        // let rowNum = index + 9;
        // let row = worksheet.getRow(rowNum);
        // row.outlineLevel = 2;
      });

      let column7 = worksheet.getColumn(7);
      // column7.alignment = {
      //     vertical: 'middle',
      //     horizontal: 'right'
      // };
      column7.width = 15;

      let total = generateDepositsTotalsCell(
        worksheet,
        "E",
        fnbDepositsByDepositDateList.length
      );

      const totalsRow = worksheet.addRow([
        "",
        "",
        "",
        "Deposits Totals",
        total,
      ]);
      worksheet.getColumn(5).numFmt = "0.00";

      totalsRow.height = 25;
      totalsRow.alignment = {
        vertical: "middle",
        horizontal: "right",
      };

      totalsRow.eachCell((cell) => {
        cell.font = {
          name: "Calibri",
          family: 4,
          size: 15,
          color: {
            argb: "FFFFFFFF",
          },
          bold: true,
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF002060",
          },
        };
      });
    };

    const ABSAdepositsListSheet = async (
      depositsByDepositDateList,
      workbook,
      logo
    ) => {
      // let merchants = [];

      const absaDepositsByDepositDateList =
        depositsByDepositDateList &&
        Object.values(depositsByDepositDateList)
          .filter((i) => i.bank === "ABSA")
          .map((deposit, index) => {
            return deposit;
          });

      let worksheet = workbook.addWorksheet("ABSA Deposits By Deposit Date", {
        properties: {
          showGridLines: false,
        },
        pageSetup: {
          paperSize: 9,
          fitToPage: true,
          orientation: "portrait",
        },
        views: [
          {
            state: "frozen",
            xSplit: 7,
            activeCell: "A1",
            showGridLines: false,
          },
        ],
      });

      worksheet.mergeCells("A1", "G2");

      worksheet.mergeCells("A3", "G3");

      worksheet.mergeCells("A4", "G4");
      worksheet.getCell("A4").value = `ABSA Deposits Transaction List`;
      worksheet.getRow(4).font = {
        name: "Calibri",
        family: 4,
        size: 23,
        bold: true,
      };

      worksheet.mergeCells("A5", "G5");

      let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

      worksheet.mergeCells("A6", "G6");

      worksheet.getCell("A6").value = {
        richText: [
          {
            font: {
              size: 12,
              color: {
                argb: "FF000000",
              },
              name: "Verdana",
              family: 4,
              scheme: "minor",
            },
            text: "Date generated: ",
          },
          {
            font: {
              size: 12,
              color: {
                argb: "FF000000",
              },
              bold: true,
              name: "Verdana",
              family: 4,
              scheme: "minor",
            },
            text: `${now}`,
          },
        ],
      };

      // insert an image over B2:D6
      worksheet.addImage(logo, {
        tl: {
          col: 0.25,
          row: 0.25,
        },
        ext: {
          width: 150,
          height: 32,
        },
      });

      // adjust pageSetup settings afterwards
      worksheet.pageSetup.margins = {
        left: 0.7,
        right: 0.7,
        top: 0.75,
        bottom: 0.75,
        header: 0.3,
        footer: 0.3,
      };

      let headers = worksheet.getRow(8);
      headers.height = 25;
      headers.values = [
        "Transaction Date-Time",
        "Account",
        "Bank",
        "Date Of Deposit",
        "Amount",
      ];
      headers.alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      headers.eachCell((cell) => {
        cell.font = {
          name: "Calibri",
          family: 4,
          size: 15,
          color: {
            argb: "FFFFFFFF",
          },
          bold: true,
        };
        cell.border = {
          bottom: {
            style: "thick",
          },
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF002060",
          },
        };
      });

      worksheet.columns = [
        {
          key: "transactionDateTime",
          width: 35,
        },
        {
          key: "account",
          width: 25,
        },

        {
          key: "bank",
          width: 20,
        },
        {
          key: "dateOfDeposit",
          width: 30,
        },
        {
          key: "transactionAmount",
          width: 18,
        },
      ];

      absaDepositsByDepositDateList.forEach((doc, index) => {
        worksheet
          .addRow({
            transactionDateTime: format(
              doc.transactionDateTime,
              "YYYY-MM-DD HH:mm:ss"
            ),
            account: doc.account + "-" + doc.merchantName,
            bank: doc.bank,
            dateOfDeposit: format(doc.dateOfDeposit, "YYYY-MM-DD"),
            transactionAmount: parseFloat(doc.transactionAmount),
            depositsByDepositDateTotal: depositsByDepositDateTotal,
          })
          .commit();

        // let rowNum = index + 9;
        // let row = worksheet.getRow(rowNum);
        // row.outlineLevel = 2;
      });

      let column7 = worksheet.getColumn(7);
      // column7.alignment = {
      //     vertical: 'middle',
      //     horizontal: 'right'
      // };
      column7.width = 15;
      worksheet.getColumn(5).numFmt = "0.00";

      let total = generateDepositsTotalsCell(
        worksheet,
        "E",
        absaDepositsByDepositDateList.length
      );

      const totalsRow = worksheet.addRow([
        "",
        "",
        "",
        "Deposits Totals",
        total,
      ]);

      totalsRow.height = 25;
      totalsRow.alignment = {
        vertical: "middle",
        horizontal: "right",
      };

      totalsRow.eachCell((cell) => {
        cell.font = {
          name: "Calibri",
          family: 4,
          size: 15,
          color: {
            argb: "FFFFFFFF",
          },
          bold: true,
        };

        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF002060",
          },
        };
      });
    };

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {deposits && deposits.length === 0 ? (
            <h4>
              There are no <strong>deposits</strong> for the selected period.
            </h4>
          ) : (
            <Fragment>
              <Tabs>
                <div label={`Capture Date`}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        Deposits By Transaction Capture Date - Total:{" "}
                        <span className={classes.MoneyTotal}>
                          {formatMoney(depositsTotal)}
                        </span>
                      </h5>
                    </div>
                  </header>

                  <div className={classes.TableWrapper}>
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">User Name</th>
                          <th scope="col">Transaction Date</th>
                          <th scope="col">Merchant Account </th>
                          <th scope="col">Bank </th>
                          <th scope="col">Deposit Date</th>
                          <th scope="col">Amount Deposited</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposits}
                        <tr className={classes.Totals}>
                          <td colSpan="5">
                            <div className={classes.MoneyTotalLabel}>
                              {" "}
                              Total:
                            </div>
                          </td>
                          <td className={classes.MoneyTotal}>
                            {formatMoney(depositsTotal)}
                          </td>
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div label={`Bank Deposit Date`}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        Deposits By Bank Deposit Date - Total:{" "}
                        <span className={classes.MoneyTotal}>
                          {formatMoney(
                            fnbDepositsByDepositDateTotal +
                              absaDepositsByDepositDateTotal +
                              stanbicDepositsByDepositDateTotal
                          )}
                        </span>
                      </h5>
                    </div>

                    <div
                      className={classes.ExportReport}
                      onClick={
                        () =>
                          createNewExcelFileWithTabs(
                            "BANK DEPOSITS REPORT",
                            depositsByDepositDateList,
                            [FNBdepositsListSheet, ABSAdepositsListSheet]
                          )

                        //ABSAdepositsListSheet
                        //absaDepositsByDepositDateList
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                        focusable="false"
                      >
                        <g>
                          <path
                            id="path1"
                            transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                            fill="#707070"
                            d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                          />
                        </g>
                      </svg>
                    </div>
                  </header>

                  <div className={classes.TableWrapper}>
                    <Tabs>
                      <div label={`First National Bank`}>
                        <header className={classes.TabHeader}>
                          <div className={classes.PageHeader}>
                            <h5>
                              First National Bank - Total:{" "}
                              <span className={classes.MoneyTotal}>
                                {formatMoney(fnbDepositsByDepositDateTotal)}
                              </span>
                            </h5>
                          </div>
                        </header>
                        <div className={classes.TableWrapper}>
                          <table className={classes.MerchantTable}>
                            <thead>
                              <tr>
                                <th scope="col">User Name</th>
                                <th scope="col">Transaction Date</th>
                                <th scope="col">Merchant Account </th>
                                <th scope="col">Bank </th>
                                <th scope="col">Deposit Date</th>
                                <th scope="col">Amount Deposited</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fnbDepositsByDepositDate}
                              <tr className={classes.Totals}>
                                <td colSpan="5">
                                  <div className={classes.MoneyTotalLabel}>
                                    Total:
                                  </div>
                                </td>
                                <td className={classes.MoneyTotal}>
                                  {formatMoney(fnbDepositsByDepositDateTotal)}
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div label={`Stanbic Bank`}>
                        <header className={classes.TabHeader}>
                          <div className={classes.PageHeader}>
                            <h5>
                              Stanbic Bank - Total:{" "}
                              <span className={classes.MoneyTotal}>
                                {formatMoney(stanbicDepositsByDepositDateTotal)}
                              </span>
                            </h5>
                          </div>
                        </header>
                        <div className={classes.TableWrapper}>
                          <table className={classes.MerchantTable}>
                            <thead>
                              <tr>
                                <th scope="col">User Name</th>
                                <th scope="col">Transaction Date</th>
                                <th scope="col">Merchant Account </th>
                                <th scope="col">Bank </th>
                                <th scope="col">Deposit Date</th>
                                <th scope="col">Amount Deposited</th>
                              </tr>
                            </thead>
                            <tbody>
                              {stanbicDepositsByDepositDate}
                              <tr className={classes.Totals}>
                                <td colSpan="5">
                                  <div className={classes.MoneyTotalLabel}>
                                    Total:
                                  </div>
                                </td>
                                <td className={classes.MoneyTotal}>
                                  {formatMoney(
                                    stanbicDepositsByDepositDateTotal
                                  )}
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div label={`ABSA `}>
                        <header className={classes.TabHeader}>
                          <div className={classes.PageHeader}>
                            <h5>
                              ABSA - Total:{" "}
                              <span className={classes.MoneyTotal}>
                                {formatMoney(absaDepositsByDepositDateTotal)}
                              </span>
                            </h5>
                          </div>
                        </header>
                        <div className={classes.TableWrapper}>
                          <table className={classes.MerchantTable}>
                            <thead>
                              <tr>
                                <th scope="col">User Name</th>
                                <th scope="col">Transaction Date</th>
                                <th scope="col">Merchant Account </th>
                                <th scope="col">Bank </th>
                                <th scope="col">Deposit Date</th>
                                <th scope="col">Amount Deposited</th>
                              </tr>
                            </thead>
                            <tbody>
                              {absaDepositsByDepositDate}
                              <tr className={classes.Totals}>
                                <td colSpan="5">
                                  <div className={classes.MoneyTotalLabel}>
                                    {" "}
                                    Total:
                                  </div>
                                </td>
                                <td className={classes.MoneyTotal}>
                                  {formatMoney(absaDepositsByDepositDateTotal)}
                                </td>
                                <td />
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </Tabs>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const actions = {
  getDepositsByDepositDate,
};

const mapStateToProps = (state, props) => ({
  depositsList: state.financials.depositsList,
  depositsTotal: state.financials.depositsTotal,
  depositsByDepositDateList: state.financials.depositsByDepositDateList,
  depositsByDepositDateTotal: state.financials.depositsByDepositDateTotal,

  initialValues: {
    startDate: props.startDate,
    endDate: props.endDate,
  },
});

export default connect(mapStateToProps, actions)(Index);
