import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  createValidator,
} from "revalidate";

import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { createWithdrawal } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const isValidNumber = createValidator(
  (message) => (value) => {
    if (value && !/^[+-]?\d+(\.\d+)?$/i.test(value)) {
      return message;
    }
  },
  "Must be a number"
);

const validate = combineValidators({
  withdrawal_from_bank: composeValidators(customIsRequired)(),
  withdrawal_to_bank: composeValidators(customIsRequired)(),
  withdrawal_to_bank_account: composeValidators(customIsRequired)(),
  // withdrawal_amount: composeValidators(
  //   customIsRequired,
  //   isNumeric({
  //     message: "Must be a number",
  //   })
  // )(),

  withdrawal_amount: composeValidators(
    customIsRequired,
    isValidNumber({
      message: "Must be a number",
    })
  )(),
  withdrawal_date: composeValidators(customIsRequired)(),
});

// const isAlphabetic = matchesPattern(/^[A-Za-z]+$/)('Username');

const $ = window.$;

const withdrawalToBank = [
  {
    label: "Access",
    value: "Access",
  },
  {
    label: "ABSA",
    value: "ABSA",
  },
  {
    label: "First National Bank",
    value: "First National Bank",
  },
  {
    label: "Stanbic Bank",
    value: "Stanbic Bank",
  },
  {
    label: "Standard Charted",
    value: "Standard Charted",
  },
];

const withdrawalFromBank = [
  {
    label: "ABSA",
    value: "ABSA",
  },

  {
    label: "Stanbic Bank",
    value: "Stanbic Bank",
  },
  {
    label: "First National Bank",
    value: "First National Bank",
  },
];

class WithdrawalForm extends Component {
  state = {
    withdrawalDate: null,
  };

  componentDidMount() {
    $(findDOMNode(this.withdrawalDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "0d",
    });
    var _this = this;
    $(findDOMNode(this.withdrawalDate)).on("changeDate", function (e) {
      _this.props.change("withdrawal_date", e.date);
    });
  }

  componentWillUnmount() {
    $(findDOMNode(this.withdrawalDate)).datepicker("destroy");
  }

  render() {
    const { handleSubmit, createWithdrawal, error } = this.props;

    const onFormSubmit = (values) => {
      createWithdrawal({
        values,
      });
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <Field
              name="withdrawal_from_bank"
              options={withdrawalFromBank}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Bank"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-sm-4"}>
            <Field
              name="withdrawal_to_bank"
              options={withdrawalToBank}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Merchant Bank"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-sm-4"}>
            <Field
              label="Withdrawal Account"
              name="withdrawal_to_bank_account"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Account"
              required="required"
            />
          </div>
          {/* <div className={"col-sm-6"}>
            <Field
              name="withdrawal_method"
              options={withdrawalType}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Method"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div> */}
        </div>
        <br />
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <Field
              label="Withdrawal Amount"
              name="withdrawal_amount"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Amount"
              required="required"
            />
          </div>
          <div className={"col-sm-4"}>
            <div className={"input-group date col-md-12 p-l-0"}>
              <Field
                type="text"
                className={"form-control"}
                component="input"
                placeholder="Pick Withdrawal Date"
                name="withdrawal_date"
                ref={(input) => {
                  this.withdrawalDate = input;
                }}
              />

              <div className={"input-group-append"}>
                <span className={"input-group-text"}>
                  <i className={"fa fa-calendar"} />
                </span>
              </div>

              {/* <Field
                type="text"
                className={"form-control"}
                component={renderField}
                placeholder="Pick Withdrawal date"
                name="withdrawal_date"
                ref={input => {
                  this.withdrawalDate = input;
                }}
              /> */}
            </div>
          </div>
        </div>
        <br />
        <div className={"row"} style={{ lineHeight: "3.8rem" }}>
          <div className={"col-md-12"}>
            <Field
              label="Withdrawal Reason"
              name="withdrawal_reason"
              component={TextArea}
              type="text"
              className={"form-control"}
              placeholder="Reason for Withdrawal"
              required="required"
            />
          </div>
        </div>
        <br />
        {error && <label className={"error semi-bold"}>{error}</label>}
        <button className={"btn btn-primary btn-cons m-t-10"}>
          Make A Withdrawal
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {
    account: state.modals.modalProps.account,
    merchantName: state.modals.modalProps.merchantName,
    transactionCategory: "Debit",
    transactionType: "Withdrawal",
    withdrawalType: "Merchant Withdrawal",
    isTransactionCharged: false,
  },
  enableReinitialize: true,
});

const actions = {
  createWithdrawal,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "withdrawalForm", validate })(WithdrawalForm));
