import React, { Component, Fragment } from "react";

import classes from "./index.module.css";
import Transactions from "./transactions";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Tabs from "../../app/UI/Tabs/Tabs";

// {merchant,merchantTransactions}
const pettyCash = ({ profile }) => {
  let transactions = <Transactions />;
  console.log("profile", profile);
  const pettyCashApprover = profile.isPettyCashApprover;
  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Petty Cash</span>
            <br />
            <span className={classes.ByLine}>
              You are in the <strong>Petty Cash</strong> panel
            </span>
          </div>
        </div>
        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h3>Petty Cash</h3>
                    </div>
                  </header>
                  {/* {pettyCashApprover ? (
                    <Tabs>
                      <div label={`Petty Cash`}>
                        <>
                          <div className={"row"}>
                            <div className={"card card-transparent"}>
                              <div className={"card-body"}>{transactions}</div>
                            </div>
                          </div>
                        </>
                      </div>
                      <div label={`transactions`}>
                        <>
                          <header className={classes.TabHeader}>
                            <div className={classes.PageHeader}>
                              <h3>All petty cash transactions</h3>
                            </div>
                          </header>
                          <div className={"row"}>
                            <div className={"card card-transparent"}>
                              <div className={"card-body"}>{transactions}</div>
                            </div>
                          </div>
                        </>
                      </div>
                    </Tabs>
                  ) : ( */}
                  <>
                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>{transactions}</div>
                      </div>
                    </div>
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
});

const actions = {};

// export default connect(null,actions)(Outlet);

// export default Outlet;
export default connect(
  mapStateToProps,
  actions
)(firestoreConnect([{ collection: "pettyCash" }])(pettyCash));
