import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import DepositForm from "../financials/DepositForm/DepositForm";

const actions = { closeModal };

const depositModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Merchant Deposit" onClose={closeModal}>
      <DepositForm />
    </Modal>
  );
};

export default connect(null, actions)(depositModal);
