import React, { Component, Fragment } from "react";

import classes from "./index.module.css";
import Transactions from "./Transactions";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    let transactions = <Transactions />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                Water Utilities Corporation
              </span>

              <br />
              <span className={classes.ByLine}>
                You are in the <strong>Water Utilities Corporation</strong>{" "}
                panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        {/* <h3>Water Utilities Corporation</h3> */}
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>{transactions}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Index;
