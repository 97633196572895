import React, { useState, useEffect, Fragment } from "react";

import classes from "./index.module.css";

import Detail from "./detail";

const inventoryItem = (props) => {
  let { device, deviceContract, outlet, group, merchantName, index } = props;

  console.log("device", device);
  console.log("deviceContract", deviceContract);

  console.log("outlet", outlet);
  console.log("merchantName", merchantName);
  console.log("group", group);

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleDeviceClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <tr
        className={classes.MerchantRow}
        key={index}
        onClick={() => handleDeviceClick(inventoryItem)}
      >
        <td>{device.model}</td>
        <td>{device.network}</td>
        <td>{device.cellNumber}</td>
        <td>{device.isActivated ? "Yes" : "No"}</td>
        <td>
          {device.deliveryStatus ? device.deliveryStatus : "Pending collection"}
        </td>
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="6">
            <Detail
              device={device}
              deviceContract={deviceContract}
              outlet={outlet}
              group={group}
              merchantName={merchantName}
            />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default inventoryItem;
