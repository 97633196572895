import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import CommissionDepositForm from "../financials/CommissionDeposit/CommissionDepositForm";

const actions = { closeModal };

const commissionDepositModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Merchant Commission Deposit" onClose={closeModal}>
      <CommissionDepositForm />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(commissionDepositModal);
