//import liraries
import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

import format from "date-fns/format";
import moment from "moment";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";

// import { getKazangDailySalesForExport } from "../../reportActions";

import LoadingComponent from "../../../../app/layout/LoadingComponent";
// import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";
import classes from "./index.module.css";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const index = ({ match, merchant, openModal }) => {
  let [loading, setLoading] = useState(false);
  // let [startDate, setStartDate] = useState(moment());
  // let [endDate, setEndDate] = useState(moment());
  // let [today, setToday] = useState(moment());
  // const [focusedInput, setFocusedInput] = useState(null);

  let [s_Date, setSDate] = useState(moment().startOf("day"));
  let [e_Date, setEDate] = useState(moment().endOf("day"));
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment().endOf("day"));
  let [startTime, setStartTime] = useState("00:00");
  let [endTime, setEndTime] = useState("23:59");

  const merchantsLink = `/merchants`;
  const selectedMerchantLink = `/merchants/${match.params.account}`;
  const transactionsReportLink = `/merchants/${match.params.account}/reports`;

  // const onFocusChangeRangeHandler = (focusedInput) => {
  //   setFocusedInput(focusedInput);
  // };

  ///////////////

  const handleStartDateChange = (date) => {
    let dateTimeString = date.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${startTime}`);

    setStartDate(dateTime);
    setSDate(date);

    if (date > e_Date) {
      let endDateTime = moment(`${dateTimeString} 23:59:59`);

      setEndDate(endDateTime);
      setEDate(date);
      setEndTime("23:59");
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (date < startDate) setStartDate(date);

    let dateTimeString = date.format("YYYY-MM-DD");

    // console.log("dateTimeString", dateTimeString);
    // console.log("endTime", endTime);

    let dateTime = moment(`${dateTimeString} ${endTime}`);

    // console.log("dateTime", dateTime);

    setEndDate(dateTime);
    setEDate(date);

    if (date < s_Date) {
      // console.log("dateTimeString", dateTimeString);

      let startDateTime = moment(`${dateTimeString} 00:00`);

      // console.log("startDateTime", startDateTime);

      setStartDate(startDateTime);
      setSDate(date);
      setStartTime("00:00");
    }
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);

    let dateTimeString = startDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    setStartDate(dateTime);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);

    let dateTimeString = endDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    setEndDate(dateTime);
  };

  ///////////////

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>
              <Link to={merchantsLink}>Home</Link>
              {" - "}
              <Link to={selectedMerchantLink}>{merchant[0].name}</Link>
              {" - "}
              <Link to={transactionsReportLink}>Reports</Link> {" - "} Merchant
              Statement
            </span>
            <br />
            <span className={classes.ByLine}>
              {/* You are in the {merchant.name} Reports panel */}
              You are in the Reports panel
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        <strong>{format(startDate, "YYYY-MM-DD")}</strong>
                        {` to `}
                        <strong>{format(endDate, "YYYY-MM-DD")}</strong>
                      </h5>
                    </div>
                    <div
                      className={"row"}
                      style={{ alignItems: "center", flex: "1 1 auto" }}
                    >
                      <div className={"col-md-10"}>
                        <div className={classes.FilterWrapper}></div>
                      </div>
                      <div className={"col-md-10"}>
                        <div className={classes.PageFilter}>
                          <div className={"row"}>
                            <div className={"offset-1"}>
                              <div className={"row"}>
                                <span>
                                  <InputLabel id="start-date-label">
                                    Start Date
                                  </InputLabel>
                                  <DatePicker
                                    labelid="start-date-label"
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    margin="none"
                                    id="start-date"
                                    value={s_Date}
                                    disableFuture={true}
                                    onChange={(date) =>
                                      handleStartDateChange(date)
                                    }
                                  />
                                </span>
                                <span>
                                  <InputLabel id="start-time-label">
                                    Time
                                  </InputLabel>
                                  <Select
                                    labelid="start-time-label"
                                    id="start-time"
                                    value={startTime}
                                    onChange={handleStartTimeChange}
                                  >
                                    <MenuItem value="00:00">00:00</MenuItem>
                                    <MenuItem value="00:30">00:30</MenuItem>
                                    <MenuItem value="01:00">01:00</MenuItem>
                                    <MenuItem value="01:30">01:30</MenuItem>
                                    <MenuItem value="02:00">02:00</MenuItem>
                                    <MenuItem value="02:30">02:30</MenuItem>
                                    <MenuItem value="03:00">03:00</MenuItem>
                                    <MenuItem value="03:30">03:30</MenuItem>
                                    <MenuItem value="04:00">04:00</MenuItem>
                                    <MenuItem value="04:30">04:30</MenuItem>
                                    <MenuItem value="05:00">05:00</MenuItem>
                                    <MenuItem value="05:30">05:30</MenuItem>
                                    <MenuItem value="06:00">06:00</MenuItem>
                                    <MenuItem value="06:30">06:30</MenuItem>
                                    <MenuItem value="07:00">07:00</MenuItem>
                                    <MenuItem value="07:30">07:30</MenuItem>
                                    <MenuItem value="08:00">08:00</MenuItem>
                                    <MenuItem value="08:30">08:30</MenuItem>
                                    <MenuItem value="09:00">09:00</MenuItem>
                                    <MenuItem value="09:30">09:30</MenuItem>
                                    <MenuItem value="10:00">10:00</MenuItem>
                                    <MenuItem value="10:30">10:30</MenuItem>
                                    <MenuItem value="11:00">11:00</MenuItem>
                                    <MenuItem value="11:30">11:30</MenuItem>
                                    <MenuItem value="12:00">12:00</MenuItem>
                                    <MenuItem value="12:30">12:30</MenuItem>
                                    <MenuItem value="13:00">13:00</MenuItem>
                                    <MenuItem value="13:30">13:30</MenuItem>
                                    <MenuItem value="14:00">14:00</MenuItem>
                                    <MenuItem value="14:30">14:30</MenuItem>
                                    <MenuItem value="15:00">15:00</MenuItem>
                                    <MenuItem value="15:30">15:30</MenuItem>
                                    <MenuItem value="16:00">16:00</MenuItem>
                                    <MenuItem value="16:30">16:30</MenuItem>
                                    <MenuItem value="17:00">17:00</MenuItem>
                                    <MenuItem value="17:30">17:30</MenuItem>
                                    <MenuItem value="18:00">18:00</MenuItem>
                                    <MenuItem value="18:30">18:30</MenuItem>
                                    <MenuItem value="19:00">19:00</MenuItem>
                                    <MenuItem value="19:30">19:30</MenuItem>
                                    <MenuItem value="20:00">20:00</MenuItem>
                                    <MenuItem value="20:30">20:30</MenuItem>
                                    <MenuItem value="21:00">21:00</MenuItem>
                                    <MenuItem value="21:30">21:30</MenuItem>
                                    <MenuItem value="22:00">22:00</MenuItem>
                                    <MenuItem value="22:30">22:30</MenuItem>
                                    <MenuItem value="23:00">23:00</MenuItem>
                                    <MenuItem value="23:30">23:30</MenuItem>
                                  </Select>
                                </span>
                                <span style={{ marginRight: "30px" }}> </span>

                                <span>
                                  <InputLabel id="end-date-label">
                                    End Date
                                  </InputLabel>
                                  <DatePicker
                                    labelid="end-date-label"
                                    id="end-date"
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    margin="none"
                                    value={e_Date}
                                    disableFuture={true}
                                    onChange={(date) =>
                                      handleEndDateChange(date)
                                    }
                                  />
                                </span>
                                <span>
                                  <InputLabel id="end-time-label">
                                    Time
                                  </InputLabel>
                                  <Select
                                    labelid="end-time-label"
                                    id="end-time"
                                    value={endTime}
                                    onChange={(time) =>
                                      handleEndTimeChange(time)
                                    }
                                  >
                                    <MenuItem value="00:00">00:00</MenuItem>
                                    <MenuItem value="00:30">00:30</MenuItem>
                                    <MenuItem value="01:00">01:00</MenuItem>
                                    <MenuItem value="01:30">01:30</MenuItem>
                                    <MenuItem value="02:00">02:00</MenuItem>
                                    <MenuItem value="02:30">02:30</MenuItem>
                                    <MenuItem value="03:00">03:00</MenuItem>
                                    <MenuItem value="03:30">03:30</MenuItem>
                                    <MenuItem value="04:00">04:00</MenuItem>
                                    <MenuItem value="04:30">04:30</MenuItem>
                                    <MenuItem value="05:00">05:00</MenuItem>
                                    <MenuItem value="05:30">05:30</MenuItem>
                                    <MenuItem value="06:00">06:00</MenuItem>
                                    <MenuItem value="06:30">06:30</MenuItem>
                                    <MenuItem value="07:00">07:00</MenuItem>
                                    <MenuItem value="07:30">07:30</MenuItem>
                                    <MenuItem value="08:00">08:00</MenuItem>
                                    <MenuItem value="08:30">08:30</MenuItem>
                                    <MenuItem value="09:00">09:00</MenuItem>
                                    <MenuItem value="09:30">09:30</MenuItem>
                                    <MenuItem value="10:00">10:00</MenuItem>
                                    <MenuItem value="10:30">10:30</MenuItem>
                                    <MenuItem value="11:00">11:00</MenuItem>
                                    <MenuItem value="11:30">11:30</MenuItem>
                                    <MenuItem value="12:00">12:00</MenuItem>
                                    <MenuItem value="12:30">12:30</MenuItem>
                                    <MenuItem value="13:00">13:00</MenuItem>
                                    <MenuItem value="13:30">13:30</MenuItem>
                                    <MenuItem value="14:00">14:00</MenuItem>
                                    <MenuItem value="14:30">14:30</MenuItem>
                                    <MenuItem value="15:00">15:00</MenuItem>
                                    <MenuItem value="15:30">15:30</MenuItem>
                                    <MenuItem value="16:00">16:00</MenuItem>
                                    <MenuItem value="16:30">16:30</MenuItem>
                                    <MenuItem value="17:00">17:00</MenuItem>
                                    <MenuItem value="17:30">17:30</MenuItem>
                                    <MenuItem value="18:00">18:00</MenuItem>
                                    <MenuItem value="18:30">18:30</MenuItem>
                                    <MenuItem value="19:00">19:00</MenuItem>
                                    <MenuItem value="19:30">19:30</MenuItem>
                                    <MenuItem value="20:00">20:00</MenuItem>
                                    <MenuItem value="20:30">20:30</MenuItem>
                                    <MenuItem value="21:00">21:00</MenuItem>
                                    <MenuItem value="21:30">21:30</MenuItem>
                                    <MenuItem value="22:00">22:00</MenuItem>
                                    <MenuItem value="22:30">22:30</MenuItem>
                                    <MenuItem value="23:00">23:00</MenuItem>
                                    <MenuItem value="23:30">23:30</MenuItem>
                                    <MenuItem value="23:59">23:59</MenuItem>
                                  </Select>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  {loading ? (
                    <LoadingComponent inverted={true} />
                  ) : (
                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              <div className={"col-lg-3"}>
                                <button
                                  className={classes.SalesBtn}
                                  onClick={() => {
                                    // console.log(
                                    //   'endDate.format("YYYY-MM-DD HH:mm:ss")',
                                    //   endDate.format("YYYY-MM-DD HH:mm:ss")
                                    // );
                                    // console.log(
                                    //   'moment(startDate.toDate()).format("YYYY-MM-DD HH:mm:ss")',
                                    //   moment(startDate.toDate()).format(
                                    //     "YYYY-MM-DD HH:mm:ss"
                                    //   )
                                    // );
                                    openModal("MerchantStatementModal", {
                                      account: match.params.account,
                                      reportStartDate: startDate.valueOf(),
                                      reportEndDate: endDate.valueOf(),
                                    });
                                  }}
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                                      />
                                    </svg>
                                  </span>{" "}
                                  Download PDF Statement
                                </button>
                              </div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  merchant: state.firestore.ordered.merchant,

  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(index);

// const mapStateToProps = (state) => ({
//   startingBalance: state.financials.startingBalance,
//   closingBalance: state.financials.closingBalance,
//   deposits: state.financials.depositsTotal,
//   reversalsTotal: state.financials.reversalsTotal,
//   withdrawals: state.financials.withdrawalsList,
//   withdrawalsTotal: state.financials.withdrawalsTotal,
//   sales: state.financials.totalsByProvider,
//   salesTotal: state.financials.salesTotal,
//   financials: state.financials.summary,
//   deviceFees: state.financials.deviceFees,
//   providerBalances: state.financials.providerBalances,
//   commissionCredited: state.financials.creditedCommissionListTotal,
//   merchantCommissionTotal: state.financials.earnedCommissionTotal,
//   prepaiPlusCommissionTotal: state.financials.vendorCommissionEarnedTotal,
// });

// export default connect(mapStateToProps)(index);
