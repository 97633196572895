import moment from "moment";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";

import firebase from "../../../../app/config/firebase";
import { createReversalTransaction } from "../../../../app/common/util/helpers";
import { FETCH_MERCHANT_TRANSACTIONS } from "./transactionsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../../../async/asyncActions";

export const getTransactionsForDashboard =
  (values) => async (dispatch, getState) => {

    const group = values.group;
    const account = values.account;
    const role = values.role;
    const transactionsStartDate = values.transactionStartDate; // set to 12:00 am today
    const transactionsEndDate = values.transactionEndDate; // set to 23:59 pm today

    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("transactions");

    try {
      dispatch(asyncActionStart());

      console.log("group,role, accounts",group,role, account)


    const accountList = await getMerchantList(group,role, account);

    console.log("accountList",accountList)

      let query = transactionsRef
          .where("transactionDateTime", ">=", transactionsStartDate)
          .where("transactionDateTime", "<=", transactionsEndDate)
          .where("account", "==", account)
          .orderBy("transactionDateTime");
    

      let querySnap = await query.get();

      console.log("querySnap.docs.length", querySnap.docs.length);
      let merchantTransactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { merchantTransactions } });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        merchantTransactions.push(transaction);
      }

      dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { merchantTransactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };






export const clearTransactionsForDashboard =
  () => async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());

      let transactions = [];
      dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

  const getMerchantList = async (group, role, accounts) => {
    let merchantList = [];

    const firestore = firebase.firestore();
  
  if (role === "Group Administrator") {
      await firestore.collection("merchants")
        .where("group", "==", group)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size > 0) {
            for (let i = 0; i < querySnapshot.docs.length; i++) {
              let account = querySnapshot.docs[i].id;
     
  
              merchantList.push(account);
            }

            return merchantList
          }
        });
    } 
    
  
    return accounts;
  };

export const reportByCategory = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            saleType: doc.data().saleType,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.saleType in arr))
              arr.__array.push((arr[doc.saleType] = doc));
            else {
              arr[doc.saleType].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    // // console.log("querySnap.docs.length", querySnap.docs.length);
    // let transactions = [];

    // if (querySnap.docs.length === 0) {
    //   dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { transactions } });
    //   dispatch(asyncActionFinish());
    //   return querySnap;
    // }

    // for (let i = 0; i < querySnap.docs.length; i++) {
    //   let transaction = {
    //     ...querySnap.docs[i].data(),
    //     id: querySnap.docs[i].id
    //   };
    //   transactions.push(transaction);
    // }

    dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByDate = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            transactionDateTime: doc
              .data()
              .dateCreated.toDate()
              .toISOString()
              .slice(0, 10),
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.transactionDateTime in arr))
              arr.__array.push((arr[doc.transactionDateTime] = doc));
            else {
              arr[doc.transactionDateTime].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByUser = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            userId: doc.data().createdBy,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.userId in arr)) arr.__array.push((arr[doc.userId] = doc));
            else {
              arr[doc.userId].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_MERCHANT_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// export const reverseTransaction = transaction => async (
//   dispatch,
//   getState
// ) => {};

export const reverseTransaction =
  (transaction) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let dateNow = new Date();
    let transactionCategory =
      transaction.transactionCategory === "Debit" ? "Credit" : "Debit";

    // let isTransactionCharged = transaction.isTransactionCharged ? false : true;

    let reverseTransactionObject = createReversalTransaction(
      transaction,
      transactionCategory,
      user
    );

    try {
      dispatch(asyncActionStart());

      await firestore.add(`transactions`, reverseTransactionObject);

      dispatch(asyncActionFinish());
      toastr.success("Success", "Transaction has been reversed");
    } catch (error) {
      console.log(error);

      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
