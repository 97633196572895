import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { openModal } from "../../modals/modalActions";
import classes from "./index.module.css";
import { formatMoney } from "../../../app/common/util/helpers";
import firebase from "../../../app/config/firebase";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  createReportExcelFile,
  createNewExcelFile,
} from "../../../app/excel/excel";

const Payments = ({ openModal }) => {
  const firestore = firebase.firestore();
  const triggersRef = firestore.collection("triggers");
  const popStorageRef = firebase.storage().ref();

  let [payments, setPayments] = useState([]);
  let [popLoading, setPopLoading] = useState(false);
  let [canceledLoading, setCanceledLoading] = useState(false);
  let [focusedInput, setFocusedInput] = useState(null);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  let fetchData = async () => {
    // console.log(
    //   "startDate",
    //   moment(startDate.toDate()).startOf("day").toDate()
    // );
    console.log("endDate", moment(endDate.toDate()).endOf("day").toDate());
    triggersRef
      .doc("provider_triggers")
      .collection("payments")
      .where("time", ">", moment(startDate.toDate()).startOf("day").toDate())
      .where("time", "<", moment(endDate.toDate()).endOf("day").toDate())
      .orderBy("time", "desc")
      .onSnapshot((snapshot) => {
        let _payments = [];
        if (snapshot.size > 0) {
          snapshot.forEach((doc) => {
            _payments.push({
              id: doc.id,
              provider: doc.data().provider,
              credited: doc.data().credited,
              amount: doc.data().amount,
              time: doc.data().time,
              pop: doc.data().pop,
              canceled: doc.data().canceled,
            });
          });
        }
        setPayments(_payments);
      });
  };

  const cancelPayment = async (event) => {
    let docId = event.target.id;
    setCanceledLoading(true);
    console.log("fuck yeah", event.target.id);
    await triggersRef
      .doc("provider_triggers")
      .collection("payments")
      .doc(docId)
      .set(
        {
          credited: true,
          canceled: true,
        },
        {
          merge: true,
        }
      )
      .then((response) => {
        console.log(response);
        setCanceledLoading(false);
      })
      .catch((e) => {
        setCanceledLoading(false);
      });
  };

  const handleFileChange = async (event) => {
    setPopLoading(true);
    let docId = event.target.id;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const popFileRef = popStorageRef.child(
        `provider_pop/${selectedFile.name}`
      );
      await popFileRef
        .put(selectedFile)
        .then(async () => {
          await popFileRef
            .getDownloadURL()
            .then(async (url) => {
              await triggersRef
                .doc("provider_triggers")
                .collection("payments")
                .doc(docId)
                .update({
                  pop: url,
                })
                .then(() => {
                  setPopLoading(false);
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  let paymentsreportSheet = async (payments, workbook, logo, accountName) => {
    let paymentsheet = workbook.addWorksheet("Provider payments", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 15,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    paymentsheet.mergeCells("A1", "G2");
    paymentsheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    paymentsheet.mergeCells("A4", "G4");
    paymentsheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Provider Payments Report",
        },
      ],
    };

    paymentsheet.mergeCells("A6", "B6");
    paymentsheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Start date: ",
        },
      ],
    };

    paymentsheet.mergeCells("A7", "B7");
    paymentsheet.getCell("A7").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "End date: ",
        },
      ],
    };

    paymentsheet.mergeCells("C6", "E6");
    paymentsheet.getCell("C6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(startDate.toDate())
            .startOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    paymentsheet.mergeCells("C7", "E7");
    paymentsheet.getCell("C7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(endDate.toDate())
            .endOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    paymentsheet.mergeCells("A10", "C10");
    paymentsheet.mergeCells("D10", "F10");
    paymentsheet.mergeCells("G10", "I10");
    paymentsheet.mergeCells("J10", "L10");
    paymentsheet.getCell("A10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Provider",
        },
      ],
    };
    paymentsheet.getCell("D10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Date time",
        },
      ],
    };
    paymentsheet.getCell("G10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Amount",
        },
      ],
    };

    paymentsheet.getCell("J10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Status",
        },
      ],
    };

    let _index_paymentsheet = 0;
    payments.forEach((payment) => {
      paymentsheet.mergeCells(
        `A${11 + _index_paymentsheet}`,
        `C${11 + _index_paymentsheet}`
      );
      paymentsheet.mergeCells(
        `D${11 + _index_paymentsheet}`,
        `F${11 + _index_paymentsheet}`
      );
      paymentsheet.mergeCells(
        `G${11 + _index_paymentsheet}`,
        `I${11 + _index_paymentsheet}`
      );
      paymentsheet.mergeCells(
        `J${11 + _index_paymentsheet}`,
        `L${11 + _index_paymentsheet}`
      );

      paymentsheet.getCell(`A${11 + _index_paymentsheet}`).value =
        payment.provider;

      paymentsheet.getCell(`D${11 + _index_paymentsheet}`).value = `${new Date(
        payment.time.seconds * 1000
      ).toLocaleString()}`;

      paymentsheet.getColumn(7).numFmt = "0.00";
      paymentsheet.getCell(
        `G${11 + _index_paymentsheet}`
      ).value = `${formatMoney(payment.amount)}`;

      paymentsheet.getCell(`J${11 + _index_paymentsheet}`).value = `${
        payment.credited && !payment.canceled
          ? "Credited"
          : payment.canceled
          ? "Canceled"
          : "Pending"
      }`;

      _index_paymentsheet++;
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      setPayments([]);
    };
  }, []);

  return (
    <>
      <Fragment>
        <div label={`Transactions`}>
          <div className={classes.Container}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <button
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                  className={"btn btn-primary btn-cons"}
                  type="submit"
                  onClick={() => openModal("ProviderTrigerDepositModal")}
                >
                  <span>Capture Payment</span>
                </button>
              </div>
              <div className={"col-md-6"}>
                <div
                  style={{
                    marginTop: "55px",
                    marginBottom: "50px",
                    marginLeft: "20px",
                    float: "right",
                    cursor: "pointer",
                  }}
                  className={classes.ToolsExportIconWrapper}
                  onClick={() =>
                    createNewExcelFile(
                      "Payment Triggers Report",
                      payments,
                      paymentsreportSheet,
                      "Payment Triggers Report"
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        id="path1"
                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                        fill="#707070"
                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  style={{
                    marginTop: "50px",
                    marginBottom: "50px",
                    float: "right",
                  }}
                  className={classes.PageFilter}
                >
                  <DateRangePicker
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="startDate" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="endDate" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                      // console.log(startDate, endDate);
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                    numberOfMonths={1}
                    isOutsideRange={(date) => date.isAfter(moment())}
                    displayFormat={"YYYY-MM-DD"}
                    minimumNights={0}
                    small={true}
                    readOnly={true}
                  />

                  <button
                    className={classes.FilterReportBtn}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => fetchData()}
                  >
                    <svg
                      height="18"
                      width="18"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          id="path1"
                          transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                          fill="#FFFFFF"
                          d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className={"row"}>
              <div className={"col-md-12"}>
                <div
                  className={classes.TableWrapper}
                  style={{ flexDirection: "column" }}
                >
                  <table className={classes.MerchantTable}>
                    <thead>
                      <tr>
                        <th scope="col">Provider</th>
                        <th scope="col">Time</th>
                        <th scope="col">Amount</th>
                        <th scope="col">PoP</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments.length === 0 ? (
                        <tr>
                          <td colSpan="6">No payments found</td>
                        </tr>
                      ) : (
                        payments.map((payment, index) => (
                          <tr key={index} className={classes.Totals}>
                            <td>{payment.provider}</td>
                            <td>
                              {new Date(
                                payment.time.seconds * 1000
                              ).toLocaleString()}
                            </td>
                            <td>{formatMoney(payment.amount)}</td>
                            <td>
                              {payment.pop == "#" &&
                              !payment.canceled &&
                              !popLoading ? (
                                <input
                                  type="file"
                                  accept="application/pdf"
                                  name="proof"
                                  id={payment.id}
                                  className="form-control"
                                  required
                                  onChange={handleFileChange}
                                  style={{
                                    paddingTop: 7,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    margin: 0,
                                    width: "98px",
                                    marginLeft: "-20px",
                                  }}
                                />
                              ) : null}
                              {payment.pop != "#" && !popLoading ? (
                                <a
                                  href={payment.pop}
                                  download={payment.pop}
                                  target="_blank"
                                >
                                  Download
                                </a>
                              ) : null}
                              {popLoading ? "🔃" : null}
                            </td>
                            <td>
                              {payment.credited && !payment.canceled
                                ? "✅"
                                : payment.canceled
                                ? "❌"
                                : "⌛"}
                            </td>
                            <td>
                              {payment.credited || payment.canceled ? null : (
                                <a
                                  style={{ cursor: "pointer" }}
                                  id={payment.id}
                                  onClick={
                                    canceledLoading != true
                                      ? cancelPayment
                                      : null
                                  }
                                >
                                  {canceledLoading != true ? "Cancel" : "⌛"}
                                </a>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

const mapStateToProps = (state) => ({});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(Payments);
