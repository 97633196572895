import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { createPettyCashRequest } from "../pettyCashActions";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";

const validate = combineValidators({
  amount: isRequired("amount"),
  reason: isRequired("reason"),
});

const pettyCashRequestForm = ({
  initialValues,
  handleSubmit,
  createPettyCashRequest,
  error,
  profile,
  loading,
  approvers,
}) => {
  console.log("initialValues", initialValues);

  const onFormSubmit = async (values) => {
    const approver = approvers.map((approver) => {
      return approver.displayName;
    });

    await createPettyCashRequest(values, approver);
  };

  const clearFormSubmit = async () => {
    console.log("clearFormSubmit");
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <>
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <div
              className={"all-caps no-margin hint-text"}
              style={{ fontSize: "10px" }}
            >
              Approver
            </div>
            <strong>
              {approvers &&
                approvers.map((approver, index) => {
                  return <div>{approver.displayName}</div>;
                })}
            </strong>
          </div>
          <div className={"col-sm-4"}>
            <Field
              label="Amount Requested"
              name="amount"
              component={TextInput}
              type="number"
              className={"form-control"}
              placeholder="Amount"
              required=""
              aria-required="true"
            />
          </div>
        </div>

        <br />
        <div className={"row"} style={{ lineHeight: "3.8rem" }}>
          <div className={"col-md-12"}>
            <Field
              label="Reason for Request"
              name="reason"
              component={TextArea}
              type="text"
              className={"form-control"}
              placeholder="Reason for Request"
              required=""
              aria-required="true"
            />
          </div>
        </div>
      </>

      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      <button
        className={"btn btn-primary btn-cons m-t-10"}
        type="submit"
        disabled={loading}
      >
        {loading ? (
          <Spinner
            style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
          />
        ) : (
          <span>Submit Application</span>
        )}
      </button>
    </form>
  );
};

const query = (props) => {
  // console.log("props", props);
  return [
    {
      collection: "users",
      storeAs: "approvers",
      where: ["departmentCode", "==", "IT"],
      where: ["isPettyCashApprover", "==", true],
    },
    {
      collection: "pettyCash",
      storeAs: "pettyCashOrders",
      where: ["uid", "==", props.auth.uid],
    },
  ];
};

const mapStateToProps = (state) => ({
  initialValues: {
    uid: state.firebase.auth.uid,
    createdBy: state.firebase.auth.displayName,
    // approvers: state.firestore.ordered.approvers,
    isPettyCashApproved: false,
  },
  enableReinitialize: true,
  approvers: state.firestore.ordered.approvers,
  pettyCashOrders: state.firestore.ordered.pettyCashOrders,
  loading: state.async.loading,
  profile: state.firebase.profile,
  auth: state.firebase.auth,
});

const actions = {
  createPettyCashRequest,
  change,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "pettyCashRequestForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(pettyCashRequestForm);
