import React, { Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";

const index = ({ reversals, reversalsTotal }) => {
  return (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>
          {reversals.length > 0 ? (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Reversal Date</th>
                  <th scope="col"> Reversal Detail</th>
                  <th scope="col">Amount Reversed</th>
                  {/* <th scope="col">Balance After Reversal</th> */}
                </tr>
              </thead>
              <tbody>
                {Object.values(reversals).map((reversal, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>
                        {format(
                          reversal.transactionDateTime,
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {reversal.reversalType +
                          " - " +
                          reversal.reversalReason}
                      </td>
                      <td>{formatMoney(reversal.transactionAmount)}</td>
                    </tr>
                  );
                })}
                <tr className={classes.Totals}>
                  <td colSpan="2">
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {formatMoney(reversalsTotal)}
                  </td>
                  <td />
                </tr>
              </tbody>
            </table>
          ) : (
            <h4>
              There are no <strong>reversals</strong> for the selected period.
            </h4>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  reversals: state.financials.reversalsList,
  reversalsTotal: state.financials.reversalsTotal,
});

export default connect(mapStateToProps)(index);
