import React, { Component } from "react";
import classes from "./MerchantForm.module.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withFirestore } from "react-redux-firebase";
import { Field, reduxForm, formValueSelector } from "redux-form";

import { createCorporateMerchant, updateMerchant } from "../merchantActions";
import MerchantDetails from "./FormSections/MerchantDetailsCorporate";
import MerchantOutlet from "./FormSections/MerchantOutlet";
import ReviewMerchant from "./FormSections/ReviewMerchantCorporate";
import Commissions from "./FormSections/Commissions";

import initialState from "./initialState";

class MerchantCorporateForm extends Component {
  attachedFormGroupClasses = [classes.FormGroup, classes.FormGroupDefault];
  attachedCardClasses = ["card", "card-transparent"];
  attachedColClasses = "col-md-6";
  attachedWizardListClasses = ["cf"];
  attachedSplit1 = [classes.Split, classes.One];
  attachedSplit2 = [classes.Split, classes.Two];

  //currentStep = null;

  state = {
    files: [],
    fileName: "",
    omang: [],
    omangFileName: "",
    affidavit: [],
    affidavitFileName: "",
    merchantName: "",
    merchantId: "",
    merchantType: "",
    merchantCity: "",
    merchantSuburb: "",
    merchant_contact_name: "",
    merchant_contact_email: "",
    merchant_contact_telephone: "",
    merchantGroup: "",
    attachementError: false,

    bemobile: "",
    mascom: "",
    orange: "",
    electricity: "",
    bLife: "",
    bpc: "",
    dstv: "",
    wuc: "",
    btcl: "",

    outletName: "",
    outletCity: "",
    suburb: "",
    contactName: "",
    contactEmail: "",
    contactTelephone: "",
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
    };
  }

  nextPage() {
    if (
      this.state.omangFileName != "" &&
      this.state.omangFileName != undefined &&
      this.state.fileName != "" &&
      this.state.fileName != undefined
    ) {
      this.setState({ attachementError: false });
      this.setState({ page: this.state.page + 1 });
    } else {
      this.setState({ attachementError: true });
    }
  }

  onDrop = (files) => {
    console.log("uploaded files", files);
    this.setState({
      files,
      fileName: files[0].name,
    });
  };

  onDrop1 = (omang) => {
    console.log("uploaded omang", omang);
    this.setState({
      omang,
      omangFileName: omang[0].name,
    });
  };

  onDrop2 = (affidavit) => {
    console.log("uploaded affidavit", affidavit);
    this.setState({
      affidavit,
      affidavitFileName: affidavit[0].name,
    });
  };

  onMerchantName = (event) => {
    console.log(event.target.value);
    this.setState({
      merchantName: event.target.value,
    });
  };

  onMerchantGroup = (event) => {
    console.log(event.target.value);
    this.setState({
      merchantGroup: event.target.value,
    });
  };

  onMerchantId = (event) => {
    console.log(event.target.value);
    this.setState({
      merchantId: event.target.value,
    });
  };

  onmerchantType = (event) => {
    console.log(event.target);
    this.setState({
      merchantType: event.target.value,
    });
  };

  onmerchantCity = (event) => {
    console.log(event.target.value);
    this.setState({
      merchantCity: event.target.value,
    });
  };

  onmerchantSuburb = (event) => {
    console.log(event.target.value);
    this.setState({
      merchantSuburb: event.target.value,
    });
  };

  onmerchant_contact_email = (event) => {
    console.log(event.target.value);
    this.setState({
      merchant_contact_email: event.target.value,
    });
  };

  onmerchant_contact_telephone = (event) => {
    console.log(event.target.value);
    this.setState({
      merchant_contact_telephone: event.target.value,
    });
  };

  onmerchant_contact_name = (event) => {
    console.log(event.target.value);
    this.setState({
      merchant_contact_name: event.target.value,
    });
  };

  onbemobile = (event) => {
    console.log(event.target.value);
    this.setState({
      bemobile: event.target.value,
    });
  };

  onmascom = (event) => {
    console.log(event.target.value);
    this.setState({
      mascom: event.target.value,
    });
  };
  onorange = (event) => {
    console.log(event.target.value);
    this.setState({
      orange: event.target.value,
    });
  };
  onelectricity = (event) => {
    console.log(event.target.value);
    this.setState({
      electricity: event.target.value,
    });
  };
  onbLife = (event) => {
    console.log(event.target.value);
    this.setState({
      bLife: event.target.value,
    });
  };

  onbpc = (event) => {
    console.log(event.target.value);
    this.setState({
      bpc: event.target.value,
    });
  };
  ondstv = (event) => {
    console.log(event.target.value);
    this.setState({
      dstv: event.target.value,
    });
  };

  onwuc = (event) => {
    console.log(event.target.value);
    this.setState({
      wuc: event.target.value,
    });
  };

  onbtcl = (event) => {
    console.log(event.target.value);
    this.setState({
      btcl: event.target.value,
    });
  };

  onoutletName = (event) => {
    console.log(event.target.value);
    this.setState({
      outletName: event.target.value,
    });
  };

  onoutletCity = (event) => {
    console.log(event.target.value);
    this.setState({
      outletCity: event.target.value,
    });
  };

  onsuburb = (event) => {
    console.log(event.target.value);
    this.setState({
      suburb: event.target.value,
    });
  };

  oncontactName = (event) => {
    console.log(event.target.value);
    this.setState({
      contactName: event.target.value,
    });
  };

  oncontactEmail = (event) => {
    console.log(event.target.value);
    this.setState({
      contactEmail: event.target.value,
    });
  };

  oncontactTelephone = (event) => {
    console.log(event.target.value);
    this.setState({
      contactTelephone: event.target.value,
    });
  };

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  onFormSubmit = (values) => {
    let merchantAgreement = this.state.files ? this.state.files[0] : null;
    let merchantOmang = this.state.omang ? this.state.omang[0] : null;
    let merchantAffidavit = this.state.affidavit
      ? this.state.affidavit[0]
      : null;

    console.log("uploaded files", merchantAgreement);

    this.props.createCorporateMerchant(
      values,
      merchantAgreement,
      merchantOmang,
      merchantAffidavit
    );
  };

  render() {
    const { onSubmit } = this.props;
    const page = this.state.page;
    return (
      <div className={this.attachedCardClasses.join(" ")}>
        <div className={classes.CardBody}>
          <div id="rootwizard" className={classes.Wizard}>
            <div className={classes.TabContent}>
              {page === 1 && (
                <MerchantDetails
                  onDrop={this.onDrop}
                  onDrop1={this.onDrop1}
                  onDrop2={this.onDrop2}
                  files={this.state.files}
                  fileName={this.state.fileName}
                  omang={this.state.omang}
                  omangFileName={this.state.omangFileName}
                  affidavit={this.state.affidavit}
                  affidavitFileName={this.state.affidavitFileName}
                  merchantName={this.state.merchantName}
                  onMerchantName={this.onMerchantName}
                  merchantGroup={this.state.merchantGroup}
                  onMerchantGroup={this.onMerchantGroup}
                  merchantId={this.state.merchantId}
                  onMerchantId={this.onMerchantId}
                  merchantType={this.state.merchantType}
                  onmerchantType={this.onmerchantType}
                  merchantCity={this.state.merchantCity}
                  onmerchantCity={this.onmerchantCity}
                  merchantSuburb={this.state.merchantSuburb}
                  onmerchantSuburb={this.onmerchantSuburb}
                  merchant_contact_name={this.state.merchant_contact_name}
                  onmerchant_contact_name={this.onmerchant_contact_name}
                  merchant_contact_email={this.state.merchant_contact_email}
                  onmerchant_contact_email={this.onmerchant_contact_email}
                  merchant_contact_telephone={
                    this.state.merchant_contact_telephone
                  }
                  onmerchant_contact_telephone={
                    this.onmerchant_contact_telephone
                  }
                  onSubmit={this.nextPage}
                  attachementError={this.state.attachementError}
                />
              )}
              {page === 2 && (
                <Commissions
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                  bemobile={this.state.bemobile}
                  mascom={this.state.mascom}
                  orange={this.state.orange}
                  electricity={this.state.electricity}
                  bLife={this.state.bLife}
                  bpc={this.state.bpc}
                  dstv={this.state.dstv}
                  wuc={this.state.wuc}
                  btcl={this.state.btcl}
                  onbemobile={this.onbemobile}
                  onmascom={this.onmascom}
                  onorange={this.onorange}
                  onelectricity={this.onelectricity}
                  onbLife={this.onbLife}
                  onbpc={this.onbpc}
                  ondstv={this.ondstv}
                  onwuc={this.onwuc}
                  onbtcl={this.onbtcl}
                />
              )}
              {page === 3 && (
                <MerchantOutlet
                  outletName={this.state.outletName}
                  onoutletName={this.onoutletName}
                  outletCity={this.state.outletCity}
                  onoutletCity={this.onoutletCity}
                  suburb={this.state.suburb}
                  onsuburb={this.onsuburb}
                  contactName={this.state.contactName}
                  oncontactName={this.oncontactName}
                  contactEmail={this.state.contactEmail}
                  oncontactEmail={this.oncontactEmail}
                  contactTelephone={this.state.contactTelephone}
                  oncontactTelephone={this.oncontactTelephone}
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )}
              {page === 4 && (
                <ReviewMerchant
                  previousPage={this.previousPage}
                  fileName={this.state.fileName}
                  omangFileName={this.state.omangFileName}
                  onSubmit={onSubmit}
                  onFormSubmit={this.onFormSubmit}
                  affidavit={onSubmit}
                  affidavitFileName={this.state.affidavitFileName}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(mapState)(
//   reduxForm({ form: "MerchantCorporateForm" })(MerchantCorporateForm)
// );
const actions = {
  createCorporateMerchant,
  updateMerchant,
};

export default withFirestore(
  connect(
    null,
    actions
  )(reduxForm({ form: "merchantForm" })(MerchantCorporateForm))
);
