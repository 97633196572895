import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import SelectInput from "../../../../app/common/form/SelectInput";
import TextInput from "../../../../app/common/form/TextInput";

import { addCommissionRate } from "../../settingsActions";

import validate from "../validate";

const commissionType = [
  {
    label: "Airtime - beMobile",
    value: "Airtime - beMobile",
  },
  {
    label: "Airtime - Mascom",
    value: "Airtime - Mascom",
  },
  {
    label: "Airtime - Orange",
    value: "Airtime - Orange",
  },
  {
    label: "Bills - BPC",
    value: "Bills - BPC",
  },
  {
    label: "Bills - BTCL",
    value: "Bills - BTCL",
  },
  {
    label: "Bills - DSTV",
    value: "Bills - DSTV",
  },
  {
    label: "Bills - WUC",
    value: "Bills - WUC",
  },
  {
    label: "Electricity - BPC",
    value: "Electricity - BPC",
  },
  {
    label: "Collections - Botswana Life",
    value: "Collections - Botswana Life",
  },
];

const commissionMeasurementType = [
  {
    label: "Fixed Rate",
    value: "Fixed Rate",
  },
  {
    label: "Percentage (%)",
    value: "Percentage (%)",
  },
];

class CreateCommissionForm extends Component {
  state = {};

  render() {
    const { handleSubmit, createDeposit, error } = this.props;

    const onFormSubmit = (values) => {
      createDeposit({
        values,
      });
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              name="commission_type"
              options={commissionType}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Commission Type"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              name="commission_measurement_type"
              options={commissionMeasurementType}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Measurement Type"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="Minimun Rate"
              name="minimum_commission_rate"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Minimum Rate"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              label="Maximum Rate"
              name="maximum_commission_rate"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Maximum Rate"
              required="required"
            />
          </div>
        </div>
        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Create Commission Rate
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  enableReinitialize: true,
});

const actions = { addCommissionRate };

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "commissionForm", validate })(CreateCommissionForm));
