import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import Dropzone from "react-dropzone";

import {
  combineValidators,
  createValidator,
  composeValidators,
  isRequired,
} from "revalidate";

import TextInput from "../../../../../app/common/form/TextInput";
import SelectInput from "../../../../../app/common/form/SelectInput";
const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};

const customIsRequired = isRequired({ message: "Required" });

const isAFloat = createValidator(
  (message) => (value) => {
    if (isNaN(parseFloat(value))) {
      return message;
    }
  },

  (field) => `${field} should be numeric`
);

const validate = combineValidators({
  airtime_bemobile: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  airtime_mascom: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  airtime_orange: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  electricity_bpc: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  collections_botswanalife: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  bill_BPC: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  bill_DSTV: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  bill_WUC: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
  bill_BTCL: composeValidators(
    customIsRequired,
    isAFloat({
      message: "Must be a number",
    })
  )(),
});

const commissionFrequency = [
  // {
  //   label: "Instant",
  //   value: "Instant",
  // },
  {
    label: "Daily",
    value: "Daily",
  },
  {
    label: "Monthly",
    value: "Monthly",
  },
];

class MerchantAgreement extends Component {
  render() {
    const { handleSubmit, error } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <p>
          <strong>Airtime Commission</strong>
        </p>

        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <Field
              // label="Outlet Name"
              name="airtime_bemobile"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="beMobile "
              required="required"
            />
          </div>
          <div className={"col-sm-4"}>
            <Field
              // label="Outlet Name"
              name="airtime_mascom"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="Mascom "
              required="required"
            />
          </div>
          <div className={"col-sm-4"}>
            <Field
              // label="Outlet Name"
              name="airtime_orange"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="Orange "
              required="required"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <p>
              <strong>Electricity Commission</strong>
            </p>

            <div className={"col-sm-12"}>
              <Field
                // label="Outlet Name"
                name="electricity_bpc"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="BPC "
                required="required"
              />
            </div>
          </div>
          <div className={"col-sm-4"}>
            <p>
              <strong>Collections Commission</strong>
            </p>

            <div>
              <Field
                // label="Outlet Name"
                name="collections_botswanalife"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder="Botswana Life "
                required="required"
              />
            </div>
          </div>
        </div>

        <p>
          <strong>Bill Payments</strong>
        </p>
        <div className={"row clearfix"}>
          <div className={"col-sm-3"}>
            <Field
              // label="Outlet Name"
              name="bill_BPC"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="BPC "
              required="required"
            />
          </div>
          <div className={"col-sm-3"}>
            <Field
              // label="Outlet Name"
              name="bill_DSTV"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="DSTV "
              required="required"
            />
          </div>
          <div className={"col-sm-3"}>
            <Field
              // label="Outlet Name"
              name="bill_WUC"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="WUC "
              required="required"
            />
          </div>
          <div className={"col-sm-3"}>
            <Field
              // label="Outlet Name"
              name="bill_BTCL"
              component={TextInput}
              type="text"
              className={"form-control input-sm"}
              placeholder="BTCL "
              required="required"
            />
          </div>
        </div>

        <br />
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <p>
              <strong>Commission Frequency</strong>
            </p>

            <div>
              <Field
                name="commission_frequency"
                options={commissionFrequency}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0",
                  },
                })}
                component={SelectInput}
                placeholder="Commission Type"
                className={"form-control"}
                required="true"
                aria-required="true"
              />
            </div>
          </div>
          <div className={"col-sm-8"}>
            <p>
              <strong> Upload Signed Merchant Registration Form</strong>
            </p>
            <div className={"row clearfix"}>
              <div className={"col-lg-12"}>
                <div className={"card card-default"}>
                  {this.props.files && this.props.files.length > 0 ? (
                    <div className={"card-body no-scroll no-padding dropzone "}>
                      <p
                        style={{
                          lineHeight: "115px",
                          margin: "0",
                          textAlign: "center",
                        }}
                      >
                        {this.props.files[0].name}
                      </p>
                    </div>
                  ) : (
                    <div className={"card-body no-scroll no-padding dropzone "}>
                      <Dropzone
                        config={componentConfig}
                        onDrop={this.props.onDrop}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <div
                              {...getRootProps()}
                              style={{ textAlign: "center" }}
                            >
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <p style={{ lineHeight: "50px", margin: 0 }}>
                                  Drop files here...
                                </p>
                              ) : (
                                <p style={{ lineHeight: "50px", margin: 0 }}>
                                  Click to select files to upload.
                                </p>
                              )}
                            </div>
                          );
                        }}
                      </Dropzone>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {error && <label className={"error semi-bold"}>{error}</label>}
        <br />
        <button className={"btn btn-primary btn-cons m-t-10"}>
          New Mechant Agreement
        </button>
      </form>
    );
  }
}

export default reduxForm({
  form: "merchantAgreementForm", //                 <------ same form name
  destroyOnUnmount: false, //        <------ preserve form data
  forceUnregisterOnUnmount: true,
  validate,
})(MerchantAgreement);
