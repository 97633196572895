import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import ChoppiesMonthlyReportSpinner from "../reports/Cachet/ChoppiesMonthlyReport/ChoppiesMonthlyReportSpinner";

const actions = {
  closeModal,
};

const choppiesSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <ChoppiesMonthlyReportSpinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(choppiesSpinnerModal);
