import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import Tabs from "../../../app/UI/Tabs/Tabs";

import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TransactionsTextInput";

import { openModal } from "../../modals/modalActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import {
  createReportExcelFile,
  createNewExcelFile,
} from "../../../app/excel/excel";
import {
  getUnallocatedTransactionsForDashboard,
  clearUnallocatedDeposits,
  updateAccount,
} from "../depositsActions";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import DepositListItem from "./details";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let accountSearchText = "";

const transactions = ({
  handleSubmit,
  getUnallocatedTransactionsForDashboard,
  clearUnallocatedDeposits,
  transactions,
  requesting,
  initialValues,
}) => {
  let [expanded, setExpanded] = useState(false);
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment().endOf("day"));
  let [today, setToday] = useState(moment());
  let [transactionsFiltered, setTransactionsFiltered] = useState([]);
  let [selectedRow, setSelectedRow] = useState(-1);
  const [focusedInput, setFocusedInput] = useState(null);

  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let transactionsItems =
    transactionsFiltered.length === 0 ? transactions : transactionsFiltered;

  useEffect(() => {
    console.log("startOf", moment().startOf("day").toDate());
    console.log(
      "initialValues.reportStartDate",
      moment().endOf("day").toDate()
    );

    getUnallocatedTransactionsForDashboard({
      startDate: moment().startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    }).then(() => {
      setLoadingInitial(false);
    });

    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    return () => {
      clearUnallocatedDeposits();
    };
  }, [expanded]);

  const transactionsItemsreportSheet = async (
    transactionsItems,
    workbook,
    logo,
    accountName
  ) => {
    let depositsheet = workbook.addWorksheet("ReceiptIT Unallocated Deposits", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 18,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    depositsheet.mergeCells("A1", "G2");
    depositsheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    depositsheet.mergeCells("A4", "G4");
    depositsheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "ReceiptIT Unallocated Deposits Report",
        },
      ],
    };

    depositsheet.mergeCells("A6", "B6");
    depositsheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Start date: ",
        },
      ],
    };

    depositsheet.mergeCells("A7", "B7");
    depositsheet.getCell("A7").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "End date: ",
        },
      ],
    };

    depositsheet.mergeCells("C6", "E6");
    depositsheet.getCell("C6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(startDate.toDate())
            .startOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    depositsheet.mergeCells("C7", "E7");
    depositsheet.getCell("C7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(endDate.toDate())
            .endOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    depositsheet.mergeCells("A10", "C10");
    depositsheet.mergeCells("D10", "F10");
    depositsheet.mergeCells("G10", "I10");
    depositsheet.mergeCells("J10", "L10");
    depositsheet.mergeCells("M10", "O10");
    depositsheet.getCell("A10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Date Time",
        },
      ],
    };
    depositsheet.getCell("D10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Account",
        },
      ],
    };
    depositsheet.getCell("G10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Channel",
        },
      ],
    };

    depositsheet.getCell("J10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Amount",
        },
      ],
    };

    depositsheet.getCell("M10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Required Correction",
        },
      ],
    };

    let _index_depositsheet = 0;
    transactionsItems.forEach((deposit) => {
      depositsheet.mergeCells(
        `A${11 + _index_depositsheet}`,
        `C${11 + _index_depositsheet}`
      );
      depositsheet.mergeCells(
        `D${11 + _index_depositsheet}`,
        `F${11 + _index_depositsheet}`
      );
      depositsheet.mergeCells(
        `G${11 + _index_depositsheet}`,
        `I${11 + _index_depositsheet}`
      );
      depositsheet.mergeCells(
        `J${11 + _index_depositsheet}`,
        `L${11 + _index_depositsheet}`
      );
      depositsheet.mergeCells(
        `M${11 + _index_depositsheet}`,
        `O${11 + _index_depositsheet}`
      );

      depositsheet.getCell(`A${11 + _index_depositsheet}`).value = `${format(
        deposit.acceptanceCalendarDateTime.toDate(),
        "YYYY-MM-DD  HH:mm:ss"
      )}`;
      depositsheet.getColumn(4).numFmt = "0";
      depositsheet.getCell(
        `D${11 + _index_depositsheet}`
      ).value = `${deposit.reconReference}`;

      depositsheet.getCell(
        `G${11 + _index_depositsheet}`
      ).value = `${deposit.txnIdSubCatName}`;

      depositsheet.getColumn(10).numFmt = "0.00";
      depositsheet.getCell(
        `J${11 + _index_depositsheet}`
      ).value = `${moneyFormat(deposit.reconTransactionAmount)}`;

      depositsheet.getCell(
        `M${11 + _index_depositsheet}`
      ).value = `${deposit.correctionRequired}`;

      _index_depositsheet++;
    });
  };

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  let transactionRowClasses = null;
  let transactionDetailPanelOpenClasses = [classes.RevealMore];

  const loading = requesting[`merchants`];

  !selectedRow
    ? (transactionRowClasses = [classes.MerchantRow])
    : (transactionRowClasses = [
        classes.MerchantRow,
        classes.SelectedTransaction,
      ]);

  // const exportToExcel = async () => {
  //   openModal("SpinnerModal");

  //   await createReportExcelFile(
  //     "Transactions Report",
  //     transactions,
  //     transactionsSheet,
  //     "Prepaid Plus",
  //     startDate.toDate(),
  //     endDate.toDate()
  //   );
  // };

  const onFormSubmit = async (values) => {
    let transactionStartDate = startDate.startOf("day").toDate();
    let transactionEndDate = endDate.endOf("day").toDate();

    setLoadingInitial(true);
    await getUnallocatedTransactionsForDashboard({
      startDate: transactionStartDate,
      endDate: transactionEndDate,
    });
    setLoadingInitial(false);
  };

  // console.log("sortedTransactions", sortedTransactions);

  const filterByAccount = (account) => {
    let keyword = account.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.reconReference.indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    accountSearchText = keyword;
  };

  const filterCreditedDeposits = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.isCredited === true;
    });

    setTransactionsFiltered(filtered);
  };

  const filterWinning = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.transactionType === "Commission";
    });

    setTransactionsFiltered(filtered);
  };

  const filterPendingDeposits = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.isCredited === false;
    });

    setTransactionsFiltered(filtered);
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  let transactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  let salesTotal;
  let winningTotal;
  let creditedDepositsTotal;
  let pendingDepositsTotal;
  let withdrawalsTotal;

  if (loading || loadingInitial)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    winningTotal = transactions.reduce(function (total, doc) {
      total += parseFloat(doc.reconTransactionAmount)
        ? parseFloat(doc.reconTransactionAmount)
        : 0;

      return total;
    }, 0);
    creditedDepositsTotal = transactions
      .filter((item) => item.isCredited === true)
      .reduce(function (total, doc) {
        total += parseFloat(doc.reconTransactionAmount)
          ? parseFloat(doc.reconTransactionAmount)
          : 0;

        return total;
      }, 0);

    pendingDepositsTotal = transactions
      .filter((item) => item.isCredited === false)
      .reduce(function (total, doc) {
        total += parseFloat(doc.reconTransactionAmount)
          ? parseFloat(doc.reconTransactionAmount)
          : 0;

        return total;
      }, 0);

    accountSearchText.length > 0 && transactionsFiltered.length === 0
      ? (transactionList = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5>
                There are no transactions found for query {accountSearchText}
              </h5>
            </div>
          </div>
        ))
      : transactionsItems.length > 0
      ? (transactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date Time</th>
                <th scope="col">Account</th>
                <th scope="col">Channel</th>
                <th style={{ textAlign: "right" }} scope="col">
                  Amount
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  <span>
                    Required <br />
                    Correction
                  </span>
                </th>
                <th style={{ textAlign: "center" }} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionsItems
                .sort(
                  (a, b) =>
                    b.acceptanceCalendarDateTime.toDate() -
                    a.acceptanceCalendarDateTime.toDate()
                )
                .map((transaction, index) => {
                  return (
                    <DepositListItem key={index} transaction={transaction} />
                  );
                })}
            </tbody>
          </table>
        ))
      : (transactionList = <h4>There are no deposits at this time.</h4>);
  }

  return (
    <div label={`Transactions`}>
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilterTools}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div
                  className={"row"}
                  style={{ borderRight: "solid 3px #707070" }}
                >
                  <div className={"col-md-3"}>
                    <Field
                      type="text"
                      className={"form-control"}
                      style={{ marginBottom: 0 }}
                      component={TextInput}
                      placeholder="Outlet Id (ie 1000-01)"
                      name="outletId"
                      id="outletId"
                      autoComplete="off"
                    />
                  </div>

                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      {/* <DateRangePicker
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      } // PropTypes.func.isRequired,
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={onFocusChangeRangeHandler}
                      numberOfMonths={1}
                      isOutsideRange={(date) => date.isAfter(today)}
                      displayFormat={"YYYY-MM-DD"}
                      minimumNights={0}
                      small={true}
                      readOnly={true}
                    /> */}
                      <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => {
                          setStartDate(startDate);
                          setEndDate(endDate);
                        }} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={(date) => date.isAfter(today)}
                        displayFormat={"YYYY-MM-DD"}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />
                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </form>

              <div className={classes.PageFilter}>
                {/* <h4 className="m-r-20"> Search:</h4> */}

                {/* <!-- START Form Control--> */}
                <div className={attachedClasses.join(" ")}>
                  <label>Account Number</label>
                  <div className={"controls"}>
                    <input
                      type="text"
                      className={classes.FormControl}
                      placeholder="Filter by Account"
                      value={accountSearchText}
                      onChange={filterByAccount}
                    />
                  </div>
                </div>
              </div>

              {/* <!-- END Form Control--> */}
            </div>
            <div
              style={{
                marginTop: "15px",
                // marginBottom: "50px",
                marginLeft: "33%",
                float: "right",
                cursor: "pointer",
              }}
              className={classes.ToolsExportIconWrapper}
              onClick={() =>
                createNewExcelFile(
                  "RecieptIT Unallocated Deposits Report",
                  transactionsItems,
                  transactionsItemsreportSheet,
                  "RecieptIT Unallocated Deposits Report"
                )
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className={classes.MerchantTableWrapper}>
          <div
            className={classes.TableWrapper}
            style={{ flexDirection: "column" }}
          >
            <div>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">All Deposits</th>
                    <th scope="col">Credited Deposits</th>
                    <th scope="col">Pending Deposits</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.Totals}>
                    <td onClick={() => filterWinning()}>
                      {moneyFormat(winningTotal)}
                    </td>
                    <td onClick={() => filterCreditedDeposits()}>
                      {moneyFormat(creditedDepositsTotal)}
                    </td>
                    <td onClick={() => filterPendingDeposits()}>
                      {moneyFormat(pendingDepositsTotal)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ display: "flex", marginTop: "3rem" }}>
              {transactionList}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  getUnallocatedTransactionsForDashboard,
  clearUnallocatedDeposits,
  updateAccount,
  openModal,
  createReportExcelFile,
  createNewExcelFile,
};

const mapStateToProps = (state) => ({
  transactions: state.deposits,
  requesting: state.firestore.status.requesting,
  initialValues: {
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(transactions)
);
