import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AmendPettyCashRequestForm from "../pettycash/amendRequestForm/pettyCashAmendRequestForm";

const actions = { closeModal };

const pettyCashAmendRequestModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Amend Petty Cash Request" onClose={closeModal}>
      <AmendPettyCashRequestForm />
    </Modal>
  );
};

export default connect(null, actions)(pettyCashAmendRequestModal);
