import React, { Component, Fragment } from "react";

import classes from "./InventoryList.module.css";
import SIMCard from "./SIMCard/SIMCard";
import Devices from "./Devices/Devices";
import Computers from "./Computers/Computers";
import ProformaInvoice from "./ProformaInvoice/ProformaInvoices";

class InventoryItems extends Component {
  renderSwitch(param) {
    console.log("param", param);
    switch (param) {
      case "SIM":
        return <SIMCard inventoryItemList={this.props.inventoryItemList} />;
      case "Device":
        return <Devices inventoryItemList={this.props.inventoryItemList} />;
      case "Computer":
        return <Computers inventoryItemList={this.props.inventoryItemList} />;
      case "PI NUMBER":
        return (
          <ProformaInvoice
            inventoryItemList={this.props.inventoryItemList}
            invoicedItemList={this.props.invoicedItemList}
          />
        );
      default:
        return null;
    }
  }

  render() {
    let { inventoryType } = this.props;
    console.log("this.props", this.props);

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            {this.renderSwitch(inventoryType)}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default InventoryItems;
