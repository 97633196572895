import React, { useState, useEffect, Fragment } from "react";
import format from "date-fns/format";

import classes from "./index.module.css";

import { moneyFormat } from "../../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const competitionListItem = (props) => {
  let { transaction, index } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>{`${transaction.createdBy} - ${transaction.name}`}</td>
        <td>{transaction.saleType}</td>
        <td>
          {transaction.saleType === "Airtime"
            ? transaction.provider
            : transaction.transactionDetail}
        </td>
        <td>{moneyFormat(transaction.transactionAmount)}</td>
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="5">
            <Detail transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default competitionListItem;
