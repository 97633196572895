import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import firebase from "../../../../app/config/firebase";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import TextInput from "../../../../app/common/form/TextInput";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween
} from "revalidate";
import styles from "./EnableMerchant.module.css";
import { activateMerchant } from "../../../merchants/merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthBetween(4, 4)({
      message: "Must be 4 numbers"
    })
  )()
});

var storage = firebase.storage();

class enableMerchant extends Component {
  state = {
    //showSideDrawer: false
    agreementUrl: ""
  };

  componentDidMount() {
    storage
      .ref()
      .child(
        `${this.props.merchant.account}/merchantcontract/${
          this.props.merchant.account
        }MerchantContract`
      )
      .getDownloadURL()
      .then(url => {
        console.log("agreementRefUrl", url);

        this.setState({
          agreementUrl: url
        });
      });
  }

  componentDidUpdate() {
    storage
      .ref()
      .child(
        `${this.props.merchant.account}/merchantcontract/${
          this.props.merchant.account
        }MerchantContract`
      )
      .getDownloadURL()
      .then(url => {
        console.log("agreementRefUrl", url);

        this.setState({
          agreementUrl: url
        });
      });
  }

  render() {
    const { merchant, activateMerchant,loading, handleSubmit, closePanel } = this.props;

    const onFormSubmit = values => {
      activateMerchant(merchant.account);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Merchant:
                <span className={"semi-bold"}>{merchant.name}</span>
              </h5>
              <h5>
                Agreement:
                <a
                  className={"semi-bold"}
                  href={this.state.agreementUrl}
                  target="_blank"
                >
                  {this.props.merchant.name}
                </a>
              </h5>
              <br />
              {/* <br /> */}
              {/* <div className={"row clearfix"}>
              <div className={"col-sm-6"}>
                <Field
                  name="password"
                  component={TextInput}
                  type="text"
                  className={"form-control"}
                  placeholder="Password"
                  pattern="[0-9]{4}"
                  required
                />
              </div>
            </div> */}
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">

                    <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span>Enable Merchant</span>} 
                
              </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  activateMerchant
};

const mapStateToProps = state => ({
  loading: state.async.loading,
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableMerchantForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(enableMerchant)
);
