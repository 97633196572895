import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import styles from "./EnableComputer.module.css";
import { enableComputerAction } from "../../../../../merchantActions";

const enableComputer = ({
  enableComputerAction,
  handleSubmit,
  loading,
  closePanel,
  computer,
}) => {
  const onFormSubmit = (values) => {
    enableComputerAction(computer.id);
    closePanel();
  };

  return (
    <div label="Computers">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Computer Name:
              <span className={"semi-bold"}>{computer.name}</span>
            </h5>
            <h5>
              MAC Address:
              <span className={"semi-bold"}>{computer.macAddress}</span>
            </h5>

            <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={styles.btn}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <span>Enable Computer</span>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  enableComputerAction,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableComputerForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(enableComputer)
);
