import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import classes from "./details.module.css";
import Tabs from "../../../../app/UI/Tabs/Tabs";
import format from "date-fns/format";
import { openModal } from "../../../modals/modalActions";

const detail = (props) => {
  let { transaction, profile, openModal, reportStartDate, reportEndDate } =
    props;

  const userRole = profile.role;
  const userType = profile.userType;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  let chunkPin = (pin) => {
    if (/[a-zA-Z]/.test(pin)) {
      return pin;
    } else {
      let pinArray = pin.match(/.{1,4}/g);

      let chunkedPin = "";

      pinArray.forEach((element) => {
        chunkedPin = chunkedPin + ` ${element}`;
      });

      return chunkedPin;
    }
  };

  const transactionDetail = (transaction) => {
    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Electricity"
    ) {
      const response = transaction.response;
      let position = response.search("stsCipher") + 10;

      let end = position + 20;
      let result = response.substring(position, end);

      return (
        <div className={"col-lg-4"}>
          <div className={classes.FieldWrapper}>
            <label
              className={
                "control-label all-caps small no-margin hint-text semi-bold"
              }
            >
              Recharge Number
            </label>

            <h5
              className={"no-margin"}
              style={{
                userSelect: "text",
              }}
            >
              {chunkPin(result)}
            </h5>
          </div>
        </div>
      );
    }

    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Airtime"
    ) {
      const response = transaction.response;

      let pin = response.voucherPinNumber;
      let serialNo = response.voucherSerialNumber
        ? response.voucherSerialNumber
        : 0;
      let reference = response.transaction_reference
        ? response.transaction_reference
        : "None";

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Recharge Number
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {pin}
              </h5>
            </div>
          </div>

          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Serial Number
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {serialNo}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Seller Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {reference}
              </h5>
            </div>
          </div>
        </>
      );
    }

    if (
      transaction.transactionType === "Sale" &&
      transaction.saleType === "Payments" &&
      transaction.saleTypeDetail === "DSTV Payment"
    ) {
      console.log("transaction.response", transaction.response);

      const response = transaction.response;

      let name = `${response.customer_name} ${response.customer_surname}`;
      let confirmation_number = response.confirmation_number
        ? response.confirmation_number
        : 0;
      let reference = response.transaction_reference
        ? response.transaction_reference
        : "None";

      return (
        <>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Customer Name
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {name}
              </h5>
            </div>
          </div>

          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                DSTV Confirmation
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {confirmation_number}
              </h5>
            </div>
          </div>
          <div className={"col-lg-4"}>
            <div className={classes.FieldWrapper}>
              <label
                className={
                  "control-label all-caps small no-margin hint-text semi-bold"
                }
              >
                Seller Reference
              </label>

              <h5
                className={"no-margin"}
                style={{
                  userSelect: "text",
                }}
              >
                {reference}
              </h5>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={"card card-default"}>
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"card card-transparent"}>
                <div className={"card-body"}>
                  <Tabs>
                    <div label="Transaction Details">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          <div className={"row p-t-10"}>
                            <div className={"col-lg-4"}>
                              <label
                                className={
                                  "control-label all-caps small no-margin hint-text semi-bold"
                                }
                              >
                                Bank
                              </label>

                              <h5 className={"no-margin"}>
                                {transaction.bank}
                              </h5>
                            </div>
                            <div className={"col-lg-4"}>
                              <div>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Date of Deposit
                                </label>
                                <h5 className={"no-margin"}>
                                  {format(
                                    transaction.dateOfDeposit,
                                    "YYYY-MM-DD"
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-lg-4"}>
                              <div>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Deposit Method
                                </label>
                                <h5 className={"no-margin"}>
                                  {transaction.depositMethod}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className={"row p-t-30"}> </div>
                          <div className={"row p-t-30"}>
                            {transaction.createdBy === "ReceiptIT" ||
                            transaction.transactionCaptureMethod ===
                              "ReceiptIT" ? null : (
                              <div className={"col-lg-4"}>
                                <button
                                  className={classes.AmendDepositBtn}
                                  onClick={() =>
                                    openModal("AmendDepositModal", {
                                      deposit: transaction,
                                      reportStartDate: reportStartDate,
                                      reportEndDate: reportEndDate,
                                    })
                                  }
                                >
                                  Amend Deposit {"  "}
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                              </div>
                            )}
                          </div>

                          <div className={"wizard-footer padding-20"}>
                            <p
                              className={"small hint-text pull-left no-margin"}
                            />
                            <div className={"pull-right"} />
                            <div className={"clearfix"} />
                          </div>
                        </div>
                      </div>
                    </div>

                    {userRole === "Administrator" &&
                      userType === "PrepaidPlus" && (
                        <div label="Transfer">
                          <div className={classes.Details}>
                            <div className={classes.DetailsContent}></div>
                          </div>
                        </div>
                      )}

                    <div label=""></div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(detail);
