import React, { Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import format from "date-fns/format";
import { moneyFormat } from "../../app/common/util/moneyFormat";
import TextInput from "../../app/common/form/TextInput";
import TextArea from "../../app/common/form/TextArea";
import { combineValidators, composeValidators, isRequired } from "revalidate";
import classes from "./selectedTransaction.module.css";
import { reverseTransaction } from "./transactionsActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  reversal_reason: composeValidators(customIsRequired)(),
});

const selectedTransaction = (props) => {
  const { transaction, reversalTransaction, reverseTransaction, handleSubmit } =
    props;

  console.log("reversalTransaction", reversalTransaction);

  const onFormSubmit = (values) => {
    transaction.reversalReason = values.reversal_reason;
    console.log("transaction", transaction);
    reverseTransaction(transaction);
  };

  const transactionDetail = (transaction) => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.saleType
          : transaction.saleType;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType;
        break;
      case "Withdrawal":
        return transaction.withdrawalType;
        break;
      default:
        return transaction.saleType;
    }
  };

  return (
    <div label="Transactions">
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            {/* <h4>
              Selected Transaction:
              <span className={"semi-bold"}>Transaction Details</span>
            </h4> */}

            <div className={classes.Header}>
              {/* <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-start", display: "flex" }}
                >
                  {transaction.transactionType}
                </div>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  {format(
                    transaction.transactionDateTime.toDate(),
                    "YYYY-MM-DD  HH:mm:ss"
                  )}
                </div>
              </div> */}
              <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-start", display: "flex" }}
                >
                  <h5>{transactionDetail(transaction)}</h5>
                </div>
                <div
                  className={"col-md-6"}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >
                  <h5>{moneyFormat(transaction.transactionAmount)}</h5>
                </div>
              </div>

              {reversalTransaction && reversalTransaction[0] ? (
                <Fragment>
                  <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                    <div className={"col-md-12"}>
                      <h5>
                        <strong>Status:</strong> Transaction Reversed
                      </h5>
                    </div>
                  </div>
                  <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                    <div className={"col-md-12"}>
                      <h5>
                        <strong>Date:</strong>{" "}
                        {format(
                          reversalTransaction[0].transactionDateTime.toDate(),
                          "YYYY-MM-DD  HH:mm:ss"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                    <div className={"col-md-12"}>
                      <h5>
                        <strong>Reason:</strong>
                        {` ${reversalTransaction[0].reversalReason}`}
                      </h5>
                    </div>
                  </div>
                </Fragment>
              ) : transaction.transactionType === "Reversal" ? (
                <Fragment>
                  <br />
                  <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                    <div className={"col-md-12"}>
                      <h5>
                        <strong>Reason:</strong>
                        {` ${transaction.reversalReason}`}
                      </h5>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <br />
                  <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                    <div className={"col-md-12"}>
                      <Field
                        label="Reversal Reason"
                        name="reversal_reason"
                        component={TextArea}
                        type="text"
                        className={"form-control"}
                        placeholder="Reason for Reversal"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                    <ul className={"pager wizard no-style"}>
                      <li className="next">
                        <button
                          className={"btn btn-primary btn-cons"}
                          type="submit"
                        >
                          <span>Reverse Transaction</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  reverseTransaction,
};

const mapStateToProps = (state) => ({
  reversalTransaction: state.firestore.ordered.reversalTransaction,
  enableReinitialize: true,
});

// export default connect(
//   mapStateToProps,
//   actions
// )(
//   reduxForm({
//     form: "enableUserForm",
//     destroyOnUnmount: true,
//     forceUnregisterOnUnmount: true,
//     validate
//   })(selectedTransaction)
// );

export default compose(
  firestoreConnect((props) => [
    {
      collection: "transactions",
      storeAs: "reversalTransaction",
      where: ["reversalReference", "==", props.transaction.id],
    },
  ]),
  connect(mapStateToProps, actions)
)(
  reduxForm({
    form: "enableUserForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  })(selectedTransaction)
);
