import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.css";

import ReportBody from "./ReportBody";

class index extends Component {
  state = {
    initialLoading: true,
  };

  render() {
    const reportsLink = `/reports`;

    let reportBody = <ReportBody />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={reportsLink}>Reports</Link> {" - "} Reconciliation
              </span>
              <br />
              <span className={classes.ByLine}>
                You are in the PrepaidPlus Reports panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Vendor Recon.</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>{reportBody}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default index;
