import React from "react";
import { useState, useCallback, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { compose } from "redux";
import format from "date-fns/format";
import { toastr } from "react-redux-toastr";

import {
  asyncValidatePiNumber as asyncValidate,
  asyncValidateImei,
  asyncValidateMacAddress,
  asyncValidateSerialNumber,
} from "../../../../app/common/validation/asyncValidate.jsx";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../../app/common/form/TextInput.jsx";
import AsyncTextInput from "../../../../app/common/form/AsyncTextInput.jsx";
import classes from "./Devices.module.css";

import { batchWriteDeviceInventory } from "../../inventoryActions.jsx";
import { createNewExcelFile } from "../../../../app/excel/excel.jsx";
import LoadingComponent from "../../../../app/layout/LoadingComponent.jsx";
import { combineValidators, isRequired } from "revalidate";
const { readFile, utils, read } = require("xlsx");
const Joi = require("joi");

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};
const validate = combineValidators({
  piNumber: isRequired("piNumber"),
  manufacturer: isRequired("manufacturer"),
  model: isRequired("model"),
});
const deviceRecord = Joi.object({
  IMEI: Joi.string().required().messages({
    "any.required": "IMEI is required.",
    "string.empty": "Ensure IMEI is not empty.",
  }),
  IMEI2: Joi.string().required().messages({
    "any.required": "IMEI2 is required.",
    "string.empty": "Ensure IMEI2 is not empty.",
  }),
  BT_MAC: Joi.string().required().messages({
    "any.required": "Bluetooth MAC address is required.",
    "string.empty": "Ensure Bluetooth MAC address is not empty.",
  }),
  SN: Joi.string().required().messages({
    "any.required": "Serial Number (SN) is required.",
    "string.empty": "Ensure Serial Number (SN) is not empty.",
  }),
  WIFI_MAC: Joi.string().required().messages({
    "any.required": "WiFi MAC address is required.",
    "string.empty": "Ensure WiFi MAC address is not empty.",
  }),
}).unknown(true);

function stringToMacAddress(input) {
  if (input.length !== 12) {
  }

  const pairs = input.match(/.{1,2}/g);

  if (pairs) {
    return pairs.join("-").toUpperCase();
  }
}

const listOfDeviceRecords = Joi.array().items(deviceRecord);
const RegisterDeviceList = ({
  onSuccessClose,
  batchWriteDeviceInventory,
  handleSubmit,
  error,
}) => {
  // const handleSubmit
  let [parsedRecords, setParsedRecords] = useState([]);
  let [validatedRecords, setValidatedRecords] = useState([]);
  let [invalidRecords, setInvalidRecords] = useState([]);
  const [validationError, setValidationError] = useState("Enter device list");
  const [disableButton, setDisableButton] = useState(true);

  let [inventoryValidation, setInventoryValidation] = useState(false);
  let [loading, setLoading] = useState(false);
  let allRecords = [];
  useEffect(() => {
    validateData().then(() => {
      setLoading(false);
      setInventoryValidation(true);
    });
  }, [parsedRecords]);

  const onFormSubmit = async (values) => {
    console.log("form submit values:", values);
    setLoading(true);
    if (disableButton == true) {
      setValidationError("Enter device excel");
    } else {
      await batchWriteDeviceInventory(values, validatedRecords);
      onSuccessClose(false);
    }

    setLoading(false);
  };

  const validateData = async () => {
    setLoading(true);
    let newInventory = [];
    let existingInventory = [];
    await Promise.all(
      parsedRecords.map(async (value) => {
        const imeiExists = await asyncValidateImei(value);
        const macAddressExists = await asyncValidateMacAddress(value);
        const serialNumberExists = await asyncValidateSerialNumber(value);

        if (imeiExists || macAddressExists || serialNumberExists) {
          imeiExists
            ? (value.reason = "imei number exists")
            : macAddressExists
            ? (value.reason = "mac address exists")
            : serialNumberExists
            ? (value.reason = "serial number exists")
            : (value.reason = null);

          existingInventory.push(value);
        } else {
          newInventory.push(value);
        }
      })
    );
    setLoading(false);
    setInvalidRecords([...new Set(existingInventory)]);
    setValidatedRecords([...new Set(newInventory)]);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setDisableButton(false);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const records = utils.sheet_to_json(worksheet);
        const validateRecords = listOfDeviceRecords.validate(records);

        if (validateRecords.error) {
          setValidationError(validateRecords.error.message);
        } else {
          for (let value of records) {
            if (value.IMEI != undefined) {
              const deviceInventory = {
                imei: value.IMEI,
                imei2: value.IMEI2,
                macAddress: stringToMacAddress(value.BT_MAC),
                serialNumber: value.SN,
                wifiAddress: stringToMacAddress(value.WIFI_MAC),
              };

              allRecords.push(deviceInventory);
            }
          }

          setParsedRecords(allRecords);

          setLoading(false);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-2"}>
          <Field
            label="Proforma Invoice id"
            name="piNumber"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Proforma Invoice id"
            required="required"
          />
        </div>

        <div className={"col-sm-2"}>
          <Field
            label="Manufacturer"
            name="manufacturer"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="manufacturer"
            required="required"
          />
        </div>
        <div className={"col-sm-2"}>
          <Field
            label="Model"
            name="model"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="model"
            required="required"
          />
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className={""}>
              {parsedRecords.length === 0 ? (
                <div className={"row clearfix"}>
                  <div className={"col-sm-6"}>
                    <div className={classes.Dropzone}>
                      <div {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          name="excelDropzone"
                          // required
                        />
                        <div className={classes.CenteredDiv}>
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </div>
                      </div>
                    </div>
                    {validationError && (
                      <label className={"error semi-bold"}>
                        {validationError}
                      </label>
                    )}
                  </div>
                </div>
              ) : null}
              {invalidRecords.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <h4>
                      List contains invalid records, count :{" "}
                      {invalidRecords.length}
                    </h4>
                    <div
                      onClick={() =>
                        createNewExcelFile(
                          "Invalid device records",
                          invalidRecords,
                          invalidDeviceListSheet
                        )
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        width="24"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path
                            id="path1"
                            transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                            fill="#707070"
                            d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div>
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">Imei</th>
                          <th scope="col">Imei2</th>
                          <th scope="col">MAC Address</th>
                          <th className={"hide-s"} scope="col">
                            Serial Number
                          </th>
                          <th className={"hide-s"} scope="col">
                            Wifi Address
                          </th>
                          <th>Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invalidRecords.map((inventoryItem, index) => {
                          return (
                            <tr key={index}>
                              <td>{inventoryItem.imei}</td>
                              <td>{inventoryItem.imei2}</td>
                              <td>{inventoryItem.macAddress}</td>
                              <td>{inventoryItem.serialNumber}</td>
                              <td>{inventoryItem.wifiAddress}</td>
                              <td>{inventoryItem.reason}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : null}
              {invalidRecords.length == 0 && validatedRecords.length > 0 ? (
                <div>
                  <h4>Valid records :{validatedRecords.length}</h4>
                </div>
              ) : null}
              {loading ? <LoadingComponent /> : null}
            </div>
          </div>
          <div className={"col-sm-4"} />
        </div>
      </div>
      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}

      {/* {disableButton && <label>Enter device records</label>} */}

      {invalidRecords.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {/* <div>
            <button className={"btn btn-primary btn-cons m-t-10"} disabled>
              <div>List contains invalid records</div>
            </button>
          </div> */}
          <div>
            <h3>Existing inventory</h3>
          </div>
        </div>
      ) : (
        <>
          <div>
            {disableButton ? (
              <button className={"btn btn-primary btn-cons m-t-10"} disabled>
                <div>Enter device inventory</div>
              </button>
            ) : (
              <button className={"btn btn-primary btn-cons m-t-10"}>
                <div>Enter device inventory</div>
              </button>
            )}
          </div>
          <div>
            <h3>Existing inventory</h3>
          </div>
        </>
      )}
    </form>
  );
};
const invalidDeviceListSheet = async (invalidDevices, workbook, logo) => {
  let worksheet = workbook.addWorksheet("Invalid device list", {
    properties: {
      showGridLines: false,
    },
    pageSetup: {
      paperSize: 9,
      fitToPage: true,
      orientation: "portrait",
    },
    views: [
      {
        state: "frozen",
        xSplit: 9,
        activeCell: "A1",
        showGridLines: false,
      },
    ],
  });

  worksheet.mergeCells("A1", "H2");

  worksheet.mergeCells("A3", "H3");

  worksheet.mergeCells("A4", "H4");
  worksheet.getCell("A4").value = `Invalid device list`;
  worksheet.getRow(4).font = {
    name: "Calibri",
    family: 4,
    size: 20,
    bold: true,
  };

  worksheet.mergeCells("A5", "H5");

  let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

  worksheet.mergeCells("A6", "H6");

  worksheet.getCell("A6").value = {
    richText: [
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: "Date generated: ",
      },
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          bold: true,
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: `${now}`,
      },
    ],
  };

  worksheet.addImage(logo, {
    tl: {
      col: 0.25,
      row: 0.25,
    },
    ext: {
      width: 150,
      height: 32,
    },
  });

  worksheet.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3,
  };

  let headers = worksheet.getRow(8);
  headers.height = 25;
  headers.values = [
    "Imei",
    "Imei2",
    "MacAddress",
    "SerialNumber",
    "WifiAddress",
    "Reason",
  ];
  headers.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  headers.eachCell((cell) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      bottom: {
        style: "thick",
      },
    };
  });

  worksheet.columns = [
    {
      key: "imei",
      width: 15,
    },
    {
      key: "imei2",
      width: 15,
    },
    {
      key: "macAddress",
      width: 20,
    },
    {
      key: "serialNumber",
      width: 18,
    },
    {
      key: "wifiAddress",
      width: 18,
    },
    {
      key: "reason",
      width: 18,
    },
  ];

  invalidDevices.forEach((doc, index) => {
    worksheet
      .addRow({
        imei: doc.imei,
        imei2: doc.imei2,
        macAddress: doc.macAddress,
        serialNumber: doc.serialNumber,
        wifiAddress: doc.wifiAddress,
        reason: doc.reason,
      })
      .commit();
    // let rowNum = index + 9;
    // let row = worksheet.getRow(rowNum);
    // row.outlineLevel = 2;
  });
};
const mapStateToProps = (state) => {
  let devices = {};

  devices = state.firestore.ordered.inventory.filter(
    (item) => item.isAllocated === false && item.deviceType === "Device"
  );
  return {
    devices,
  };
};

const actions = {
  batchWriteDeviceInventory,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerDevice",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["piNumber", "excelDropzone"],
    validate,
  })
)(RegisterDeviceList);
