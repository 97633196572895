import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";

import { createCommissionDeposit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const isFloat = createValidator(
  (message) => (value) => {
    if (!parseFloat(value)) {
      return message;
    }
  },

  (field) => `Must be numeric`
);

const validate = combineValidators({
  sales_amount: composeValidators(
    customIsRequired,
    isFloat({
      message: "Must be a number",
    })
  )(),
  commission_rate: composeValidators(
    customIsRequired,
    isFloat({
      message: "Must be a number",
    })
  )(),
  vendor_commission_rate: composeValidators(
    customIsRequired,
    isFloat({
      message: "Must be a number",
    })
  )(),
});

const index = ({ handleSubmit, createCommissionDeposit, error }) => {
  let [amountCalculated, setAmountCalculated] = useState(false);
  let [commissionAmount, setCommissionAmount] = useState(0);
  let [vendorCommissionAmount, setVendorCommissionAmount] = useState(0);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  // useEffect(() => {}, []);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const resetCalculatedCommission = () => {
    setAmountCalculated(false);
  };

  const calculateCommission = (entries) => {
    let values = entries.values;

    console.log('values',values)
    let calculatedCommision =
      parseFloat(values.sales_amount) *
      (parseFloat(values.commission_rate) / 100);

    let calculatedVendorCommision =
      parseFloat(values.sales_amount) *
      (parseFloat(values.vendor_commission_rate) / 100);

      values.vendorCommissionAmount=calculatedVendorCommision;
      values.commissionAmount=calculatedCommision;

    setCommissionAmount(calculatedCommision);
    setVendorCommissionAmount(calculatedVendorCommision);
    setAmountCalculated(true);
    setFocusedInput(focusedInput);
  };

  const onFormSubmit = (values) => {
    amountCalculated
      ? createCommissionDeposit({
          values,
        })
      : calculateCommission({
          values,
        });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="Sales Amount"
            name="sales_amount"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Sales"
            required="required"
            onChange={resetCalculatedCommission}
          />
        </div>
        <div className={"col-sm-6"}>
          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId="startDate" // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId="endDate" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
            numberOfMonths={1}
            isOutsideRange={(date) => date.isAfter(today)}
            displayFormat={"YYYY-MM-DD"}
            minimumNights={0}
            small={true}
            readOnly={true}
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="Commission Rate"
            name="commission_rate"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Commission Rate"
            required="required"
            onChange={resetCalculatedCommission}
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            label="Vendor Commission Rate"
            name="vendor_commission_rate"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Vendor Rate"
            required="required"
            onChange={resetCalculatedCommission}
          />
        </div>
      </div>

      <br />

      {amountCalculated && (
        <Fragment>
          <div className={"row clearfix"}>
            <h6>
              Period:{" "}
              <span className={"semi-bold"}>
                {startDate.format("YYYY-MM-DD")}
              </span>
              <span> to </span>
              <span className={"semi-bold"}>
                {endDate.format("YYYY-MM-DD")}
              </span>
            </h6>
          </div>
          <div className={"row clearfix"}>
            <h6>
              Merchant Commission:{" "}
              <span className={"semi-bold"}>{commissionAmount.toFixed(2)}</span>
            </h6>
          </div>
          <div className={"row clearfix"}>
            <h6>
              Vendor Commission:{" "}
              <span className={"semi-bold"}>
                {vendorCommissionAmount.toFixed(2)}
              </span>
            </h6>
          </div>
        </Fragment>
      )}

      {error && <label className={"error semi-bold"}>{error}</label>}

      <button className={"btn btn-primary btn-cons m-t-10"}>
        {amountCalculated ? "Create Commission" : "Calculate Commission"}
      </button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    account: state.modals.modalProps.account,
    merchantName: state.modals.modalProps.merchantName,
    transactionCategory: "Credit",
    transactionType: "Commission",
    depositType: "Commission Deposit",
    commissionType: "Electricity",
    provider: "Botswana Power Corporation",
    vendorCommissionAmount:0,
    commissionAmount:0,
    isTransactionCharged: false,
  },
  enableReinitialize: true,
});

const actions = {
  createCommissionDeposit,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "commissionDepositForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  })(index)
);
