import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import classes from "./details.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { Field } from "redux-form";
import TextInput from "../../../../app/common/form/TransactionsTextInput";
import { reduxForm } from "redux-form";

const detail = (props) => {
  let { transaction, closeExpanded, profile, handleSubmit, loading } = props;

  let [loadingInitial, setLoadingInitial] = useState(true);

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  const onFormSubmit = async (values) => {
    console.log("values", values, transaction.msgId);

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;

    setLoadingInitial(true);
    // await updateAccount(values);
    closeExpanded(false);
    setLoadingInitial(false);
  };
  // let handleClick = () => {
  //   redeemWinningPurchase({
  //     id: transaction.id,
  //     meterNumber: transaction.meterNumber,
  //     transactionAmount: transaction.transactionAmount,
  //     clientSaleId: moment().valueOf(),
  //     createdBy: profile.displayName,
  //   });
  // };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          {/* <header>
            <h4>Account</h4>
          </header> */}

          <div className={classes.FuneralSchemeWrapper}>
            <div>
              <table className={classes.FuneralSchemeTable}>
                <thead>
                  <tr>
                    <th scope="col">Status</th>
                    <th className={"hide-s"} scope="col">
                      Message
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.MemberRow}>
                    <td>{transaction.status}</td>
                    <td>{transaction.message}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

// const actions = {
//   updateAccount,
// };

// export default connect(mapStateToProps, actions)(detail);
export default connect(
  mapStateToProps
  // actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(detail)
);
