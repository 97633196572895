import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import "./index.module.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import App from "./app/layout/App";
import { configureStore } from "./store/configureStore";
import ScrollToTop from "./app/common/util/ScrollToTop";
// import { loadMerchantTransactions } from "./features/merchants/Merchant/MerchantReports/merchantReportActions";

const store = configureStore();
//store.dispatch(loadMerchantTransactions())

const rootEl = document.getElementById("root");

let render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <ReduxToastr
            position="bottom-right"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
          />
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
};

// if (module.hot) {
//   module.hot.accept('./app/layout/App', () => {
//     setTimeout(render);
//   });
// }

render();
