//import liraries
import React from 'react';
import classes from './Logo.module.css';
import logo, { ReactComponent as PrepaidPlusLogo } from "../../assets/logo.svg";

// create a component
const Logo = props => (
  <div className={classes.Logo} style={{ height: props.height }}>
    <PrepaidPlusLogo className={classes.Logo} />
  </div>
);

//make this component available to the app
export default Logo;
