import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../assets/spinner.svg";
import styles from "./EnableUser.module.css";
import { enablePortalUser } from "../../../merchantActions";

const enableUser = props => {
  const { user, enablePortalUser, handleSubmit,loading, closePanel } = props;

  const onFormSubmit = values => {
    enablePortalUser(user.id);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              Selected User:
              <span className={"semi-bold"}>{user.displayName}</span>
            </h4>
            <br />
            {/* <br /> */}
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">

                  <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span>Enable User</span>} 
                
              </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.async.loading,
});

const actions = {
  enablePortalUser
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
  })(enableUser)
);
