//import liraries
import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { openModal } from "../modals/modalActions";
import classes from "./index.module.css";
import Tabs from "../../app/UI/Tabs/Tabs";
import Summary from "./Summary";
import History from "./History";
import UploadAirtime from "./Upload";
import LoadingComponent from "../../app/layout/LoadingComponent";

import { getSummaryForDashboard, clearSummary } from "./airtimeActions";

// create a component

const index = ({
  profile,
  openModal,
  getSummaryForDashboard,
  clearSummary,
}) => {
  let [loadingInitial, setLoadingInitial] = useState(true);

  const userRole = profile.role;
  const userType = profile.userType;

  useEffect(() => {
    getSummaryForDashboard().then(() => {
      setLoadingInitial(false);
    });

    return () => {
      clearSummary();
    };
  }, []);

  return loadingInitial ? (
    <LoadingComponent inverted={true} />
  ) : (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Airtime</span>

            <br />
            <span className={classes.ByLine}>
              You are in the Airtime panel.
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h3>Airtime</h3>
                    </div>
                  </header>

                  <div className={"row"}>
                    <div className={"card card-transparent"}>
                      <div className={"card-body"}>
                        <Tabs>
                          <div label={`Summary`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.Header}>
                                <h4>Summary</h4>
                              </div>
                            </header>
                            <Summary />
                          </div>
                          <div label="Upload History">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Uploads</h4>
                              </div>
                            </header>
                            <History />
                          </div>
                          <div label={`Upload Airtime`}>
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <h4>Upload Airtime</h4>
                              </div>
                              {userRole === "Administrator" &&
                                userType === "PrepaidPlus" && (
                                  <div className={classes.AddUser}>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        openModal("UploadAirtimeModal")
                                      }
                                    >
                                      Parse Airtime File
                                    </button>
                                  </div>
                                )}
                            </header>

                            <UploadAirtime />
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  openModal,
  getSummaryForDashboard,
  clearSummary,
};

export default connect(mapStateToProps, actions)(index);
