import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_MERCHANT,
  UPDATE_MERCHANT,
  DELETE_MERCHANT,
  SELECT_MERCHANT,
  FETCH_MERCHANTS
} from "./merchantConstants";

const initialState = [];

export const createMerchant = (state, payload) => {
  return [...state, Object.assign({}, payload.merchant)];
};

export const updateMerchant = (state, payload) => {
  return [
    ...state.filter(merchant => merchant.id !== payload.merchant.id),
    Object.assign({}, payload.merchant)
  ];
};

export const deleteMerchant = (state, payload) => {
  return [...state.filter(merchant => merchant.id !== payload.merchantId)];
};

export const selectMerchant = (selectedMerchant = null, payload) => {
  if (payload !== null) {
    console.log("payload", payload.merchant);
    return payload.merchant;
  }
  return selectedMerchant;
};

export const fetchMerchants = (state, payload) => {
  return payload.merchants;
};

// export const fetchMerchantsTransactions = (state, payload) => {
//   // console.log('payload',payload)
//   return payload;
// };

export default createReducer(initialState, {
  [CREATE_MERCHANT]: createMerchant,
  [UPDATE_MERCHANT]: updateMerchant,
  [DELETE_MERCHANT]: deleteMerchant,
  [SELECT_MERCHANT]: selectMerchant,
  [FETCH_MERCHANTS]: fetchMerchants
});
