import React  from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import styles from "./UnassignDevice.module.css";
import { unassignDeviceFromOutlet } from "../../../../../../inventory/inventoryActions";

const unassignDevice = props => {
  const {
    handleSubmit,loading,
    closePanel,
    device,
    unassignDeviceFromOutlet
  } = props;

  const onFormSubmit = values => {
    unassignDeviceFromOutlet( device.deviceId, device.simId,device.outletAssignmentId);
    closePanel();
  };
 
  return (
    <div label="UnassignDevice">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h5>
              Assigned to:
              <span className={"semi-bold"}>
                {device && device.outletId}
              </span>
            </h5>

            <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">

                  <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span>Remove Device</span>} 
                
              </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  unassignDeviceFromOutlet
};

const mapStateToProps = state => ({
  loading: state.async.loading,
});


export default compose(
  connect(
    mapStateToProps,
    actions
  )
)(
  reduxForm({
    form: "unAssignDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  })(unassignDevice)
);
