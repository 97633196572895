import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import SelectInput from "../../../../app/common/form/SelectInput";
import AsyncTextInput from "../../../../app/common/form/AsyncTextInput";

import TextInput from "../../../../app/common/form/TextInput";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";

import { addSelfServiceAccount } from "../../merchantActions";

import styles from "./AddSelfService.module.css";

const validate = combineValidators({
  firstName: isRequired("First Name"),
  lastName: isRequired("Last Name"),
  email: isRequired("Email"),
  contactNumber: isRequired("Contact Number"),
});

const onCreateSelfServiceClick = async (merchant) => {
  this.setState({ loadingInitial: true });
  console.log("merchant", merchant);

  // const account = merchant.account;
  await this.props.addSelfServiceAccount(merchant);
  this.setState({ loadingInitial: false });
};

const AddSelfService = ({
  addSelfServiceAccount,
  merchant,
  handleSubmit,
  loading,
  error,
  invalid,
  submitting,
  merchantId,
  onClose,
}) => {
  const onFormSubmit = async (values) => {
    console.log("merchant", merchant);
    await addSelfServiceAccount(values, merchant);

    // props.onClose();
  };

  console.log("merchant", merchant);
  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        <form className={"p-t-15"} onSubmit={handleSubmit(onFormSubmit)}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>First Names</label>
              <Field
                type="text"
                name="firstName"
                component={TextInput}
                placeholder="First Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
            <div className={"col-md-6"}>
              <label>Last Name</label>
              <Field
                type="text"
                name="lastName"
                component={TextInput}
                placeholder="Last Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>Email</label>
              <Field
                type="email"
                name="email"
                component={AsyncTextInput}
                placeholder="E-mail"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>

            <div className={"col-md-6"}>
              <label>Contact Number</label>
              <Field
                type="text"
                name="contactNumber"
                component={TextInput}
                placeholder="Contact Number"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <p />

          {error && <label className={"error semi-bold"}>{error}</label>}

          <button className={styles.btn} type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <span>Add Self Service</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
  merchant: state.modals.modalProps.merchant,
});

const actions = {
  addSelfServiceAccount,
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "addSelfServiceForm", validate })(AddSelfService));
