import { createReducer } from "../../app/common/util/reducerUtil";
import {
  FETCH_SMS_TRANSACTIONS,
} from "./smsConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.transactions;
};


export default createReducer(initialState, {
  [FETCH_SMS_TRANSACTIONS]: getTransactionsForDashboard,
});
