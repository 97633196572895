import { toastr } from "react-redux-toastr";

import firebase from "../../app/config/firebase";
import { createReversalTransaction } from "../../app/common/util/helpers";
import { FETCH_WUC_TRANSACTIONS } from "./wucConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

export const getTransactionsForDashboard = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  const transactionsStartDate = values.transactionStartDate; // set to 12:00 am today
  const transactionsEndDate = values.transactionEndDate; // set to 23:59 pm today

  console.log("transactionsStartDate", transactionsStartDate);

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query;

    query = transactionsRef
      .where("provider", "==", "Water Utilities Corporation")
      .where("transactionType", "==", "Sale")
      .where("transactionStatus", "==", "Successful")
      .where("transactionDateTime", ">=", transactionsStartDate)
      .where("transactionDateTime", "<=", transactionsEndDate)
      .orderBy("transactionDateTime", "desc");

    let querySnap = await query.get();

    let transactions = [];

    console.log("Water Utilities Corporation", querySnap.docs.length);

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id,
      };
      transactions.push(transaction);
    }

    console.log("transactions", transactions);

    dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reconcilePayment = (values) => async (dispatch, getState) => {
  const firestore = firebase.firestore();
  const transactionRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    await transactionRef
      .doc(values.id)
      .set(
        {
          reconciledBy: values.reconciledBy,
          paymentReconciled: true,
          dateReconciled: new Date(),
        },
        { merge: true }
      )

      // .get()
      // .then(async (doc) => {
      //   if (doc.exists) {
      //     return doc.data().paymentReconciled ? true : false;
      //   } else {
      //     dispatch(asyncActionFinish());
      //     toastr.error("Filed", "WUC record was not found.");
      //   }
      // })
      .then(() => {
        console.log("refreshing...");

        dispatch(getTransactionsForDashboard());
      });

    console.log("Finished refresh");
    // dispatch(getTransactionsForDashboard());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Winning has been reedeemed.");
  } catch (error) {
    dispatch(asyncActionError());

    toastr.error("Oops", "Something went wrong");
  }
};

export const uploadWUCTXTFile = (values) => async (dispatch, getState) => {
  const firestore = firebase.firestore();
  const transactionRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    await transactionRef
      .doc(values.id)
      .set(
        {
          reconciledBy: values.reconciledBy,
          paymentReconciled: false,
          dateReconciled: new Date(),
        },
        { merge: true }
      )

      // .get()
      // .then(async (doc) => {
      //   if (doc.exists) {
      //     return doc.data().paymentReconciled ? true : false;
      //   } else {
      //     dispatch(asyncActionFinish());
      //     toastr.error("Filed", "WUC record was not found.");
      //   }
      // })
      .then(() => {
        console.log("refreshing...");

        // dispatch(getTransactionsForDashboard());
      });

    console.log("Finished refresh");
    // dispatch(getTransactionsForDashboard());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Txt file successfully delivered.");
  } catch (error) {
    dispatch(asyncActionError());

    toastr.error("Oops", "Something went wrong");
  }
};

export const clearTransactionsForDashboard =
  () => async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());

      let transactions = [];
      dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const reportByCategory = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            saleType: doc.data().saleType,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.saleType in arr))
              arr.__array.push((arr[doc.saleType] = doc));
            else {
              arr[doc.saleType].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    // // console.log("querySnap.docs.length", querySnap.docs.length);
    // let transactions = [];

    // if (querySnap.docs.length === 0) {
    //   dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
    //   dispatch(asyncActionFinish());
    //   return querySnap;
    // }

    // for (let i = 0; i < querySnap.docs.length; i++) {
    //   let transaction = {
    //     ...querySnap.docs[i].data(),
    //     id: querySnap.docs[i].id
    //   };
    //   transactions.push(transaction);
    // }

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByDate = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            transactionDateTime: doc
              .data()
              .dateCreated.toDate()
              .toISOString()
              .slice(0, 10),
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.transactionDateTime in arr))
              arr.__array.push((arr[doc.transactionDateTime] = doc));
            else {
              arr[doc.transactionDateTime].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    console.log("transactions is ", transactions);

    dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByUser = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            userId: doc.data().createdBy,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.userId in arr)) arr.__array.push((arr[doc.userId] = doc));
            else {
              arr[doc.userId].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_WUC_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// export const reverseTransaction = transaction => async (
//   dispatch,
//   getState
// ) => {};

export const reverseTransaction =
  (transaction) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let dateNow = new Date();
    let transactionCategory =
      transaction.transactionCategory === "Debit" ? "Credit" : "Debit";

    // let isTransactionCharged = transaction.isTransactionCharged ? false : true;

    let reverseTransactionObject = createReversalTransaction(
      transaction,
      transactionCategory,
      user
    );

    try {
      dispatch(asyncActionStart());

      await firestore.add(`transactions`, reverseTransactionObject);

      dispatch(asyncActionFinish());
      toastr.success("Success", "Transaction has been reversed");
    } catch (error) {
      console.log(error);

      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
