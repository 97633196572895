const validate = values => {
      const errors = {};
      if (!values.commission_type) {
        errors.commission_type = "Required";
      }
      if (!values.minimum_commission_rate) {
        errors.minimum_commission_rate = "Required";
      }
    
      if (!values.maximum_commission_rate) {
        errors.maximum_commission_rate = "Required";
      }
    
      return errors;
    };
    
    export default validate;