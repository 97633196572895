import React from "react";
import { useState, useCallback, useEffect } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { compose } from "redux";
import format from "date-fns/format";

import {
  asyncValidatePiNumber as asyncValidate,
  asyncValidateSimNumber,
  asyncValidatePhoneNumber,
} from "../../../app/common/validation/asyncValidate.jsx";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../app/common/form/TextInput.jsx";
import AsyncTextInput from "../../../app/common/form/AsyncTextInput.jsx";
import classes from "./SimCards.module.css";

import { batchWriteSimcardInventory } from "../inventoryActions.jsx";
import LoadingComponent from "../../../app/layout/LoadingComponent.jsx";
import { createNewExcelFile } from "../../../app/excel/excel.jsx";
import { combineValidators, isRequired } from "revalidate";
const { readFile, utils, read } = require("xlsx");
const Joi = require("joi");

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};
const validate = combineValidators({
  network: isRequired("network"),
  piNumber: isRequired("piNumber"),
});
const simcardRecord = Joi.object({
  "PHONE NUMBER": Joi.number().required().messages({
    "any.required": "Ensure PHONE NUMBER column is not empty.",
    "number.base": "Ensure PHONE NUMBER column contains only numbers.",
  }),
  "PIN 1": Joi.number().required().messages({
    "any.required": "Ensure PIN 1 column is not empty.",
    "number.base": "Ensure PIN 1 column contains only numbers.",
  }),
  "PIN 2": Joi.number().required().messages({
    "any.required": "Ensure PIN 2 column is not empty.",
    "number.base": "Ensure PIN 2 column contains only numbers.",
  }),
  PUK1: Joi.string().required().messages({
    "any.required": "Ensure PUK1 column is not empty.",
  }),
  "PUK 2": Joi.string().required().messages({
    "any.required": "Ensure PUK 2 column is not empty.",
  }),
  "SIM NUMBER": Joi.number().required().messages({
    "any.required": "Ensure SIM number column is not empty.",
    "number.base": "Ensure SIM number column contains only numbers.",
  }),
}).unknown(true);

const listOfSimCardRecords = Joi.array().items(simcardRecord);
const RegisterSimCardList = ({
  batchWriteSimcardInventory,
  onSuccessClose,
  handleSubmit,
  error,
}) => {
  // const handleSubmit
  const [parsedRecords, setParsedRecords] = useState([]);
  let [validatedRecords, setValidatedRecords] = useState([]);
  let [invalidRecords, setInvalidRecords] = useState([]);
  const [validationError, setValidationError] = useState("Enter sim card list");
  const [disableButton, setDisableButton] = useState(true);

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    validateData().then(() => {
      setLoading(false);
    });
  }, [parsedRecords]);
  const validateData = async () => {
    setLoading(true);
    let newInventory = [];
    let existingInventory = [];

    await Promise.all(
      parsedRecords.map(async (value) => {
        const simNumberExists = await asyncValidateSimNumber(value);
        const phoneNumberExists = await asyncValidatePhoneNumber(value);

        if (simNumberExists || phoneNumberExists) {
          simNumberExists
            ? (value.reason = "Sim number exists")
            : phoneNumberExists
            ? (value.reason = "Phone number exists")
            : (value.reason = null);
          existingInventory.push(value);
        } else {
          newInventory.push(value);
        }
      })
    );

    setLoading(false);
    setInvalidRecords([...new Set(existingInventory)]);
    setValidatedRecords([...new Set(newInventory)]);
  };
  const onFormSubmit = async (values) => {
    console.log(values);
    if (disableButton == true) {
      setValidationError("Enter sim card list");
    } else {
      await batchWriteSimcardInventory(values, validatedRecords);
      onSuccessClose(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setDisableButton(false);

    acceptedFiles.forEach((file) => {
      let xlData = [];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const records = utils.sheet_to_json(worksheet);
        const validateRecords = listOfSimCardRecords.validate(records);

        if (validateRecords.error) {
          setValidationError(validateRecords.error.message);
        } else {
          records.map((value) => {
            const simCardInventory = {
              simNumber: value["SIM NUMBER"],
              pinCode: value["PIN 1"],
              puk1: value["PUK1"],
              pinCode2: value["PIN 2"],
              puk2: value["PUK 2"],
              cellNumber: value["PHONE NUMBER"],
            };

            xlData.push(simCardInventory);
          });
          setParsedRecords(xlData);
          console.log("Finished validating data");
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-3"}>
          <Field
            label="Proforma Invoice id"
            name="piNumber"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Proforma Invoice id"
            required="required"
          />
        </div>
        <div className={"col-sm-3"}>
          <Field
            label="Network"
            name="network"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Network Provider"
            required="required"
          />
        </div>
      </div>
      <div>
        <div>
          <div className={"col-sm-20"}>
            <div className={""}>
              {
                <div className={""}>
                  {parsedRecords.length === 0 ? (
                    <div className={"row clearfix"}>
                      <div className={"col-sm-6"}>
                        <div className={classes.Dropzone}>
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              name="excelDropzone"
                              // required
                            />
                            <div className={classes.CenteredDiv}>
                              <p>
                                Drag 'n' drop some files here, or click to
                                select files
                              </p>
                            </div>
                          </div>
                        </div>
                        {validationError && (
                          <label className={"error semi-bold"}>
                            {validationError}
                          </label>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {invalidRecords.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                        }}
                      >
                        <h4>
                          List contains invalid records, count :{" "}
                          {invalidRecords.length}
                        </h4>
                        <div
                          onClick={() =>
                            createNewExcelFile(
                              "Invalid sim card records",
                              invalidRecords,
                              invalidSimCardListSheet
                            )
                          }
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            width="24"
                            viewBox="0 0 24 24"
                          >
                            <g>
                              <path
                                id="path1"
                                transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                fill="#707070"
                                d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>

                      <table className={classes.MerchantTable}>
                        <thead>
                          <tr>
                            <th scope="col">Cell Number</th>
                            <th className={"hide-s"} scope="col">
                              Sim Number
                            </th>
                            <th scope="col">Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invalidRecords.map((inventoryItem, index) => {
                            return (
                              <tr className={classes.MerchantRow} key={index}>
                                <td>{inventoryItem.cellNumber}</td>
                                <td>{inventoryItem.simNumber}</td>
                                <td>{inventoryItem.reason}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : null}
                  {invalidRecords.length == 0 && validatedRecords.length > 0 ? (
                    <div>
                      <h4>Valid records :{validatedRecords.length}</h4>
                    </div>
                  ) : null}
                  {loading ? <LoadingComponent /> : null}
                </div>
              }
            </div>
          </div>
          <div className={"col-sm-4"} />
        </div>
      </div>
      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      {invalidRecords.length > 0 ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div>
            <h3>Existing inventory</h3>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <button className={"btn btn-primary btn-cons m-t-10"}>
              <div>Enter sim card inventory</div>
            </button>
          </div>
          <div>
            <h3>Existing inventory</h3>
          </div>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = (state) => {
  let devices = {};

  devices = state.firestore.ordered.inventory.filter(
    (item) => item.isAllocated === false && item.deviceType === "Device"
  );
  return {
    devices,
  };
};

const actions = {
  batchWriteSimcardInventory,
};
const invalidSimCardListSheet = async (invalidSimCards, workbook, logo) => {
  let worksheet = workbook.addWorksheet("Invalid sim card list", {
    properties: {
      showGridLines: false,
    },
    pageSetup: {
      paperSize: 9,
      fitToPage: true,
      orientation: "portrait",
    },
    views: [
      {
        state: "frozen",
        xSplit: 9,
        activeCell: "A1",
        showGridLines: false,
      },
    ],
  });

  worksheet.mergeCells("A1", "H2");

  worksheet.mergeCells("A3", "H3");

  worksheet.mergeCells("A4", "H4");
  worksheet.getCell("A4").value = `Invalid sim card list`;
  worksheet.getRow(4).font = {
    name: "Calibri",
    family: 4,
    size: 20,
    bold: true,
  };

  worksheet.mergeCells("A5", "H5");

  let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

  worksheet.mergeCells("A6", "H6");

  worksheet.getCell("A6").value = {
    richText: [
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: "Date generated: ",
      },
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          bold: true,
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: `${now}`,
      },
    ],
  };

  worksheet.addImage(logo, {
    tl: {
      col: 0.25,
      row: 0.25,
    },
    ext: {
      width: 150,
      height: 32,
    },
  });

  worksheet.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3,
  };

  let headers = worksheet.getRow(8);
  headers.height = 25;
  headers.values = [
    "Sim Number",
    "Pin Code 1",
    "Puk Number",
    "Pin Code 2",
    "Puk Number 2",
    "Cell Number",
    "Reason",
  ];
  headers.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  headers.eachCell((cell) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      bottom: {
        style: "thick",
      },
    };
  });

  worksheet.columns = [
    {
      key: "simNumber",
      width: 15,
    },
    {
      key: "pinCode",
      width: 15,
    },
    {
      key: "puk1",
      width: 20,
    },
    {
      key: "pinCode2",
      width: 18,
    },
    {
      key: "puk2",
      width: 18,
    },
    {
      key: "cellNumber",
      width: 18,
    },
    {
      key: "reason",
      width: 18,
    },
  ];

  invalidSimCards.forEach((doc, index) => {
    worksheet
      .addRow({
        simNumber: doc.simNumber,
        pinCode: doc.pinCode,
        puk1: doc.puk1,
        pinCode2: doc.pinCode2,
        puk2: doc.puk2,
        cellNumber: doc.cellNumber,
        reason: doc.reason,
      })
      .commit();
  });
};
export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerDevice",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["piNumber", "excelDropzone"],
    validate,
  })
)(RegisterSimCardList);
