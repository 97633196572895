import React from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";
import format from "date-fns/format";

import { updatePettyCash } from "../../pettyCashActions";
import { Field, reduxForm } from "redux-form";

const returned = (props) => {
  let { transaction, loading, openModal, handleSubmit } = props;

  return (
    <table className={classes.FuneralSchemeTable}>
      <thead>
        <tr>
          <th className={"hide-s"} scope="col">
            Amount
          </th>
          <th className={"hide-s"} scope="col">
            Status
          </th>

          <th className={"hide-s"} scope="col">
            Date Actioned
          </th>
          <th className={"hide-s"} scope="col">
            {" "}
          </th>
          <th className={"hide-s"} scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr className={classes.MemberRow}>
          <td>{transaction.amount}</td>
          <td>{transaction.status}</td>
          <td>
            {format(transaction.dateCreated.toDate(), "YYYY-MM-DD HH:mm:ss")}
          </td>
          <td>{transaction.notes}</td>

          <td align="left" className={"col-sm-4"} style={{ paddingLeft: "0" }}>
            <a href={transaction.documentationUrl} target="_blank">
              View support document
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
};

export default connect(mapStateToProps, actions)(returned);
