import { createReducer } from "../../app/common/util/reducerUtil";
import {
  FETCH_TRANSACTIONS,
  FETCH_RECON_TRANSACTIONS,
  FETCH_REPORTS,
} from "./reportConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.transactions;
};

export const getReconTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.transactions;
};

export const getOutletReports = (state, payload) => {
  console.log("payload", payload);

  return payload;
};

export default createReducer(initialState, {
  [FETCH_TRANSACTIONS]: getTransactionsForDashboard,
  [FETCH_RECON_TRANSACTIONS]: getReconTransactionsForDashboard,
  [FETCH_REPORTS]: getOutletReports,
});
