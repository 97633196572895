import React, { Component } from "react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

import { combineValidators, isRequired } from "revalidate";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { allocateInventoryItem } from "../../inventoryActions";
import SelectInput from "../../../../app/common/form/SelectInput";

import styles from "./RegisterDeviceWithOutlet.module.css";

const validate = combineValidators({
  device: isRequired("device"),
  devicePlan: isRequired("devicePlan"),
  sim: isRequired("sim"),
});

//
const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};

class RegisterDeviceWithOutlet extends Component {
  state = {
    files: [],
    devices: [],
    simCards: [],
    fileName: "",
  };

  onDrop = (files) => {
    console.log("uploaded files", files);
    this.setState({
      files,
      fileName: files[0].name,
    });
  };

  render() {
    this.props.merchantBalance &&
      console.log(
        "this.props.merchantBalance.balance",
        this.props.merchantBalance
      );

    const { allocateInventoryItem, loading, handleSubmit, error } = this.props;

    const onFormSubmit = (values) => {
      // if (this.state.files[0]) {
      // allocateInventoryItem(values, this.state.files[0]);
      // } else {
      //   throw new SubmissionError({
      //     _error: "Attach a Merchant Agreement to proceed",
      //   });
      // }
      allocateInventoryItem(values);
    };

    // let devicePaymentPlans = this.props.devicePaymentPlans
    //   ? this.props.devicePaymentPlans.sort((a, b) =>
    //       a.orderingNumber > b.orderingNumber
    //         ? 1
    //         : b.orderingNumber > a.orderingNumber
    //         ? -1
    //         : 0
    //     )
    //   : null;

    //  this.props.initialValues.userRole == "Supervisor"
    // const newOptions = this.props.devicePaymentPlans;
    // console.log("userRole 1", newOptions);

    // newOptions.forEach((element) => {
    //   console.log(element);
    // });

    // for (let index = 0; index < newOptions.length; index++) {
    //   const element = newOptions[index];
    //   console.log(element);
    // }

    //wawot
    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <Field
              name="devicePlan"
              options={
                this.props.initialValues.userRole == "Supervisor"
                  ? this.props.devicePaymentPlans
                  : this.props.devicePaymentPlansAdmin
              }
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              component={SelectInput}
              placeholder="Select Device Payment Plan..."
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-md-6"}>
            <h4 style={{ marginTop: 0 }}>
              {`Account Balance: ${moneyFormat(
                this.props.merchantBalance && this.props.merchantBalance.balance
              )}`}
            </h4>
          </div>
        </div>

        <div className={"row clearfix m-t-30"}>
          <div className={"col-md-6"}>
            <Field
              name="device"
              options={this.props.devices}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
              })}
              component={SelectInput}
              placeholder="Select Device..."
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>

          {/* <div className={"col-lg-6"} style={{ paddingRight: "7px" }}>
            <div className={"card card-default"}>
              <div className={"card-header "} style={{ zIndex: 0 }}>
                <div className={"card-title"}>
                  Upload Signed Merchant Acceptance Form
                </div>
              </div>
              {this.state.files.length > 0 ? (
                <div className={"card-body no-scroll no-padding dropzone "}>
                  <p
                    style={{
                      lineHeight: "100px",
                      margin: 0,
                      textAlign: "center",
                    }}
                  >
                    {this.state.files[0].name}
                  </p>
                </div>
              ) : (
                <div className={"card-body no-scroll no-padding dropzone "}>
                  <Dropzone
                    config={componentConfig}
                    onDrop={this.onDrop}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => {
                      return (
                        <div
                          {...getRootProps()}
                          style={{ textAlign: "center" }}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p style={{ lineHeight: "100px", margin: 0 }}>
                              Drop files here...
                            </p>
                          ) : (
                            <p style={{ lineHeight: "100px", margin: 0 }}>
                              Click to select files to upload.
                            </p>
                          )}
                        </div>
                      );
                    }}
                  </Dropzone>
                </div>
              )}
            </div>
          </div> */}
        </div>
        <br />
        {error && <label className={"error semi-bold"}>{error}</label>}
        <button className={styles.btn} type="submit" disabled={loading}>
          {loading ? (
            <Spinner
              style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
            />
          ) : (
            <span>Register Device</span>
          )}
        </button>
      </form>
    );
  }
}

const query = (props) => {
  console.log("props.initialValues.account", props.initialValues.account);

  return [
    {
      collection: "inventory",
      storeAs: "unallocatedInventory",
      where: [
        ["isAllocated", "==", false],
        ["isAssignedSIM", "==", true],
      ],
    },
    {
      collection: "devicePaymentPlans",
      storeAs: "devicePaymentPlans",
      where: [["isAvailable", "==", true]],
    },
    {
      collection: "merchant_balance",
      storeAs: "merchantBalance",
      where: [
        ["isActiveBalance", "==", true],
        ["account", "==", props.initialValues.account],
      ],
    },
  ];
};

const mapStateToProps = (state) => ({
  devices:
    state.firestore.ordered.unallocatedInventory &&
    state.firestore.ordered.unallocatedInventory
      .filter((item) => item.deviceType === "Device")
      .map((item) => {
        return {
          label: `${item.manufacturer} ${item.model} - ${item.macAddress} - ${item.cellNumber}`,
          value: item,
        };
      }),
  merchantBalance:
    state.firestore.ordered.merchantBalance &&
    state.firestore.ordered.merchantBalance[0],
  devicePaymentPlansAdmin:
    state.firestore.ordered.devicePaymentPlans &&
    state.firestore.ordered.devicePaymentPlans.map((item) => {
      return {
        label: `${item.plan}`,
        value: item,
      };
    }),
  devicePaymentPlans:
    state.firestore.ordered.devicePaymentPlans &&
    state.firestore.ordered.devicePaymentPlans
      .filter((item) => item.plan === "Cash")
      .map((item) => ({
        label: item.plan,
        value: item,
      })),

  initialValues: {
    outletId: state.modals.modalProps.outletId,
    account: state.modals.modalProps.account,
    userRole: state.modals.modalProps.userRole,
    outletName: state.firestore.ordered.outlet[0].name,
    merchantName: state.firestore.ordered.merchant[0].name,
    group: state.firestore.ordered.merchant[0].group,
  },
  loading: state.async.loading,
});

const actions = {
  allocateInventoryItem,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerDeviceWithOutlet",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(RegisterDeviceWithOutlet);
