//import liraries
import React, { Fragment, Component } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { Link } from "react-router-dom";
import { compose } from "redux";

import EditName from "./EditFields/EditName";
import EditGroup from "./EditFields/EditGroup";
import EditStatus from "./EditFields/EditStatus";
import EditContact from "./EditFields/EditContact";
import EditLocation from "./EditFields/EditLocation";
import EditSellerDetails from "./EditFields/EditSellerDetails";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

import Outlets from "./Outlets/Outlets";
import Documents from "./Documents/Documents";
import CreditLimit from "../../../financials/Credit/CreditLimit";
import Providers from "../../../providers/Providers";

import Users from "./Users/Users";
import { openModal } from "../../../modals/modalActions";
import Tabs from "../../../../app/UI/Tabs/Tabs";
import { createNewExcelFile } from "../../../../app/excel/excel";

import classes from "./MerchantProfile.module.css";

// create a component
class MerchantProfile extends Component {
  state = {
    loadingInitial: true,
    openManageDetailsPanel: false,
    selectedField: null,
    selectedFieldHeader: null,
    outletsFiltered: [],
    outletSearchText: "",
  };

  filterByOutletName = (event) => {
    let outletsList = Object.values(this.props.outlets);
    let keyword = event.target.value.toLowerCase();
    let outletsFiltered = outletsList.filter((outlet) => {
      return outlet.name.toLowerCase().indexOf(keyword) > -1;
    });
    this.setState({
      outletsFiltered,
      outletSearchText: event.target.value,
    });
  };

  outletsListSheet = async (outlets, workbook, logo, accountName) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("Outlet List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 10,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "J2");

    worksheet.mergeCells("A3", "J3");

    worksheet.mergeCells("A4", "J4");
    worksheet.getCell("A4").value = `${accountName} Outlets List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "J5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "J6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = [
      "Name",
      "Account",
      "Type",
      "Contact",
      "E-Mail",
      "Telephone",
      "City",
      "Suburb",
      "Date Created",
      "Created By",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "name",
        width: 20,
      },
      {
        key: "outlet",
        width: 10,
      },
      {
        key: "type",
        width: 15,
      },
      {
        key: "contact",
        width: 25,
      },
      {
        key: "email",
        width: 25,
      },
      {
        key: "telephone",
        width: 10,
      },
      {
        key: "city",
        width: 15,
      },
      {
        key: "suburb",
        width: 18,
      },
      {
        key: "dateCreated",
        width: 12,
      },
      {
        key: "createdBy",
        width: 25,
      },
    ];

    outlets.forEach((doc, index) => {
      worksheet
        .addRow({
          name: doc.name,
          outlet: doc.id,
          type: doc.type,
          contact: doc.contact.name,
          email: doc.contact.email,
          telephone: doc.contact.telephone,
          city: doc.city,
          suburb: doc.suburb,
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  portalUsersListSheet = async (users, workbook, logo, accountName) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("Outlet List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 10,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "J2");

    worksheet.mergeCells("A3", "J3");

    worksheet.mergeCells("A4", "J4");
    worksheet.getCell("A4").value = `${accountName} Portal Users List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "J5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "J6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = [
      "Name",
      "Role",
      "E-mail",
      "Is Active",
      "Date Created",
      "Created By",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "displayName",
        width: 25,
      },
      {
        key: "role",
        width: 20,
      },
      {
        key: "email",
        width: 30,
      },
      {
        key: "disabled",
        width: 10,
      },
      {
        key: "dateCreated",
        width: 15,
      },
      {
        key: "createdBy",
        width: 25,
      },
    ];

    users.forEach((doc, index) => {
      worksheet
        .addRow({
          displayName: doc.displayName,
          role: doc.role,
          email: doc.email,
          disabled: doc.disabled ? "No" : "Yes",
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  handleFieldClick = (selectedField) => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    let field;
    switch (selectedField) {
      case "Name":
        field = (
          <EditName
            name={this.props.merchant.name}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Group":
        field = (
          <EditGroup
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Contact":
        field = (
          <EditContact
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Location":
        field = (
          <EditLocation
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "SellerDetails":
        field = (
          <EditSellerDetails
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
          />
        );
        break;
      case "Status":
        field = (
          <EditStatus
            name={this.props.merchant.group}
            closePanel={this.handleCloseClick}
            account={this.props.merchant.account}
            status={this.props.merchant.isMerchantActive}
          />
        );
        break;

      default:
        field = null;
    }

    this.setState({
      openManageDetailsPanel: true,
      selectedField: field,
      selectedFieldHeader: selectedField,
    });
  };

  handleCloseClick = () => {
    this.setState({
      openManageDetailsPanel: false,
      selectedField: null,
      selectedFieldHeader: null,
    });
  };

  async componentDidMount() {
    this.setState({ loadingInitial: false });
  }

  render() {
    const { merchant, auth, profile, outlets, users, commission } = this.props;
    const { outletsFiltered, outletSearchText } = this.state;
    const { openManageDetailsPanel, selectedField, selectedFieldHeader } =
      this.state;

    console.log("users", users);

    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;
    const reportsLink = `/merchants/${merchant.account}/reports`;
    const financialsLink = `/merchants/${merchant.account}/financials`;

    let attachedOutletListAreaClasses = [
      classes.OutletListArea,
      classes.clearfix,
    ];

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault,
    ];

    const { openModal } = this.props;

    if (this.state.loadingInitial)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    return (
      <div className={"card card-default"}>
        {/* <div className={"card-header"}>
          <div className={"card-title"}>Merchant Details</div>
        </div> */}
        <div className={"card-body"}>
          <header className={classes.TabHeader}>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Name
                </h5>
                <h3 className={"no-margin"}>{merchant.name}</h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Account
                </h5>
                <h3 className={"no-margin"}>{merchant.account}</h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Financials
                </h5>

                <h3
                  className={"no-margin bold"}
                  style={{ display: "inline-block", verticalAlign: "top" }}
                >
                  <Link to={financialsLink}>Financials</Link>
                </h3>
              </div>
            </div>
            <div className={"col-lg-3"} style={{ paddingLeft: "0" }}>
              <div>
                <h5 className={"all-caps small no-margin hint-text semi-bold"}>
                  Reports
                </h5>
                <h3 className={"no-margin bold"}>
                  <Link to={reportsLink}>Reports</Link>
                </h3>
              </div>
            </div>
          </header>

          <div className={"card card-transparent"}>
            <div className={classes.CardBody}>
              <Tabs>
                <div label="Details">
                  <div className={classes.Details}>
                    <div className={classes.DetailsContent}>
                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Name")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Name
                            </label>
                            <h5 className={"no-margin"}>{merchant.name}</h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Group")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Group
                            </label>
                            <h5 className={"no-margin"}>{merchant.group}</h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Account
                          </label>
                          <h5 className={"no-margin"}>{merchant.account}</h5>
                        </div>
                      </div>

                      <div className={"row p-t-10"}>
                        <div className={"col-lg-4"}>
                          <label
                            className={
                              "control-label all-caps small no-margin hint-text semi-bold"
                            }
                          >
                            Date Registered
                          </label>
                          <h5 className={"no-margin"}>
                            {format(
                              merchant.dateCreated &&
                                merchant.dateCreated.toDate(),
                              "YYYY-MM-DD"
                            )}
                          </h5>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Status")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Is Merchant Active
                            </label>
                            <h5 className={"no-margin"}>
                              {merchant.isMerchantActive ? "Yes" : "No"}
                            </h5>
                          </div>
                        </div>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() =>
                              this.handleFieldClick("SellerDetails")
                            }
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              ClientID
                            </label>
                            <h5 className={"no-margin"}>{merchant.sellerId}</h5>
                          </div>
                        </div>
                      </div>

                      <div className={"row p-t-30"}>
                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Contact")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Contact
                            </label>

                            <h5 className={"no-margin"}>
                              {merchant.contact.name}
                            </h5>
                            <h5 className={"no-margin"}>
                              {merchant.contact.email}
                            </h5>
                            <h5 className={"no-margin"}>
                              {merchant.contact.telephone}
                            </h5>
                          </div>
                        </div>

                        <div className={"col-lg-4"}>
                          <div
                            className={classes.FieldWrapper}
                            onClick={() => this.handleFieldClick("Location")}
                          >
                            <label
                              className={
                                "control-label all-caps small no-margin hint-text semi-bold"
                              }
                            >
                              Location
                            </label>

                            <h5 className={"no-margin"}>{merchant.suburb}</h5>
                            <h5 className={"no-margin"}>{merchant.city}</h5>
                          </div>
                        </div>
                        {/* {userRole === "Administrator" &&
                          userType === "PrepaidPlus" && (
                            <div className={"col-lg-4"}>
                              <div>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Self Service
                                </label>
                                {merchant.hasSelfService ? (
                                  <h5 className={"no-margin"}>Active</h5>
                                ) : (
                                  <div className={classes.AddUser}>
                                    <button
                                      className={classes.AddUserBtn}
                                     
                                      onClick={() =>
                                        openModal("AddSelfServiceModal", {
                                          merchant: merchant,
                                        })
                                      }
                                    >
                                      Add Self Service
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )} */}

                        <div className={"col-lg-4"}>
                          <div>
                            {merchant.hasSelfService ? (
                              <>
                                <label
                                  className={
                                    "control-label all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Self Service
                                </label>
                                <h5 className={"no-margin"}>Active</h5>
                              </>
                            ) : (
                              (userRole === "Administrator" ||
                                userRole === "Supervisor") &&
                              userType === "PrepaidPlus" && (
                                <>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Self Service
                                  </label>
                                  <div className={classes.AddUser}>
                                    <div className={classes.AddUser}>
                                      <button
                                        className={classes.AddUserBtn}
                                        onClick={() =>
                                          openModal(
                                            "CreateSelfServiceOutletModal",
                                            {
                                              merchant: merchant,
                                            }
                                          )
                                        }
                                      >
                                        Add Self Service
                                      </button>
                                    </div>
                                  </div>
                                </>
                              )
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={"wizard-footer padding-20"}>
                        <p className={"small hint-text pull-left no-margin"} />
                        <div className={"pull-right"} />
                        <div className={"clearfix"} />
                      </div>
                    </div>

                    {openManageDetailsPanel ? (
                      <div className={classes.DetailsEdit}>
                        <div className={classes.EnableUser}>
                          <div className={classes.EnableUser}>
                            <div className={"card card-transparent"}>
                              <div
                                className={"card-header "}
                                style={{
                                  padding: "0",
                                  minHeight: "4.1rem",
                                  padding: "1rem",
                                  color: "#666",
                                  fontSize: "1.4rem",
                                  fontFamily: "Roboto",
                                  fontWeight: "500",
                                }}
                              >
                                <div
                                  className={"card-title semi-bold"}
                                  style={{
                                    color: "#666",
                                    fontSize: "1.4rem",
                                    fontFamily: "Roboto",
                                    fontWeight: "500",
                                    textTransform: "none",
                                    marginLeft: "0.7rem",
                                  }}
                                >
                                  Manage Merchant {selectedFieldHeader}
                                </div>
                                <div className={"card-controls"}>
                                  <ul>
                                    <li>
                                      <a
                                        data-toggle="close"
                                        className={"card-close"}
                                        href="#"
                                        onClick={this.handleCloseClick}
                                      >
                                        <i
                                          className={
                                            "card-icon card-icon-close"
                                          }
                                        />
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className={"card-body"}>
                                <div className={"panel"}>
                                  <Fragment>{selectedField}</Fragment>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div label="Portal Users">
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h4>Merchant Users</h4>
                    </div>
                    {(userRole === "Administrator" ||
                      userType === "PrepaidPlus") && (
                      <Fragment>
                        <div className={classes.Tools}>
                          <div className={classes.AddUser}>
                            <button
                              className={classes.AddUserBtn}
                              onClick={() =>
                                openModal("RegisterModal", {
                                  account: merchant.account,
                                  merchantName: merchant.name,
                                  group: merchant.group,
                                })
                              }
                            >
                              Create New User
                            </button>
                          </div>
                          <div
                            className={classes.ToolsExportIconWrapper}
                            onClick={() =>
                              createNewExcelFile(
                                `${merchant.name} Users`,
                                users,
                                this.portalUsersListSheet,
                                merchant.name
                              )
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              width="24"
                              viewBox="0 0 24 24"
                            >
                              <g>
                                <path
                                  id="path1"
                                  transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                  fill="#707070"
                                  d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </header>
                  <Users />
                </div>
                <div label="Outlets">
                  <header className={classes.TabHeader}>
                    {/* <div className={classes.PageHeader}>
                        <h4>Outlet(ss)</h4>
                      </div> */}

                    <div className={classes.PageHeader}>
                      <div className={classes.PageTitle}>
                        <h4>{merchant.name} Outlets</h4>
                      </div>
                      <div className={classes.PageFilter}>
                        {/* <h4 className="m-r-20"> Search:</h4> */}

                        {/* <!-- START Form Control--> */}
                        <div className={attachedClasses.join(" ")}>
                          <label>Outlet Name</label>
                          <div className={"controls"}>
                            <input
                              type="text"
                              className={classes.FormControl}
                              placeholder="Search by Outlet Name"
                              value={this.state.outletSearchText}
                              onChange={this.filterByOutletName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {userRole === "Administrator" &&
                      userType === "PrepaidPlus" && (
                        <Fragment>
                          <div className={classes.Tools}>
                            <div>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal("CreateOutletModal", {
                                    merchantName: merchant.name,
                                    merchantId: merchant.id,
                                  })
                                }
                              >
                                Add New Outlet
                              </button>
                            </div>
                            <div
                              className={classes.ToolsExportIconWrapper}
                              onClick={() =>
                                createNewExcelFile(
                                  `${merchant.name} Outlets`,
                                  outlets,
                                  this.outletsListSheet,
                                  merchant.name
                                )
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                width="24"
                                viewBox="0 0 24 24"
                              >
                                <g>
                                  <path
                                    id="path1"
                                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                    fill="#707070"
                                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                        </Fragment>
                      )}
                  </header>
                  {outletSearchText.length > 0 &&
                  outletsFiltered.length === 0 ? (
                    <div ref={this.handleContextRef}>
                      <div style={{ paddingBottom: "30px" }}>
                        <h4>
                          No outlets matched for query "{outletSearchText}"
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <Outlets
                      outlets={
                        outletsFiltered.length === 0 ? outlets : outletsFiltered
                      }
                      account={merchant.account}
                    />
                  )}
                </div>

                <div label="Limits & Documents">
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h4>Limits & Documents</h4>
                    </div>
                  </header>

                  <Tabs>
                    <div label={"Credit Limit"}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          <h4>Credit Limits</h4>
                        </div>
                        {(userRole === "Administrator" ||
                          userRole === "Supervisor") &&
                          userType === "PrepaidPlus" && (
                            <div className={classes.AddUser}>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal("CreditLimitModal", {
                                    account: merchant.account,
                                    name: merchant.name,
                                  })
                                }
                              >
                                Set New Credit Limit
                              </button>
                            </div>
                          )}
                      </header>
                      <CreditLimit account={merchant.account} />
                    </div>
                    <div label={"Providers"}>
                      <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          <h4>Manage Providers</h4>
                        </div>
                      </header>
                      <Providers account={merchant.account} />
                    </div>
                    <div label={`Merchant Agreement`}>
                      {/* <header className={classes.TabHeader}>
                        <div className={classes.PageHeader}>
                          { <h4>Merchants</h4>}
                        </div>
                        {userRole === "Administrator" &&
                          userType === "PrepaidPlus" && (
                            <div className={classes.AddUser}>
                              <button
                                className={classes.AddUserBtn}
                                onClick={() =>
                                  openModal(
                                    "MerchantAgreementModal",
                                    merchant.id
                                  )
                                }
                              >
                                Upload New Agreement
                              </button>
                            </div>
                          )}
                      </header> */}
                      {commission && (
                        <Fragment>
                          <h5>
                            <strong>Airtime Commission</strong>
                          </h5>

                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem",
                              padding: "10px 0",
                              backgroundColor: "rgba(0,0,255,0.075)",
                            }}
                          >
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  beMobile
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.beMobile.rate} ${
                                    commission[0].airtime.beMobile
                                      .measurement === "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Mascom
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.mascom.rate} ${
                                    commission[0].airtime.mascom.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>

                              {/* {commission.airtime_mascom} */}
                            </div>
                            <div className={"col-sm-4"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  Orange
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].airtime.orange.rate} ${
                                    commission[0].airtime.orange.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem",
                            }}
                          >
                            <div
                              className={"col-sm-4"}
                              style={{
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                            >
                              <h5 style={{ margin: "0 15px" }}>
                                <strong>Electricity Commission</strong>
                              </h5>
                              <div
                                style={{
                                  marginBottom: "2rem",
                                  padding: "10px 0",
                                  backgroundColor: "rgba(0,0,255,0.075)",
                                }}
                              >
                                <h6
                                  className={
                                    "all-caps small  hint-text semi-bold"
                                  }
                                  style={{ margin: "0 15px" }}
                                >
                                  BPC
                                </h6>
                                <h5 style={{ margin: "0 15px" }}>
                                  {`${commission[0].electricity.bpc.rate} ${
                                    commission[0].electricity.bpc
                                      .measurement === "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div
                              className={"col-sm-4"}
                              style={{
                                paddingLeft: "0",
                                paddingRight: "0",
                              }}
                            >
                              <h5 style={{ margin: "0 15px" }}>
                                <strong>Collections Commission</strong>
                              </h5>
                              <div
                                style={{
                                  marginBottom: "2rem",
                                  padding: "10px 0",
                                  backgroundColor: "rgba(0,0,255,0.075)",
                                }}
                              >
                                <h6
                                  className={
                                    "all-caps smal hint-text semi-bold"
                                  }
                                  style={{ margin: "0 15px" }}
                                >
                                  Botswana Life
                                </h6>
                                <h5 style={{ margin: "0 15px" }}>
                                  {`${commission[0].collections.blil.rate} ${
                                    commission[0].collections.blil
                                      .measurement === "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>
                          <h5>
                            <strong>Bill Payments</strong>
                          </h5>
                          <div
                            className={"row clearfix"}
                            style={{
                              marginBottom: "2rem",
                              padding: "10px 0",
                              backgroundColor: "rgba(0,0,255,0.075)",
                            }}
                          >
                            <div className={"col-sm-3"}>
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  BPC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.bpc.rate} ${
                                    commission[0].bills.bpc.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_DSTV} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  BTC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.btc.rate} ${
                                    commission[0].bills.btc.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_WUC} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  DSTV
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.dstv.rate} ${
                                    commission[0].bills.dstv.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                            <div className={"col-sm-3"}>
                              {/* {commission.bill_BTCL} */}
                              <div>
                                <h6
                                  className={
                                    "all-caps small no-margin hint-text semi-bold"
                                  }
                                >
                                  WUC
                                </h6>
                                <h5 className={"no-margin"}>
                                  {`${commission[0].bills.wuc.rate} ${
                                    commission[0].bills.wuc.measurement ===
                                    "Percentage"
                                      ? "%"
                                      : " per transaction"
                                  }`}
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div
                            className={"row clearfix"}
                            style={{ marginBottom: "3rem" }}
                          >
                            <div className={"col-sm-4"}>
                              <h5>
                                <strong>Commission Frequency</strong>
                              </h5>
                              <h4 className={"no-margin"}>
                                {`${commission[0].frequency} `}
                              </h4>
                              {/* <div>{commission.commission_frequency}</div> */}
                            </div>
                          </div>
                        </Fragment>
                      )}
                      <Documents
                        merchant={merchant}
                        filterByOutletName={this.filterByOutletName}
                      />
                    </div>
                  </Tabs>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapStateToProps = state => ({
//   loading: state.async.loading
// });

const actions = {
  openModal,
};

const mapStateToProps = (state) => ({
  users: state.firestore.ordered.users,
  outlets: state.firestore.ordered.outlets,
  commission: state.firestore.ordered.commissionRates,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default compose(
  firestoreConnect((props) => [
    {
      collection: "merchants",
      storeAs: "outlets",
      doc: props.merchant.account,
      subcollections: [{ collection: "outlets" }],
    },
    {
      collection: "merchant_commission",
      storeAs: "commissionRates",
      where: ["account", "==", props.merchant.account],
    },
    {
      collection: "users",
      where: ["account", "==", props.merchant.account],
    },
  ]),
  connect(mapStateToProps, actions)
)(MerchantProfile);
