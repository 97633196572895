import { createReducer } from "../../app/common/util/reducerUtil";
import { FETCH_DASHBOARD_BALANCES } from "./dashboardConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.financials;
};

export default createReducer(initialState, {
  [FETCH_DASHBOARD_BALANCES]: getTransactionsForDashboard,
});
