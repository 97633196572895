import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TransactionsTextInput";
import { openModal } from "../../modals/modalActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import { createReportExcelFile } from "../../../app/excel/excel";
import {
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
} from "../wucActions";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import WUCListItem from "./details";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let customerNoSearchText = "";
let utilContractSearchText = "";
let customerFirstNameSearchText = "";
let customerLastNameSearchText = "";

const index = ({
  handleSubmit,
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
  transactions,
  openModal,
  createReportExcelFile,
  requesting,
  outletId,
  initialValues,
}) => {
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());

  let [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [focusedInput, setFocusedInput] = useState(null);

  let [selectedRow, setSelectedRow] = useState(-1);

  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    getTransactionsForDashboard({
      transactionStartDate: new Date(initialValues.transactionStartDate),
      transactionEndDate: new Date(initialValues.transactionEndDate),
    }).then(() => {
      setLoadingInitial(false);
    });

    return () => {
      clearTransactionsForDashboard();
    };
  }, []);

  useEffect(() => {
    customerNoSearchText = "";
    utilContractSearchText = "";
    customerFirstNameSearchText = "";
    customerLastNameSearchText = "";
    setTransactionsFiltered([]);

    // return clearMerchants;
  }, []);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  // let transactionRowClasses = null;
  // let transactionDetailPanelOpenClasses = [classes.RevealMore];

  const loading = requesting[`merchants`];

  // !selectedRow
  //   ? (transactionRowClasses = [classes.MerchantRow])
  //   : (transactionRowClasses = [
  //       classes.MerchantRow,
  //       classes.SelectedTransaction,
  //     ]);

  const transactionsSheet = async (
    transactions,
    workbook,
    logo,
    reportStartDate,
    reportEndDate,
    merchantName
  ) => {
    let startDate = format(reportStartDate, "YYYY-MM-DD");
    let endDate = format(reportEndDate, "YYYY-MM-DD");

    let worksheet = workbook.addWorksheet("WUC Payments", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 9,
          activeCell: "G1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "I2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A3", "I3");

    // worksheet.mergeCells("A4", "H4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "For ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${merchantName}`,
        },
      ],
    };

    worksheet.mergeCells("A5", "I5");

    // worksheet.mergeCells("A6", "H6");
    worksheet.getCell("A6").value = `Transactions For Period`;
    worksheet.getRow(6).font = {
      name: "Calibri",
      family: 4,
      size: 16,
      bold: true,
    };

    worksheet.mergeCells("A7", "I7");

    // worksheet.mergeCells("A8", "H8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Date From ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${startDate}`,
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: " to ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${endDate}`,
        },
      ],
    };

    worksheet.mergeCells("A9", "I9");

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(10);
    headers.height = 25;
    headers.values = [
      "Date Time",
      "Transaction Narrative",
      "Customer No",
      "Utility Contract",
      "First Name",
      "Last Name",
      "Plot Number",
      "Amount Paid",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    headers.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "transactionDateTime",
        width: 20,
      },
      {
        key: "transactionNarrative",
        width: 28,
      },

      {
        key: "customerNumber",
        width: 16,
      },
      {
        key: "contractNumber",
        width: 18,
      },
      {
        key: "firstName",
        width: 18,
      },
      {
        key: "lastName",
        width: 18,
      },
      {
        key: "customerPlot",
        width: 12,
      },
      {
        key: "transactionAmount",
        width: 12,
      },
      // {
      //   key: "wucFileUploadStatus",
      //   width: 16,
      // },
    ];

    worksheet.getColumn(2).numFmt = "0000";
    worksheet.getColumn(7).numFmt = "0.00";

    ["A10", "B10", "C10", "D10", "E10", "F10", "G10", "H10", "I10"].map(
      (key) => {
        worksheet.getCell(key).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: "FF002060",
          },
        };
      }
    );

    transactions.forEach((doc, index) => {
      let transactionNarrative = `Cachet-01 PMNT ${index}`;

      worksheet
        .addRow({
          transactionDateTime: format(
            doc.transactionDateTime.toDate(),
            "YYYY-MM-DD HH:mm:ss"
          ),

          transactionNarrative: doc.transactionNarrative
            ? doc.transactionNarrative
            : transactionNarrative,
          customerNumber: doc.customer.customerNumber,
          contractNumber: doc.customer.contractNumber,
          firstName: doc.customer.firstName,
          lastName: doc.customer.lastName,
          customerPlot: doc.customer.customerPlot,
          transactionAmount: parseFloat(doc.paymentAmount),

          // wucFileUploadStatus: `   ${doc.wucFileUploadStatus}`,
        })
        .commit();
      let rowNum = index + 11;
      let row = worksheet.getRow(rowNum);
      row.outlineLevel = 2;
      // row.alignment = {
      //     vertical: "middle",
      //     horizontal: "center"
      // };
    });

    let column8 = worksheet.getColumn(8);
    column8.alignment = {
      vertical: "middle",
      horizontal: "right",
    };

    column8.numFmt = "0.00";

    // column7.width = 15;

    let total = generateSalesTotalsCell(worksheet, "H", transactions.length);

    const totalsRow = worksheet.addRow([
      "Sales Totals",
      "",
      "",
      "",
      "",
      "",
      "",
      total,
      "",
    ]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });
  };

  const exportToExcel = async () => {
    openModal("SpinnerModal");

    await createReportExcelFile(
      "WUC Payments",
      transactions,
      transactionsSheet,
      "Water Utilities Corporation",
      startDate.toDate(),
      endDate.toDate()
    );
  };

  const onFormSubmit = async (values) => {
    const outletId = values.outletId;
    let transactionStartDate = new Date(startDate);
    transactionStartDate.setHours(0, 0, 0, 0);
    let transactionEndDate = new Date(endDate);
    transactionEndDate.setHours(23, 59, 59, 999);

    setLoadingInitial(true);
    await getTransactionsForDashboard({
      transactionStartDate: transactionStartDate,
      transactionEndDate: transactionEndDate,
      outletId: outletId,
    });
    setLoadingInitial(false);
  };

  const filterByCustomerNumber = (customerNumber) => {
    console.log(" customerNumber", customerNumber);
    let keyword = customerNumber.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.customer.customerNumber.indexOf(keyword) > -1;
    });
    console.log(" filtered", filtered);
    setTransactionsFiltered(filtered);

    customerNoSearchText = keyword;
  };

  const filterByUtilityContractNumber = (contractNumber) => {
    console.log(" contractNumber", contractNumber);
    let keyword = contractNumber.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.customer.contractNumber.indexOf(keyword) > -1;
    });

    console.log(" filtered", filtered);

    setTransactionsFiltered(filtered);

    utilContractSearchText = keyword;
  };

  const filterByCustomerFirstName = (customerName) => {
    let keyword = customerName.target.value.toLowerCase();

    let filtered = transactions.filter((transaction) => {
      return transaction.customer.firstName.toLowerCase().indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    customerFirstNameSearchText = keyword;
  };

  const filterByCustomerLastName = (customerLastName) => {
    let keyword = customerLastName.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.customer.lastName.toLowerCase().indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    customerLastNameSearchText = keyword;
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  let transactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  if (loading || loadingInitial)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    let transactionsItems =
      transactionsFiltered.length === 0 ? transactions : transactionsFiltered;

    (customerNoSearchText.length > 0 ||
      utilContractSearchText.length > 0 ||
      customerFirstNameSearchText.length > 0 ||
      customerLastNameSearchText.length > 0) &&
    transactionsFiltered.length === 0
      ? (transactionList = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5>
                There are no transactions found for query {customerNoSearchText}{" "}
                {utilContractSearchText} {customerFirstNameSearchText}{" "}
                {customerLastNameSearchText}
              </h5>
            </div>
          </div>
        ))
      : transactionsItems.length > 0
      ? (transactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date Time</th>
                <th scope="col">Receipt No.</th>
                <th scope="col">Customer No</th>
                <th scope="col">Contract No</th>
                <th scope="col">Name</th>
                <th scope="col">Surname</th>
                <th scope="col">Payment</th>
              </tr>
            </thead>
            <tbody>
              {transactionsItems.map((transaction, index) => {
                return <WUCListItem key={index} transaction={transaction} />;
              })}
            </tbody>
          </table>
        ))
      : (transactionList = <h4>There are no payments at this time.</h4>);
  }

  return (
    <div className={classes.Container}>
      <div className={classes.ToolsWrapper}>
        <div className={classes.Tools}>
          <div className={classes.PageFilterTools}>
            {/* <h4 className="m-r-20"> Filter:</h4> */}

            <div className={classes.PageFilter}>
              {/* <!-- START Form Control--> */}
              <div className={attachedClasses.join(" ")}>
                <label>Customer Number</label>
                <div className={"controls"}>
                  <input
                    type="text"
                    className={classes.FormControl}
                    placeholder="Customer Number"
                    value={customerNoSearchText}
                    onChange={filterByCustomerNumber}
                  />
                </div>
              </div>

              {/* <!-- START Form Control--> */}
              <div className={attachedClasses.join(" ")}>
                <label>Utility Contract Number</label>
                <div className={"controls"}>
                  <input
                    type="text"
                    className={classes.FormControl}
                    placeholder="Utility Contract Number"
                    value={utilContractSearchText}
                    onChange={filterByUtilityContractNumber}
                  />
                </div>
              </div>

              <div className={attachedClasses.join(" ")}>
                <label>First Name</label>
                <div className={"controls"}>
                  <input
                    type="text"
                    className={classes.FormControl}
                    placeholder="First Name"
                    value={customerFirstNameSearchText}
                    onChange={filterByCustomerFirstName}
                  />
                </div>
              </div>

              <div className={attachedClasses.join(" ")}>
                <label>Last Name</label>
                <div className={"controls"}>
                  <input
                    type="text"
                    className={classes.FormControl}
                    placeholder="Last Name"
                    value={customerLastNameSearchText}
                    onChange={filterByCustomerLastName}
                  />
                </div>
              </div>
            </div>
            {/* <!-- END Form Control--> */}
          </div>
          <div className={classes.PrintToolsWrapper}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div
                className={"row"}
                style={{ borderRight: "solid 3px #707070" }}
              >
                <div className={"col-md-12"}>
                  <div className={"row"}>
                    <DateRangePicker
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => {
                        setStartDate(startDate);
                        setEndDate(endDate);
                      }} // PropTypes.func.isRequired,
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                      numberOfMonths={1}
                      isOutsideRange={(date) => date.isAfter(today)}
                      displayFormat={"YYYY-MM-DD"}
                      minimumNights={0}
                      small={true}
                      readOnly={true}
                    />

                    <button
                      className={classes.FilterReportBtn}
                      style={{ marginLeft: "1rem", marginRight: "2rem" }}
                      type="submit"
                    >
                      <svg
                        height="20"
                        width="20"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path
                            id="path1"
                            transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                            fill="#FFFFFF"
                            d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div
              className={classes.ToolsDataExportIconWrapper}
              style={{ marginLeft: "2rem" }}
              onClick={() => exportToExcel()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.MerchantTableWrapper}>
        <div
          className={classes.TableWrapper}
          style={{ flexDirection: "column" }}
        >
          <div style={{ display: "flex", marginTop: "3rem" }}>
            {transactionList}
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state) => ({
  transactions: state.wuc,
  requesting: state.firestore.status.requesting,
  initialValues: {
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(index)
);
