import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import { connect } from "react-redux";

import { uploadDecryptedAirtimeFile } from "../airtimeActions";


function index({uploadDecryptedAirtimeFile}) {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    uploadDecryptedAirtimeFile(acceptedFiles[0]);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div {...getRootProps()} className={"dropzone"}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({

});

const actions = {
  uploadDecryptedAirtimeFile,
};

export default connect(mapStateToProps, actions)(index);




// import React, { useState } from "react";
// import { connect } from "react-redux";
// import Dropzone from "react-dropzone";

// import { compose } from "redux";
// import { toastr } from "react-redux-toastr";
// import { reduxForm } from "redux-form";

// import { decryptAirtimeFile } from "../airtimeActions";
// import { ReactComponent as Spinner } from "../../../assets/spinner.svg"
// import styles from "./index.module.css";


// const componentConfig = {
//   iconFiletypes: [".jpg", ".png", ".gif"],
//   showFiletypeIcon: true,
//   postUrl: "/uploadHandler",
// };

// const index = ({ decryptAirtimeFile,loading, handleSubmit, error }) => {

//   const [files, setFiles] = useState([]);
//   const [fileName, setFileName] = useState("");

//  const onDrop = (files) => {
//     console.log("uploaded files", files);
//     setFiles(files);
//     setFileName(files[0].name);
    
//   };


//     const onFormSubmit = () => {
//       try {
//         decryptAirtimeFile(files[0]);
//         toastr.success("Success", "Merchant Agreement has been uploaded");
//       } catch (error) {
//         toastr.error("Oops", error.message);
//       }
//     };

//     return (
//       <form onSubmit={handleSubmit(onFormSubmit)}>
 
//         <div className={"row clearfix m-t-30"}>
//           <div className={"col-lg-6"} style={{ paddingRight: "7px" }}>
//             <div className={"card card-default"}>
//               <div className={"card-header "} style={{ zIndex: 0 }}>
//                 <div className={"card-title"}>
//                   Upload Signed Merchant Acceptance Form
//                 </div>
//               </div>
//               {files.length > 0 ? (
//                 <div className={"card-body no-scroll no-padding dropzone "}>
//                   <p
//                     style={{
//                       lineHeight: "100px",
//                       margin: 0,
//                       textAlign: "center",
//                     }}
//                   >
//                     {fileName}
//                   </p>
//                 </div>
//               ) : (
//                 <div className={"card-body no-scroll no-padding dropzone "}>
//                   <Dropzone
//                     config={componentConfig}
//                     onDrop={onDrop}
//                     multiple={false}
//                   >
//                     {({ getRootProps, getInputProps, isDragActive }) => {
//                       return (
//                         <div
//                           {...getRootProps()}
//                           style={{ textAlign: "center" }}
//                         >
//                           <input {...getInputProps()} />
//                           {isDragActive ? (
//                             <p style={{ lineHeight: "100px", margin: 0 }}>
//                               Drop files here...
//                             </p>
//                           ) : (
//                             <p style={{ lineHeight: "100px", margin: 0 }}>
//                               Click to select files to upload.
//                             </p>
//                           )}
//                         </div>
//                       );
//                     }}
//                   </Dropzone>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//         <br />

//         {error && <label className={"error semi-bold"}>{error}</label>}

//         <button className={styles.btn} type="submit" disabled={loading}>
//           {loading ? (
//             <Spinner
//               style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
//             />
//           ) : (
//             <span>Parse Airtime</span>
//           )}
//         </button>
//       </form>
//     );
//   }

// const mapStateToProps = (state) => ({

// });

// const actions = {
//   decryptAirtimeFile,
// };

// export default compose(
//   connect(mapStateToProps, actions),
//   reduxForm({
//     form: "uploadAirtime",
//     destroyOnUnmount: false,
//     forceUnregisterOnUnmount: true,
//   }),
// )(index);
