import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
import { formatMoney } from "../../../../app/common/util/helpers";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const index = (props) => {
  let { deposit, index, reportStartDate, reportEndDate } = props;

  let [expanded, setExpanded] = useState(false);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  // !selectedRow
  // ? (deviceRowClasses = [classes.MerchantRow])
  // : (deviceRowClasses = [classes.MerchantRow, classes.SelectedTransaction]);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  const transactionDetail = (transaction) => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.saleType + " - " + transaction.provider
          : transaction.saleType + " - " + transaction.transactionDetail;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank + " - " + transaction.depositMethod
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType + " - " + transaction.reversalReference;
        break;
      case "Withdrawal":
        return (
          transaction.withdrawalType + " - " + transaction.withdrawalDetail
        );
        break;
      case "Commission":
        return transaction.commissionType + " - " + transaction.provider;
        break;
      default:
        return transaction.saleType + " - " + transaction.transactionDetail;
    }
  };

  return (
    <Fragment>
      <tr
        className={`${MemberRowClasses}`}
        key={index}
        onClick={() => handleClick()}
      >
        <td>{format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}</td>
        <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
        <td>{deposit.bank}</td>
        <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
        <td style={{ textAligh: "end" }}>
          {formatMoney(deposit.transactionAmount)}
        </td>
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="6">
            <Detail
              transaction={deposit}
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
            />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default index;
