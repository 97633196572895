import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import MerchantContractDepositsReportSpinner from "../reports/Cachet/MerchantContractDepositsReport/MerchantContractDepositsReportSpinner";

const actions = {
  closeModal,
};

const merchantContractDepositsSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <MerchantContractDepositsReportSpinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(merchantContractDepositsSpinnerModal);
