import React, { Fragment } from "react";
import { connect } from "react-redux";

import styles from "./Layout.module.css";
import Toolbar from "../../features/nav/Toolbar/Toolbar";
import Sidebar from "../../features/nav/Sidebar/Sidebar";
import ModalManager from "../../features/modals/ModalManager";

const Layout = ({ profile,children }) => {
  return (
    <Fragment>
      <ModalManager />
      <div className={styles.Wrapper}>
        <div className={styles.ToolBar}>
          <Toolbar />
        </div>

        <div className={styles.FlexColumns}>
          {profile.account === "PrepaidPlus" ? (
            <Fragment>
              <Sidebar />
              <main className={styles.Content}>{children}</main>
            </Fragment>
          ) : (
            <Fragment>
              <Sidebar />
            <main className={styles.Content}>{children}</main>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(Layout);

// {/* <Toolbar drawerToggleClicked={this.sidedrawerToggleHandler} /> */}
