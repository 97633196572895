import React, { Fragment } from "react";
import format from "date-fns/format";
import { createNewExcelFile } from "../../../../app/excel/excel";

// import { createNewExcelFile } from "../../app/excel/excel";

import classes from "./ProformaInvoices.module.css";
const deviceListSheet = async (devices, workbook, logo) => {
  // let merchants = [];

  let worksheet = workbook.addWorksheet("Device goods received note", {
    properties: {
      showGridLines: false,
    },
    pageSetup: {
      paperSize: 9,
      fitToPage: true,
      orientation: "portrait",
    },
    views: [
      {
        state: "frozen",
        xSplit: 8,
        activeCell: "A1",
        showGridLines: false,
      },
    ],
  });

  worksheet.mergeCells("A1", "H2");

  worksheet.mergeCells("A3", "H3");

  worksheet.mergeCells("A4", "H4");
  worksheet.getCell("A4").value = `Goods received note - ${devices[0].grn}`;
  worksheet.getRow(4).font = {
    name: "Calibri",
    family: 4,
    size: 20,
    bold: true,
  };

  worksheet.mergeCells("A5", "H5");

  let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

  worksheet.mergeCells("A6", "H6");

  worksheet.getCell("A6").value = {
    richText: [
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: "Date generated: ",
      },
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          bold: true,
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: `${now}`,
      },
    ],
  };

  // insert an image over B2:D6
  worksheet.addImage(logo, {
    tl: {
      col: 0.25,
      row: 0.25,
    },
    ext: {
      width: 150,
      height: 32,
    },
  });

  // adjust pageSetup settings afterwards
  worksheet.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3,
  };

  let headers = worksheet.getRow(8);
  headers.height = 25;
  headers.values = [
    "Manufacturer",
    "Model",
    "Serial Number",
    "Mac Address",
    "Wifi Mac Address",
    "Date Created",
    "Purchase Invoice Number",
    "Created By",
  ];
  headers.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  headers.eachCell((cell) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      bottom: {
        style: "thick",
      },
    };
  });

  worksheet.columns = [
    {
      key: "manufacturer",
      width: 15,
    },
    {
      key: "model",
      width: 15,
    },
    {
      key: "serialNumber",
      width: 20,
    },
    {
      key: "macAddress",
      width: 18,
    },
    {
      key: "wifiAddress",
      width: 18,
    },
    {
      key: "dateCreated",
      width: 14,
    },
    {
      key: "piNumber",
      width: 14,
    },
    {
      key: "createdBy",
      width: 25,
    },
  ];

  devices.forEach((doc, index) => {
    worksheet
      .addRow({
        manufacturer: doc.manufacturer,
        model: doc.model,
        serialNumber: doc.serialNumber,
        macAddress: doc.macAddress,
        wifiAddress: doc.wifiAddress,
        dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
        piNumber: doc.piNumber,
        createdBy: doc.createdBy,
      })
      .commit();
    // let rowNum = index + 9;
    // let row = worksheet.getRow(rowNum);
    // row.outlineLevel = 2;
  });
};
const simCardListSheet = async (simCards, workbook, logo) => {
  // let merchants = [];

  let worksheet = workbook.addWorksheet("SIM Card Inventory List", {
    properties: {
      showGridLines: false,
    },
    pageSetup: {
      paperSize: 9,
      fitToPage: true,
      orientation: "portrait",
    },
    views: [
      {
        state: "frozen",
        xSplit: 9,
        activeCell: "A1",
        showGridLines: false,
      },
    ],
  });

  worksheet.mergeCells("A1", "H2");

  worksheet.mergeCells("A3", "H3");

  worksheet.mergeCells("A4", "H4");
  worksheet.getCell("A4").value = `Goods received note - ${simCards[0].grn}`;
  worksheet.getRow(4).font = {
    name: "Calibri",
    family: 4,
    size: 20,
    bold: true,
  };

  worksheet.mergeCells("A5", "H5");

  let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

  worksheet.mergeCells("A6", "H6");

  worksheet.getCell("A6").value = {
    richText: [
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: "Date generated: ",
      },
      {
        font: {
          size: 12,
          color: {
            argb: "FF000000",
          },
          bold: true,
          name: "Verdana",
          family: 4,
          scheme: "minor",
        },
        text: `${now}`,
      },
    ],
  };

  // insert an image over B2:D6
  worksheet.addImage(logo, {
    tl: {
      col: 0.25,
      row: 0.25,
    },
    ext: {
      width: 150,
      height: 32,
    },
  });

  // adjust pageSetup settings afterwards
  worksheet.pageSetup.margins = {
    left: 0.7,
    right: 0.7,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3,
  };

  let headers = worksheet.getRow(8);
  headers.height = 25;
  headers.values = [
    "Network",
    "Cell Number",
    "SIM Number",
    "PIN 1",
    "PIN 2",
    "PUK",
    "PUK2",
    "Date Created",
    "Purchase Invoice Number",
    "Created By",
  ];
  headers.alignment = {
    vertical: "middle",
    horizontal: "left",
  };
  headers.eachCell((cell) => {
    cell.font = {
      bold: true,
    };
    cell.border = {
      bottom: {
        style: "thick",
      },
    };
  });

  worksheet.columns = [
    {
      key: "network",
      width: 15,
    },
    {
      key: "cellNumber",
      width: 15,
    },
    {
      key: "simNumber",
      width: 20,
    },
    {
      key: "pinCode",
      width: 18,
    },
    {
      key: "pinCode2",
      width: 12,
    },
    {
      key: "puk1",
      width: 12,
    },
    {
      key: "puk2",
      width: 12,
    },
    {
      key: "dateCreated",
      width: 14,
    },
    {
      key: "piNumber",
      width: 25,
    },
    {
      key: "createdBy",
      width: 25,
    },
  ];

  simCards.forEach((doc, index) => {
    worksheet
      .addRow({
        network: doc.network,
        cellNumber: doc.cellNumber,
        simNumber: doc.simNumber,
        pinCode: doc.pinCode,
        pinCode2: doc.pinCode2,
        puk1: doc.puk1,
        puk2: doc.puk2,
        dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
        piNumber: doc.piNumber,
        createdBy: doc.createdBy,
      })
      .commit();
    // let rowNum = index + 9;
    // let row = worksheet.getRow(rowNum);
    // row.outlineLevel = 2;
  });
};

const proformaInvoice = (props) => {
  let { inventoryItemList, invoicedItemList } = props;
  console.log("Invoices", invoicedItemList);
  function filterList(id) {
    return invoicedItemList.filter((item) => item.piNumber === id);
  }
  let inventoryItems = Object.values(inventoryItemList).map(
    (inventoryItem, index) => {
      return (
        <tr className={classes.MerchantRow} key={index}>
          <td>{inventoryItem.grn}</td>
          <td>{inventoryItem.deviceType}</td>
          <td>
            {format(
              inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(),
              "YYYY-MM-DD"
            )}
          </td>
          <td>
            <div
              className={classes.ToolsExportIconWrapper}
              onClick={() => {
                if (inventoryItem.deviceType == "SIM") {
                  createNewExcelFile(
                    `Sim Card Goods Received Note`,
                    filterList(inventoryItem.piNumber),
                    simCardListSheet
                  );
                } else {
                  createNewExcelFile(
                    `Device Goods Received Note`,
                    filterList(inventoryItem.piNumber),
                    deviceListSheet
                  );
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </td>
        </tr>
      );
    }
  );

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr>
            <th scope="col">Goods received note</th>
            <th scope="col">Device type</th>
            <th className={"hide-s"} scope="col">
              Date Added
            </th>
          </tr>
        </thead>
        <tbody>{inventoryItems}</tbody>
      </table>
    </Fragment>
  );
};

export default proformaInvoice;
