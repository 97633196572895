import React, { Fragment, Component } from "react";
import LoadingComponent from "../../../../../../../app/layout/LoadingComponent";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import { firestoreConnect } from "react-redux-firebase";

import styles from "./GeneratePairingCode.module.css";
import { generatePairingCode } from "../../../../../merchantActions";

class PairingCode extends Component {
  render() {
    const {
      handleSubmit,
      asyncLoading,
      computer,
      generatePairingCode,
      pairing,
      requesting,
    } = this.props;
    const loading =
      requesting[`inventory_pairing?where=registrationId:==:${computer.id}`];
    let pairingCode;

    // console.log("computer.outletName", computer.outletName);

    const onFormSubmit = (values) => {
      generatePairingCode(computer.merchantName, computer.outletName, computer);
      // closePanel();
    };

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (pairing) {
      pairingCode = pairing.length > 0 && pairing[0].pairingCode;
    }

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h3>
                Code:
                <span className={"semi-bold"}>
                  {pairingCode ? pairingCode : "None"}
                </span>
              </h3>

              {!pairingCode ? (
                <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                  <ul className={"pager wizard no-style"}>
                    <li className="next">
                      <button
                        className={styles.btn}
                        type="submit"
                        disabled={asyncLoading}
                      >
                        {asyncLoading ? (
                          <Spinner
                            style={{
                              width: "20px",
                              height: "20px",
                              verticalAlign: "middle",
                            }}
                          />
                        ) : (
                          <span>Generate Code</span>
                        )}
                      </button>
                    </li>
                  </ul>
                </div>
              ) : null}
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const query = (props) => {
  return [
    {
      collection: "inventory_pairing",
      where: ["registrationId", "==", props.computer.id],
    },
  ];
};

const actions = {
  generatePairingCode,
};

const mapStateToProps = (state) => ({
  asyncLoading: state.async.loading,
  pairing: state.firestore.ordered.inventory_pairing,
  requesting: state.firestore.status.requesting,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "pairingCodeForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(firestoreConnect(query)(PairingCode))
);

// const mapStateToProps = state => ({
//   inventory: state.firestore.ordered.outlet_inventory,
//   requesting: state.firestore.status.requesting
// });

// const actions = {
//   fetchOutletInventory,
//   resetOutletInventory
// };

// export default connect(
//   mapStateToProps,
//   actions
// )(firestoreConnect(query)(OutletInventory));
