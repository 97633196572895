import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import LoadingComponent from "../../../app/layout/LoadingComponent";

import { selectMerchant } from "../merchantActions";

import styles from "./Merchant.module.css";
import MerchantProfile from "./MerchantProfile/MerchantProfile";

class Merchant extends Component {
  state = {
    initialLoading: true
  };
  render() {
    const { merchant } = this.props;

    if (merchant) {
      return (
        <Fragment>
          <div className={styles.Container}>
            <div className={styles.BreadcrumbWrapper}>
              <div className={styles.Breadcrumb}>
                <span className={styles.Header}>
                  <Link to="/merchants">Home</Link> - {merchant.name}
                </span>
                <br />
                <span className={styles.ByLine}>
                  You are in the <span className={"bold"}>{merchant.name}</span>
                  panel
                </span>
              </div>
            </div>

            <div className={styles.Content}>
              <div className={styles.WelcomeContainer}>
                <div className={styles.MerchantProfile}>
                  {/* <MerchantProfile merchant={merchant} /> */}
                  <MerchantProfile merchant={merchant} />;
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <LoadingComponent inverted={true} />;
    }
  }
}

// const actions = {
//   selectMerchant
// };

// const mapStateToProps = state => ({
//   merchant: state.merchants,
//   loading: state.async.loading,
//   auth: state.firebase.auth
// });

export default compose(
  firestoreConnect(props => [
    { collection: "merchants", storeAs: "merchant", doc: props.match.params.id }
  ]),
  connect(({ firestore: { ordered } }, props) => ({
    merchant: ordered.merchant && ordered.merchant[0]
  }))
)(Merchant);
