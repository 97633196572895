import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../assets/spinner.svg";
import styles from "./Edit.module.css";
import { updateMerchantField } from "../../../merchantActions";

const editStatus = (props) => {
  const {
    account,
    handleSubmit,
    loading,
    closePanel,
    updateMerchantField,
    status,
  } = props;
  const onFormSubmit = (values) => {
    let type = "Status";
    values.status = props.status;
    updateMerchantField(values, account, type);
    closePanel();
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <h5>
        Status:
        <span className={"semi-bold"}>{status ? "Enabled" : "Disabled"}</span>
      </h5>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button className={styles.btn} type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>{!status ? "Enable" : "Disabled"} Merchant</span>
              )}
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateMerchantField,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editMerchantFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(editStatus)
);
