import React, { useState } from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";

import {
  updatePettyCash,
  uploadPettyCashDocumentation,
} from "../../pettyCashActions";
import { Field, reduxForm } from "redux-form";

const FileInput = ({
  input: { value: omitValue, ...inputProps },
  meta: omitMeta,
  ...props
}) => {
  let attachedClasses = ["form-group-default", props.required];
  return (
    <div className={attachedClasses.join(" ")}>
      <div className={"controls"}>
        <input
          type="file"
          accept="application/pdf"
          {...inputProps}
          {...props}
          style={{ paddingTop: 7 }}
        />
      </div>
    </div>
  );
};
const authorised = (props) => {
  let {
    transaction,
    profile,
    auth,
    loading,
    openModal,
    handleSubmit,
    closeExpanded,
    uploadPettyCashDocumentation,
  } = props;
  let [expanded, setExpanded] = useState(true);

  const onFormSubmit = async (values) => {
    console.log("values", values);

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;
    values.id = transaction.id;

    // console.log("values !!!!!! authorised", values);

    // setLoadingInitial(true);
    await uploadPettyCashDocumentation(values);
    closeExpanded(false);

    // setLoadingInitial(false);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      {!transaction.documentationUrl ? (
        <>
          <table className={classes.FuneralSchemeTable}>
            <thead>
              <tr>
                <th className={"hide-s"} scope="col">
                  Amount
                </th>

                <th className={"hide-s"} scope="col">
                  Status
                </th>
                <th className={"hide-s"} scope="col">
                  Actioned By
                </th>
                <th className={"hide-s"} scope="col">
                  Upload File
                </th>
                <th className={"hide-s"} scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr className={classes.MemberRow}>
                <td>{transaction.amount}</td>
                <td>{transaction.status}</td>
                <td>{transaction.approvedBy}</td>
                <td>
                  <Field
                    name="proof"
                    id="proof"
                    component={FileInput}
                    className="form-control"
                    required="required"
                  />
                </td>{" "}
                <td align="right">
                  <button
                    className={classes.AddUserBtn}
                    // onSubmit={() => {
                    //   handleClick();s
                    // }}
                    type="submit"
                    style={{ width: "21rem" }}
                  >
                    {loading ? (
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <span>Upload Documentation</span>
                    )}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <>
          {" "}
          <table className={classes.FuneralSchemeTable}>
            <thead>
              <tr>
                <th className={"hide-s"} scope="col">
                  Amount
                </th>

                <th className={"hide-s"} scope="col">
                  Status
                </th>
                <th className={"hide-s"} scope="col">
                  Actioned By
                </th>
                <th className={"hide-s"} scope="col">
                  Date Actioned{" "}
                </th>
                <th className={"hide-s"} scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr className={classes.MemberRow}>
                <td>{transaction.amount}</td>
                <td>{transaction.status}</td>
                <td>{transaction.approvedBy}</td>
                <td>{transaction.actionedBy}</td>
                <td style={{ whiteSpace: "pre-wrap" }}>
                  Awaiting approval by Finance.
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </form>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
  uploadPettyCashDocumentation,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableUserForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(authorised)
);
