import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import format from "date-fns/format";
import { openModal } from "../modals/modalActions";
import Tabs from "../../app/UI/Tabs/Tabs";
import InventoryList from "./InventoryList/InventoryList";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { createNewExcelFile } from "../../app/excel/excel";
import RegisterDeviceList from "../inventory/Devices/Registration/RegisterDeviceList";
import classes from "./Inventory.module.css";
import RegisterSimCardList from "../inventory/SIMCard/SimCardDeviceList";
import { Router } from "react-router-dom";

let cellSearchText = "";
let simSearchText = "";
let deviceSearchText = "";
let deviceSerialNumberSearchText = "";
let goodsReceiveNoteSearchText = "";

const inventory = ({ inventory, openModal, requesting }) => {
  const [simFiltered, setSIMFiltered] = useState([]);
  const [deviceFiltered, setDeviceFiltered] = useState([]);
  const [grnFiltered, setGRNFiltered] = useState([]);
  const [createDeviceList, setCreateDeviceList] = useState(false);
  const [createSimCardList, setCreateSimCardList] = useState(false);

  useEffect(() => {
    cellSearchText = "";
    deviceSearchText = "";
    simSearchText = "";
    goodsReceiveNoteSearchText = "";
    deviceSerialNumberSearchText = "";
  }, []);

  const deviceListSheet = async (devices, workbook, logo) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("Device Inventory List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 8,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "H2");

    worksheet.mergeCells("A3", "H3");

    worksheet.mergeCells("A4", "H4");
    worksheet.getCell("A4").value = `Device Inventory List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "H5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "H6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = [
      "Manufacturer",
      "Model",
      "Serial Number",
      "Mac Address",
      "Cell Number",
      "Is Allocated",
      "Date Created",
      "Created By",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "manufacturer",
        width: 15,
      },
      {
        key: "model",
        width: 15,
      },
      {
        key: "serialNumber",
        width: 20,
      },
      {
        key: "macAddress",
        width: 18,
      },
      {
        key: "cellNumber",
        width: 12,
      },
      {
        key: "isAllocated",
        width: 15,
      },
      {
        key: "dateCreated",
        width: 14,
      },
      {
        key: "createdBy",
        width: 25,
      },
    ];

    devices.forEach((doc, index) => {
      worksheet
        .addRow({
          manufacturer: doc.manufacturer,
          model: doc.model,
          serialNumber: doc.serialNumber,
          macAddress: doc.macAddress,
          cellNumber: doc.cellNumber,
          isAllocated: doc.isAllocated ? `Yes - ${doc.allocatedTo}` : "No",
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  const simCardListSheet = async (simCards, workbook, logo) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("SIM Card Inventory List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 9,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "H2");

    worksheet.mergeCells("A3", "H3");

    worksheet.mergeCells("A4", "H4");
    worksheet.getCell("A4").value = `SIM Card Inventory List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "H5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "H6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = [
      "Network",
      "Cell Number",
      "SIM Number",
      "PIN 1",
      "PIN 2",
      "PUK",
      "Is Allocated",
      "Date Created",
      "Created By",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "network",
        width: 15,
      },
      {
        key: "cellNumber",
        width: 15,
      },
      {
        key: "simNumber",
        width: 20,
      },
      {
        key: "pinCode",
        width: 18,
      },
      {
        key: "pin2Code",
        width: 12,
      },
      {
        key: "pukNumber",
        width: 12,
      },
      {
        key: "isAllocated",
        width: 15,
      },
      {
        key: "dateCreated",
        width: 14,
      },
      {
        key: "createdBy",
        width: 25,
      },
    ];

    simCards.forEach((doc, index) => {
      worksheet
        .addRow({
          network: doc.network,
          cellNumber: doc.cellNumber,
          simNumber: doc.simNumber,
          pinCode: doc.pinCode,
          pin2Code: doc.pin2Code,
          pukNumber: doc.pukNumber,
          isAllocated: doc.isAllocated ? `Yes - ${doc.allocatedTo}` : "No",
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  const filterByCellNumber = (event) => {
    let simCardList = inventory.filter((item) => item.deviceType === "SIM");
    let keyword = event.target.value.toLowerCase();
    let simFiltered = simCardList.filter((sim) => {
      return sim.cellNumber.toString().toLowerCase().indexOf(keyword) > -1;
    });
    setSIMFiltered(simFiltered);
    cellSearchText = keyword;
  };
  const filterBySimNumber = (event) => {
    let simCardList = inventory.filter((item) => item.deviceType === "SIM");
    let keyword = event.target.value.toLowerCase();
    let simFiltered = simCardList.filter((sim) => {
      return sim.simNumber.toString().toLowerCase().indexOf(keyword) > -1;
    });

    setSIMFiltered(simFiltered);
    simSearchText = keyword;
  };

  const filterByMACAddress = (event) => {
    let deviceList = inventory.filter((item) => item.deviceType === "Device");
    let keyword = event.target.value.toLowerCase();
    let deviceFiltered = deviceList.filter((device) => {
      return device.macAddress.toLowerCase().indexOf(keyword) > -1;
    });

    setDeviceFiltered(deviceFiltered);
    deviceSearchText = keyword;
  };

  const filterSerialNumber = (event) => {
    let deviceList = inventory.filter((item) => item.deviceType === "Device");
    let keyword = event.target.value.toLowerCase();
    console.log("deviceFiltered", deviceFiltered);
    let deviceFiltered = deviceList.filter((device) => {
      return device.serialNumber.toLowerCase().indexOf(keyword) > -1;
    });

    setDeviceFiltered(deviceFiltered);
    deviceSerialNumberSearchText = keyword;
  };

  const filterByGRN = (event) => {
    console.log("Value event", event.target.value);
    let keyword = event.target.value;
    let filteredPInumber = piNumberList.filter((piInvoice) => {
      return piInvoice.grn.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
    });
    setGRNFiltered(filteredPInumber);
    goodsReceiveNoteSearchText = keyword;
  };
  const loading = requesting[`inventory`];
  let inventoryList = "Spinner.....";
  let simCard;
  let devices;
  let deviceList = [];
  let simCardList = [];
  let piNumberList = [];
  let GRN;

  let computers;

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  const getUniqueInventoryPI = (inventory) => {
    const uniqueIds = new Set();
    const result = [];

    for (const item of inventory) {
      if (!uniqueIds.has(item.grn) && item.grn !== undefined) {
        console.log("grn!!!", item.grn);

        uniqueIds.add(item.grn);
        result.push(item);
      }
    }
    return result;
  };
  if (inventory) {
    simCardList = inventory.filter((item) => item.deviceType === "SIM");
    deviceList = inventory.filter((item) => item.deviceType === "Device");
    piNumberList = getUniqueInventoryPI(inventory);
    let computerList = inventory.filter(
      (item) => item.deviceType === "Computer"
    );

    cellSearchText.length > 0 && simFiltered.length === 0
      ? (simCard = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5> No SIM cards found for query "{cellSearchText}" </h5>
            </div>
          </div>
        ))
      : (simCard = (
          <div>
            <InventoryList
              inventoryItemList={
                simFiltered.length === 0 ? simCardList : simFiltered
              }
              inventoryType="SIM"
            />
          </div>
        ));

    (deviceSearchText.length > 0 || deviceSerialNumberSearchText.length > 0) &&
    deviceFiltered.length === 0
      ? (devices = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5>
                {" "}
                No devices found for query "
                {deviceSearchText
                  ? deviceSearchText
                  : deviceSerialNumberSearchText}
                "{" "}
              </h5>
            </div>
          </div>
        ))
      : (devices = (
          <div>
            <InventoryList
              inventoryItemList={
                deviceFiltered.length === 0 ? deviceList : deviceFiltered
              }
              inventoryType="Device"
            />
          </div>
        ));
    goodsReceiveNoteSearchText.length > 0 && grnFiltered.length === 0
      ? (GRN = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5> No Inventory found with a goods received note </h5>
            </div>
          </div>
        ))
      : (GRN = (
          <div>
            <InventoryList
              inventoryItemList={
                grnFiltered.length === 0 ? piNumberList : grnFiltered
              }
              invoicedItemList={inventory}
              inventoryType="PI NUMBER"
            />
          </div>
        ));

    computers = (
      <InventoryList
        inventoryItemList={computerList}
        inventoryType="Computer"
      />
    );
  }
  if (loading)
    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>PrepaidPlus Inventory</span>

              <br />
              <span className={classes.ByLine}>
                You are in the
                <span className={"bold"}>PrepaidPlus Inventory</span> panel
              </span>
            </div>
          </div>
          <LoadingComponent inverted={true} />
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>PrepaidPlus Inventory</span>
            <br />
            <span className={classes.ByLine}>
              You are in the{" "}
              <span className={"bold"}>PrepaidPlus Inventory</span>
              panel
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h3>PrepaidPlus Inventory</h3>
                    </div>
                  </header>

                  <div className={"row"}>
                    <div className={"card card-transparent"}>
                      <div className={"card-body"}>
                        <Tabs>
                          <div label="Devices">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <div className={classes.PageTitle}>
                                  <h4>Devices</h4>
                                </div>
                                <div className={classes.PageFilter}>
                                  {/* <h4 className="m-r-20"> Search:</h4> */}

                                  {/* <!-- START Form Control--> */}
                                  <div className={attachedClasses.join(" ")}>
                                    <label>MAC Address</label>
                                    <div className={"controls"}>
                                      <input
                                        type="text"
                                        className={classes.FormControl}
                                        placeholder="Filter by MAC Address"
                                        value={deviceSearchText}
                                        onChange={filterByMACAddress}
                                      />
                                    </div>
                                  </div>
                                  <div className={attachedClasses.join(" ")}>
                                    <label>Serial Number</label>
                                    <div className={"controls"}>
                                      <input
                                        type="text"
                                        className={classes.FormControl}
                                        placeholder="Filter by Serial Number"
                                        value={deviceSerialNumberSearchText}
                                        onChange={filterSerialNumber}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className={classes.AddUser}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ padding: "1px 2px" }}>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        openModal("RegisterDeviceModal")
                                      }
                                    >
                                      Add New Device
                                    </button>
                                  </div>
                                  <div style={{ padding: "1px 2px" }}>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() => {
                                        setCreateDeviceList(!createDeviceList);
                                      }}
                                    >
                                      Upload Device List
                                    </button>
                                  </div>
                                </div>

                                <div
                                  className={classes.ToolsExportIconWrapper}
                                  onClick={() =>
                                    createNewExcelFile(
                                      `Device Inventory`,
                                      deviceList,
                                      deviceListSheet
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <g>
                                      <path
                                        id="path1"
                                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                        fill="#707070"
                                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </header>
                            {createDeviceList ? (
                              <div>
                                <RegisterDeviceList
                                  onSuccessClose={setCreateDeviceList}
                                />
                              </div>
                            ) : null}
                            {devices}
                          </div>
                          <div label="SIM Cards">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <div className={classes.PageTitle}>
                                  <h4>SIM Cards</h4>
                                </div>
                                <div className={classes.PageFilter}>
                                  {/* <h4 className="m-r-20"> Search:</h4> */}

                                  {/* <!-- START Form Control--> */}
                                  <div className={attachedClasses.join(" ")}>
                                    <label>Cell Number</label>
                                    <div className={"controls"}>
                                      <input
                                        type="text"
                                        className={classes.FormControl}
                                        placeholder="Filter by Cell Number"
                                        value={cellSearchText}
                                        onChange={filterByCellNumber}
                                      />
                                    </div>
                                  </div>
                                  <div className={attachedClasses.join(" ")}>
                                    <label>Sim Number</label>
                                    <div className={"controls"}>
                                      <input
                                        type="text"
                                        className={classes.FormControl}
                                        placeholder="Filter by sim Number"
                                        value={simSearchText}
                                        onChange={filterBySimNumber}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className={classes.AddUser}>
                                <div style={{ padding: "1px 2px" }}>
                                  <div>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        openModal("RegisterSIMCardModal")
                                      }
                                    >
                                      Register New SIM Card
                                    </button>
                                  </div>
                                </div>
                                <div style={{ padding: "1px 2px" }}>
                                  <div>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        setCreateSimCardList(!createSimCardList)
                                      }
                                    >
                                      Upload sim card list
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className={classes.ToolsExportIconWrapper}
                                  onClick={() =>
                                    createNewExcelFile(
                                      `SIM Card Inventory`,
                                      simCardList,
                                      simCardListSheet
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    width="24"
                                    viewBox="0 0 24 24"
                                  >
                                    <g>
                                      <path
                                        id="path1"
                                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                        fill="#707070"
                                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </header>
                            {createSimCardList ? (
                              <div>
                                <RegisterSimCardList
                                  onSuccessClose={setCreateSimCardList}
                                />
                              </div>
                            ) : null}
                            {simCard}
                          </div>
                          <div label="Goods Received Notes">
                            <header className={classes.TabHeader}>
                              <div className={classes.PageHeader}>
                                <div className={classes.PageTitle}>
                                  <h4>Goods Received Notes</h4>
                                </div>
                                <div className={classes.PageFilter}>
                                  {/* <h4 className="m-r-20"> Search:</h4> */}

                                  {/* <!-- START Form Control--> */}
                                  <div className={attachedClasses.join(" ")}>
                                    <label>Goods Received Note</label>
                                    <div className={"controls"}>
                                      <input
                                        type="text"
                                        className={classes.FormControl}
                                        placeholder="Filter Goods Received Note"
                                        value={goodsReceiveNoteSearchText}
                                        onChange={filterByGRN}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </header>
                            {GRN}
                          </div>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  inventory: state.firestore.ordered.inventory,
  requesting: state.firestore.status.requesting,
});

const actions = {
  openModal,
};

// export default connect(null,actions)(Outlet);

// export default Outlet;
export default connect(
  mapStateToProps,
  actions
)(firestoreConnect([{ collection: "inventory" }])(inventory));
