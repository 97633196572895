import React, { Component, Fragment } from "react";
import format from "date-fns/format";

import classes from "./UserList.module.css";

class Users extends Component {
  render() {
    let { userList } = this.props;

    let numberOfRecords = this.props.userList.length;

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    const users = Object.values(userList).map((user, index) => {
      return (
        <tr className={classes.MerchantRow} key={index}>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.email}</td>
          <td>{user.role}</td>
          <td>
            {format(
              user.dateCreated && user.dateCreated.toDate(),
              "YYYY-MM-DD"
            )}
          </td>
        </tr>
      );
    });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">E-Mail(User Name)</th>
                  <th className={"hide-s"} scope="col">
                    Role
                  </th>
                  <th className={"hide-s"} scope="col">
                    Date Created
                  </th>
                </tr>
              </thead>
              <tbody>{users}</tbody>
            </table>
          </div>
          <div className={classes.Row}>
            <div>
              <div className={attachedPaginateSimpleNumbersClasses.join(" ")}>
                <ul>
                  <li>
                    <a href="">
                      <i className={classes.pg_arrow_left} />
                    </a>
                  </li>
                  <li className={attachedPaginateActiveClasses.join(" ")}>
                    <a href="">1</a>
                  </li>
                  <li
                    className={attachedPaginateNextClasses.join(" ")}
                    id="tableWithExportOptions_next"
                  >
                    <a href="">
                      <i className={classes.pg_arrow_right} />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={classes.dataTables_info}
                id="tableWithExportOptions_info"
                role="status"
                aria-live="polite"
              >
                Showing <b>1 to {this.props.userList.length}</b> of
                {numberOfRecords} entries
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Users;
