import React, { Fragment } from "react";
import format from "date-fns/format";

import classes from "./SIMCard.module.css";

const simCard = (props) => {
  let { inventoryItemList } = props;

  let inventoryItems = Object.values(inventoryItemList).map(
    (inventoryItem, index) => {
      return (
        <tr className={classes.MerchantRow} key={index}>
          <td>{inventoryItem.network}</td>
          <td>{inventoryItem.simNumber}</td>
          <td>{inventoryItem.cellNumber}</td>
          <td>
            {inventoryItem.isAssigned
              ? inventoryItem.assignedMacAddress
              : "N/A"}
          </td>
          <td>
            {inventoryItem.isAllocated ? inventoryItem.allocatedTo : "N/A"}
          </td>
          <td>
            {format(
              inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(),
              "YYYY-MM-DD"
            )}
          </td>
        </tr>
      );
    }
  );

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr>
            <th scope="col">Network</th>
            <th scope="col">SIM Number</th>
            <th scope="col">Cell Number</th>
            <th className={"hide-s"} scope="col">
              MAC Address
            </th>
            <th className={"hide-s"} scope="col">
              Outled ID
            </th>
            <th className={"hide-s"} scope="col">
              Date Added
            </th>
          </tr>
        </thead>
        <tbody>
          {inventoryItems.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>No SIMCards or Query yielded no results.</h3>
            </div>
          ) : (
            inventoryItems
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default simCard;
