import React, { useState, useEffect } from "react";
import styles from "./BalanceForm.module.css";
import firebase from "../../../../app/config/firebase";

const BalanceForm = (props) => {
  let attachedClasses = ["form-group", "form-group-default"];
  const firestore = firebase.firestore();
  const triggersRef = firestore.collection("triggers");

  const [bpcTradingTimeLimit, setBpcTradingTimeLimit] = useState(
    localStorage.getItem("bpcTradingTimeLimit") || ""
  );
  const [kazangTradingTimeLimit, setKazangTradingTimeLimit] = useState(
    localStorage.getItem("kazangTradingTimeLimit") || ""
  );
  const [bpcTradingBalanceLimit, setBpcTradingBalanceLimit] = useState(
    localStorage.getItem("bpcTradingBalanceLimit") || ""
  );
  const [kazangTradingBalanceLimit, setKazangTradingBalanceLimit] = useState(
    localStorage.getItem("kazangTradingBalanceLimit") || ""
  );
  const [smsContacts, setSmsContacts] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [formMessage, setformMessage] = useState("");
  const [formErrorMessage, setformErrorMessage] = useState("");
  const [preventSubmit, setPreventSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async (event) => {
    // Handle form submission here
    event.preventDefault();
    setLoading(true);
    try {
      await triggersRef
        .doc("bpc_trading_time_limit")
        .update({ time: bpcTradingTimeLimit })
        .then(async () => {
          await triggersRef
            .doc("kazang_trading_time_limit")
            .update({ time: kazangTradingTimeLimit })
            .then(async () => {
              await triggersRef
                .doc("bpc_trading_balance_limit")
                .update({ balance: bpcTradingBalanceLimit })
                .then(async () => {
                  await triggersRef
                    .doc("kazang_trading_balance_limit")
                    .update({ balance: kazangTradingBalanceLimit })
                    .then(async () => {
                      let contacts = smsContacts.split(",");
                      let processed = [];
                      for (let contact of contacts) {
                        contact = contact.trim();
                        if (contact != "")
                          processed.push({
                            name: "No Name",
                            number: parseInt(contact),
                          });
                      }
                      await triggersRef
                        .doc("sms_contacts")
                        .collection("contacts")
                        .get()
                        .then((snapshot) => {
                          snapshot.forEach(async (doc) => {
                            await triggersRef
                              .doc("sms_contacts")
                              .collection("contacts")
                              .doc(doc.id)
                              .delete();
                          });
                        })
                        .then(() => {
                          processed.forEach((contact) => {
                            triggersRef
                              .doc("sms_contacts")
                              .collection("contacts")
                              .doc()
                              .set(contact)
                              .then(() => {
                                setformMessage("Updated values successfully");
                                setLoading(false);
                              });
                          });
                        });
                    });
                });
            });
        });
    } catch {
      setformErrorMessage("A fault occured while updating, please try again");
    }
  };

  function validatePhoneNumberList(value) {
    // Split the value by comma
    const numbers = value.split(",");

    // Regular expression to match 8-digit numbers starting with 7
    const regex = /^7\d{7}$/;

    // Check each number in the list
    for (let number of numbers) {
      // Remove whitespace
      number = number.trim();
      // If any number does not match the format, return false
      if (!regex.test(number)) {
        return false;
      }
    }
    // All numbers match the format
    return true;
  }

  const onInputChange = (event, setStateFunction) => {
    const { name, value } = event.target;
    if (setStateFunction == setSmsContacts) {
      if (!validatePhoneNumberList(value)) {
        setErrorMessage(
          "Please enter comma-separated 8-digit numbers starting with 7."
        );
        setPreventSubmit(true);
      } else {
        setErrorMessage("");
        setPreventSubmit(false);
      }
      setStateFunction(value);
      localStorage.setItem(name, JSON.stringify(value));
    } else {
      setStateFunction(value);
      localStorage.setItem(name, value);
    }
  };

  useEffect(() => {
    let isMounted = true;
    let contacts = localStorage.getItem("smsContacts");
    let processed = "";

    if (contacts) {
      contacts = JSON.parse(contacts);
      contacts.forEach((contact) => {
        processed = processed + contact + ",";
      });
    }
    if (isMounted) {
      setSmsContacts(processed);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (formMessage != "") {
      setTimeout(() => {
        if (isMounted) {
          setformMessage("");
        }
      }, 3000);
    }
    return () => {
      isMounted = false;
    };
  }, [formMessage]);

  return (
    <form onSubmit={(e) => onFormSubmit(e)}>
      <br />
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <h5 style={{ marginTop: 0 }}>BPC</h5>
        </div>
        <div className={"col-md-6"}>
          <h5 style={{ marginTop: 0 }}>Kazang</h5>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Trading Time Limit</b>
        </div>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Trading Time Limit</b>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <input
                name="bpcTradingTimeLimit"
                placeholder="Trading Time Limit"
                type="number"
                value={bpcTradingTimeLimit}
                onChange={(e) => onInputChange(e, setBpcTradingTimeLimit)}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <input
                name="kazangTradingTimeLimit"
                placeholder="Trading Time Limit"
                type="number"
                value={kazangTradingTimeLimit}
                onChange={(e) => onInputChange(e, setKazangTradingTimeLimit)}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Trading Balance Limit</b>
        </div>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Trading Balance Limit</b>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <input
                name="bpcTradingBalanceLimit"
                placeholder="Trading Balance Limit"
                type="number"
                value={bpcTradingBalanceLimit}
                onChange={(e) => onInputChange(e, setBpcTradingBalanceLimit)}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <input
                name="kazangTradingBalanceLimit"
                placeholder="Trading Balance Limit"
                type="number"
                value={kazangTradingBalanceLimit}
                onChange={(e) => onInputChange(e, setKazangTradingBalanceLimit)}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>SMS Notification Contacts</b>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-12"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <textarea
                name="smsContacts"
                placeholder="SMS Notification Contacts"
                rows={5}
                value={smsContacts}
                className="form-control"
                autoComplete="off"
                onChange={(e) => onInputChange(e, setSmsContacts)}
                required
              />
              {errorMessage && (
                <div className="text-danger">{errorMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <button className={styles.btn} type="submit" disabled={preventSubmit}>
        {loading ? <span>⏳</span> : <span>Update</span>}
      </button>
      <br></br>
      {formMessage && <div className="text-success">{formMessage}</div>}
      {formErrorMessage && (
        <div className="text-danger">{formErrorMessage}</div>
      )}
    </form>
  );
};

export default BalanceForm;
