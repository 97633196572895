import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AddSelfService from "../merchants/Merchant/AddSelfService/AddSelfService";

const actions = { closeModal };

const addSelfServiceModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Add Self Service" onClose={closeModal}>
      <AddSelfService />
    </Modal>
  );
};

export default connect(null, actions)(addSelfServiceModal);
