import React from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import validate from "../Validate";

import TextInput from "../../../../app/common/form/TextInput";
import SelectInput from "../../../../app/common/form/SelectInput";

const merchantOutlet = (props) => {
  const { handleSubmit, previousPage, outletTypes } = props;
  const renderError = ({ meta: { touched, error } }) =>
    touched && error ? <span className={"error"}>{error}</span> : false;

  let outlets;

  outlets =
    props.outletTypes &&
    props.outletTypes.map((type) => {
      return {
        label: type.outletType,
        value: type.outletType,
      };
    });

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <strong>Outlet Details</strong>
      </p>

      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            // label="Outlet Name"
            name="merchant_outlet_name"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.outletName == undefined || null ? "Name" : props.outletName
            }
            onChange={props.onoutletName}
            required="required"
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            name="merchant_outlet_type"
            options={outlets}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "neutral0",
              },
            })}
            component={SelectInput}
            placeholder="Select Outlet..."
            className={"form-control"}
            required="true"
            aria-required="true"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            // label="City"
            name="merchant_outlet_city"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.outletCity == undefined || null ? "City" : props.outletCity
            }
            onChange={props.onoutletCity}
            required="required"
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            // label="Suburb"
            name="merchant_outlet_suburb"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.suburb == undefined || null ? "Suburb" : props.suburb
            }
            onChange={props.onsuburb}
            required="required"
          />
        </div>
      </div>

      <br />
      <p>
        <strong>Contact and Address</strong>
      </p>
      <div className={"row clearfix"}>
        <div className={"col-sm-12"}>
          <Field
            // label="Contact Name"
            name="merchant_outlet_contact_name"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.contactName == undefined || null
                ? "Name"
                : props.contactName
            }
            onChange={props.oncontactName}
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            // label="Contact Email"
            name="merchant_outlet_contact_email"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.contactEmail == undefined || null
                ? "Email"
                : props.contactEmail
            }
            onChange={props.oncontactEmail}
            required="required"
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            // label="Contact Telephone"
            name="merchant_outlet_contact_telephone"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder={
              props.contactTelephone == undefined || null
                ? "Telephone"
                : props.contactTelephone
            }
            onChange={props.oncontactTelephone}
            required="required"
          />
        </div>
      </div>
      <br />
      <div
        className={"p-t-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix"}
      >
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button
              className={"btn btn-primary btn-cons pull-right"}
              type="submit"
            >
              <span>Next</span>
            </button>
          </li>
          <li className={"previous"}>
            <button
              className={"btn btn-default btn-cons pull-right"}
              type="button"
              onClick={previousPage}
            >
              <span>Previous</span>
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const query = [
  {
    collection: "outletTypes",
    orderBy: ["outletType", "desc"],
  },
];

const mapState = (state) => ({
  outletTypes: state.firestore.ordered.outletTypes,
});

export default compose(
  connect(mapState),
  reduxForm({
    form: "merchantForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(merchantOutlet);
