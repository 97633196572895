import { createReducer } from "../../app/common/util/reducerUtil";
import {
  SET_SUMMARY,
} from "./airtimeConstants";

const initialState = [];

export const setSummary = (state, payload) => {
  console.log("payload", payload.summary);

  return payload.summary;
};

export default createReducer(initialState, {
  [SET_SUMMARY]: setSummary
});