import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import format from "date-fns/format";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
// import { DateRangePicker } from "react-dates";

import { openModal } from "../../../modals/modalActions";
import { createReportExcelFile } from "../../../../app/excel/excel";

import { DatePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import { reportByDate } from "../../reportActions";
import SelectInput from "../../../../app/common/form/SelectInput";
import classes from "./ReportBody.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId"),
});

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

class ReportBody extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    s_Date: moment().startOf("day"),
    e_Date: moment().endOf("day"),
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    startTime: "00:00",
    endTime: "23:59",
    outletName: "",
  };

  // handleStartDateChange = (date) => {

  //   this.setState({ startDate: date });

  //   if (date > this.state.endDate) this.setState({ endDate: date });

  // };

  //  handleEndDateChange = (date) => {

  //   this.setState({ endDate: date });

  //   if (date < this.state.startDate) this.setState({ startDate: date });
  // };

  handleStartDateChange = (date) => {
    let dateTimeString = date.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${this.state.startTime}`);

    this.setState({ startDate: dateTime });
    this.setState({ s_Date: date });

    if (date > this.state.e_Date) {
      let endDateTime = moment(`${dateTimeString} 23:59:59`);

      this.setState({ endDate: endDateTime });
      this.setState({ e_Date: date });
      this.setState({ endTime: "23:59" });
    }
  };

  handleEndDateChange = (date) => {
    // setEndDate(date);

    if (date < this.state.startDate) this.setState({ startDate: date });

    let dateTimeString = date.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${this.state.endTime}`);

    this.setState({ endDate: dateTime });
    this.setState({ e_Date: date });

    if (date < this.state.s_Date) {
      let startDateTime = moment(`${dateTimeString} 00:00`);

      this.setState({ startDate: startDateTime });
      this.setState({ s_Date: date });
      this.setState({ startTime: "00:00" });
    }
  };

  handleStartTimeChange = (event) => {
    this.setState({ startTime: event.target.value });

    let dateTimeString = this.state.startDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    this.setState({ startDate: dateTime });
  };

  handleEndTimeChange = (event) => {
    this.setState({ endTime: event.target.value });

    let dateTimeString = this.state.endDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    this.setState({ endDate: dateTime });
  };

  async componentDidMount() {
    await this.props.reportByDate({
      reportStartDate: moment(this.props.initialValues.reportStartDate)
        .startOf("day")
        .toDate(),
      reportEndDate: moment(this.props.initialValues.reportEndDate)
        .endOf("day")
        .toDate(),
      outletId: this.props.initialValues.outletId,
      outletName: this.props.initialValues.outletName,
    });

    this.setState({ outletName: this.props.initialValues.outletName });
    this.setState({ loadingInitial: false });
  }

  salesByDateSheet = async (
    transactions,
    workbook,
    logo,
    reportStartDate,
    reportEndDate,
    merchantName
  ) => {
    let startDate = format(reportStartDate, "YYYY-MM-DD");
    let endDate = format(reportEndDate, "YYYY-MM-DD");
    let salesByDate = [];

    let worksheet = workbook.addWorksheet("Sales By Date", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 7,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "G2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A3", "G3");

    worksheet.mergeCells("A4", "G4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "For ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${merchantName}`,
        },
      ],
    };

    worksheet.mergeCells("A5", "G5");

    worksheet.mergeCells("A6", "G6");
    worksheet.getCell("A6").value = `Sales By Date`;
    worksheet.getRow(6).font = {
      name: "Verdana",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A7", "G7");

    worksheet.mergeCells("A8", "G8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date From ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${startDate}`,
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: " to ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${endDate}`,
        },
      ],
    };

    // insert an image over B2:D6
    // worksheet.addImage(imageId1, 'A1:G7');

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(10);
    headers.height = 25;
    headers.values = ["Date", "Amount"];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };

      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    // worksheet.getCell('A10').alignment = {
    //     vertical: 'middle',
    //     horizontal: 'center'
    // };

    worksheet.columns = [
      {
        key: "date",
        width: 30,
      },
      {
        key: "transactionAmount",
        width: 15,
      },
    ];

    worksheet.getColumn(2).numFmt = "0.00";

    transactions.forEach((doc, index) => {
      worksheet
        .addRow({
          date: doc.transactionDateTime,
          transactionAmount: parseFloat(doc.transactionAmount),
        })
        .commit();
    });

    let total = generateSalesTotalsCell(worksheet, "B", transactions.length);

    const totalsRow = worksheet.addRow(["Sales Totals", total]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
      horizontal: "right",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Verdana",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });
  };

  render() {
    const {
      handleSubmit,
      openModal,
      createReportExcelFile,
      merchant,
      reportByDate,
      transactions,
      requesting,
    } = this.props;

    const { outletName } = this.state;
    const loading = requesting[`merchants`];

    let outlets = Object.values(this.props.outlets).map((outlet) => {
      return {
        label: `${outlet.id} - ${outlet.name}`,
        value: outlet.id,
      };
    });

    const onFormSubmit = async (values) => {
      const outletId = values.outletId;

      const reportStartDate = new Date(this.state.startDate);
      const reportEndDate = new Date(this.state.endDate);

      this.setState({ loadingInitial: true });
      await reportByDate({
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        outletId: outletId,
      });
      this.setState({
        loadingInitial: false,
        outletName: values.outletId.label,
      });
    };

    const exportToExcel = async () => {
      openModal("SpinnerModal");

      await createReportExcelFile(
        "Sales By Date",
        transactions,
        this.salesByDateSheet,
        outletName,
        this.state.startDate.toDate(),
        this.state.endDate.toDate()
      );
    };

    // let itemisedReport = "Spinner.....";
    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (!this.state.loadingInitial) {
      let salesTotal = transactions.reduce(function (accumulator, transaction) {
        return accumulator + transaction.transactionAmount;
      }, 0);

      // let salesTotal = 0;

      transactions.length > 0
        ? (itemisedReport = (
            <Fragment>
              {outletName && (
                <div style={{ marginTop: "3rem" }}>
                  <h4>Outlet: {outletName}</h4>
                </div>
              )}

              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Date of Sale</th>
                    <th scope="col">Sale Value</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((report, index) => {
                    return (
                      <tr className={classes.MerchantRow} key={index}>
                        <td>{report.transactionDateTime}</td>
                        <td>{moneyFormat(report.transactionAmount)}</td>
                      </tr>
                    );
                  })}
                  <tr className={classes.Totals}>
                    <td>
                      <div className={classes.MoneyTotalLabel}> Total:</div>
                    </td>
                    <td className={classes.MoneyTotal}>
                      {moneyFormat(salesTotal)}
                    </td>
                    {/* <td className={classes.MoneyTotal}>{salesTotal2}</td> */}
                  </tr>
                </tbody>
              </table>
            </Fragment>
          ))
        : (itemisedReport = (
            <h4>Selected Outlet has no transactions for selected period.</h4>
          ));
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <Field
                      name="outletId"
                      options={outlets}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "neutral0",
                        },
                      })}
                      component={SelectInput}
                      placeholder="Select Outlet..."
                      className={"form-control"}
                      required="true"
                      aria-required="true"
                    />
                  </div>

                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <div className={"offset-1"}>
                        {/* <div className={"row"}>
                        <span>
                          <DateTimePicker
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            ampm={false}
                            inputProps={{
                              style: {
                                padding: 2,
                              },
                            }}
                            margin="none"
                            id="start-date"
                            label="Start Date"
                            value={this.state.startDate}
                            disableFuture={true}
                            onChange={(date) => this.handleStartDateChange(date)}
                          />
                        </span>
                        <span> &nbsp;&nbsp;&nbsp; </span>

                        <span>
                          <DateTimePicker autoOk={true}
                            disableToolbar
                            variant="inline"
                            ampm={false}
                            inputProps={{
                              style: {
                                padding: 2,
                              },
                            }}
                            margin="none"
                            id="start-date"
                            label="End Date"
                            value={this.state.endDate}
                            disableFuture={true}
                            onChange={(date) => this.handleEndDateChange(date)}
                          />
                        </span>

                        <button
                          className={classes.FilterReportBtn}
                          style={{ marginLeft: "1rem" }}
                          type="submit"
                        >
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 18 18"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                id="path1"
                                transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                fill="#FFFFFF"
                                d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                              />
                            </g>
                          </svg>
                        </button>
                      </div> */}

                        <div className={"row"}>
                          <span>
                            <InputLabel id="start-date-label">
                              Start Date
                            </InputLabel>
                            <DatePicker
                              labelid="start-date-label"
                              autoOk={true}
                              disableToolbar
                              variant="inline"
                              margin="none"
                              id="start-date"
                              value={this.state.s_Date}
                              disableFuture={true}
                              onChange={(date) =>
                                this.handleStartDateChange(date)
                              }
                            />
                          </span>
                          <span>
                            <InputLabel id="start-time-label">Time</InputLabel>
                            <Select
                              labelid="start-time-label"
                              id="start-time"
                              value={this.state.startTime}
                              onChange={this.handleStartTimeChange}
                            >
                              <MenuItem value="00:00">00:00</MenuItem>
                              <MenuItem value="00:30">00:30</MenuItem>
                              <MenuItem value="01:00">01:00</MenuItem>
                              <MenuItem value="01:30">01:30</MenuItem>
                              <MenuItem value="02:00">02:00</MenuItem>
                              <MenuItem value="02:30">02:30</MenuItem>
                              <MenuItem value="03:00">03:00</MenuItem>
                              <MenuItem value="03:30">03:30</MenuItem>
                              <MenuItem value="04:00">04:00</MenuItem>
                              <MenuItem value="04:30">04:30</MenuItem>
                              <MenuItem value="05:00">05:00</MenuItem>
                              <MenuItem value="05:30">05:30</MenuItem>
                              <MenuItem value="06:00">06:00</MenuItem>
                              <MenuItem value="06:30">06:30</MenuItem>
                              <MenuItem value="07:00">07:00</MenuItem>
                              <MenuItem value="07:30">07:30</MenuItem>
                              <MenuItem value="08:00">08:00</MenuItem>
                              <MenuItem value="08:30">08:30</MenuItem>
                              <MenuItem value="09:00">09:00</MenuItem>
                              <MenuItem value="09:30">09:30</MenuItem>
                              <MenuItem value="10:00">10:00</MenuItem>
                              <MenuItem value="10:30">10:30</MenuItem>
                              <MenuItem value="11:00">11:00</MenuItem>
                              <MenuItem value="11:30">11:30</MenuItem>
                              <MenuItem value="12:00">12:00</MenuItem>
                              <MenuItem value="12:30">12:30</MenuItem>
                              <MenuItem value="13:00">13:00</MenuItem>
                              <MenuItem value="13:30">13:30</MenuItem>
                              <MenuItem value="14:00">14:00</MenuItem>
                              <MenuItem value="14:30">14:30</MenuItem>
                              <MenuItem value="15:00">15:00</MenuItem>
                              <MenuItem value="15:30">15:30</MenuItem>
                              <MenuItem value="16:00">16:00</MenuItem>
                              <MenuItem value="16:30">16:30</MenuItem>
                              <MenuItem value="17:00">17:00</MenuItem>
                              <MenuItem value="17:30">17:30</MenuItem>
                              <MenuItem value="18:00">18:00</MenuItem>
                              <MenuItem value="18:30">18:30</MenuItem>
                              <MenuItem value="19:00">19:00</MenuItem>
                              <MenuItem value="19:30">19:30</MenuItem>
                              <MenuItem value="20:00">20:00</MenuItem>
                              <MenuItem value="20:30">20:30</MenuItem>
                              <MenuItem value="21:00">21:00</MenuItem>
                              <MenuItem value="21:30">21:30</MenuItem>
                              <MenuItem value="22:00">22:00</MenuItem>
                              <MenuItem value="22:30">22:30</MenuItem>
                              <MenuItem value="23:00">23:00</MenuItem>
                              <MenuItem value="23:30">23:30</MenuItem>
                            </Select>
                          </span>
                          <span style={{ marginRight: "30px" }}> </span>

                          <span>
                            <InputLabel id="end-date-label">
                              End Date
                            </InputLabel>
                            <DatePicker
                              labelid="end-date-label"
                              id="end-date"
                              autoOk={true}
                              disableToolbar
                              variant="inline"
                              margin="none"
                              value={this.state.e_Date}
                              disableFuture={true}
                              onChange={(date) =>
                                this.handleEndDateChange(date)
                              }
                            />
                          </span>
                          <span>
                            <InputLabel id="end-time-label">Time</InputLabel>
                            <Select
                              labelid="end-time-label"
                              id="end-time"
                              value={this.state.endTime}
                              onChange={(time) =>
                                this.handleEndTimeChange(time)
                              }
                            >
                              <MenuItem value="00:00">00:00</MenuItem>
                              <MenuItem value="00:30">00:30</MenuItem>
                              <MenuItem value="01:00">01:00</MenuItem>
                              <MenuItem value="01:30">01:30</MenuItem>
                              <MenuItem value="02:00">02:00</MenuItem>
                              <MenuItem value="02:30">02:30</MenuItem>
                              <MenuItem value="03:00">03:00</MenuItem>
                              <MenuItem value="03:30">03:30</MenuItem>
                              <MenuItem value="04:00">04:00</MenuItem>
                              <MenuItem value="04:30">04:30</MenuItem>
                              <MenuItem value="05:00">05:00</MenuItem>
                              <MenuItem value="05:30">05:30</MenuItem>
                              <MenuItem value="06:00">06:00</MenuItem>
                              <MenuItem value="06:30">06:30</MenuItem>
                              <MenuItem value="07:00">07:00</MenuItem>
                              <MenuItem value="07:30">07:30</MenuItem>
                              <MenuItem value="08:00">08:00</MenuItem>
                              <MenuItem value="08:30">08:30</MenuItem>
                              <MenuItem value="09:00">09:00</MenuItem>
                              <MenuItem value="09:30">09:30</MenuItem>
                              <MenuItem value="10:00">10:00</MenuItem>
                              <MenuItem value="10:30">10:30</MenuItem>
                              <MenuItem value="11:00">11:00</MenuItem>
                              <MenuItem value="11:30">11:30</MenuItem>
                              <MenuItem value="12:00">12:00</MenuItem>
                              <MenuItem value="12:30">12:30</MenuItem>
                              <MenuItem value="13:00">13:00</MenuItem>
                              <MenuItem value="13:30">13:30</MenuItem>
                              <MenuItem value="14:00">14:00</MenuItem>
                              <MenuItem value="14:30">14:30</MenuItem>
                              <MenuItem value="15:00">15:00</MenuItem>
                              <MenuItem value="15:30">15:30</MenuItem>
                              <MenuItem value="16:00">16:00</MenuItem>
                              <MenuItem value="16:30">16:30</MenuItem>
                              <MenuItem value="17:00">17:00</MenuItem>
                              <MenuItem value="17:30">17:30</MenuItem>
                              <MenuItem value="18:00">18:00</MenuItem>
                              <MenuItem value="18:30">18:30</MenuItem>
                              <MenuItem value="19:00">19:00</MenuItem>
                              <MenuItem value="19:30">19:30</MenuItem>
                              <MenuItem value="20:00">20:00</MenuItem>
                              <MenuItem value="20:30">20:30</MenuItem>
                              <MenuItem value="21:00">21:00</MenuItem>
                              <MenuItem value="21:30">21:30</MenuItem>
                              <MenuItem value="22:00">22:00</MenuItem>
                              <MenuItem value="22:30">22:30</MenuItem>
                              <MenuItem value="23:00">23:00</MenuItem>
                              <MenuItem value="23:30">23:30</MenuItem>
                              <MenuItem value="23:59">23:59</MenuItem>
                            </Select>
                          </span>

                          <button
                            className={classes.FilterReportBtn}
                            style={{ marginLeft: "1rem" }}
                            type="submit"
                          >
                            <svg
                              height="18"
                              width="18"
                              viewBox="0 0 18 18"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path
                                  id="path1"
                                  transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                  fill="#FFFFFF"
                                  d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                                />
                              </g>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
              <div
                className={classes.ToolsDataExportIconWrapper}
                onClick={() => exportToExcel()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>{itemisedReport}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  reportByDate,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state, props) => ({
  transactions: state.reports,
  requesting: state.firestore.status.requesting,
  merchant: state.firestore.ordered.merchant[0],
  initialValues: {
    outletId: Object.values(props.outlets)[0].id,
    outletName: Object.values(props.outlets)[0].name,
    reportStartDate: format(new Date(), "YYYY-MM-DD"),
    reportEndDate: format(new Date(), "YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterReportForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(ReportBody)
);
