import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import ChangeUserName from "../users/Manage/ChangeUserName";

const actions = { closeModal };

const changeUserNameModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Change User Name(s)" onClose={closeModal}>
      <ChangeUserName />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(changeUserNameModal);
