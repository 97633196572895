import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { firestoreConnect } from "react-redux-firebase";

import validate from "./validate";
import { registerMerchantDeviceAction } from "../../../../../merchantActions";
import AsyncTextInput from "../../../../../../../app/common/form/AsyncTextInput";
import SelectInput from "../../../../../../../app/common/form/SelectInput";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import styles from "./RegisterComputer.module.css";

import { asyncInventoryComputersValidate as asyncValidate } from "../../../../../../../app/common/validation/asyncValidate.jsx";

const RegisterMerchantDevice = ({
  registerMerchantDeviceAction,
  handleSubmit,
  loading,
  error,
  deviceTypes,
  invalid,
  submitting,
  merchantId,
}) => {
  const onFormSubmit = (values) => {
    registerMerchantDeviceAction(values);
  };

  let deviceNetworkTypeList = {};
  let deviceTypeList = {};

  deviceNetworkTypeList =
    deviceTypes &&
    deviceTypes.map((deviceType) => {
      return {
        label: deviceType.deviceType,
        value: deviceType.deviceType,
      };
    });

  deviceTypeList = [
    {
      label: "Computer",
      value: "Computer",
    },
    {
      label: "Mobile",
      value: "Mobile",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="Name"
            name="name"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Device Name"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="MAC Address"
            name="macAddress"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="MAC Address"
            required="required"
          />
        </div>
      </div>

      <div className={"row clearfix"}>
        <div className={"col-md-4"}>
          <Field
            name="deviceType"
            options={deviceTypeList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            component={SelectInput}
            placeholder="Select Device Type"
            className={"form-control"}
            required="true"
            aria-required="true"
          />
        </div>

        <div className={"col-md-4"}>
          <Field
            name="deviceNetworkType"
            options={deviceNetworkTypeList}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            component={SelectInput}
            placeholder="Network Device Type"
            className={"form-control"}
            required="true"
            aria-required="true"
          />
        </div>
      </div>

      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}

      <button className={styles.btn} type="submit" disabled={loading}>
        {loading ? (
          <Spinner
            style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
          />
        ) : (
          <span>Register Device</span>
        )}
      </button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  deviceTypes: state.firestore.ordered.macAddressTypes,
  initialValues: {
    outletId: state.modals.modalProps.outletId,
    account: state.modals.modalProps.account,
    outletName: state.modals.modalProps.outletName,
    merchantName: state.modals.modalProps.merchantName,
  },
  enableReinitialize: true,
});

const actions = {
  registerMerchantDeviceAction,
};

const query = [
  {
    collection: "macAddressTypes",
    orderBy: ["deviceType", "desc"],
  },
];

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerMerchantDevice",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["macAddress", "serialNumber"],
    validate,
  }),
  firestoreConnect(query)
)(RegisterMerchantDevice);
