//import liraries
import React, { useState, Fragment } from "react";

import { connect } from "react-redux";

import format from "date-fns/format";
import moment from "moment";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";

import { getChoppiesSalesAndCommissionForExport } from "../../reportActions";

import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { openModal } from "../../../modals/modalActions";
import classes from "./index.module.css";

const index = ({
  initialValues,
  openModal,
  getChoppiesSalesAndCommissionForExport,
}) => {
  let [loading, setLoading] = useState(false);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>
              Reports - Sales and Commission
            </span>
            <br />
            <span className={classes.ByLine}>
              You are in the PrepaidPlus Reports panel
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        <strong>{format(startDate, "YYYY-MM-DD")}</strong>
                        {` to `}
                        <strong>{format(endDate, "YYYY-MM-DD")}</strong>
                      </h5>
                    </div>
                    <div
                      className={"row"}
                      style={{ alignItems: "center", flex: "1 1 auto" }}
                    >
                      <div className={"col-md-6"}>
                        <div className={classes.FilterWrapper}></div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className={classes.PageFilter}>
                          <div className={"row"}>
                            <DateRangePicker
                              startDate={startDate} // momentPropTypes.momentObj or null,
                              startDateId="startDate" // PropTypes.string.isRequired,
                              endDate={endDate} // momentPropTypes.momentObj or null,
                              endDateId="endDate" // PropTypes.string.isRequired,
                              onDatesChange={({ startDate, endDate }) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                              }} // PropTypes.func.isRequired,
                              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                              onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                              numberOfMonths={1}
                              isOutsideRange={(date) => date.isAfter(today)}
                              displayFormat={"YYYY-MM-DD"}
                              minimumNights={0}
                              small={true}
                              readOnly={true}
                            />
                          </div>

                          {/* <!-- END Form Control--> */}
                        </div>
                      </div>
                    </div>
                  </header>

                  {loading ? (
                    <LoadingComponent inverted={true} />
                  ) : (
                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              <div className={"col-lg-3"}>
                                <button
                                  className={classes.SalesBtn}
                                  onClick={() =>
                                    openModal(
                                      "ChoppiesSalesAndCommissionReportSpinnerModal",
                                      {
                                        reportStartDate: moment(
                                          startDate.toDate()
                                        ).format("YYYY-MM-DD"),
                                        reportEndDate:
                                          endDate.format("YYYY-MM-DD"),
                                      }
                                    )
                                  }
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                                      />
                                    </svg>
                                  </span>{" "}
                                  Download Sales and Commission Report
                                </button>
                              </div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

const actions = {
  getChoppiesSalesAndCommissionForExport,
  openModal,
};

export default connect(mapStateToProps, actions)(index);
