import React from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";

import { updatePettyCash } from "../../pettyCashActions";

const approved = (props) => {
  let { transaction, profile, auth, loading, openModal } = props;

  return (
    <table className={classes.FuneralSchemeTable}>
      <thead>
        <tr>
          <th className={"hide-s"} scope="col">
            Amount
          </th>

          <th className={"hide-s"} scope="col">
            Status
          </th>
          <th className={"hide-s"} scope="col">
            Actioned By
          </th>
          <th className={"hide-s"} scope="col">
            Date Actioned
          </th>
          <th className={"hide-s"} scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr className={classes.MemberRow}>
          <td>{transaction.amount}</td>
          <td>{transaction.status}</td>
          <td>{transaction.approvedBy}</td>
          <td>{transaction.dateActioned.toDate()}</td>
          <td style={{ whiteSpace: "pre-wrap" }}>
            Awaiting approval by Finance.
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(mapStateToProps, actions)(approved);
