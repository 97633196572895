import axios from "../../axios-firebase";

import { FETCH_FNB_RECON_TRANSACTIONS } from "./reconsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";

export const getTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    //console.log("recon values", values);

    const startDate = values.startDate;
    const endDate = values.endDate;
    try {
      dispatch(asyncActionStart());

      let response = await axios
        .post("/recon-fnbSalesRecon", {
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          return response;
        });

      // //console.log("response.data", response.data);

      const recons = response.data;

      dispatch({ type: FETCH_FNB_RECON_TRANSACTIONS, payload: { recons } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        //console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getKazangTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    //console.log("recon values", values);

    const startDate = values.startDate;
    const endDate = values.endDate;
    try {
      dispatch(asyncActionStart());

      let response = await axios
        .post("/recon-kazangSalesRecon", {
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          return response;
        });

      //console.log("response.data", response.data);

      const recons = response.data;

      dispatch({ type: FETCH_FNB_RECON_TRANSACTIONS, payload: { recons } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        //console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getBPCTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    //console.log("recon values", values);

    const startDate = values.startDate;
    const endDate = values.endDate;
    try {
      dispatch(asyncActionStart());

      let response = await axios
        .post("/recon-bpcSalesRecon", {
          startDate: startDate,
          endDate: endDate,
        })
        .then((response) => {
          return response;
        });

      //console.log("response.data", response.data);

      const recons = response.data;

      dispatch({ type: FETCH_FNB_RECON_TRANSACTIONS, payload: { recons } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        //console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const clearTodaysRecons = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let recons = [];
    dispatch({ type: FETCH_FNB_RECON_TRANSACTIONS, payload: { recons } });
    dispatch(asyncActionFinish());
  } catch (error) {
    //console.log(error);
    dispatch(asyncActionError());
  }
};
