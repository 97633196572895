import { SIDE_DRAWER_OPEN, SIDE_DRAWER_CLOSE } from "./sideDrawerConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = null;

export const openSideDrawer = (state, payload) => {
  const { sideDrawerType, sideDrawerProps } = payload;
  return { sideDrawerType, sideDrawerProps };
};

export const closeSideDrawer = (state, payload) => {
  return null;
};

export default createReducer(initialState, {
  [SIDE_DRAWER_OPEN]: openSideDrawer,
  [SIDE_DRAWER_CLOSE]: closeSideDrawer
});
