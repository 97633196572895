import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import format from "date-fns/format";
import { moneyFormat } from "../../../app/common/util/moneyFormat";

import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween
} from "revalidate";
import classes from "./SuspendCreditFacility.module.css";
import { suspendFacility } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthBetween(4, 4)({
      message: "Must be 4 numbers"
    })
  )()
});

class SuspendCreditFacility extends Component {
  render() {
    const {
      suspendFacility,
      handleSubmit,
      closePanel,
      creditFacility,
      account
    } = this.props;

    const onFormSubmit = values => {
      suspendFacility(creditFacility.id);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={classes.TabHeader}>
          <div className={classes.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h6>
                Status:
                <span className={"semi-bold"}> {creditFacility.status}</span>
              </h6>
              <h6>
                Amount:
                <span className={"semi-bold"}>
                  {" "}
                  {moneyFormat(creditFacility.amount)}
                </span>
              </h6>
              <h6>
                Applied:
                <span className={"semi-bold"}>
                  {" "}
                  {format(
                    creditFacility.dateApplied &&
                      creditFacility.dateApplied.toDate(),
                    "YYYY-MM-DD"
                  )}
                </span>
              </h6>
              {creditFacility.status === "Approved" && (
                <h6>
                  Approved:
                  <span className={"semi-bold"}>
                    {" "}
                    {format(
                      creditFacility.expiryDate &&
                        creditFacility.expiryDate.toDate(),
                      "YYYY-MM-DD"
                    )}
                  </span>
                </h6>
              )}
              <br />

              {creditFacility.status === "Approved" ? (
                <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                  <ul className={"pager wizard no-style"}>
                    <li className="next">
                      <button
                        className={"btn btn-primary btn-cons"}
                        type="submit"
                      >
                        <span>Suspend Credit Facility</span>
                      </button>
                    </li>
                  </ul>
                </div>
              ) : null}
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  suspendFacility
};

const mapStateToProps = state => ({
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(SuspendCreditFacility)
);
