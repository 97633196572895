import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import TextInput from "../../../../../../../app/common/form/TextInput";
import { asyncOutletNameValidate as asyncValidate } from "../../../../../../../app/common/validation/asyncValidate";
import validate from "./validate";
import styles from "./Edit.module.css";

import { updateOutletField } from "../../../../../merchantActions";

const editName = (props) => {
  const { name, handleSubmit, loading, closePanel, updateOutletField } = props;
  const onFormSubmit = (values) => {
    let type = "Name";
    updateOutletField(values, props.account, props.outletId, type);
    closePanel();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="outletName"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Name"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">
            <button className={styles.btn} type="submit" disabled={loading}>
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>Update Name</span>
              )}
            </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateOutletField,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editOutletFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(editName)
);

