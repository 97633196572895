import React, { Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { formatMoney } from "../../../app/common/util/helpers";

import classes from "./index.module.css";

const index = ({ withdrawals, withdrawalsTotal }) => {
  return (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>
          {withdrawals.length > 0 ? (
            <Fragment>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Captured By</th>
                    <th scope="col">Authorised By</th>
                    <th scope="col"> Withdrawal Detail</th>
                    <th scope="col">Amount Withdrawn</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(withdrawals).map((withdrawal, index) => {
                    return (
                      <tr className={classes.MerchantRow} key={index}>
                        <td>
                          {format(
                            withdrawal.transactionDateTime,
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                        <td>{withdrawal.createdBy}</td>
                        <td>{withdrawal.authorisedBy}</td>
                        <td>
                          {withdrawal.withdrawalType +
                            " - " +
                            withdrawal.withdrawalReason}
                        </td>
                        <td>{formatMoney(withdrawal.transactionAmount)}</td>
                      </tr>
                    );
                  })}
                  <tr className={classes.Totals}>
                    <td colSpan="4">
                      <div className={classes.MoneyTotalLabel}> Total:</div>
                    </td>
                    <td className={classes.MoneyTotal}>
                      {formatMoney(withdrawalsTotal)}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </Fragment>
          ) : (
            <h4>
              There are no <strong>withdrawals</strong> for the selected period.
            </h4>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  withdrawals: state.financials.withdrawalsList,
  withdrawalsTotal: state.financials.withdrawalsTotal,
});

export default connect(mapStateToProps)(index);
