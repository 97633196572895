import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import ReversalReportSpinner from "../reports/Cachet/ReversalsReport/ReversalsReportSpinner";

const actions = {
  closeModal,
};

const reversalSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <ReversalReportSpinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(reversalSpinnerModal);
