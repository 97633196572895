//import liraries
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import format from "date-fns/format";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { createNewExcelFile } from "../../app/excel/excel";

import { openModal } from "../modals/modalActions";
import Tabs from "../../app/UI/Tabs/Tabs";
import UserList from "./UserList/UserList";

import classes from "./Users.module.css";

// create a component
class Users extends Component {
  userListSheet = async (users, workbook, logo) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("WUC User List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 5,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "E2");

    worksheet.mergeCells("A3", "E3");

    worksheet.mergeCells("A4", "E4");
    worksheet.getCell("A4").value = `WUC User List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "E5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "E6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = ["Name", "E-mail", "Role", "Date Created", "Created By"];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "displayName",
        width: 25,
      },
      {
        key: "email",
        width: 30,
      },
      {
        key: "role",
        width: 20,
      },
      {
        key: "dateCreated",
        width: 14,
      },
      {
        key: "createdBy",
        width: 25,
      },
    ];

    users.forEach((doc, index) => {
      worksheet
        .addRow({
          displayName: doc.displayName,
          email: doc.email,
          role: doc.role,
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  render() {
    let { users, cachetUsers, merchantUsers, openModal, requesting } =
      this.props;
    const loading = requesting[`users?where=userType:==:WUC`];

    if (loading)
      return (
        <Fragment>
          <div className={classes.Container}>
            <div className={classes.BreadcrumbWrapper}>
              <div className={classes.Breadcrumb}>
                <span className={classes.Header}>WUC Users</span>
                <br />
                <span className={classes.ByLine}>
                  You are in the <span className={"bold"}>WUC Users</span>
                  panel
                </span>
              </div>
            </div>
            <LoadingComponent />
          </div>
        </Fragment>
      );

    // if (users) {
    //   let cachetUserList = users.filter(user => user.accountType === "PrepaidPlus");
    //   let merchantUserList = users.filter(
    //     user => user.accountType === "Merchant"
    //   );
    // }

    const wucUsers = users && <UserList userList={users} />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>Portal Users</span>
              <br />
              <span className={classes.ByLine}>
                You are in the <span className={"bold"}>Portal Users</span>
                panel
              </span>
            </div>
          </div>

          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Users</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>WUC Users</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          <Tabs>
                            <div label="WUC Users">
                              <header className={classes.TabHeader}>
                                <div className={classes.PageHeader}>
                                  <h4>WUC Users</h4>
                                </div>
                                <div className={classes.AddUser}>
                                  <div>
                                    <button
                                      className={classes.AddUserBtn}
                                      onClick={() =>
                                        openModal("RegisterWUCUserModal")
                                      }
                                    >
                                      Create WUC User
                                    </button>
                                  </div>
                                  <div
                                    className={classes.ToolsExportIconWrapper}
                                    onClick={() =>
                                      createNewExcelFile(
                                        `WUC User List`,
                                        users,
                                        this.userListSheet
                                      )
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24"
                                      width="24"
                                      viewBox="0 0 24 24"
                                    >
                                      <g>
                                        <path
                                          id="path1"
                                          transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                          fill="#707070"
                                          d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </header>
                              {wucUsers}
                            </div>
                            <div label={""} />
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const actions = {
  openModal,
};

const query = (props) => {
  // console.log("props", props);
  return [
    {
      collection: "users",
      where: ["userType", "==", "WUC"],
    },
  ];
};

const mapStateToProps = (state) => ({
  users: state.firestore.ordered.users,
  requesting: state.firestore.status.requesting,
});

export default compose(
  connect(mapStateToProps, actions),
  firestoreConnect(query)
)(Users);
