import React from "react";

const TextInput = ({
  label,
  required,
  input,
  type,
  placeholder,
  value,
  meta: { touched, error }
}) => {
  let attachedClasses = ["form-group", "form-group-default", required];

  //  error={condition && value}, pass error={condition ? value : undefined}

  return (
    <div className={attachedClasses.join(" ")}>
      {/* <label className={"label-sm"}>{label}</label> */}
      <div className={"controls"}>
        <input
          {...input}
          value={value}
          placeholder={placeholder}
          type={type}
          className={"form-control"}
          autoComplete="off"
        />
        {touched && error && <label className={"error"}>{error}</label>}
      </div>
    </div>
  );
};

export default TextInput;
