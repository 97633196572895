import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import MerchantCorporateForm from "../merchants/MerchantForm/MerchantCorporateForm";

const actions = { closeModal };

const createMerchantModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create Corporate Merchant" onClose={closeModal}>
      <MerchantCorporateForm />
    </Modal>
  );
};

export default connect(null, actions)(createMerchantModal);
