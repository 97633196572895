import React, { Fragment } from "react";
import { connect } from "react-redux";
import { moneyFormat } from "../../../app/common/util/moneyFormat";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const financialRecords = ({ mascom, orange, bemobile }) => {
  let mascomOpeningQuantity = 0;
  let mascomPurchasesQuantity = 0;
  let mascomSalesQuantity = 0;
  let mascomClosingQuantity = 0;
  let mascomOpeningValue = 0;
  let mascomPurchasesValue = 0;
  let mascomSalesValue = 0;
  let mascomClosingValue = 0;

  let orangeOpeningQuantity = 0;
  let orangePurchasesQuantity = 0;
  let orangeSalesQuantity = 0;
  let orangeClosingQuantity = 0;
  let orangeOpeningValue = 0;
  let orangePurchasesValue = 0;
  let orangeSalesValue = 0;
  let orangeClosingValue = 0;

  let beMobileOpeningQuantity = 0;
  let beMobilePurchasesQuantity = 0;
  let beMobileSalesQuantity = 0;
  let beMobileClosingQuantity = 0;
  let beMobileOpeningValue = 0;
  let beMobilePurchasesValue = 0;
  let beMobileSalesValue = 0;
  let beMobileClosingValue = 0;

  let mascomReduced = mascom
    .map((doc) => {
      return {
        total: doc.total,
        salesTotal: 0,
        purchaseTotal: 0,
        count: doc.count,
        salesCount: 0,
        purchaseCount: 0,
        providerDenomination: `${doc.provider} ${doc.denomination}`,
        provider: doc.provider,
      };
    })
    .reduce(function (arr, doc) {
      if (!(doc.providerDenomination in arr)) {
        arr.push((arr[doc.providerDenomination] = doc));

        mascomOpeningQuantity += parseInt(doc.count) + parseInt(doc.salesCount);
        mascomClosingQuantity += parseInt(doc.count);

        mascomOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
        mascomClosingValue += parseInt(doc.total);

        return arr;
      }
      arr[doc.providerDenomination].total += doc.total ? doc.total : 0;
      arr[doc.providerDenomination].count += doc.count ? doc.count : 0;

      mascomOpeningQuantity += parseInt(doc.count) + parseInt(doc.salesCount);
      mascomClosingQuantity += parseInt(doc.count);

      mascomOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
      mascomClosingValue += parseInt(doc.total);

      return arr;
    }, [])
    .sort((a, b) =>
      a.denomination > b.denomination
        ? 1
        : b.denomination > a.denomination
        ? -1
        : 0
    );

  let bemobileReduced = bemobile
    .map((doc) => {
      return {
        total: doc.total,
        salesTotal: 0,
        purchaseTotal: 0,
        count: doc.count,
        salesCount: 0,
        purchaseCount: 0,
        denomination: doc.denomination,
        providerDenomination: `${doc.provider} ${doc.denomination}`,
        provider: doc.provider,
      };
    })
    .reduce(function (arr, doc) {
      if (!(doc.providerDenomination in arr)) {
        arr.push((arr[doc.providerDenomination] = doc));
        beMobileOpeningQuantity +=
          parseInt(doc.count) + parseInt(doc.salesCount);
        beMobileClosingQuantity += parseInt(doc.count);

        beMobileOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
        beMobileClosingValue += parseInt(doc.total);
        return arr;
      }
      arr[doc.providerDenomination].total += doc.total ? doc.total : 0;
      arr[doc.providerDenomination].count += doc.count ? doc.count : 0;

      beMobileOpeningQuantity += parseInt(doc.count) + parseInt(doc.salesCount);
      beMobileClosingQuantity += parseInt(doc.count);

      beMobileOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
      beMobileClosingValue += parseInt(doc.total);

      return arr;
    }, [])
    .sort((a, b) =>
      a.denomination > b.denomination
        ? 1
        : b.denomination > a.denomination
        ? -1
        : 0
    );

  let orangeReduced = orange
    .map((doc) => {
      return {
        total: doc.total,
        salesTotal: 0,
        purchaseTotal: 0,
        count: doc.count,
        salesCount: 0,
        purchaseCount: 0,
        denomination: doc.denomination,
        providerDenomination: `${doc.provider} ${doc.denomination}`,
        provider: doc.provider,
      };
    })
    .reduce(function (arr, doc) {
      if (!(doc.providerDenomination in arr)) {
        arr.push((arr[doc.providerDenomination] = doc));
        orangeOpeningQuantity += parseInt(doc.count) + parseInt(doc.salesCount);
        orangeClosingQuantity += parseInt(doc.count);

        orangeOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
        orangeClosingValue += parseInt(doc.total);
        return arr;
      }
      arr[doc.providerDenomination].total += doc.total ? doc.total : 0;
      arr[doc.providerDenomination].count += doc.count ? doc.count : 0;

      orangeOpeningQuantity += parseInt(doc.count) + parseInt(doc.salesCount);
      orangeClosingQuantity += parseInt(doc.count);

      orangeOpeningValue += parseInt(doc.total) + parseInt(doc.salesTotal);
      orangeClosingValue += parseInt(doc.total);

      return arr;
    }, [])
    .sort((a, b) =>
      a.denomination > b.denomination
        ? 1
        : b.denomination > a.denomination
        ? -1
        : 0
    );
  //   orange.sort((a, b) =>
  //   a.orderNumber > b.orderNumber ? 1 : b.orderNumber > a.orderNumber ? -1 : 0
  // );
  // bemobile.sort((a, b) =>
  // a.orderNumber > b.orderNumber ? 1 : b.orderNumber > a.orderNumber ? -1 : 0
  // );

  mascomReduced && console.log(" mascomReduced", mascomReduced);

  let mascomRecords =
    mascomReduced && mascomReduced.length > 0 ? (
      <Fragment>
        <table className={classes.MerchantTable} style={{ width: "100%" }}>
          <thead>
            <tr>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Quantity`}</th>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Values`}</th>
            </tr>
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th scope="col">{` `}</th>
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
              <th style={{ padding: "5px", background: "#fcfcfc" }} />
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
            </tr>
          </thead>
          <tbody>
            {mascomReduced.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={classes.MerchantRow}
                  style={{ textAlign: "right", verticalAlign: "middle" }}
                >
                  <td style={{ textAlign: "left" }}>
                    {item.providerDenomination}
                  </td>
                  <td>{parseInt(item.count) + parseInt(item.salesCount)}</td>
                  <td>{item.purchaseCount}</td>
                  <td>{item.salesCount}</td>
                  <td style={{ paddingRight: "20px" }}>{item.count}</td>
                  <td style={{ padding: "5px", background: "#ececec" }} />
                  <td>{moneyFormat(item.total + item.salesTotal)}</td>
                  <td>{moneyFormat(item.purchaseTotal)}</td>
                  <td>{moneyFormat(item.salesTotal)}</td>
                  <td style={{ paddingRight: "20px" }}>
                    {moneyFormat(item.total)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th>{`Totals:`}</th>
              <th>{mascomOpeningQuantity}</th>
              <th>{mascomPurchasesQuantity}</th>
              <th>{mascomSalesQuantity}</th>
              <th style={{ paddingRight: "20px" }}>{mascomClosingQuantity}</th>
              <th style={{ padding: "5px", background: "#ececec" }} />
              <th>{moneyFormat(mascomOpeningValue)}</th>
              <th>{moneyFormat(mascomPurchasesValue)}</th>
              <th>{moneyFormat(mascomSalesValue)}</th>
              <th style={{ paddingRight: "20px" }}>
                {moneyFormat(mascomClosingValue)}
              </th>
            </tr>
          </tbody>
        </table>
        <br />
      </Fragment>
    ) : (
      <h4>No Mascom vouchers available.</h4>
    );

  let bemobileRecords =
    bemobileReduced && bemobileReduced.length > 0 ? (
      <Fragment>
        <table className={classes.MerchantTable} style={{ width: "100%" }}>
          <thead>
            <tr>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Quantity`}</th>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Values`}</th>
            </tr>
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th scope="col">{` `}</th>
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
              <th style={{ padding: "5px", background: "#fcfcfc" }} />
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
            </tr>
          </thead>
          <tbody>
            {bemobileReduced.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={classes.MerchantRow}
                  style={{ textAlign: "right", verticalAlign: "middle" }}
                >
                  <td style={{ textAlign: "left" }}>
                    {item.providerDenomination}
                  </td>
                  <td>{parseInt(item.count) + parseInt(item.salesCount)}</td>
                  <td>{item.purchaseCount}</td>
                  <td>{item.salesCount}</td>
                  <td style={{ paddingRight: "20px" }}>{item.count}</td>
                  <td style={{ padding: "5px", background: "#ececec" }} />
                  <td>{moneyFormat(item.total + item.salesTotal)}</td>
                  <td>{moneyFormat(item.purchaseTotal)}</td>
                  <td>{moneyFormat(item.salesTotal)}</td>
                  <td style={{ paddingRight: "20px" }}>
                    {moneyFormat(item.total)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th>{`Totals:`}</th>
              <th>{beMobileOpeningQuantity}</th>
              <th>{beMobilePurchasesQuantity}</th>
              <th>{beMobileSalesQuantity}</th>
              <th style={{ paddingRight: "20px" }}>
                {beMobileClosingQuantity}
              </th>
              <th style={{ padding: "5px", background: "#ececec" }} />
              <th>{moneyFormat(beMobileOpeningValue)}</th>
              <th>{moneyFormat(beMobilePurchasesValue)}</th>
              <th>{moneyFormat(beMobileSalesValue)}</th>
              <th style={{ paddingRight: "20px" }}>
                {moneyFormat(beMobileClosingValue)}
              </th>
            </tr>
          </tbody>
        </table>
        <br />
      </Fragment>
    ) : (
      <h4>No be-Mobile vouchers available.</h4>
    );

  let orangeRecords =
    orangeReduced && orangeReduced.length > 0 ? (
      <Fragment>
        <table className={classes.MerchantTable} style={{ width: "100%" }}>
          <thead>
            <tr>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Quantity`}</th>
              <th scope="col">{` `}</th>
              <th
                scope="col"
                style={{ textAlign: "center", verticalAlign: "middle" }}
                colSpan={4}
              >{`Values`}</th>
            </tr>
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th scope="col">{` `}</th>
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
              <th style={{ padding: "5px", background: "#fcfcfc" }} />
              <th scope="col">Opening</th>
              <th scope="col">Purchases</th>
              <th scope="col">Sales</th>
              <th scope="col" style={{ paddingRight: "20px" }}>
                Closing
              </th>
            </tr>
          </thead>
          <tbody>
            {orangeReduced.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={classes.MerchantRow}
                  style={{ textAlign: "right", verticalAlign: "middle" }}
                >
                  <td style={{ textAlign: "left" }}>
                    {item.providerDenomination}
                  </td>
                  <td>{parseInt(item.count) + parseInt(item.salesCount)}</td>
                  <td>{item.purchaseCount}</td>
                  <td>{item.salesCount}</td>
                  <td style={{ paddingRight: "20px" }}>{item.count}</td>
                  <td style={{ padding: "5px", background: "#ececec" }} />
                  <td>{moneyFormat(item.total + item.salesTotal)}</td>
                  <td>{moneyFormat(item.purchaseTotal)}</td>
                  <td>{moneyFormat(item.salesTotal)}</td>
                  <td style={{ paddingRight: "20px" }}>
                    {moneyFormat(item.total)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
              <th>{`Totals:`}</th>
              <th>{orangeOpeningQuantity}</th>
              <th>{orangePurchasesQuantity}</th>
              <th>{orangeSalesQuantity}</th>
              <th style={{ paddingRight: "20px" }}>{orangeClosingQuantity}</th>
              <th style={{ padding: "5px", background: "#ececec" }} />
              <th>{moneyFormat(orangeOpeningValue)}</th>
              <th>{moneyFormat(orangePurchasesValue)}</th>
              <th>{moneyFormat(orangeSalesValue)}</th>
              <th style={{ paddingRight: "20px" }}>
                {moneyFormat(orangeClosingValue)}
              </th>
            </tr>
          </tbody>
        </table>
        <br />
      </Fragment>
    ) : (
      <h4>No Orange vouchers available.</h4>
    );

  return mascomRecords ? (
    <div className={classes.MerchantTableWrapper}>
      <h5>Be Mobile</h5>
      <div className={classes.TableWrapper}>{bemobileRecords}</div>
      <div>
        <h5>Mascom</h5>
        <div className={classes.TableWrapper}>{mascomRecords}</div>
      </div>
      <div>
        <h5>Orange</h5>
        <div className={classes.TableWrapper}>{orangeRecords}</div>
      </div>
    </div>
  ) : (
    <Fragment>
      <LoadingComponent inverted={true} />
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  mascom: state.airtimeSummary.mascom,
  orange: state.airtimeSummary.orange,
  bemobile: state.airtimeSummary.beMobile,
});

export default connect(mapStateToProps)(financialRecords);
