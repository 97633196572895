import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import LoadingComponent from "../../../app/layout/LoadingComponent";

import { moneyFormat } from "../../../app/common/util/moneyFormat";
import { openModal } from "../../modals/modalActions";
import classes from "./CreditLimit.module.css";
import SuspendCreditFacility from "./SuspendCreditFacility";

class CreditLimit extends Component {
  state = {
    loadingInitial: true,
    activateCreditFacilityPanelOpen: false,
    agreementUrl: "",
    showModal: false,
    selectedCreditFacility: null,
    selectedRow: -1
  };

  TableRowClasses = null;
  approveCreditPanelOpenClasses = [classes.RevealMore];

  handleCreditFacilityClick = (creditFacility, index) => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    this.setState({
      activateCreditFacilityPanelOpen: true,
      selectedCreditFacility: creditFacility,
      selectedRow: index
    });
  };

  handleCloseClick = () => {
       if (this.props.profile.userType === "Merchant") {
      return;
    }
    this.setState({
      activateCreditFacilityPanelOpen: false,
      selectedCreditFacility: null,
      selectedRow: -1
    });
  };

  render() {
    let {
      activateCreditFacilityPanelOpen,
      selectedCreditFacility
    } = this.state;


    if (!this.props.limits) return <LoadingComponent inverted={true} />;



    let limits =
      this.props.limits &&
      [...this.props.limits].sort((a, b) =>
      a.status > b.status ? 1 : b.status > a.status ? -1 : 0
    ).map(limit => {
        return (
          <tr
            className={classes.MerchantRow}
            key={limit.id}
            onClick={() => this.handleCreditFacilityClick(limit)}
          >
            <td>
              {format(
                limit.dateCreated && limit.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{moneyFormat(limit.amount)}</td>

            <td>
              {format(
                limit.expiryDate && limit.expiryDate.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{limit.status}</td>
            <td>{limit.actionedBy}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {limits && limits.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>Merchant does not have credit facility.</h3>
            </div>
          ) : (
            <div className={classes.TableWrapper}>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Date Approved</th>
                    <th scope="col">Credit Amount</th>
                    <th scope="col">Expiry Date</th>
                    <th>Status</th>

                    <th className={"hide-s"} scope="col">
                      Authorised By
                    </th>
                  </tr>
                </thead>
                <tbody>{limits}</tbody>
              </table>
              {activateCreditFacilityPanelOpen ? (
                <div className={classes.EnableDevice}>
                  <div className={classes.EnableDevice}>
                    <div className={"card card-transparent"}>
                      <div
                        className={"card-header "}
                        style={{
                          minHeight: "4.1rem",
                          padding: "1rem",
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500"
                        }}
                      >
                        <div
                          className={"card-title semi-bold"}
                          style={{
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            textTransform: "none",
                            marginLeft: "0.7rem"
                          }}
                        >
                          Suspend Facility
                        </div>
                        <div className={"card-controls"}>
                          <ul>
                            <li>
                              <a
                                data-toggle="close"
                                className={"card-close"}
                                href="#"
                                onClick={this.handleCloseClick}
                              >
                                <i className={"card-icon card-icon-close"} />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={"card-body"}>
                        <div className={"panel"}>
                          <Fragment>
                            <ul
                              className={
                                "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                              }
                            >
                              <li className={"nav-item active"}>
                                <a
                                  data-toggle="tab"
                                  href="#activation"
                                  className={"active show"}
                                >
                                  Suspend Facility
                                </a>
                              </li>
                            </ul>
                            <div className={"tab-content"}>
                              <div
                                className={"tab-pane active"}
                                id="activation"
                              >
                                <div className={"row column-seperation"}>
                                  <div className={"col-md-12"}>
                                    <SuspendCreditFacility
                                      creditFacility={selectedCreditFacility}
                                      closePanel={this.handleCloseClick}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

let query = props => [
  {
    collection: "credit",
    where: [["account", "==",props.account]]
  }
];

const mapStateToProps = state => ({
  limits: state.firestore.ordered.credit,
  profile:state.firebase.profile,
  requesting: state.firestore.status.requesting
});

const actions = {
  openModal
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(CreditLimit));
