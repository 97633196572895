const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Required";
  }
  if (!values.lastName) {
    errors.lastName = "Required";
  }

  console.log("values.role", values.role);
  if (!values.role) {
    errors.role = "Required";
  }

  if (values.role && values.role.value === "User" && !values.outletId) {
    errors.outletId = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.password) {
    errors.password = "Required";
  }

  return errors;
};

export default validate;
