import React, { Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import format from "date-fns/format";
import Tabs from "../../../app/UI/Tabs/Tabs";
import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const index = ({ airtimeUpload }) => {
  if (!airtimeUpload)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  let orange = airtimeUpload.filter((item) => item.provider === "ORANGE");
  orange.sort((a, b) =>
    a.orderNumber > b.orderNumber ? 1 : b.orderNumber > a.orderNumber ? -1 : 0
  );

  let mascom = airtimeUpload.filter((item) => item.provider === "MASCOM");
  mascom.sort((a, b) =>
    a.orderNumber > b.orderNumber ? 1 : b.orderNumber > a.orderNumber ? -1 : 0
  );

  let bemobile = airtimeUpload.filter((item) => item.provider === "BEMOBILE");
  bemobile.sort((a, b) =>
    a.orderNumber > b.orderNumber ? 1 : b.orderNumber > a.orderNumber ? -1 : 0
  );

  return (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        {airtimeUpload && airtimeUpload.length === 0 ? (
          <div style={{ paddingBottom: "30px" }}>
            <h3>There are no uploads.</h3>
          </div>
        ) : (
          <Tabs>
            <div label={`beMobile`}>
              <div className={classes.MerchantTableWrapper}>
                <div className={classes.TableWrapper}>
                  {bemobile.length > 0 ? (
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">OrderNumber</th>
                          <th scope="col">Batch Number</th>
                          <th scope="col">Denomination</th>
                          <th scope="col">Total</th>
                          <th scope="col">Upload Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bemobile.map((item, index) => {
                          return (
                            <tr key={index} className={classes.MerchantRow}>
                              <td>{item.orderNumber}</td>
                              <td>{item.batchNo}</td>
                              <td>{formatMoney(item.denomination)}</td>
                              <td>{formatMoney(item.total)}</td>
                              <td>
                                {format(
                                  item.dateCreated.toDate(),
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr className={classes.Totals}>
                        <td colSpan="2">
                          <div className={classes.MoneyTotalLabel}>Totals</div>
                        </td>
                        <td className={classes.MoneyTotal}>
                          {formatMoney(commissionSalesTotal)}
                        </td>

                        <td className={classes.MoneyTotal}>
                          {formatMoney(creditedCommissionListTotal)}
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  ) : (
                    <h4>
                      There are no <strong>airtime uploads</strong> for the
                      selected provider.
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div label={`Mascom`}>
              <div className={classes.MerchantTableWrapper}>
                <div className={classes.TableWrapper}>
                  {mascom.length > 0 ? (
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">OrderNumber</th>
                          <th scope="col">Batch Number</th>
                          <th scope="col">Denomination</th>
                          <th scope="col">Total</th>
                          <th scope="col">Upload Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mascom.map((item, index) => {
                          return (
                            <tr key={index} className={classes.MerchantRow}>
                              <td>{item.orderNumber}</td>
                              <td>{item.batchNo}</td>
                              <td>{formatMoney(item.denomination)}</td>
                              <td>{formatMoney(item.total)}</td>
                              <td>
                                {format(
                                  item.dateCreated.toDate(),
                                  "YYYY-MM-DD HH:mm:ss"
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        {/* <tr className={classes.Totals}>
                        <td colSpan="2">
                          <div className={classes.MoneyTotalLabel}>Totals</div>
                        </td>
                        <td className={classes.MoneyTotal}>
                          {formatMoney(commissionSalesTotal)}
                        </td>

                        <td className={classes.MoneyTotal}>
                          {formatMoney(creditedCommissionListTotal)}
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  ) : (
                    <h4>
                      There are no <strong>airtime uploads</strong> for the
                      selected provider.
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div label={`Orange`}>
              <div className={classes.MerchantTableWrapper}>
                <div className={classes.TableWrapper}>
                  {orange.length > 0 ? (
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">OrderNumber</th>
                          <th scope="col">Batch Number</th>
                          <th scope="col">Denomination</th>
                          <th scope="col">Total</th>
                          <th scope="col">Upload Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          // items.sort(function (a, b) {
                          //   return a.value - b.value;
                          // });
                          orange.map((item, index) => {
                            return (
                              <tr key={index} className={classes.MerchantRow}>
                                <td>{item.orderNumber}</td>
                                <td>{item.batchNo}</td>
                                <td>{formatMoney(item.denomination)}</td>
                                <td>{formatMoney(item.total)}</td>
                                <td>
                                  {format(
                                    item.dateCreated.toDate(),
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        }
                        {/* <tr className={classes.Totals}>
                        <td colSpan="2">
                          <div className={classes.MoneyTotalLabel}>Totals</div>
                        </td>
                        <td className={classes.MoneyTotal}>
                          {formatMoney(commissionSalesTotal)}
                        </td>

                        <td className={classes.MoneyTotal}>
                          {formatMoney(creditedCommissionListTotal)}
                        </td>
                      </tr> */}
                      </tbody>
                    </table>
                  ) : (
                    <h4>
                      There are no <strong>airtime uploads</strong> for the
                      selected provider.
                    </h4>
                  )}
                </div>
              </div>
            </div>
          </Tabs>
        )}
      </div>
    </Fragment>
  );
};

let query = [
  {
    collection: "airtimeUpload",
    where: ["status", "==", "Successful"],
  },
];

const mapStateToProps = (state) => ({
  airtimeUpload: state.firestore.ordered.airtimeUpload,
});

export default connect(mapStateToProps)(firestoreConnect(query)(index));
