import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AdjustmentDepositForm from "../financials/AdjustmentDepositForm/AdjustmentDepositForm";

const actions = { closeModal };

const adjustmentDepositModal = ({ closeModal }) => {
  return (
    <Modal
      modalHeader="Merchant Balance Adjustment Deposit"
      onClose={closeModal}
    >
      <AdjustmentDepositForm />
    </Modal>
  );
};

export default connect(null, actions)(adjustmentDepositModal);
