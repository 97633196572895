import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { openModal } from "../../modals/modalActions";
import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { formatMoney } from "../../../app/common/util/helpers";
import firebase from "../../../app/config/firebase";

const Balance = ({ openModal }) => {
  const firestore = firebase.firestore();
  const triggersRef = firestore.collection("triggers");

  let [bpcRemainingTradingTime, setbpcRemainingTradingTime] = useState("⌛");
  let [bpcTradingTimeLimit, setbpcTradingTimeLimit] = useState("⌛");
  let [bpcTradingBalanceLimit, setbpcTradingBalanceLimit] = useState("⌛");
  let [kazangRemainingTradingTime, setkazangRemainingTradingTime] =
    useState("⌛");
  let [kazangTradingTimeLimit, setkazangTradingTimeLimit] = useState("⌛");
  let [kazangTradingBalanceLimit, setkazangTradingBalanceLimit] =
    useState("⌛");
  let [bpcRemainingBalance, setbpcRemainingBalance] = useState("⌛");
  let [kazangRemainingBalance, setkazangRemainingBalance] = useState("⌛");
  let [smsContacts, setsmsContacts] = useState([]);

  let fetchData = async () => {
    triggersRef.doc("bpc_remaining_transaction_time").onSnapshot((snapshot) => {
      setbpcRemainingTradingTime(`${snapshot.data().time} hrs`);
    });
    triggersRef.doc("bpc_trading_time_limit").onSnapshot((snapshot) => {
      setbpcTradingTimeLimit(`${snapshot.data().time} hrs`);
      localStorage.setItem("bpcTradingTimeLimit", `${snapshot.data().time}`);
    });
    triggersRef.doc("bpc_trading_balance_limit").onSnapshot((snapshot) => {
      setbpcTradingBalanceLimit(formatMoney(snapshot.data().balance));
      localStorage.setItem(
        "bpcTradingBalanceLimit",
        `${snapshot.data().balance}`
      );
    });
    triggersRef.doc("bpc_remaining_balance").onSnapshot((snapshot) => {
      setbpcRemainingBalance(formatMoney(snapshot.data().balance));
    });
    triggersRef
      .doc("kazang_remaining_transaction_time")
      .onSnapshot((snapshot) => {
        setkazangRemainingTradingTime(`${snapshot.data().time} hrs`);
      });
    triggersRef.doc("kazang_trading_time_limit").onSnapshot((snapshot) => {
      setkazangTradingTimeLimit(`${snapshot.data().time} hrs`);
      localStorage.setItem("kazangTradingTimeLimit", `${snapshot.data().time}`);
    });
    triggersRef.doc("kazang_trading_balance_limit").onSnapshot((snapshot) => {
      setkazangTradingBalanceLimit(formatMoney(snapshot.data().balance));
      localStorage.setItem(
        "kazangTradingBalanceLimit",
        `${snapshot.data().balance}`
      );
    });
    triggersRef.doc("kazang_remaining_balance").onSnapshot((snapshot) => {
      setkazangRemainingBalance(formatMoney(snapshot.data().balance));
    });
    triggersRef
      .doc("sms_contacts")
      .collection("contacts")
      .onSnapshot((snapshot) => {
        let contacts = [];
        if (snapshot.size > 0) {
          snapshot.forEach((doc) => {
            contacts.push(doc.data().number);
          });
        }
        setsmsContacts(contacts);
        localStorage.setItem("smsContacts", JSON.stringify(contacts));
      });
  };
  useEffect(() => {
    fetchData();
    return () => {
      setkazangRemainingBalance({});
      setkazangTradingBalanceLimit({});
      setkazangTradingTimeLimit({});
      setkazangRemainingTradingTime({});
      setbpcRemainingBalance({});
      setbpcTradingBalanceLimit({});
      setbpcTradingTimeLimit({});
      setbpcRemainingTradingTime({});
    };
  }, []);

  return (
    <>
      <Fragment>
        <div label={`Transactions`}>
          <div className={classes.Container}>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <button
                  style={{ marginTop: "50px" }}
                  className={"btn btn-primary btn-cons"}
                  type="submit"
                  onClick={() => openModal("BalanceTriggerValueModal")}
                >
                  <span>Update Limits</span>
                </button>
              </div>
              <div className={"col-md-6"}>
                <h5>BPC</h5>
                <div
                  className={classes.TableWrapper}
                  style={{ flexDirection: "column" }}
                >
                  <table className={classes.MerchantTable}>
                    <tbody>
                      <tr className={classes.Totals}>
                        <td>Trading Time Limit</td>
                        <td>{bpcTradingTimeLimit}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Remaining Trading Time</td>
                        <td>{bpcRemainingTradingTime}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Trading Balance Limit</td>
                        <td>{bpcTradingBalanceLimit}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Remaining Balance</td>
                        <td>{bpcRemainingBalance}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>SMS Notification Contacts</td>
                        <td>
                          <ul>
                            {smsContacts.map((contact, index) => {
                              return <li key={index}>{contact}</li>;
                            })}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={"col-md-6"}>
                <h5>Kazang</h5>
                <div
                  className={classes.TableWrapper}
                  style={{ flexDirection: "column" }}
                >
                  <table className={classes.MerchantTable}>
                    <tbody>
                      <tr className={classes.Totals}>
                        <td>Trading Time Limit</td>
                        <td>{kazangTradingTimeLimit}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Remaining Trading Time</td>
                        <td>{kazangRemainingTradingTime}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Trading Balance Limit</td>
                        <td>{kazangTradingBalanceLimit}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>Remaining Balance</td>
                        <td>{kazangRemainingBalance}</td>
                      </tr>
                      <tr className={classes.Totals}>
                        <td>SMS Notification Contacts</td>
                        <td>
                          <ul>
                            {smsContacts.map((contact, index) => {
                              return <li key={index}>{contact}</li>;
                            })}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

const mapStateToProps = (state) => ({});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(Balance);
