
import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";
import ReferralProgrammeReportSpinner from "../reports/Cachet/ReferralProgrammeReport/ReferralProgrammeReportSpinner";

const actions = {
  closeModal
};

const ReferralProgrammeReportModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
   <ReferralProgrammeReportSpinner/>
    </TransparentModal>
  );
};

export default connect(
  null,
  actions
)(ReferralProgrammeReportModal);

