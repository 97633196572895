import React, { Component } from "react";
import classes from "./Tabs.module.css";

import PropTypes from "prop-types";

import Tab from "./Tab";

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    console.log("this.props.children[0].props", this.props.children[0].props);
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this;

    // let classesTabContent = [classes.TabContent]

    const existingChildren = children.filter((x) => !!x);

    return (
      <div className={classes.Tabs}>
        <ul
          className={
            "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
          }
        >
          {existingChildren.map((child) => {
            const { label } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            );
          })}
        </ul>
        <div className={classes.TabContent}>
          {existingChildren.map((child) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;
