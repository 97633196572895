import React, { Fragment } from "react";
import NavigationItems from "../NavigationItems/NavigationItems";
import Logo from "../../../app/Logo/Logo";
import classes from "./Sidebar.module.css";

const sidebar = props => {
  // let attachedClasses = [classes.SideDrawer, classes.Close];
  // if (props.open) {
  //   attachedClasses = [classes.SideDrawer, classes.Open];
  // }
  return (
    <Fragment>
      <div className={classes.SidebarContainer}>
        <nav className={classes.SidebarView}>
          {/* <div className={classes.AppLogo}>
            DATA
            <span className={classes.Bold}>MANAGER</span>
          </div> */}
          <div data-id="navContainerNode">
            <div className={classes.CompanyLogo} style={{ display: "none" }}>
              <Logo />
            </div>
            <nav className={classes.DesktopOnly}>
              <NavigationItems />
            </nav>
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default sidebar;
