import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";
import { saveAs } from "file-saver";

import { FETCH_DASHBOARD_BALANCES } from "./dashboardConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
import { closeModal } from "../modals/modalActions";

import {
  createBankDepositObject,
  createCreditObject,
} from "../../app/common/util/helpers";

export const createDeposit = (deposit) => {
  console.log("deposit", deposit);

  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newDeposit = createBankDepositObject(deposit, user);
    try {
      dispatch(asyncActionStart());
      await firestore.collection("transactions").add(newDeposit);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Deposit has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const createCredit = (credit, expiryDate) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let newCredit = createCreditObject(credit, expiryDate, user);
    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").add(newCredit);

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Credit has been created");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const approveCredit = (credit) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    console.log("credit", credit);

    try {
      dispatch(asyncActionStart());
      let approvedCreditFacilityRef = await firestore
        .collection("credit")
        .where("account", "==", credit.account)
        .where("status", "==", "Approved");

      let approvedCreditList = await approvedCreditFacilityRef
        .get()
        .then((querySnapshot) => {
          let approvedCredit = [];

          if (querySnapshot.size > 0) {
            approvedCredit = querySnapshot.docs.map((doc) => {
              let facility = doc.data();
              facility.id = doc.id;
              return facility;
            });
          }

          return approvedCredit;
        });

      let approvedCreditRef = await firestore
        .collection("credit")
        .doc(credit.id);

      let batch = firestore.batch();

      approvedCreditList.forEach(async (creditFacility) => {
        let approvedCreditRef = firestore
          .collection("credit")
          .doc(creditFacility.id);

        await batch.update(approvedCreditRef, { status: "Closed" });
      });

      await batch.update(approvedCreditRef, {
        actionedBy: currentUser,
        status: "Approved",
        dateActioned: now,
      });

      await batch.commit();
      dispatch(asyncActionFinish());
      dispatch(closeModal());
      toastr.success("Success", "Credit Facility has been approved");
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const declineCredit = (credit) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").doc(credit.id).set(
        {
          actionedBy: currentUser,
          status: "Declined",
          dateActioned: now,
        },
        { merge: true }
      );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Credit Facility has been declined");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};

export const getTransactionsForDashboard =
  (values) => async (dispatch, getState) => {
    const account = values.account;
    // const reportStartDate = moment(values.reportStartDate)
    //   .startOf("day")
    //   .toDate(); // set to 12:00 am today
    // const reportEndDate = moment(values.reportEndDate)
    //   .endOf("day")
    //   .toDate(); // set to 23:59 pm today

    const reportStartDate = values.reportStartDate;
    const reportEndDate = values.reportEndDate;

    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    try {
      dispatch(asyncActionStart());

      let response = await axios
        .post("/api/financials", {
          account: account,
          reportStartDate: values.reportStartDate,
          reportEndDate: values.reportEndDate,
        })
        .then((response) => {
          return response;
        });

      const financials = response.data;

      dispatch({ type: FETCH_DASHBOARD_BALANCES, payload: { financials } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getMerchantTransactionsForDashboard =
  (values) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const group = values.group;
    const accounts = values.accounts;
    const role = values.role;

    const reportStartDate = values.reportStartDate.valueOf(); // set to 12:00 am today
    const reportEndDate = values.reportEndDate.valueOf(); // set to 23:59 pm today

    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    // const firestore = firebase.firestore();

    // const transactionsRef = firestore.collection("merchant_balance");
    const accountRef = firestore.collection("merchants");

    try {
      dispatch(asyncActionStart());

      console.log("selected Group", group);

      let response =
        group != null
          ? await axios
              .post("api/merchantFinancials", {
                reportStartDate: reportStartDate,
                reportEndDate: reportEndDate,
                group: group,
                accounts,
                role,
              })
              .then((response) => {
                return response.data;
              })
          : [];

      // let response = await axios
      //   .post("api/merchantFinancials", {
      //     reportStartDate: values.reportStartDate,
      //     reportEndDate: values.reportEndDate,
      //     group:'Ismail Group'
      //   })
      //   .then(response => {
      //     return response;
      //   });

      const financials = response;

      dispatch({ type: FETCH_DASHBOARD_BALANCES, payload: { financials } });
      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(asyncActionError());
    }
  };

export const getCachetTransactionsForExport =
  (startDate, endDate) => async (dispatch) => {
    console.log("start,end", startDate, endDate);

    try {
      dispatch(asyncActionStart());

      const reportName = "Merchant Financials";
      const now = Date.now();

      await axios
        .post("api/prepaidplusFinancialsExcel", {
          reportStartDate: startDate,
          reportEndDate: endDate,
        })
        .then((response) => {
          let buff = new Buffer(response.data.base64, "base64");

          const blob = new Blob([buff], { type: "application/octet-stream" });
          saveAs(blob, `${reportName}-${now}.xlsx`);
        });

      dispatch(closeModal());

      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(closeModal());
      dispatch(asyncActionError());
    }
  };

export const getTransactionsForExport =
  (account, merchantName, startDate, endDate) => async (dispatch) => {
    console.log("start,end", startDate, endDate);

    try {
      dispatch(asyncActionStart());

      const reportName = "Merchant Financials";
      const now = Date.now();

      await axios
        .post("api/financialsExcel", {
          account: account,
          merchantName: merchantName,
          reportStartDate: startDate,
          reportEndDate: endDate,
        })
        .then((response) => {
          let buff = new Buffer(response.data.base64, "base64");

          const blob = new Blob([buff], { type: "application/octet-stream" });
          saveAs(blob, `${reportName}-${now}.xlsx`);
        });

      dispatch(closeModal());

      dispatch(asyncActionFinish());
    } catch (error) {
      if (error) {
        console.log(error);
      }
      dispatch(closeModal());
      dispatch(asyncActionError());
    }
  };

export const clearFinancialTransactions = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let financials = [];

    dispatch({ type: FETCH_DASHBOARD_BALANCES, payload: { financials } });

    dispatch(asyncActionFinish());
  } catch (error) {
    if (error) {
      console.log(error);
    }

    dispatch(asyncActionError());
  }
};

export const suspendFacility = (creditFacilityId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const currentUser = firebase.auth().currentUser.displayName;

    const now = new Date();

    console.log("credit limit in suspendFacility", creditFacilityId);

    try {
      dispatch(asyncActionStart());
      await firestore.collection("credit").doc(creditFacilityId).set(
        {
          actionedBy: currentUser,
          status: "Suspended",
          dateActioned: now,
        },
        { merge: true }
      );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Facility has been suspended");
    } catch (error) {
      toastr.error("Oops", "Something went wrong");
      dispatch(asyncActionError());
    }
  };
};
