import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import classes from "./details.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { Field } from "redux-form";
import TextInput from "../../../../app/common/form/TransactionsTextInput";
import { reduxForm } from "redux-form";

import { updateAccount, sendForAuthorization } from "../../depositsActions";

const FileInput = ({
  input: { value: omitValue, ...inputProps },
  meta: omitMeta,
  ...props
}) => {
  let attachedClasses = ["form-group-default", props.required];
  return (
    <div className={attachedClasses.join(" ")}>
      <div className={"controls"}>
        <input
          type="file"
          accept="application/pdf"
          {...inputProps}
          {...props}
          style={{ paddingTop: 7 }}
        />
      </div>
    </div>
  );
};

const detail = (props) => {
  let {
    transaction,
    closeExpanded,
    profile,
    updateAccount,
    sendForAuthorization,
    handleSubmit,
    loading,
  } = props;

  let [loadingInitial, setLoadingInitial] = useState(true);

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);
  const acceptanceDate = transaction.acceptanceCalendarDateTime.toDate();
  const currentDate = new Date();
  const timeDifference = currentDate - acceptanceDate;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  const onFormSubmit = async (values) => {
    console.log("values", values, transaction.msgId);

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;

    setLoadingInitial(true);

    if (values && values.proof) {
      console.log("values.proof", values.proof[0]);
      await sendForAuthorization(values);
    } else {
      await updateAccount(values);
    }
    closeExpanded(false);
    setLoadingInitial(false);
  };
  // let handleClick = () => {
  //   redeemWinningPurchase({
  //     id: transaction.id,
  //     meterNumber: transaction.meterNumber,
  //     transactionAmount: transaction.transactionAmount,
  //     clientSaleId: moment().valueOf(),
  //     createdBy: profile.displayName,
  //   });
  // };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          {/* <header>
            <h4>Account</h4>
          </header> */}

          <div className={classes.FuneralSchemeWrapper}>
            {transaction.isCredited === false ? (
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <table className={classes.FuneralSchemeTable}>
                  <thead>
                    <tr>
                      <th scope="col">Reference</th>
                      <th className={"hide-s"} scope="col">
                        Amount
                      </th>
                      <th className={"hide-s"} scope="col">
                        {""}
                      </th>
                      <th className={"hide-s"} scope="col">
                        {""}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.MemberRow}>
                      <td>{transaction.reconReference}</td>
                      <td>{transaction.reconTransactionAmount}</td>
                      <td>
                        <Field
                          type="text"
                          className={"form-control"}
                          style={{ marginBottom: 0 }}
                          component={TextInput}
                          placeholder="Account Number (4 or 8 digit number)"
                          name="account"
                          id="account"
                          required="required"
                          autoComplete="off"
                        />
                      </td>
                      {daysDifference >= 7 ? (
                        <td>
                          <Field
                            name="proof"
                            id="proof"
                            component={FileInput}
                            className="form-control"
                            //
                            required="required"
                          />
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>
                        <button
                          className={classes.AddUserBtn}
                          // onSubmit={() => {
                          //   handleClick();
                          // }}
                          type="submit"
                          disabled={loading}
                          style={{ width: "21rem" }}
                        >
                          {loading ? (
                            <Spinner
                              style={{
                                width: "20px",
                                height: "20px",
                                verticalAlign: "middle",
                              }}
                            />
                          ) : (
                            <span>Amend Account</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            ) : (
              <div>
                <table className={classes.FuneralSchemeTable}>
                  <thead>
                    <tr>
                      <th scope="col">Reference</th>
                      <th className={"hide-s"} scope="col">
                        Amount
                      </th>
                      <th className={"hide-s"} scope="col">
                        Date Posted
                      </th>
                      <th className={"hide-s"} scope="col">
                        {transaction.correctionRequired === "Yes"
                          ? "Corrected By"
                          : "Posted By"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.MemberRow}>
                      <td>{transaction.reconReference}</td>
                      <td>{transaction.reconTransactionAmount}</td>
                      <td>
                        {format(
                          transaction.dateUpdated.toDate(),
                          "YYYY-MM-DD  HH:mm:ss"
                        )}
                      </td>
                      <td>{transaction.updatedBy}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updateAccount,
  sendForAuthorization,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(detail)
);
