import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import classes from "./details.module.css";
import {redeemWinningPurchase} from "../../competitonsActions";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";

const detail = (props) => {
  let { transaction, auth, profile,redeemWinningPurchase,loading } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  let handleClick = () => {
    redeemWinningPurchase({
      id: transaction.id,
      meterNumber: transaction.meterNumber,
      transactionAmount: transaction.transactionAmount,
      clientSaleId: moment().valueOf(),
      createdBy: profile.displayName,
    });
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Redeem Winning Meter</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            {transaction.isRedeemed === true ? (
              <table className={classes.FuneralSchemeTable}>
                <thead>
                  <tr>
                    <th scope="col">Meter</th>

                    <th className={"hide-s"} scope="col">
                      Amount
                    </th>
                    <th className={"hide-s"} scope="col">
                      Recharge Code{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.MemberRow}>
                    <td>{transaction.meterNumber}</td>
                    <td>{transaction.transactionAmount}</td>
                    <td>
                      <span>{transaction.voucher}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>
                <button
                  className={classes.AddUserBtn}
                  onClick={() => {
                    handleClick();
                  }}
                  disabled={loading}
                  style={{width:'21rem'}}
                >
                 

                  {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <span>Redeem Winning Voucher</span>
                )}


                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};


const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  redeemWinningPurchase,
};

export default connect(mapStateToProps, actions)(detail);
