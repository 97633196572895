import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import SalesAndCommissionReportSpinner from "../reports/Cachet/SalesAndCommissionReport/SalesAndCommissionReportSpinner";

const actions = {
  closeModal,
};

const salesAndCommissionReportSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <SalesAndCommissionReportSpinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(salesAndCommissionReportSpinnerModal);
