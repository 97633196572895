import moment from "moment";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";

import firebase from "../../app/config/firebase";
import { FETCH_SMS_TRANSACTIONS } from "./smsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

export const getTransactionsForDashboard = (values) => async (dispatch) => {
  const firestore = firebase.firestore();

  const transactionsStartDate = values.startDate; // set to 12:00 am today
  const transactionsEndDate = values.endDate;

  const transactionsRef = firestore.collection("sms-service");

  try {
    dispatch(asyncActionStart());

    let query;

    console.log("transactionsStartDate", transactionsStartDate);
    console.log("transactionsEndDate", transactionsEndDate);

    query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .orderBy("dateCreated");

    let querySnap = await query.get();

    let transactions = [];

    console.log("querySnap.docs.length", querySnap.docs.length);

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_SMS_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id,
      };
      transactions.push(transaction);
    }
    console.log("transactions", transactions);
    dispatch({ type: FETCH_SMS_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    // return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};





export const clearSMSTransactions = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({ type: FETCH_SMS_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

