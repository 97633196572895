import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import classes from "./details.module.css";
import { ReactComponent as Spinner } from "../../../../../assets/spinner.svg";
import { Field, change } from "redux-form";
import TextInput from "../../../../../app/common/form/TextInput";
import TextArea from "../../../../../app/common/form/TextArea";
import { reduxForm } from "redux-form";
import { useDropzone } from "react-dropzone";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";
import {
  actionPettyCash,
  rejectPettyCash,
  authorisePettyCash,
} from "../../../../pettycash/pettyCashActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  authoriser_notes: isRequired({ message: "Required" }),
  approver_notes: isRequired({ message: "Required" }),
});

const detail = (props) => {
  let {
    transaction,
    closeExpanded,
    profile,
    actionPettyCash,
    rejectPettyCash,
    handleSubmit,
    approvers,
    loading,
    initialValues,
  } = props;
  // console.log("petty cash !!!", transaction);
  // console.log("approvers", approvers);
  let [supportingDocument, setSupportingDocument] = useState(null);
  let [supportingDocumentRequired, setSupportingDocumentRequired] =
    useState(false);
  let [
    supportingDocumentRequiredMessage,
    setSupportingDocumentRequiredMessage,
  ] = useState(null);
  const [buttonClicked, setButtonClicked] = useState("");
  const buttonState = {
    state: undefined,
  };

  const today = new Date();

  let [loadingInitial, setLoadingInitial] = useState(true);

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  const onFormSubmit = async (values) => {
    // console.log("values", values);

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;
    values.id = transaction.id;

    console.log("values !!!!!!", values);
    if (buttonState.state === "submit") {
      setLoadingInitial(true);
      await actionPettyCash(values, transaction.status);
      closeExpanded(false);
    } else if (buttonState.state === "reject") {
      setLoadingInitial(true);
      await rejectPettyCash(values, transaction.status);
      closeExpanded(false);
    }
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  const transactionDetail = (transaction) => {
    switch (transaction.status) {
      case "Application":
        return (
          <>
            <div className={"row clearfix"}>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Reason
                </div>
                <strong>{transaction.reason}</strong>
              </div>
              <div className={"col-sm-4"}>
                <div className={"row clearfix"}>
                  <div className={"col-sm-4"}>
                    <div
                      className={"all-caps no-margin hint-text"}
                      style={{ fontSize: "10px" }}
                    >
                      {/* {transaction.reason} - {merchantName} */}
                      Approver Notes
                    </div>
                    {transaction.approverNotes ? (
                      <div>{transaction.approverNotes}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Approver:
                </div>
                <strong>
                  {approvers &&
                    approvers.map((approver, index) => {
                      return <div>{approver.displayName}</div>;
                    })}
                </strong>
              </div>
            </div>

            <br />
            <div className={"col-sm-8"} style={{ paddingLeft: "0" }}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Notes
              </div>
              <Field
                label="Approver Notes"
                name="approver_notes"
                component={TextArea}
                type="text"
                className={"form-control"}
                placeholder="Approver notes"
                required=""
                aria-required="true"
              />
            </div>

            <br />
            {/* {error && <label className={"error semi-bold"}>{error}</label>} */}
            <button
              className={"btn btn-primary btn-cons m-t-10 m-r-20"}
              type="submit"
              disabled={loading}
              onClick={() => {
                buttonState.state = "submit";
                console.log("buttonState", buttonState);
              }}
            >
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>Approve</span>
              )}
            </button>

            <button
              onClick={() => {
                buttonState.state = "reject";
                console.log("buttonState", buttonState);
              }}
              className={"btn btn-danger btn-cons m-t-10"}
            >
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>Reject</span>
              )}
            </button>
          </>
        );

      case "Approved":
        return (
          <>
            <div className={"row clearfix"}>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Reason
                </div>
                <strong>{transaction.reason}</strong>
              </div>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Approved By:
                </div>
                <strong>{transaction.approvedBy}</strong>
                {transaction.approverNotes ? (
                  <div>{transaction.approverNotes}</div>
                ) : null}
              </div>

              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Approver:
                </div>
                <strong>
                  {approvers &&
                    approvers.map((approver, index) => {
                      return <div>{approver.displayName}</div>;
                    })}
                </strong>
              </div>
            </div>

            <br />

            {!!transaction.documentationUrl ? (
              <>
                <div className={"col-sm-8"} style={{ paddingLeft: "0" }}>
                  <div
                    className={"all-caps no-margin hint-text"}
                    style={{ fontSize: "10px" }}
                  >
                    Notes
                  </div>
                  <Field
                    label="Approver Notes"
                    name="authoriser_notes"
                    component={TextArea}
                    type="text"
                    className={"form-control"}
                    placeholder="Authoriser notes"
                    required=""
                    aria-required="true"
                  />
                </div>
                <div className={"col-sm-4"} style={{ paddingLeft: "0" }}>
                  <h5>
                    <a href={transaction.documentationUrl} target="_blank">
                      View support document
                    </a>
                  </h5>
                </div>
                <br />
                <button
                  className={"btn btn-primary btn-cons m-t-10 m-r-20"}
                  onClick={() => {
                    buttonState.state = "submit";
                  }}
                >
                  {"Completed"}
                </button>

                <button
                  className={"btn btn-danger btn-cons m-t-10"}
                  onClick={() => {
                    buttonState.state = "reject";
                  }}
                >
                  {loading ? (
                    <Spinner
                      style={{
                        width: "20px",
                        height: "20px",
                        verticalAlign: "middle",
                      }}
                    />
                  ) : (
                    <span>Reject</span>
                  )}
                </button>
              </>
            ) : (
              <>Wait for requester to upload documentation</>
            )}
          </>
        );

      case "Authorised":
        return (
          <>
            <div className={"row clearfix"}>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Reason
                </div>
                <strong>{transaction.reason}</strong>
              </div>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Approved By:
                </div>
                <strong>
                  {/* {transaction.reason} - {merchantName} */}
                  Approver
                </strong>

                {!transaction.authoriserNotes ? (
                  <div>Go ahead and purchase</div>
                ) : null}
              </div>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Authoriser
                </div>
                <strong>
                  {/* {transaction.reason} - {merchantName} */}
                  Authoriser
                </strong>
              </div>
            </div>

            <br />
            <div className={"col-sm-8"} style={{ paddingLeft: "0" }}>
              <div
                className={"all-caps no-margin hint-text"}
                style={{ fontSize: "10px" }}
              >
                Notes
              </div>
              <Field
                label="Approval Notes"
                name="approval_notes"
                component={TextArea}
                type="text"
                className={"form-control"}
                placeholder="Additional notes"
                required=""
                aria-required="true"
              />
            </div>

            <br />
            {/* {error && <label className={"error semi-bold"}>{error}</label>} */}
            <button className={"btn btn-primary btn-cons m-t-10 m-r-20"}>
              {"Complete"}
            </button>

            <button className={"btn btn-secondary btn-cons m-t-10 m-r-20"}>
              {"Return"}
            </button>

            <button
              // onClick={() => clearFormSubmit()}
              className={"btn btn-danger btn-cons m-t-10"}
            >
              Reject
            </button>
          </>
        );

      case "Returned":
        return (
          <>
            <div className={"row clearfix"}>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Reason
                </div>
                <strong>{transaction.reason}</strong>
              </div>
              <div className={"col-sm-4"}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Approved By:
                </div>
                <strong>{transaction.approvedBy}</strong>
              </div>
              <div className={"col-sm-4"} style={{ paddingLeft: "0" }}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Notes
                </div>
                {transaction.approverNotes}
              </div>
            </div>

            <br />
            <div className={"row clearfix"}>
              <div className={"col-sm-4"} style={{ paddingLeft: "0" }}>
                <div
                  className={"all-caps no-margin hint-text"}
                  style={{ fontSize: "10px" }}
                >
                  Notes
                </div>
                <Field
                  label="Approver Notes"
                  name="authoriser_notes"
                  component={TextArea}
                  type="text"
                  className={"form-control"}
                  placeholder="Authoriser notes"
                  required=""
                  aria-required="true"
                />
              </div>
              <div className={"col-sm-4"} style={{ paddingLeft: "0" }}>
                <h5>
                  <a href={transaction.documentationUrl} target="_blank">
                    View support document
                  </a>
                </h5>
              </div>
            </div>

            <br />
            {/* {error && <label className={"error semi-bold"}>{error}</label>} */}
            <button
              className={"btn btn-primary btn-cons m-t-10 m-r-20"}
              onClick={() => {
                buttonState.state = "submit";
              }}
            >
              {"Approve"}
            </button>

            <button
              className={"btn btn-danger btn-cons m-t-10"}
              onClick={() => {
                buttonState.state = "reject";
              }}
            >
              Reject
            </button>
          </>
        );
      default:
        return <h1>Status not allowed</h1>;
    }
  };

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <div className={classes.FuneralSchemeWrapper}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              {transactionDetail(transaction)}
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  approvers: state.firestore.ordered.approvers,
});

const actions = {
  actionPettyCash,
  rejectPettyCash,
};
const query = (props) => {
  return [
    {
      collection: "users",
      storeAs: "approvers",
      where: ["isPettyCashApprover", "==", true],
    },
  ];
};
export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "actionPettyCashRequestForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(detail);
