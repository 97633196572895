import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import RegisterDevice from "../inventory/Devices/Registration/RegisterDevice";

const actions = { closeModal };

const registerDeviceModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Register New Device" onClose={closeModal}>
      <RegisterDevice />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(registerDeviceModal);
