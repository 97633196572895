import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AssignSIMCardTodeviceForm from "../inventory/Devices/AssignSIMToDevice"

const actions = { closeModal };

const assignSIMTodeviceModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Assign SIM To Device" onClose={closeModal}>
      <AssignSIMCardTodeviceForm />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(assignSIMTodeviceModal);
