import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import SelectInput from "../../../app/common/form/SelectInput";
import TextInput from "../../../app/common/form/TextInput";

import { updatePassword } from "../../auth/authActions";
// import styles from "./CreateOutlet.module.css";

const validate = combineValidators({
  firstName: isRequired("firstName"),
  lastName: isRequired("lastName"),
  role: isRequired("role"),
  email: isRequired("email"),
  password: isRequired("password")
});

const changeUserPassword = ({
  updatePassword,
  handleSubmit,
  error,
  invalid,
  submitting,
  merchantId,
  onClose
}) => {
  // let { users, loading } = this.props;

  // const onFormSubmit = values => {
  //   updatePassword({
  //     values
  //   });
  //   // props.onClose();
  // };

  return (
    <form onSubmit={handleSubmit(updatePassword)}>
      <p>
        <strong>Password Details</strong>
      </p>

      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="Current Password"
            name="oldPassword"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Current Password"
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="New Password"
            name="newPassword"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="New Password"
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="Confirm New Password"
            name="confirmPassword"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Confirm New Password"
            required="required"
          />
        </div>
      </div>
      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}
      <br />
      <button className={"btn btn-primary btn-cons m-t-10"}>
        Change Password
      </button>
    </form>
  );
};

// const mapStateToProps = state => ({
//   initialValues: {
//     userId: state.modals.modalProps
//   },
//   enableReinitialize: true,
//   userId: state.modals.modalProps
// });

const mapStateToProps = state => ({
  enableReinitialize: true
});

const actions = {
  updatePassword
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "updatePasswordForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(changeUserPassword)
);
