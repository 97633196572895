import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import RegisterSytemUserForm from "../auth/Register/RegisterSytemUserForm";

const actions = { closeModal };

const registerSystemUserModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create PrepaidPlus User" onClose={closeModal}>
      <RegisterSytemUserForm />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(registerSystemUserModal);
