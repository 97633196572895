import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

const actions = {
  closeModal
};

const TestModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="This is the Header" onClose={closeModal}>
      <div className={"modal-body"}>Modal Body</div>
    </Modal>
  );
};

export default connect(
  null,
  actions
)(TestModal);
