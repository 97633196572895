import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import classes from "./details.module.css";
import { openModal } from "../../../../modals/modalActions";

// import classes from "./SelectedDevice.module.css";

const detail = (props) => {
  let { device, openModal } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  // let handleClick = () => {
  //   redeemWinningPurchase({
  //     id: transaction.id,
  //     meterNumber: transaction.meterNumber,
  //     transactionAmount: transaction.transactionAmount,
  //     clientSaleId: moment().valueOf(),
  //     createdBy: profile.displayName,
  //   });
  // };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Device Information</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            <div label="Transactions">
              <header className={classes.TabHeader}>
                <div className={classes.PageHeader}>
                  <div className={classes.Header}>
                    <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                      <div
                        className={"col-md-6"}
                        style={{
                          justifyContent: "flex-start",
                          display: "flex",
                        }}
                      >
                        <strong>Model:</strong> {"\u00A0"}
                        {device.model}
                      </div>
                      <div
                        className={"col-md-6"}
                        style={{ justifyContent: "flex-end", display: "flex" }}
                      >
                        <strong>Model:</strong> {"\u00A0"}
                        {device.model}
                      </div>
                    </div>
                  </div>
                  {!device.isAssignedSIM ? (
                    <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                      <ul className={"pager wizard no-style"}>
                        <li className="next">
                          <button
                            className={"btn btn-primary btn-cons"}
                            onClick={() =>
                              openModal("AssignSIMToDeviceModal", {
                                deviceId: device.id,
                                macAddress: device.macAddress,
                                deviceModel: `${device.manufacturer} ${device.model}`,
                              })
                            }
                          >
                            <span>Assign SIM Card</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : device.isAssignedSIM && !device.isAllocated ? (
                    <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                      <ul className={"pager wizard no-style"}>
                        <li className="next">
                          <button
                            className={"btn btn-primary btn-cons"}
                            onClick={() =>
                              openModal("UnassignSIMFromDeviceModal", {
                                deviceId: device.id,
                                simId: device.assignedSIMId,
                                deviceModel: `${device.manufacturer} ${device.model}`,
                                deviceMACAddress: device.macAddress,
                                assignedCellNumber: device.cellNumber,
                              })
                            }
                          >
                            <span>Remove SIM Card</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                      <strong>Note:</strong> {"\u00A0"}
                      <span className={classes.Note}>
                        Devices must be un-allocated from outlet before SIM
                        Cards are unassigned from devices.
                      </span>
                    </div>
                  )}
                </div>
              </header>
            </div>

            {/* {transaction.isRedeemed === true ? (
              <table className={classes.FuneralSchemeTable}>
                <thead>
                  <tr>
                    <th scope="col">Meter</th>

                    <th className={"hide-s"} scope="col">
                      Amount
                    </th>
                    <th className={"hide-s"} scope="col">
                      Recharge Code{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.MemberRow}>
                    <td>{transaction.meterNumber}</td>
                    <td>{transaction.transactionAmount}</td>
                    <td>
                      <span>{transaction.voucher}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div>
                <button
                  className={classes.AddUserBtn}
                  onClick={() => {
                    handleClick();
                  }}
                  disabled={loading}
                  style={{width:'21rem'}}
                >
                 

                  {loading ? (
                  <Spinner
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                    }}
                  />
                ) : (
                  <span>Redeem Winning Voucher</span>
                )}


                </button>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(detail);
