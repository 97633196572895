import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

const actions = {
  closeModal,
};

const spinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <div className={"full-height sm-p-t-30"}>
        <div
          className={
            "d-flex justify-content-center align-items-center flex-column full-height "
          }
        >
  <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50px"
          height="50px"
          viewBox="0 0 50 50"
        >
          <circle
            fill="none"
            opacity="0.10"
            stroke="#000000"
            strokeWidth="3"
            cx="25"
            cy="25"
            r="20"
          />
          <g transform="translate(25,25) rotate(-90)">
            <circle
              style={{
                stroke: "#48B0F7",
                fill: "none",
                strokeWidth: "3px",
                strokeLinecap: "round",
              }}
              strokeDasharray="110"
              strokeDashoffset="0"
              cx="0"
              cy="0"
              r="20"
            >
              <animate
                attributeName="strokeDashoffset"
                values="360;140"
                dur="2.2s"
                keyTimes="0;1"
                calcMode="spline"
                fill="freeze"
                keySplines="0.41,0.314,0.8,0.54"
                repeatCount="indefinite"
                begin="0"
              />
              <animateTransform
                attributeName="transform"
                type="rotate"
                values="0;274;360"
                keyTimes="0;0.74;1"
                calcMode="linear"
                dur="2.2s"
                repeatCount="indefinite"
                begin="0"
              />
              <animate
                attributeName="stroke"
                values="#10CFBD;#48B0F7;#6D5CAE;#48B0F7;#10CFBD"
                fill="freeze"
                dur="8s"
                begin="0"
                repeatCount="indefinite"
              />
            </circle>
          </g>
        </svg>
        </div>
      </div>
    </TransparentModal>
  );
};

export default connect(null, actions)(spinnerModal);
