import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import TextInput from "../../../../../../../app/common/form/TextInput";
import styles from "./EnableOutletUser.module.css";
import { updateUserName } from "../../../../../merchantActions";

const updateOutletUserName = (props) => {
  const { user, updateUserName, handleSubmit, loading, closePanel } = props;

  const onFormSubmit = (values) => {
    updateUserName(values, user.id, user.account);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              Selected User:
              <span className={"semi-bold"}>{user.userId}</span>
            </h4>
            <br />
            <div className={"row clearfix"}>
              <div>
                <Field
                  name="name"
                  component={TextInput}
                  type="text"
                  className={"form-control"}
                  placeholder="Name"
                  required
                />
              </div>
            </div>
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={styles.btn}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <span>Update Name</span>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const actions = {
  updateUserName,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "updateUserNameForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })(updateOutletUserName)
);
