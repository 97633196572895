import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import format from "date-fns/format";
import { moneyFormat } from "../../../../../../../../app/common/util/moneyFormat";
import { openModal } from "../../../../../../../modals/modalActions";
import classes from "./details.module.css";
import Tabs from "../../../../../../../../app/UI/Tabs/Tabs";
import LoadingComponent from "../../../../../../../../app/layout/LoadingComponent";
import styles from "../DisableDevice.module.css";
import firebase from "../../../../../../../../app/config/firebase";

import EnableDevice from "../../Devices/EnableDevice";
import DisableDevice from "../../Devices/DisableDevice";
import GeneratePairingCode from "../../Devices/GeneratePairingCode";
import UnassignDevice from "../../Devices/UnassignDevice";

const detail = (props) => {
  const [pname, setName] = useState("");
  const [pid, setId] = useState("");
  const [placez, setPlacez] = useState("");
  const [wayz, setWayz] = useState("");
  const [filez, setFilez] = useState(null);
  const [loading, setLoading] = useState(false);

  const stylez = {
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "300px",
      margin: "0 auto",
      padding: "20px",
      border: "1px solid #ccc",
      borderRadius: "10px",
      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    },
    input: {
      width: "40%",
      padding: "10px",
      margin: "10px 0",
      border: "1px solid #ccc",
      borderRadius: "5px",
      fontSize: "16px",
    },
    button: {
      padding: "10px 20px",
      margin: "10px 0",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "#4CAF50",
      color: "white",
      fontSize: "16px",
      cursor: "pointer",
    },
  };

  let {
    openModal,
    device,
    deviceContract,
    deviceTransactions,
    outlet,
    group,
    merchantName,
  } = props;

  const firestore = firebase.firestore();
  const outletInventoryRef = firestore.collection("outlet_inventory");
  const popStorageRef = firebase.storage().ref();

  let captureCollectedByCourier = async (e) => {
    e.preventDefault();
    setLoading(true);
    const wayBillFile = popStorageRef.child(`dispatch_courier/${filez.name}`);
    await wayBillFile
      .put(filez)
      .then(async () => {
        await wayBillFile
          .getDownloadURL()
          .then(async (url) => {
            await outletInventoryRef
              .doc(device.id)
              .set(
                {
                  deliveryClosed: false,
                  deliveryDate: Date(),
                  deliveryType: "courier",
                  deliveryLocation: placez,
                  deliveryApprover: "",
                  deliveryApproved: false,
                  deliveryCollectorId: wayz,
                  deliveryCollector: "courier",
                  deliveryAttachment: url,
                  deliveryHandler: props.profile.displayName,
                  deliveryStatus: "Pending waybill approval",
                },
                {
                  merge: true,
                }
              )
              .then(() => {
                setLoading(false);
              })
              .catch((e) => {
                setLoading(false);
                console.log("An error occured", e);
              });
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  let markAsCollectedByCourier = async (e) => {};

  let markAsCollectedByWalkin = async (e) => {
    e.preventDefault();
    await outletInventoryRef
      .doc(device.id)
      .set(
        {
          deliveryClosed: true,
          deliveryDate: Date(),
          deliveryHandler: props.profile.displayName,
          deliveryStatus: "Collected by walk-in collector",
        },
        {
          merge: true,
        }
      )
      .catch((e) => {
        console.log("An error occured", e);
      });
  };

  let captureCollectedByWalkinDetails = async (e) => {
    e.preventDefault();
    await outletInventoryRef
      .doc(device.id)
      .set(
        {
          deliveryClosed: false,
          deliveryDate: Date(),
          deliveryType: "walkin",
          deliveryCollectorId: pid,
          deliveryCollector: pname,
          deliveryAttachment: "",
          deliveryHandler: props.profile.displayName,
          deliveryStatus: "Pending collection",
        },
        {
          merge: true,
        }
      )
      .catch((e) => {
        console.log("An error occured", e);
      });
  };

  let markAsCollectedByCustomer = async (e) => {
    e.preventDefault();
    await outletInventoryRef
      .doc(device.id)
      .set(
        {
          deliveryClosed: true,
          deliveryDate: Date(),
          deliveryType: "owner",
          deliveryCollectorId: merchantName,
          deliveryAttachment: "",
          deliveryCollector: merchantName,
          deliveryHandler: props.profile.displayName,
          deliveryStatus: "Collected by owner",
        },
        {
          merge: true,
        }
      )
      .catch((e) => {
        console.log("An error occured", e);
      });
  };

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    // return sortedDeviceTransactions;
  }, [expanded]);

  if (!deviceTransactions) return <LoadingComponent inverted={true} />;

  let paymentTotal =
    props.deviceTransactions.length > 0
      ? deviceTransactions.reduce(function (accumulator, transaction) {
          return accumulator + transaction.transactionAmount;
        }, 0)
      : 0;

  let sortedDeviceTransactions = [...deviceTransactions];

  // sortedDeviceTransactions.sort((a, b) => {
  //   if (a.transactionDateTime.toDate() < b.transactionDateTime.toDate()) {
  //     return -1;
  //   }
  //   if (a.transactionDateTime.toDate() > b.transactionDateTime.toDate()) {
  //     return 1;
  //   }

  //   return 0;
  // });

  // sortedDeviceTransactions.sort((a, b) => a - b);

  console.log("sortedDeviceTransactions", sortedDeviceTransactions);

  let sortedTransactions = sortedDeviceTransactions.sort(function (a, b) {
    return a.recordId - b.recordId;
  });

  console.log("sortedTransactions", sortedTransactions);

  // import store from "./myStore";

  // const state = store.getState();
  // const getItems = state => state.user.items;
  // // ↓ in my case items is an array, so I create a new array by spreading state here
  // const items = [...getItems(state)];
  // // ↓ which means we're not manipulating state, but just our `items` array alone
  // items.sort((a, b) => a.order - b.order);

  console.log("props.deviceTransactions", props.deviceTransactions);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={"card card-default"}>
          <div className={"card-body"}>
            <div className={"row"}>
              <div className={"card card-transparent"}>
                <div className={"card-body"} style={{ padding: 0 }}>
                  <Tabs>
                    <div label="Manage Device">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          <div className={classes.EnableDevice}>
                            <div className={classes.EnableDevice}>
                              <div className={"card card-transparent"}>
                                <div
                                  className={"card-body"}
                                  style={{ paddingLeft: 0 }}
                                >
                                  <div className={"panel"}>
                                    <Fragment>
                                      <ul
                                        className={
                                          "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                        }
                                      >
                                        <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#activation"
                                            className={"active show"}
                                          >
                                            {device.isActivated
                                              ? "Disable"
                                              : "Enable"}
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#pairing">
                                            Pairing
                                          </a>
                                        </li>
                                        {/* <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#agreement"
                                          >
                                            Agreement
                                          </a>
                                        </li> */}
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#unlink">
                                            Unlink
                                          </a>
                                        </li>
                                      </ul>
                                      <div className={"tab-content"}>
                                        <div
                                          className={"tab-pane active"}
                                          id="activation"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              {device.isActivated ? (
                                                <DisableDevice
                                                  device={device}
                                                  outletId={device.outletId}
                                                  account={device.account}
                                                />
                                              ) : (
                                                <EnableDevice
                                                  device={device}
                                                  outletId={device.outletId}
                                                  account={device.account}
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={"tab-pane"}
                                          id="pairing"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <GeneratePairingCode
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={"tab-pane"} id="unlink">
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <UnassignDevice
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div
                                          className={"tab-pane"}
                                          id="agreement"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                <GeneratePairingCode
                                                  device={device}
                                                  outlet={outlet}
                                                  merchantName={merchantName}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                      </div>
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div label="Delivery">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          <div className={classes.EnableDevice}>
                            <div className={classes.EnableDevice}>
                              <div className={"card card-transparent"}>
                                <div
                                  className={"card-body"}
                                  style={{ paddingLeft: 0 }}
                                >
                                  <div className={"panel"}>
                                    <Fragment>
                                      <ul
                                        className={
                                          "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                        }
                                      >
                                        <li className={"nav-item active"}>
                                          <a
                                            data-toggle="tab"
                                            href="#owner"
                                            className={"active show"}
                                          >
                                            Owner
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#courier">
                                            Courier
                                          </a>
                                        </li>
                                        <li className={"nav-item active"}>
                                          <a data-toggle="tab" href="#walkin">
                                            Walk-in
                                          </a>
                                        </li>
                                      </ul>
                                      <div className={"tab-content"}>
                                        <div
                                          className={"tab-pane active"}
                                          id="owner"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              {device.deliveryClosed ? (
                                                device.deliveryType ==
                                                "owner" ? (
                                                  <h5 className={"no-margin"}>
                                                    {`Device collected by owner on ${
                                                      device.deliveryDate
                                                        .toString()
                                                        .split("GMT")[0]
                                                    } from ${
                                                      device.deliveryHandler
                                                    }`}
                                                  </h5>
                                                ) : (
                                                  <h5 className={"no-margin"}>
                                                    {`Device collected via ${device.deliveryType}`}
                                                  </h5>
                                                )
                                              ) : device.deliveryClosed ? null : (
                                                <form
                                                  onSubmit={
                                                    markAsCollectedByCustomer
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      "p-t-10 sm-m-t-20 bg-white clearfix"
                                                    }
                                                  >
                                                    <ul
                                                      className={
                                                        "pager wizard no-style"
                                                      }
                                                    >
                                                      <li className="next">
                                                        <button
                                                          className={styles.btn}
                                                          type="submit"
                                                        >
                                                          <span>
                                                            Collected by owner
                                                          </span>
                                                        </button>
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </form>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={"tab-pane"}
                                          id="courier"
                                        >
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                {device.deliveryClosed ? (
                                                  device.deliveryType ==
                                                  "courier" ? (
                                                    <h5 className={"no-margin"}>
                                                      {`Device delivered to customer via courier`}
                                                      <br />
                                                      {`Waybill number: ${device.deliveryCollectorId}`}
                                                      <br />
                                                      {`Destination: ${device.deliveryLocation}`}
                                                      <br />
                                                      {`Handler: ${device.deliveryHandler}`}
                                                      <br />
                                                      {`Approved by: ${device.deliveryApprover}`}
                                                      <br />
                                                      {`Date: ${
                                                        device.deliveryDate
                                                          .toString()
                                                          .split("GMT")[0]
                                                      }`}
                                                      <br />
                                                      <a
                                                        href={
                                                          device.deliveryAttachment
                                                        }
                                                        target="_blank"
                                                      >
                                                        View Waybill
                                                      </a>
                                                    </h5>
                                                  ) : (
                                                    <h5 className={"no-margin"}>
                                                      {`Device collected via ${device.deliveryType}`}
                                                    </h5>
                                                  )
                                                ) : device.deliveryApprover &&
                                                  device.deliveryAttachment ? (
                                                  <form
                                                  // onSubmit={
                                                  //
                                                  // }
                                                  >
                                                    <h5 className={"no-margin"}>
                                                      {`Device waybill has been approved`}
                                                      <br />
                                                      {`Waybill number: ${device.deliveryCollectorId}`}
                                                      <br />
                                                      {`Destination: ${device.deliveryLocation}`}
                                                      <br />
                                                      {`Approved by: ${device.deliveryApprover}`}
                                                      <br />
                                                      {`Date: ${
                                                        device.deliveryDate
                                                          .toString()
                                                          .split("GMT")[0]
                                                      }`}
                                                      <br />
                                                      <a
                                                        href={
                                                          device.deliveryAttachment
                                                        }
                                                        target="_blank"
                                                      >
                                                        View Waybill
                                                      </a>
                                                    </h5>
                                                    <div
                                                      className={
                                                        "p-t-10 sm-m-t-20 bg-white clearfix"
                                                      }
                                                    >
                                                      <ul
                                                        className={
                                                          "pager wizard no-style"
                                                        }
                                                      >
                                                        <li className="next">
                                                          <button
                                                            className={
                                                              styles.btn
                                                            }
                                                            type="submit"
                                                          >
                                                            <span>
                                                              Mark as delivered
                                                            </span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </form>
                                                ) : device.deliveryCollectorId ? (
                                                  <h5 className={"no-margin"}>
                                                    {`Device waybill awaiting approval`}
                                                    <br />
                                                    {`Waybill number: ${device.deliveryCollectorId}`}
                                                    <br />
                                                    {`Destination: ${device.deliveryLocation}`}
                                                    <br />
                                                    {`Approved by: ${device.deliveryApprover}`}
                                                    <br />
                                                    {`Date: ${
                                                      device.deliveryDate
                                                        .toString()
                                                        .split("GMT")[0]
                                                    }`}
                                                    <br />
                                                    <a
                                                      href={
                                                        device.deliveryAttachment
                                                      }
                                                      target="_blank"
                                                    >
                                                      View Waybill
                                                    </a>
                                                  </h5>
                                                ) : (
                                                  <form
                                                    onSubmit={
                                                      captureCollectedByCourier
                                                    }
                                                  >
                                                    <input
                                                      type="text"
                                                      name="placez"
                                                      placeholder="Enter delivery destination"
                                                      style={stylez.input}
                                                      onChange={(e) =>
                                                        setPlacez(
                                                          e.target.value
                                                        )
                                                      }
                                                      required
                                                    />
                                                    <p></p>
                                                    <input
                                                      type="text"
                                                      name="wayz"
                                                      placeholder="Enter waybill number"
                                                      style={stylez.input}
                                                      onChange={(e) =>
                                                        setWayz(e.target.value)
                                                      }
                                                      required
                                                    />
                                                    <p></p>
                                                    <h6
                                                      style={{
                                                        marginBottom: "-5px",
                                                      }}
                                                    >
                                                      Attach waybill
                                                    </h6>
                                                    <input
                                                      type="file"
                                                      name="filez"
                                                      style={stylez.input}
                                                      onChange={(e) =>
                                                        setFilez(
                                                          e.target.files[0]
                                                        )
                                                      }
                                                      required
                                                    />
                                                    <p></p>
                                                    <div
                                                      className={
                                                        "p-t-10 sm-m-t-20 bg-white clearfix"
                                                      }
                                                    >
                                                      <ul
                                                        className={
                                                          "pager wizard no-style"
                                                        }
                                                      >
                                                        <li className="next">
                                                          <button
                                                            className={
                                                              styles.btn
                                                            }
                                                            type="submit"
                                                            disabled={loading}
                                                          >
                                                            <span>
                                                              {loading
                                                                ? `⌛`
                                                                : `Send for
                                                              authorisation`}
                                                            </span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </form>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={"tab-pane"} id="walkin">
                                          <div
                                            className={"row column-seperation"}
                                          >
                                            <div className={"col-md-12"}>
                                              <div>
                                                {device.deliveryClosed ? (
                                                  device.deliveryType ==
                                                  "walkin" ? (
                                                    <h5 className={"no-margin"}>
                                                      {`Device collected by walk-in collector`}
                                                      <br />
                                                      {`Name: ${device.deliveryCollector}`}
                                                      <br />
                                                      {`ID/passport: ${device.deliveryCollectorId}`}
                                                      <br />
                                                      {`Handler: ${device.deliveryHandler}`}
                                                      <br />
                                                      {`Date: ${
                                                        device.deliveryDate
                                                          .toString()
                                                          .split("GMT")[0]
                                                      }`}
                                                    </h5>
                                                  ) : (
                                                    <h5 className={"no-margin"}>
                                                      {`Device collected via ${device.deliveryType}`}
                                                    </h5>
                                                  )
                                                ) : device.deliveryCollector &&
                                                  device.deliveryCollector ==
                                                    "walkin" ? (
                                                  <form
                                                    onSubmit={
                                                      markAsCollectedByWalkin
                                                    }
                                                  >
                                                    <h5>
                                                      Expected collector name:
                                                      <span
                                                        className={"semi-bold"}
                                                      >
                                                        {` ${device.deliveryCollector}`}
                                                      </span>
                                                    </h5>
                                                    <h5>
                                                      Expected collector
                                                      ID/passport:
                                                      <span
                                                        className={"semi-bold"}
                                                      >
                                                        {` ${device.deliveryCollectorId}`}
                                                      </span>
                                                    </h5>
                                                    <div
                                                      className={
                                                        "p-t-10 sm-m-t-20 bg-white clearfix"
                                                      }
                                                    >
                                                      <ul
                                                        className={
                                                          "pager wizard no-style"
                                                        }
                                                      >
                                                        <li className="next">
                                                          <button
                                                            className={
                                                              styles.btn
                                                            }
                                                            type="submit"
                                                          >
                                                            <span>
                                                              Collected by
                                                              walk-in collector
                                                            </span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </form>
                                                ) : (
                                                  <form
                                                    onSubmit={
                                                      captureCollectedByWalkinDetails
                                                    }
                                                  >
                                                    <input
                                                      type="text"
                                                      name="name"
                                                      placeholder="Enter expected collector name"
                                                      style={stylez.input}
                                                      onChange={(e) =>
                                                        setName(e.target.value)
                                                      }
                                                      required
                                                    />
                                                    <p></p>
                                                    <input
                                                      type="text"
                                                      name="id"
                                                      placeholder="Enter expected collector ID/passport"
                                                      style={stylez.input}
                                                      onChange={(e) =>
                                                        setId(e.target.value)
                                                      }
                                                      required
                                                    />
                                                    <div
                                                      className={
                                                        "p-t-10 sm-m-t-20 bg-white clearfix"
                                                      }
                                                    >
                                                      <ul
                                                        className={
                                                          "pager wizard no-style"
                                                        }
                                                      >
                                                        <li className="next">
                                                          <button
                                                            className={
                                                              styles.btn
                                                            }
                                                            type="submit"
                                                          >
                                                            <span>
                                                              Capture walk-in
                                                              collector details
                                                            </span>
                                                          </button>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </form>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div label="Contract">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          {deviceContract ? (
                            <>
                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Plan
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {deviceContract.plan}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Contract Status
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {deviceContract.contractStatus}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Contract Amount
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {deviceContract.contractAmount}
                                  </h5>
                                </div>
                              </div>

                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Installment &amp; Duration
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {`P${moneyFormat(
                                      deviceContract.installmentAmount
                                    )} over
                                      ${
                                        deviceContract.installmentPeriodInMonths
                                      } Months`}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Amount Paid(including Deposit)
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {`P${moneyFormat(
                                        deviceContract.amountPaid
                                      )}`}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Amount Outstanding
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {/* {`P${moneyFormat(
                                        deviceContract.amountOutstanding
                                      )}`} */}

                                      {`P${moneyFormat(
                                        deviceContract.amountOutstanding
                                      )} over
                                      ${
                                        deviceContract.contractRemaining
                                      } Months`}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div className={"row p-t-10"}>
                                <div className={"col-lg-4"}>
                                  <label
                                    className={
                                      "control-label all-caps small no-margin hint-text semi-bold"
                                    }
                                  >
                                    Date Allocated
                                  </label>
                                  <h5 className={"no-margin"}>
                                    {format(
                                      deviceContract.dateCreated.toDate(),
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </h5>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Installment Start
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {format(
                                        deviceContract.contractStartDate.toDate(),
                                        "YYYY-MM-DD"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className={"col-lg-4"}>
                                  <div>
                                    <label
                                      className={
                                        "control-label all-caps small no-margin hint-text semi-bold"
                                      }
                                    >
                                      Installment End
                                    </label>
                                    <h5 className={"no-margin"}>
                                      {format(
                                        deviceContract.contractEndDate.toDate(),
                                        "YYYY-MM-DD"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div className={"wizard-footer p-t-30"}>
                                <button
                                  className={`${classes.AmendContactBtn} m-r-30`}
                                  onClick={() =>
                                    openModal("AmendDeviceContractModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Amend Contract {"  "}
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <button
                                  className={`${classes.AmendContactBtn} m-r-30`}
                                  onClick={() =>
                                    openModal("AdhocDevicePaymentModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Adhoc Payment
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <button
                                  className={classes.AmendContactBtn}
                                  onClick={() =>
                                    openModal("ManualDevicePaymentModal", {
                                      deviceContract: deviceContract,
                                      outletName: outlet.name,
                                      group: group,
                                      merchantName: merchantName,
                                    })
                                  }
                                >
                                  Installment Payment
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                      />
                                    </svg>
                                  </span>{" "}
                                </button>
                                <div className={"pull-right"} />
                                <div className={"clearfix"} />
                              </div>
                            </>
                          ) : (
                            <h4>Device has no contract</h4>
                          )}
                        </div>
                      </div>
                    </div>
                    <div label="Transactions">
                      <div className={classes.Details}>
                        <div className={classes.DetailsContent}>
                          {sortedTransactions.length > 0 ? (
                            <div className={"row"}>
                              <div className={"card card-transparent"}>
                                <div className={"card-body"}>
                                  {
                                    <table className={classes.MerchantTable}>
                                      <thead>
                                        <tr>
                                          <th scope="col">Date Time</th>
                                          <th scope="col">Type</th>
                                          <th style={{ textAlign: "right" }}>
                                            Amount
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Total Paid
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Balance Remaining
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            contractBalance
                                          </th>
                                          <th style={{ textAlign: "right" }}>
                                            Contract Remaining
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {sortedTransactions.map(
                                          (transaction, index) => {
                                            return (
                                              <tr
                                                className={classes.MerchantRow}
                                                key={index}
                                              >
                                                <td>
                                                  {format(
                                                    transaction.transactionDateTime.toDate(),
                                                    "YYYY-MM-DD  HH:mm:ss"
                                                  )}
                                                </td>
                                                <td>
                                                  {`${transaction.deviceContractTransactionType}`}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.transactionAmount
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.amountPaid
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.contractBalance
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {moneyFormat(
                                                    transaction.contractBalance
                                                  )}
                                                </td>
                                                <td align="right">
                                                  {
                                                    transaction.contractRemaining
                                                  }
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                        <tr className={classes.Totals}>
                                          <td colSpan="2" align="right">
                                            <div
                                              className={
                                                classes.MoneyTotalLabel
                                              }
                                            >
                                              {" "}
                                              Total:
                                            </div>
                                          </td>
                                          <td
                                            className={classes.MoneyTotal}
                                            align="right"
                                          >
                                            {moneyFormat(paymentTotal)}
                                          </td>
                                          <td colSpan="4"> </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  }
                                </div>
                              </div>
                            </div>
                          ) : (
                            <h4>Device has no transactions</h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const query = (props) => {
  if (!props.deviceContract) {
    return [
      {
        collection: "merchant_balance",
        storeAs: "deviceTransactions",
        where: [
          ["transactionType", "==", "DeviceContract"],
          ["account", "==", "1913"],
        ],
      },
    ];
  }

  return [
    {
      collection: "merchant_balance",
      storeAs: "deviceTransactions",
      where: [
        ["transactionType", "==", "DeviceContract"],
        ["account", "==", props.deviceContract.account],
      ],
    },
  ];
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  deviceTransactions: state.firestore.ordered.deviceTransactions,
});

const actions = { openModal };

export default compose(
  connect(mapStateToProps, actions),
  firestoreConnect(query)
)(detail);
