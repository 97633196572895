import React from "react";

import Tabs from "../../../app/UI/Tabs/Tabs";

import Deposits from "./deposits";
import UnAllocatedTransactions from "./unallocated";
import Upload from "./upload";

const index = () => {
  return (
    <Tabs>
      <div label={`Transactions`}>
        <Deposits />
      </div>
      <div label={`Un-Allocated`}>
        <UnAllocatedTransactions />
      </div>
      <div label={`Manual Upload`}>
        <Upload />
      </div>
    </Tabs>
  );
};

export default index;
