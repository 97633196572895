import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import format from "date-fns/format";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween
} from "revalidate";
import styles from "./ApproveCredit.module.css";
import { declineCredit } from "../../../financials/financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number"
    }),
    hasLengthBetween(4, 4)({
      message: "Must be 4 numbers"
    })
  )()
});

class DeclineCreditApplication extends Component {
  render() {
    const { credit, declineCredit,loading, handleSubmit } = this.props;

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(declineCredit)}>
              <div className={styles.Header}>
                <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                  <div
                    className={"col-md-6"}
                    style={{ justifyContent: "flex-start", display: "flex" }}
                  >
                    Merchant: <span className={"semi-bold"}>{credit.name}</span>
                  </div>
                  <div
                    className={"col-md-6"}
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    Account:
                    <span className={"semi-bold"}>{credit.account}</span>
                  </div>
                </div>
                <div className={"row"} style={{ lineHeight: "3.8rem" }}>
                  <div
                    className={"col-md-6"}
                    style={{ justifyContent: "flex-start", display: "flex" }}
                  >
                    Credit:
                    <span className={"semi-bold"}>
                      {moneyFormat(credit.amount)}
                    </span>
                  </div>
                  <div
                    className={"col-md-6"}
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    Expiry:
                    <span className={"semi-bold"}>
                      {format(
                        credit.expiryDate && credit.expiryDate.toDate(),
                        "YYYY-MM-DD"
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">

                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Decline Credit</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  declineCredit
};

const mapStateToProps = (state, props) => ({
  loading: state.async.loading,
  initialValues: {
    id: props.credit.id
  },
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "ApproveCreditForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  })(DeclineCreditApplication)
);
