import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import CreateSeller from "../settings/sellers/Forms/CreateSeller";

const actions = { closeModal };

const createSellerModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create Seller" onClose={closeModal}>
      <CreateSeller />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(createSellerModal);
