import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { reducer as FormReducer } from "redux-form";
import modalReducer from "../features/modals/modalReducer";
import sideDrawerReducer from "../features/sideDrawer/sideDrawerReducer";
import merchantReducer from "../features/merchants/merchantReducer";
import usersReducer from "../features/users/usersReducer";
import inventoryReducer from "../features/inventory/inventoryReducer";
import reportReducer from "../features/reports/reportReducer";
import financialsReducer from "../features/financials/financialsReducer";
import dashboardReducer from "../features/dashboard/dashboardReducer";
import transactionsReducer from "../features/transactions/transactionsReducer";
import merchantTransactionsReducer from "../features/reports/Merchant/transactions/transactionsReducer";

import depositsReducer from "../features/deposits/depositsReducer.jsx";
import reconsReducer from "../features/recons/reconsReducer.jsx";


import competitionsReducer from "../features/competitions/competitionsReducer";
import airtimeReducer from "../features/airtime/airtimeReducer";
import wucReducer from "../features/wuc/wucReducer";
import smsReducer from "../features/sms/smsReducer";

import settingsReducer from "../features/settings/settingsReducer";
import asyncReducer from "../features/async/asyncReducer";
import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: FormReducer,
  modals: modalReducer,
  sideDrawer: sideDrawerReducer,
  merchants: merchantReducer,
  inventory: inventoryReducer,
  reports: reportReducer,
  financials: financialsReducer,
  dashboard: dashboardReducer,
  transactions: transactionsReducer,
  merchantTransactions:merchantTransactionsReducer,
  deposits: depositsReducer,
  recons:reconsReducer,
  competitions: competitionsReducer,
  wuc: wucReducer,
  sms:smsReducer,
  airtimeSummary: airtimeReducer,
  settings: settingsReducer,
  async: asyncReducer,
  users: usersReducer,
  toastr: toastrReducer,
});

//

export default rootReducer;

// export const createRootReducer = asyncReducers => {
//     const appReducer = combineReducers({
//         myReducer...asyncReducers
//     });
//     return (state, action) => {
//         if (action.type === 'LOGOUT_USER') {
//             state = undefined;
//         }
//         return appReducer(state, action);
//     }
// };
