import React, { useState, useEffect, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { openSideDrawer } from "../../../sideDrawer/sideDrawerActions";
// import SideDrawerManager from "../../../sideDrawer/sideDrawerManager";
import classes from "./Devices.module.css";
import Device from "./details";

const devices = ({ inventoryItemList, sideDrawer, openSideDrawer }) => {
  const [selectedRow, setSelectedRow] = useState(-1);
  const [deviceDetailPanelOpen, setDeviceDetailPanelOpen] = useState(false);

  const handleDeviceClick = (device, index) => {
    setSelectedRow(index);
    setDeviceDetailPanelOpen(true);
    openSideDrawer("InventoryDeviceSideDrawer", device);
  };

  useEffect(() => {
    if (sideDrawer === null) {
      setDeviceDetailPanelOpen(false);
      setSelectedRow(-1);
    }
  }, [sideDrawer]);

  let deviceRowClasses = null;
  let deviceDetailPanelOpenClasses = [classes.RevealMore];

  !selectedRow
    ? (deviceRowClasses = [classes.MerchantRow])
    : (deviceRowClasses = [classes.MerchantRow, classes.SelectedTransaction]);
  // console.log("inventoryItemList device", inventoryItemList);
  let inventoryItems = Object.values(inventoryItemList).map(
    (inventoryItem, index) => {
      return <Device inventoryItem={inventoryItem} />;
    }
  );

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr>
            <th scope="col">Model</th>
            <th scope="col">Serial Number</th>
            <th scope="col">MAC Address</th>
            <th className={"hide-s"} scope="col">
              Cell Number
            </th>
            <th className={"hide-s"} scope="col">
              Outlet ID
            </th>{" "}
            <th className={"hide-s"} scope="col">
              Status
            </th>
            <th className={"hide-s"} scope="col">
              Date Added
            </th>
          </tr>
        </thead>
        <tbody>{inventoryItems}</tbody>
      </table>
    </Fragment>
  );
};

const actions = {
  openSideDrawer,
};

const mapStateToProps = (state) => ({
  sideDrawer: state.sideDrawer,
});

export default connect(mapStateToProps, actions)(devices);
// export default Devices;
