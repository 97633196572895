import React, { useState, useEffect } from "react";
import styles from "./BalanceForm.module.css";
import firebase from "../../../../app/config/firebase";
import moment from "moment";

const PaymentsForm = (props) => {
  let attachedClasses = ["form-group", "form-group-default"];
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const triggersRef = firestore.collection("triggers");
  const popStorageRef = firebase.storage().ref();

  const [formMessage, setformMessage] = useState("");
  const [formErrorMessage, setformErrorMessage] = useState("");
  const [preventSubmit, setPreventSubmit] = useState(false);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setPreventSubmit(true);
    const formData = new FormData(event.target);
    const provider = formData.get("provider");
    const amount = formData.get("amount");
    const pop = formData.get("pop");

    try {
      if (pop.name == "") {
        await triggersRef
          .doc("provider_triggers")
          .collection("payments")
          .add({
            provider,
            amount,
            pop: "#",
            time: moment().toDate(),
            credited: false,
          })
          .then(() => {
            setformMessage("Captured payment successfully");
            event.target.reset();
            setPreventSubmit(false);
          });
      } else {
        const popFileRef = popStorageRef.child(`provider_pop/${pop.name}`);
        await popFileRef.put(pop).then(async () => {
          await popFileRef.getDownloadURL().then(async (url) => {
            await triggersRef
              .doc("provider_triggers")
              .collection("payments")
              .add({
                provider,
                amount,
                pop: url,
                time: moment().toDate(),
                credited: false,
              })
              .then(() => {
                setformMessage("Captured payment successfully");
                event.target.reset();
                setPreventSubmit(false);
              });
          });
        });
      }
    } catch (error) {
      setformErrorMessage("A fault occurred while capturing, please try again");
      console.error("Error capturing payment:", error);
      setPreventSubmit(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (formMessage != "") {
      setTimeout(() => {
        if (isMounted) {
          setformMessage("");
        }
      }, 3000);
    }
    return () => {
      isMounted = false;
    };
  }, [formMessage]);

  return (
    <form onSubmit={(e) => onFormSubmit(e)}>
      <br />
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Provider</b>
        </div>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>Amount</b>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <select name="provider" className="form-control" required>
                <option value="BPC">BPC</option>
                <option value="Kazang">Kazang</option>
              </select>
            </div>
          </div>
        </div>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"}>
              <input
                name="amount"
                placeholder="amount"
                type="number"
                className="form-control"
                autoComplete="off"
                required
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <b style={{ marginTop: 0 }}>
            Proof of Payment - Can be attached later
          </b>
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-md-6"}>
          <div className={attachedClasses.join(" ")}>
            <div className={"controls"} style={{ marginTop: "15px" }}>
              <input
                name="pop"
                placeholder="pop"
                type="file"
                accept=".pdf,.jpg,.jpeg"
                className="form-control"
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <button className={styles.btn} type="submit" disabled={preventSubmit}>
        {preventSubmit ? <span>⏳</span> : <span>Capture</span>}
      </button>
      <br></br>
      {formMessage && <div className="text-success">{formMessage}</div>}
      {formErrorMessage && (
        <div className="text-danger">{formErrorMessage}</div>
      )}
    </form>
  );
};

export default PaymentsForm;
