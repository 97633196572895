import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";

import { reduxForm } from "redux-form";
import { useDropzone } from "react-dropzone";
import asyncValidateMerchantName from "../../asyncValidate";
import validate from "../../validate";
import Complete from "./complete";

import Application from "./application";
import Approved from "./approved";
import Authorised from "./authorised";
import Rejected from "./rejected";
import Returned from "./returned";
import Escalated from "./escalated";

import { updatePettyCash } from "../../pettyCashActions";

const index = (props) => {
  let {
    transaction,
    closeExpanded,
    profile,
    auth,
    updatePettyCash,
    handleSubmit,
    loading,
    initialValues,
    openModal,
  } = props;

  let [supportingDocument, setSupportingDocument] = useState(null);
  let [supportingDocumentRequired, setSupportingDocumentRequired] =
    useState(false);
  let [
    supportingDocumentRequiredMessage,
    setSupportingDocumentRequiredMessage,
  ] = useState(null);

  const today = new Date();

  let [loadingInitial, setLoadingInitial] = useState(true);

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  const onFormSubmit = async (values) => {
    console.log("onFormSubmit", values);

    let validatedMerchantName = await asyncValidateMerchantName(values.account);

    console.log("validatedMerchantName", validatedMerchantName);

    if (
      supportingDocument === null &&
      today -
        transaction.acceptanceCalendarDateTime.toDate() /
          (1000 * 60 * 60 * 24) >=
        7
    ) {
      setSupportingDocumentRequiredMessage("Provide supporting documentation");
      return;
    }

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;

    setLoadingInitial(true);
    await updatePettyCash(values, supportingDocument, initialValues);
    closeExpanded(false);
    // setLoadingInitial(false);
  };

  const transactionDetail = (transaction) => {
    switch (transaction.status) {
      case "Application":
        return (
          <Application
            transaction={transaction}
            closeExpanded={closeExpanded}
          />
        );
      case "Approved":
        return (
          <Authorised transaction={transaction} closeExpanded={closeExpanded} />
        );
      case "Complete":
        return (
          <Complete transaction={transaction} closeExpanded={closeExpanded} />
        );
      case "Rejected":
        return (
          <Rejected transaction={transaction} closeExpanded={closeExpanded} />
        );
      case "Returned":
        return (
          <Returned transaction={transaction} closeExpanded={closeExpanded} />
        );
      case "Escalated":
        return (
          <Escalated transaction={transaction} closeExpanded={closeExpanded} />
        );

      default:
        return <div>Current status is not supported</div>;
    }
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);
  console.log("transaction@@", transaction);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          {/* <header>
            <h4>Account</h4>
          </header> */}

          <div className={classes.FuneralSchemeWrapper}>
            {transactionDetail(transaction)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(mapStateToProps, actions)(index);
