import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from "moment";
import format from "date-fns/format";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

import { DateRangePicker } from "react-dates";

import SelectInput from "../../../../app/common/form/SelectInput";
import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

import { sendReportByMail } from "../../reportActions";

// sendReportByMail

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId")
});

class Index extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    startDate: moment(),
    endDate: moment(),
    today: moment()
  };

  render() {
    const { handleSubmit, merchantsEmailList, requesting } = this.props;

    let merchantList =
      merchantsEmailList &&
      merchantsEmailList.map(merchant => {
        return {
          label: merchant.name,
          value: merchant.account
        };
      });

    const loading = requesting[`merchants`];

    // let outlets = Object.values(this.props.outlets).map(outlet => {
    //   return {
    //     label: `${outlet.id} - ${outlet.name}`,
    //     value: outlet.id
    //   };
    // });

    const onFormSubmit = async values => {
      const outletId = values.outletId.value;
      const reportStartDate = new Date(this.state.startDate);
      const reportEndDate = new Date(this.state.endDate);

      await sendReportByMail({
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
        outletId: outletId
      });
      this.setState({ loadingInitial: false });
    };

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-3"}>
                    <Field
                      name="merchant"
                      options={merchantList}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary: "neutral0"
                        }
                      })}
                      component={SelectInput}
                      placeholder="Select Merchant..."
                      className={"form-control"}
                      required="true"
                      aria-required="true"
                    />
                  </div>

                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <div className={"col-md-8 offset-1"}>
                        <div className={"row"}>
                          <DateRangePicker
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="startDate" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="endDate" // PropTypes.string.isRequired,
                            onDatesChange={({ startDate, endDate }) =>
                              this.setState({ startDate, endDate })
                            } // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput =>
                              this.setState({ focusedInput })
                            } // PropTypes.func.isRequired,
                            numberOfMonths={1}
                            isOutsideRange={date =>
                              date.isAfter(this.state.today)
                            }
                            displayFormat={"YYYY-MM-DD"}
                            minimumNights={0}
                            small={true}
                            readOnly={true}
                          />
                          <button
                            className={classes.FilterReportBtn}
                            style={{ marginLeft: "1rem" }}
                            type="submit"
                          >
                            Send Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  merchantsEmailList: state.firestore.ordered.merchantsEmailList,
  requesting: state.firestore.status.requesting,
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD")
  },
  enableReinitialize: true
});

const actions = {
  sendReportByMail
};

export default compose(
  firestoreConnect(props => [
    {
      collection: "merchants",
      storeAs: "merchantsEmailList",
      orderBy: ["name", "asc"]
    }
  ]),
  connect(
    mapStateToProps,
    actions
  )
)(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(Index)
);
