import React from "react";

const AsyncTextInput = ({
  label,
  required,
  input,
  value,
  type,
  placeholder,
  meta: { asyncValidating, touched, error }
}) => {
  let attachedClasses = ["form-group", "form-group-default", required];

  //  error={condition && value}, pass error={condition ? value : undefined}

  return (
    <div className={attachedClasses.join(" ")}>
      {/* <label className={"label-sm"}>{label}</label> */}
      <div className={"controls"}>
        <div className={asyncValidating ? "async-validating" : ""}>
          <input
            {...input}
            placeholder={placeholder}
            type={type}
            className={"form-control"}
            value={value}
          />
          {touched && error && <label className={"error"}>{error}</label>}
        </div>
      </div>
    </div>
  );
};

export default AsyncTextInput;
