import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.css";

const index = (props) => {
  let { merchant } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Transaction Summary</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            {props.transactions.length > 0 ? (
              <table className={classes.FuneralSchemeTable}>
                <thead>
                  <tr>
                    <th scope="col">Sale Type</th>
                    <th scope="col">Vendor</th>
                    <th className={"hide-s"} scope="col">
                      Amount
                    </th>
                    <th className={"hide-s"} scope="col">
                      Commission
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.transactions.map((transaction, index) => {
                    console.log("transaction", transaction);
                    return (
                      <tr key={index} className={classes.MemberRow}>
                        <td>{transaction.type}</td>
                        <td>{transaction.vendor}</td>
                        <td>{transaction.sales}</td>
                        <td>{transaction.commission}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <h4>The are no sales for selected period</h4>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default index;
