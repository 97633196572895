//import liraries
import React, { Fragment, useState } from "react";

import classes from "./MerchantList.module.css";
import MerchantListItem from "./MerchantListItem";
import { formatMoney } from "../../../app/common/util/helpers";
// create a component
const index = (props) => {
  let { transactions, merchants, showCommission } = props;
  console.log("showCommission", showCommission);

  let list = merchants.map((merchant) => {
    return {
      account: merchant.account,
      name: merchant.merchantName,
      openingBalance: transactions.startingBalances
        .filter((item) => item.account === merchant.account)
        .map((doc) => {
          return doc.startingBalance;
        })[0],
      deposits: transactions.summary
        .filter(
          (item) =>
            item.account === merchant.account &&
            item.transactionType === "Deposit"
        )
        .map((doc) => {
          return doc.transactionAmount;
        })[0],
      commission: transactions.summary
        .filter(
          (item) =>
            item.account === merchant.account &&
            item.transactionType === "Commission"
        )
        .map((doc) => {
          return doc.transactionAmount;
        })[0],
      sales: transactions.summary
        .filter(
          (item) =>
            item.account === merchant.account && item.transactionType === "Sale"
        )
        .map((doc) => {
          return doc.transactionAmount;
        })[0],
      closingBalance: transactions.closingBalances
        .filter((item) => item.account === merchant.account)
        .map((doc) => {
          return doc.closingBalance;
        })[0],
      transactions: transactions.sales
        .filter((item) => item.account === merchant.account)
        .map((doc) => {
          return {
            type: doc.saleType,
            vendor: doc.provider,
            sales: formatMoney(doc.transactionAmount),
            commission: formatMoney(doc.commissionAmount),
          };
        }),
    };
  });

  const merchantsList = list.map((merchant) => {
    return (
      <MerchantListItem
        key={merchant.account}
        merchant={merchant}
        showCommission={showCommission}
      />
    );
  });

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr style={{ textAlign: "right", verticalAlign: "middle" }}>
            <th scope="col" style={{ textAlign: "left" }}>
              Merchant
            </th>
            <th className={"hide-s"} scope="col">
              Opening
            </th>
            <th className={"hide-s"} scope="col">
              Deposit
            </th>
            {showCommission && (
              <th className={"hide-s"} scope="col">
                Commission
              </th>
            )}
            <th className={"hide-s"} scope="col">
              Sales
            </th>
            <th className={"hide-s"} scope="col">
              Closing
            </th>
            <th className={"hide-s"} scope="col">
              Reports
            </th>
          </tr>
        </thead>
        <tbody>{merchantsList}</tbody>
      </table>
    </Fragment>
  );
};

//make this component available to the app
export default index;
