import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const MerchantRoute = ({
  isAuthenticated,
  profile,
  component: Component,
  location,
  ...rest
}) => {
  console.log("MerchantRoute", location);
  console.log("pathname", location.pathname);
  let pathName = location.pathname; // String
  let pathArray = pathName.split("/");
  let isAuthorised =
    profile.accounts && profile.role === "Group Administrator"
      ? profile.accounts.includes(pathArray[2])
      : profile.outlets === pathArray[2];

  profile.accounts && console.log("isAuthorised", isAuthorised);

  profile.accounts && pathArray[2]
    ? console.log("profile", profile.accounts.includes(pathArray[2]))
    : console.log("pathArray", pathArray[2]);
  return (
    profile && (
      <Route
        {...rest}
        component={(props) =>
          isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
        }
      />
    )
  );
};

// console.log("loggedInUser",loggedInUser)
// if(loggedInUser.account === "PrepaidPlus") {
//  merchantsRef = firestore.collection("merchants").doc(merchantId)
// } else if(loggedInUser.outlets.includes(merchantId)) {
//  merchantsRef = firestore.collection("merchants").doc(merchantId)
// }

// const PrivateRoute({ children, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           fakeAuth.isAuthenticated ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }

// {/* <Route {...rest} component={() => (
//     isAuthenticated ? (
//         <MerchantLayout>
//       <Component {...rest} />
//         </MerchantLayout>
//     ): (
//         <Redirect to="/" />
//     )
// )}/> */}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.firebase.auth.uid,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(MerchantRoute);

// const PrivateRoute({ children, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           fakeAuth.isAuthenticated ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }
