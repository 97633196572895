import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import Tabs from "../../../app/UI/Tabs/Tabs";
import axios from "../../../axios-firebase";
import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TransactionsTextInput";

import { openModal } from "../../modals/modalActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import {
  createReportExcelFile,
  createNewExcelFile,
} from "../../../app/excel/excel";
import {
  getKazangTransactionsForDashboard,
  clearTodaysRecons,
} from "../reconsActions";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import DepositListItem from "./details";
import { formatMoney } from "../../../app/common/util/helpers";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let accountSearchText = "";

const transactions = ({
  handleSubmit,
  getKazangTransactionsForDashboard,
  fnbTotalValue,
  fnbMissingValue,
  fnbTotalCount,
  fnbOnlyRecords,
  fnbOnlyCount,
  pppTotalValue,
  pppMissingValue,
  pppTotalCount,
  pppOnlyRecords,
  pppOnlyCount,
  clearTodaysRecons,
  recons,
  requesting,
  initialValues,
}) => {
  let [expanded, setExpanded] = useState(false);
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(
    moment().subtract(1, "days").startOf("day")
  );
  let [endDate, setEndDate] = useState(
    moment().subtract(1, "days").endOf("day")
  );
  let [today, setToday] = useState(moment());
  let [yesterday, setYesterday] = useState(moment().subtract(1, "days"));
  let [transactionsFiltered, setTransactionsFiltered] = useState([]);
  let [selectedRow, setSelectedRow] = useState(-1);
  const [focusedInput, setFocusedInput] = useState(null);
  let [uploaded, setUploaded] = useState(null);
  let [disabled, setDisabled] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    getKazangTransactionsForDashboard({
      startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    }).then(() => {
      setLoadingInitial(false);
    });

    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    return () => {
      clearTodaysRecons();
    };
  }, [expanded]);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setUploaded("waiting");
      const reader = new FileReader();
      reader.onload = async () => {
        const base64File = reader.result.split(",")[1];
        if (window.confirm("Are you sure you want to upload selected file")) {
          setDisabled(true);
          // console.log("base64File", base64File);
          await axios
            .post("/recon-kazangSalesRecon", {
              startDate: startDate,
              file: base64File,
            })
            .then((response) => {
              if (response.status === 200) {
                setUploaded("good");
              } else {
                setUploaded("bad");
              }
            })
            .catch((e) => {
              setUploaded("bad");
            });
        } else {
          setUploaded(null);
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  let transactionRowClasses = null;
  let transactionDetailPanelOpenClasses = [classes.RevealMore];
  let fnbReconSheet = async (recons, workbook, logo, accountName) => {
    let worksheet = workbook.addWorksheet(
      "Prepaid Plus - Kazang Recon Report",
      {
        properties: {
          showGridLines: false,
        },
        pageSetup: {
          paperSize: 9,
          fitToPage: true,
          orientation: "portrait",
        },
        views: [
          {
            state: "frozen",
            xSplit: 15,
            activeCell: "A1",
            showGridLines: false,
          },
        ],
      }
    );

    worksheet.mergeCells("A1", "G2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A4", "G4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Kazang Report",
        },
      ],
    };

    worksheet.mergeCells("I4", "O4");
    worksheet.getCell("O4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "PrepaidPlus Report",
        },
      ],
    };

    worksheet.mergeCells("A6", "C6");
    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Total Sales Value: ",
        },
      ],
    };

    worksheet.mergeCells("A7", "C7");
    worksheet.getCell("A7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Missing Sales Value: ",
        },
      ],
    };

    worksheet.mergeCells("A8", "C8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Total Sales Count: ",
        },
      ],
    };

    worksheet.mergeCells("A9", "C9");
    worksheet.getCell("A9").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Missing Sales Count: ",
        },
      ],
    };

    worksheet.mergeCells("D6", "E6");
    worksheet.getCell("D6").value = formatMoney(recons.kazangTotalValue);

    worksheet.mergeCells("D7", "E7");
    worksheet.getCell("D7").value = formatMoney(recons.kazangMissingValue);

    worksheet.mergeCells("D8", "E8");
    worksheet.getCell("D8").value = `${recons.kazangTotalCount}`;

    worksheet.mergeCells("D9", "E9");
    worksheet.getCell("D9").value = `${recons.kazangOnlyCount}`;

    worksheet.mergeCells("I6", "K6");
    worksheet.getCell("I6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Total Sales Value: ",
        },
      ],
    };

    worksheet.mergeCells("I7", "K7");
    worksheet.getCell("I7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Missing Sales Value: ",
        },
      ],
    };

    worksheet.mergeCells("I8", "K8");
    worksheet.getCell("I8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Total Sales Count: ",
        },
      ],
    };

    worksheet.mergeCells("I9", "K9");
    worksheet.getCell("I9").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Missing Sales Count: ",
        },
      ],
    };

    worksheet.mergeCells("L6", "M6");
    worksheet.getCell("L6").value = formatMoney(recons.kazangPppTotalValue);

    worksheet.mergeCells("L7", "M7");
    worksheet.getCell("L7").value = formatMoney(recons.kazangPppMissingValue);

    worksheet.mergeCells("L8", "M8");
    worksheet.getCell("L8").value = `${recons.kazangPppTotalCount}`;

    worksheet.mergeCells("L9", "M9");
    worksheet.getCell("L9").value = `${recons.kazangPppOnlyCount}`;

    worksheet.getCell("A12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Date",
        },
      ],
    };
    worksheet.getCell("B12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Time",
        },
      ],
    };
    worksheet.getCell("C12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Product",
        },
      ],
    };
    worksheet.getCell("D12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Reference",
        },
      ],
    };
    worksheet.getCell("E12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Amount",
        },
      ],
    };

    worksheet.getCell("I12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Date",
        },
      ],
    };
    worksheet.getCell("J12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Time",
        },
      ],
    };
    worksheet.getCell("K12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Product",
        },
      ],
    };
    worksheet.getCell("L12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Reference",
        },
      ],
    };
    worksheet.getCell("M12").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Amount",
        },
      ],
    };

    recons.kazangOnlyRecords.forEach((element, index) => {
      worksheet.getCell(`A${13 + index}`).value = element.transaction_date;
      worksheet.getCell(`B${13 + index}`).value = element.transaction_time;
      worksheet.getCell(`C${13 + index}`).value = element.meter_number;
      worksheet.getCell(`D${13 + index}`).value = element.reciept_number;
      worksheet.getCell(`E${13 + index}`).value = formatMoney(element.amount);
    });

    recons.kazangPppOnlyRecords.forEach((element, index) => {
      worksheet.getCell(`I${13 + index}`).value = element.transaction_date;
      worksheet.getCell(`J${13 + index}`).value = element.transaction_time;
      worksheet.getCell(`K${13 + index}`).value = element.meter_number;
      worksheet.getCell(`L${13 + index}`).value = element.reciept_number;
      worksheet.getCell(`M${13 + index}`).value = formatMoney(element.amount);
    });
  };

  const loading = requesting[`merchants`];

  !selectedRow
    ? (transactionRowClasses = [classes.MerchantRow])
    : (transactionRowClasses = [
        classes.MerchantRow,
        classes.SelectedTransaction,
      ]);

  const onFormSubmit = async (values) => {
    if (startDate != null && endDate != null) {
      let transactionStartDate = startDate.format("YYYY-MM-DD");
      let transactionEndDate = endDate.format("YYYY-MM-DD");

      setLoadingInitial(true);
      await getKazangTransactionsForDashboard({
        startDate: transactionStartDate,
        endDate: transactionEndDate,
      });
      setLoadingInitial(false);
    }
  };

  // console.log("sortedTransactions", sortedTransactions);

  const filterByAccount = (account) => {
    let keyword = account.target.value.toLowerCase();
    let filtered = recons.filter((transaction) => {
      return transaction.reconReference.indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    accountSearchText = keyword;
  };

  const filterCreditedRecons = () => {
    let filtered = recons.filter((transaction) => {
      return transaction.isCredited === true;
    });

    setTransactionsFiltered(filtered);
  };

  const filterWinning = () => {
    let filtered = recons.filter((transaction) => {
      return transaction.transactionType === "Commission";
    });

    setTransactionsFiltered(filtered);
  };

  const filterPendingRecons = () => {
    let filtered = recons.filter((transaction) => {
      return transaction.isCredited === false;
    });

    setTransactionsFiltered(filtered);
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  let fnbTransactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  let pppTransactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  if (loading || loadingInitial)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    let transactionsItems =
      transactionsFiltered.length === 0 ? recons : transactionsFiltered;
    let length = 0;
    let kazangLength = recons.kazangOnlyRecords
      ? recons.kazangOnlyRecords.length
      : 0;

    kazangLength != 0
      ? (fnbTransactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Product</th>
                <th scope="col">Reference</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {recons.kazangOnlyRecords.map((transaction, index) => {
                if (index <= 20) {
                  length++;
                  return (
                    <tr key={index}>
                      <td>{transaction.transaction_date}</td>
                      <td>{transaction.transaction_time}</td>
                      <td>{transaction.meter_number}</td>
                      <td>{transaction.reciept_number}</td>
                      <td>{formatMoney(parseInt(transaction.amount))}</td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td>
                  Displaying {length} of {fnbOnlyCount} records.
                </td>
              </tr>
            </tbody>
          </table>
        ))
      : fnbTotalCount == 0
      ? (fnbTransactionList = (
          <div className={attachedClasses.join(" ")}>
            <h4>
              {!uploaded
                ? "Upload recon file below."
                : uploaded == "waiting"
                ? "Please wait, sending file for processing"
                : uploaded == "good"
                ? "File is processing, check recons later"
                : "File sending failed, try again"}
            </h4>
            <div
              className={"controls"}
              style={{ marginTop: "15px", width: "100%" }}
            >
              <input
                disabled={disabled}
                name="recon_file"
                placeholder="recon_file"
                type="file"
                accept=".xlsx"
                className="form-control"
                autoComplete="off"
                onChange={handleFileChange}
              />
            </div>
          </div>
        ))
      : (fnbTransactionList = (
          <h4>There are no missing recons at this time.</h4>
        ));

    length = 0;
    let pppLength = recons.kazangPppOnlyRecords
      ? recons.kazangPppOnlyRecords.length
      : 0;

    pppLength != 0
      ? (pppTransactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Product</th>
                <th scope="col">Reference</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {recons.kazangPppOnlyRecords.map((transaction, index) => {
                if (index <= 20) {
                  length++;
                  return (
                    <tr key={index}>
                      <td>{transaction.transaction_date}</td>
                      <td>{transaction.transaction_time}</td>
                      <td>{transaction.meter_number}</td>
                      <td>{transaction.reciept_number}</td>
                      <td>{formatMoney(parseInt(transaction.amount))}</td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td>
                  Displaying {length} of {pppOnlyCount} records.
                </td>
              </tr>
            </tbody>
          </table>
        ))
      : (pppTransactionList = (
          <h4>There are no missing recons at this time.</h4>
        ));
  }

  return (
    <div label={`Transactions`}>
      <div className={classes.Container}>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <div className={classes.ToolsWrapper}>
              <div className={classes.Tools}>
                <div className={classes.PageFilterTools}>
                  <form onSubmit={handleSubmit(onFormSubmit)}>
                    {/* <div
                  className={"row"}
                  style={{ borderRight: "solid 3px #707070" }}
                > */}
                    {/* <div className={"col-md-3"}>
                    <Field
                      type="text"
                      className={"form-control"}
                      style={{ marginBottom: 0 }}
                      component={TextInput}
                      placeholder="Outlet Id (ie 1000-01)"
                      name="outletId"
                      id="outletId"
                      autoComplete="off"
                    />
                  </div> */}

                    {/* <div className={"col-md-12"}> */}
                    <div className={"row"}>
                      {/* <DateRangePicker
                      startDate={startDate} // momentPropTypes.momentObj or null,
                      startDateId="startDate" // PropTypes.string.isRequired,
                      endDate={endDate} // momentPropTypes.momentObj or null,
                      endDateId="endDate" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      } // PropTypes.func.isRequired,
                      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={onFocusChangeRangeHandler}
                      numberOfMonths={1}
                      isOutsideRange={(date) => date.isAfter(today)}
                      displayFormat={"YYYY-MM-DD"}
                      minimumNights={0}
                      small={true}
                      readOnly={true}
                    /> */}
                      <DateRangePicker
                        startDate={startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => {
                          setStartDate(startDate);
                          setEndDate(endDate);
                        }} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={(date) => date.isAfter(yesterday)}
                        displayFormat={"YYYY-MM-DD"}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />
                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>{" "}
                      {/* </div> */}
                    </div>
                    {/* </div> */}
                  </form>
                  {/* 
              <div className={classes.PageFilter}>

                <div className={attachedClasses.join(" ")}>
                  <label>Account Number</label>
                  <div className={"controls"}>
                    <input
                      type="text"
                      className={classes.FormControl}
                      placeholder="Filter by Account"
                      value={accountSearchText}
                      onChange={filterByAccount}
                    />
                  </div>
                </div>
              </div> */}

                  {/* <!-- END Form Control--> */}
                </div>
              </div>
            </div>
          </div>
          <div className={"col-md-6"}>
            <div
              className={classes.ExportReport}
              style={{ float: "right", marginTop: 10 }}
            >
              <div
                className={classes.ExportIconWrapper}
                onClick={() =>
                  createNewExcelFile(
                    "PrepaidPlus Kazang Recon Report",
                    recons,
                    fnbReconSheet,
                    "First National Bank"
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                  focusable="false"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-md-6"}>
            <h2>Kazang</h2>
            <div
              className={classes.TableWrapper}
              style={{ flexDirection: "column" }}
            >
              <div>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      {/* <th scope="col">Header</th>
                      <th scope="col">Recons</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.Totals}>
                      <td>Total Sales Value</td>
                      <td>{formatMoney(fnbTotalValue)}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Missing Sales Value</td>
                      <td>{formatMoney(fnbMissingValue)}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Total Sales Count</td>
                      <td>{fnbTotalCount}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Missing Sales Count</td>
                      <td>{fnbOnlyCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ display: "flex", marginTop: "3rem" }}>
                {fnbTransactionList}
              </div>
            </div>
          </div>
          <div className={"col-md-6"}>
            <h3>PrepaidPlus</h3>
            <div
              className={classes.TableWrapper}
              style={{ flexDirection: "column" }}
            >
              <div>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      {/* <th scope="col">Header</th>
                      <th scope="col">Recons</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={classes.Totals}>
                      <td>Total Sales Value</td>
                      <td>{formatMoney(pppTotalValue)}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Missing Sales Value</td>
                      <td>{formatMoney(pppMissingValue)}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Total Sales Count</td>
                      <td>{pppTotalCount}</td>
                    </tr>
                    <tr className={classes.Totals}>
                      <td>Missing Sales Count</td>
                      <td>{pppOnlyCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div style={{ display: "flex", marginTop: "3rem" }}>
                {pppTransactionList}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  getKazangTransactionsForDashboard,
  clearTodaysRecons,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state) => ({
  recons: state.recons,
  fnbTotalValue: state.recons.kazangTotalValue,
  fnbMissingValue: state.recons.kazangMissingValue,
  fnbTotalCount: state.recons.kazangTotalCount,
  fnbOnlyCount: state.recons.kazangOnlyCount,
  fnbOnlyRecords: state.recons.kazangOnlyRecords,
  pppTotalValue: state.recons.kazangPppTotalValue,
  pppMissingValue: state.recons.kazangPppMissingValue,
  pppTotalCount: state.recons.kazangPppTotalCount,
  pppOnlyCount: state.recons.kazangPppOnlyCount,
  pppOnlyRecords: state.recons.kazangPppOnlyRecords,
  requesting: state.firestore.status.requesting,
  initialValues: {
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(transactions)
);
