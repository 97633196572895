import React, { Component } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";

// const modalRoot = document.getElementById("modal-root");

class Modal extends Component {
  attachedModalClasses = [
    "modal",
    "fade",
    "slide-up",
    "disable-scroll",
    "show"
  ];
  attachedFormGroupDefault = "form-group form-group-default";
  attachedModalHeader = "modal-header clearfix text-left";
  attachedClose = [styles.close];
  attachedPGClose = "pg-close fs-14";

  render() {
    return (
      <div
        className={this.attachedModalClasses.join(" ")}
        style={{ display: "block" }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content-wrapper">
            <div className="modal-content" style={{border:"none",backgroundColor:"transparent"}}>
              <div className={this.attachedModalHeader}>
                
                
                {/* <p className="p-b-10">
                We need information in order to create a new member.padding-20
              </p> */}
              </div>

              <div className="modal-body">
                <div className="padding-5">{this.props.children}</div>
              </div>

              {/* {this.props.children}</div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
