import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import Tabs from "../../../app/UI/Tabs/Tabs";

import { reduxForm, Field } from "redux-form";
import TextInput from "../../../app/common/form/TransactionsTextInput";

import { openModal } from "../../modals/modalActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import {
  createReportExcelFile,
  createNewExcelFile,
} from "../../../app/excel/excel";
import {
  getTransactionsForDashboard,
  clearSMSTransactions,
} from "../smsActions";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import SMSListItem from "./details";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let cellphoneSearchText = "";

const transactions = ({
  handleSubmit,
  getTransactionsForDashboard,
  clearSMSTransactions,
  transactions,
  requesting,
  initialValues,
}) => {
  let [expanded, setExpanded] = useState(false);
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment().endOf("day"));
  let [today, setToday] = useState(moment());
  let [transactionsFiltered, setTransactionsFiltered] = useState([]);
  let [selectedRow, setSelectedRow] = useState(-1);
  const [focusedInput, setFocusedInput] = useState(null);

  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let transactionsItems =
    transactionsFiltered.length === 0 ? transactions : transactionsFiltered;

  useEffect(() => {
    console.log("startOf", moment().startOf("day").toDate());
    console.log(
      "initialValues.reportStartDate",
      moment().endOf("day").toDate()
    );

    // transactionStartDate: moment().startOf("day"),
    // transactionEndDate: moment().endOf("day"),

    getTransactionsForDashboard({
      startDate: moment().startOf("day").toDate(),
      endDate: moment().endOf("day").toDate(),
    }).then(() => {
      setLoadingInitial(false);
    });

    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    return () => {
      clearSMSTransactions();
    };
  }, [expanded]);

  const transactionsItemsreportSheet = async (
    transactionsItems,
    workbook,
    logo,
    accountName
  ) => {
    let _smssheet = workbook.addWorksheet("ReceiptIT Unallocated _smss", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 18,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    _smssheet.mergeCells("A1", "G2");
    _smssheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    _smssheet.mergeCells("A4", "G4");
    _smssheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "ReceiptIT Unallocated _smss Report",
        },
      ],
    };

    _smssheet.mergeCells("A6", "B6");
    _smssheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Start date: ",
        },
      ],
    };

    _smssheet.mergeCells("A7", "B7");
    _smssheet.getCell("A7").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "End date: ",
        },
      ],
    };

    _smssheet.mergeCells("C6", "E6");
    _smssheet.getCell("C6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(startDate.toDate())
            .startOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    _smssheet.mergeCells("C7", "E7");
    _smssheet.getCell("C7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(endDate.toDate())
            .endOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    _smssheet.mergeCells("A10", "C10");
    _smssheet.mergeCells("D10", "F10");
    _smssheet.mergeCells("G10", "I10");
    _smssheet.mergeCells("J10", "L10");
    _smssheet.mergeCells("M10", "O10");
    _smssheet.getCell("A10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Date Time",
        },
      ],
    };
    _smssheet.getCell("D10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Cellphone",
        },
      ],
    };
    _smssheet.getCell("G10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Channel",
        },
      ],
    };

    _smssheet.getCell("J10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Status",
        },
      ],
    };

    _smssheet.getCell("M10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Provider",
        },
      ],
    };

    let _index__smssheet = 0;
    transactionsItems.forEach((_sms) => {
      _smssheet.mergeCells(
        `A${11 + _index__smssheet}`,
        `C${11 + _index__smssheet}`
      );
      _smssheet.mergeCells(
        `D${11 + _index__smssheet}`,
        `F${11 + _index__smssheet}`
      );
      _smssheet.mergeCells(
        `G${11 + _index__smssheet}`,
        `I${11 + _index__smssheet}`
      );
      _smssheet.mergeCells(
        `J${11 + _index__smssheet}`,
        `L${11 + _index__smssheet}`
      );
      _smssheet.mergeCells(
        `M${11 + _index__smssheet}`,
        `O${11 + _index__smssheet}`
      );

      _smssheet.getCell(`A${11 + _index__smssheet}`).value = `${format(
        _sms.dateCreated.toDate(),
        "YYYY-MM-DD  HH:mm:ss"
      )}`;
      _smssheet.getColumn(4).numFmt = "0";
      _smssheet.getCell(
        `D${11 + _index__smssheet}`
      ).value = `${_sms.cellphoneNumber}`;

      _smssheet.getCell(`G${11 + _index__smssheet}`).value = `${_sms.service}`;

      _smssheet.getCell(`J${11 + _index__smssheet}`).value = `${_sms.status}`;

      _smssheet.getCell(`M${11 + _index__smssheet}`).value = `Mascom`;

      _index__smssheet++;
    });
  };

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  let transactionRowClasses = null;
  let transactionDetailPanelOpenClasses = [classes.RevealMore];

  const loading = requesting[`merchants`];

  !selectedRow
    ? (transactionRowClasses = [classes.MerchantRow])
    : (transactionRowClasses = [
        classes.MerchantRow,
        classes.SelectedTransaction,
      ]);

  const onFormSubmit = async (values) => {
    let transactionStartDate = startDate.startOf("day").toDate();
    let transactionEndDate = endDate.endOf("day").toDate();

    setLoadingInitial(true);
    await getTransactionsForDashboard({
      startDate: transactionStartDate,
      endDate: transactionEndDate,
    });
    setLoadingInitial(false);
  };

  // console.log("sortedTransactions", sortedTransactions);

  const filterByCellphone = (cellphone) => {
    let keyword = cellphone.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.cellphoneNumber.toString().indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    cellphoneSearchText = keyword;
  };

  const filterDelivered = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.status === "DeliveredToTerminal";
    });

    setTransactionsFiltered(filtered);
  };

  const filterAll = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction;
    });

    setTransactionsFiltered(filtered);
  };

  const filterPendingDelivery = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.status === "Sent";
    });

    setTransactionsFiltered(filtered);
  };

  const filterFailed = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.status === "Failed";
    });

    setTransactionsFiltered(filtered);
  };

  const filterOther = () => {
    let filtered = transactions.filter((transaction) => {
      return (
        transaction.status !== "DeliveredToTerminal" &&
        transaction.status !== "Sent" &&
        transaction.status !== "Failed"
      );
    });

    setTransactionsFiltered(filtered);
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  let transactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  let smsCount;
  let deliveredCount;
  let pendingDeliveryConfirmationCount;
  let failedCount;
  let otherCount;

  if (loading || loadingInitial)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    smsCount = transactions.reduce(function (total, doc) {
      total += 1 ? 1 : 0;

      return total;
    }, 0);

    deliveredCount = transactions
      .filter((item) => item.status === "DeliveredToTerminal")
      .reduce(function (total, doc) {
        total += 1 ? 1 : 0;

        return total;
      }, 0);

    pendingDeliveryConfirmationCount = transactions
      .filter((item) => item.status === "Sent")
      .reduce(function (total, doc) {
        total += 1 ? 1 : 0;

        return total;
      }, 0);

    failedCount = transactions
      .filter((item) => item.status === "Failed")
      .reduce(function (total, doc) {
        total += 1 ? 1 : 0;

        return total;
      }, 0);

    otherCount = transactions
      .filter(
        (item) =>
          item.status !== "DeliveredToTerminal" &&
          item.status !== "Sent" &&
          item.status !== "Failed"
      )
      .reduce(function (total, doc) {
        total += 1 ? 1 : 0;

        return total;
      }, 0);

    cellphoneSearchText.length > 0 && transactionsFiltered.length === 0
      ? (transactionList = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5>
                There are no transactions found for query {cellphoneSearchText}
              </h5>
            </div>
          </div>
        ))
      : transactionsItems.length > 0
      ? (transactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date Time</th>
                <th scope="col">Cellphone</th>
                <th scope="col">Channel</th>
                <th style={{ textAlign: "left" }} scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {transactionsItems
                .sort((a, b) => b.dateCreated.toDate() - a.dateCreated.toDate())
                .map((transaction, index) => {
                  return <SMSListItem key={index} transaction={transaction} />;
                })}
            </tbody>
          </table>
        ))
      : (transactionList = <h4>There are no sms at this time.</h4>);
  }

  return (
    <div label={`Transactions`}>
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilterTools}>
              <div>
                <form onSubmit={handleSubmit(onFormSubmit)}>
                  <div style={{ borderRight: "solid 3px #707070" }}>
                    <div className={"col-md-12"}>
                      <div
                        style={{
                          display: "inline-flex",
                          width: "max-content",
                        }}
                      >
                        <DateRangePicker
                          startDate={startDate} // momentPropTypes.momentObj or null,
                          startDateId="startDate" // PropTypes.string.isRequired,
                          endDate={endDate} // momentPropTypes.momentObj or null,
                          endDateId="endDate" // PropTypes.string.isRequired,
                          onDatesChange={({ startDate, endDate }) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                          }} // PropTypes.func.isRequired,
                          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                          onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                          numberOfMonths={1}
                          isOutsideRange={(date) => date.isAfter(today)}
                          displayFormat={"YYYY-MM-DD"}
                          minimumNights={0}
                          small={true}
                          readOnly={true}
                        />
                        <button
                          className={classes.FilterReportBtn}
                          style={{ marginLeft: "1rem" }}
                          type="submit"
                        >
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                id="path1"
                                transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                fill="#FFFFFF"
                                d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                              />
                            </g>
                          </svg>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className={classes.PageFilter}>
                {/* <h4 className="m-r-20"> Search:</h4> */}

                {/* <!-- START Form Control--> */}
                <div className={attachedClasses.join(" ")}>
                  <label>Cellphone Number</label>
                  <div className={"controls"}>
                    <input
                      type="text"
                      className={classes.FormControl}
                      placeholder="Filter by Cellphone"
                      value={cellphoneSearchText}
                      onChange={filterByCellphone}
                    />
                  </div>
                </div>
              </div>

              {/* <!-- END Form Control--> */}
            </div>
            <div
              style={{
                marginTop: "15px",
                // marginBottom: "50px",
                marginLeft: "40%",
                float: "right",
                cursor: "pointer",
              }}
              className={classes.ToolsExportIconWrapper}
              onClick={() =>
                createNewExcelFile(
                  "SMS Report",
                  transactionsItems,
                  transactionsItemsreportSheet,
                  "SMS Report"
                )
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div className={classes.MerchantTableWrapper}>
          <div
            className={classes.TableWrapper}
            style={{ flexDirection: "column" }}
          >
            <div>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Total SMS Count</th>
                    <th scope="col">Delivered</th>
                    <th scope="col">Pending Delivery(Sent)</th>
                    <th scope="col">Failed</th>
                    <th scope="col">Other Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.Totals}>
                    <td onClick={() => filterAll()}>{smsCount}</td>
                    <td onClick={() => filterDelivered()}>{deliveredCount}</td>
                    <td onClick={() => filterPendingDelivery()}>
                      {pendingDeliveryConfirmationCount}
                    </td>
                    <td onClick={() => filterFailed()}>{failedCount}</td>
                    <td onClick={() => filterOther()}>{otherCount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ display: "flex", marginTop: "3rem" }}>
              {transactionList}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  getTransactionsForDashboard,
  clearSMSTransactions,
  openModal,
  createReportExcelFile,
  createNewExcelFile,
};

const mapStateToProps = (state) => ({
  transactions: state.sms,
  requesting: state.firestore.status.requesting,
  initialValues: {
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(transactions)
);
