import React, { useState, useEffect, Fragment } from "react";
// import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
// import { formatMoney } from "../../../../app/common/util/helpers";
// import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const deviceListItem = (props) => {
  let { inventoryItem, index } = props;
  // console.log("inventoryItem", inventoryItem);
  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };
  const status = (item) => {
    if (item.allocatedTo) {
      return "Paired";
    }
    if (item.assignedSIMId) {
      return "Assigned";
    }
    if (!item.assignedSIMId) {
      return "Available";
    } else {
      return "";
    }
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>{`${inventoryItem.manufacturer} - ${inventoryItem.model}`}</td>
        <td>{inventoryItem.serialNumber}</td>
        <td>{inventoryItem.macAddress}</td>

        <td>{inventoryItem.cellNumber ? inventoryItem.cellNumber : "N/A"}</td>
        <td>{inventoryItem.isAllocated ? inventoryItem.allocatedTo : `N/A`}</td>
        <td>{status(inventoryItem)}</td>
        <td>
          {format(
            inventoryItem.dateCreated && inventoryItem.dateCreated.toDate(),
            "YYYY-MM-DD"
          )}
        </td>
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="6">
            <Detail device={inventoryItem} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default deviceListItem;
