import React from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";

import { updatePettyCash } from "../../pettyCashActions";

const application = (props) => {
  let { transaction, profile, auth, loading, openModal, closeExpanded } = props;

  return (
    <table className={classes.FuneralSchemeTable}>
      <thead>
        <tr>
          <th className={"hide-s"} scope="col">
            Amount
          </th>

          <th className={"hide-s"} scope="col">
            Status
          </th>
          <th className={"hide-s"} scope="col">
            {""}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className={classes.MemberRow}>
          <td>{transaction.amount}</td>
          <td>
            Awaiting authorisation. Authorisers:{" "}
            {transaction.approvers.map((approver, index) => {
              console.log("approver", approver);
              return (
                <>
                  <b>{approver}</b>
                  <span>{"; "}</span>
                </>
              );
            })}
          </td>

          <td align="right">
            <button
              className={classes.AddUserBtn}
              // onSubmit={() => {
              //   handleClick();
              // }}
              type="button"
              onClick={() =>
                openModal("PettyCashAmendRequestModal", {
                  auth: auth,
                  profile: profile,
                  transaction: transaction,
                })
              }
              style={{ width: "21rem" }}
            >
              {loading ? (
                <Spinner
                  style={{
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                  }}
                />
              ) : (
                <span>Amend Request</span>
              )}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(mapStateToProps, actions)(application);
