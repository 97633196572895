import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import { compose } from "redux";

import { asyncInventorySIMValidate as asyncValidate } from "../../../app/common/validation/asyncValidate.jsx";
import AsyncTextInput from "../../../app/common/form/AsyncTextInput";
// import validate from "./validate";
import { createSIMInventoryItem } from "../inventoryActions";

const validate = combineValidators({
  network: isRequired("network"),
  cellNumber: isRequired("cellNumber"),
  simNumber: isRequired("simNumber"),
  pinCode: isRequired("pinCode"),
  pin2Code: isRequired("pin2Code"),
  pukNumber: isRequired("pukNumber")
});

const RegisterSIMCard = ({
  createSIMInventoryItem,
  handleSubmit,
  error,
  invalid,
  submitting,
  merchantId
}) => {
  const onFormSubmit = values => {
    createSIMInventoryItem(values);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="Network"
            name="network"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Network"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="Cell Number"
            name="cellNumber"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="CellNumber"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="SIM Number"
            name="simNumber"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="SIM Number"
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="PIN Code"
            name="pinCode"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="PIN Code"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="PIN 2 Code"
            name="pin2Code"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="PIN 2 Code"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="PUK Number"
            name="pukNumber"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="PUK Number"
            required="required"
          />
        </div>
      </div>

      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}

      <button className={"btn btn-primary btn-cons m-t-10"}>
        Register SIM Card
      </button>
    </form>
  );
};

const mapStateToProps = state => {
  let SIM = {};

  SIM = state.firestore.ordered.inventory.filter(
    item => item.isAllocated === false && item.deviceType === "SIM"
  );
  return {
    SIM
  };
};

const actions = {
  createSIMInventoryItem
};

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerSIMCard",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["cellNumber", "simNumber"]
  })
)(RegisterSIMCard);
