import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import firebase from "../../../../app/config/firebase";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import TextInput from "../../../../app/common/form/TextInput";
import {
  createValidator,
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";
import styles from "./EnableDevice.module.css";
import { activateDevice } from "../../../merchants/merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    }),
    hasLengthBetween(
      4,
      4
    )({
      message: "Must be 4 numbers",
    })
  )(),
});

var storage = firebase.storage();

class EnableDevice extends Component {
  state = {
    //showSideDrawer: false
    agreementUrl: "",
  };

  async componentDidMount() {
    storage
      .ref()
      .child(
        `${this.props.account}/${this.props.outletId}/devicecontracts/${this.props.outletId}DeviceContract`
      )
      .getDownloadURL()
      .then((url) => {
        console.log("agreementRefUrl", url);

        this.setState({
          agreementUrl: url,
        });
      });
  }

  render() {
    const {
      activateDevice,
      handleSubmit,
      loading,
      closePanel,
      deviceId,
      outletId,
      account,
    } = this.props;

    const onFormSubmit = (values) => {
      activateDevice(deviceId);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Outlet Id: <span className={"semi-bold"}>{outletId}</span>
              </h5>
              <h5>
                Agreement:
                <a
                  className={"semi-bold"}
                  href={this.state.agreementUrl}
                  target="_blank"
                >
                  {`${outletId} Device Agreement`}
                </a>
              </h5>
              <br />

              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Enable Device</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  activateDevice,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  })(EnableDevice)
);
