import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

class Sales extends Component {
  render() {
    const { salesTotal, commissionTotal, totalsByProvider } = this.props;

    let salesRecords = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    totalsByProvider.length > 0
      ? (salesRecords = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Sale Type</th>
                <th scope="col">Provider</th> 
                {/* <th scope="col">Vendor</th> */}
                <th scope="col">Total</th>
              </tr>
            </thead>
            <tbody>
              {totalsByProvider
                // .sort((a, b) => {
                //   if (a.provider < b.provider) {
                //     return -1;
                //   }
                //   if (a.provider > b.provider) {
                //     return 1;
                //   }

                //   return 0;
                // })
                .map((sale, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>{sale.saleType}</td>
                      <td>{sale.provider}</td> 
                      {/* <td>{sale.vendor}</td> */}
                      <td>{formatMoney(sale.transactionAmount)}</td>
                    </tr>
                  );
                })}

              <tr className={classes.Totals}>
                <td className={classes.MoneyTotal}>&nbsp;</td> 
                <td>
                  <div className={classes.MoneyTotalLabel}> Total:</div>
                </td>
                <td className={classes.MoneyTotal}>
                  {formatMoney(salesTotal)}
                </td>
              </tr>
            </tbody>
          </table>
        ))
      : (salesRecords = (
          <h4>
            There are no <strong>sales</strong> for the selected period.
          </h4>
        ));

    return (
      // <div className={classes.Container}>
      //   <header className={classes.TabHeader}>
      //     <div className={classes.PageHeader}>
      //       <h4>Sales</h4>
      //     </div>
      //   </header>

      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>{salesRecords}</div>
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  salesTotal: state.financials.salesTotal,
  totalsByProvider: state.financials.totalsByProvider,
});

export default connect(mapStateToProps)(Sales);
