
import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";
import MerchantActivityReportSpinner from "../reports/Cachet/MerchantActivityReport/MerchantActivityReportSpinner";

const actions = {
  closeModal
};

const MerchantActivityReportModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
   <MerchantActivityReportSpinner/>
    </TransparentModal>
  );
};

export default connect(
  null,
  actions
)(MerchantActivityReportModal);

