import React, { useState } from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";

import {
  updatePettyCash,
  uploadPettyCashDocumentation,
} from "../../pettyCashActions";
import { Field, reduxForm } from "redux-form";

const FileInput = ({
  input: { value: omitValue, ...inputProps },
  meta: omitMeta,
  ...props
}) => {
  let attachedClasses = ["form-group-default", props.required];
  return (
    <div className={attachedClasses.join(" ")}>
      <div className={"controls"}>
        <input
          type="file"
          accept="application/pdf"
          {...inputProps}
          {...props}
          style={{ paddingTop: 7 }}
        />
      </div>
    </div>
  );
};
const complete = (props) => {
  let {
    transaction,
    profile,
    auth,
    loading,
    openModal,
    handleSubmit,
    closeExpanded,
    uploadPettyCashDocumentation,
  } = props;
  let [expanded, setExpanded] = useState(true);

  const onFormSubmit = async (values) => {
    console.log("values", values);

    values.msgId = transaction.msgId;
    values.currentUser = profile.displayName;
    values.id = transaction.id;

    console.log("values !!!!!! authorised", values);

    // setLoadingInitial(true);
    await uploadPettyCashDocumentation(values);
    closeExpanded(false);

    // setLoadingInitial(false);
  };

  return (
    <>
      <table className={classes.FuneralSchemeTable}>
        <thead>
          <tr>
            <th className={"hide-s"} scope="col">
              Amount
            </th>

            <th className={"hide-s"} scope="col">
              Status
            </th>
            <th className={"hide-s"} scope="col">
              Actioned By
            </th>
            <th className={"hide-s"} scope="col">
              Approver notes
            </th>
            <th className={"hide-s"} scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.MemberRow}>
            <td>{transaction.amount}</td>
            <td>{transaction.status}</td>
            <td>{transaction.approvedBy}</td>
            <td>{transaction.approverNotes}</td>
            <td>
              <h5>
                <a href={transaction.documentationUrl} target="_blank">
                  View support document
                </a>
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
  uploadPettyCashDocumentation,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableUserForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(complete)
);
