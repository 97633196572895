import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import PettyCashRequestForm from "../pettycash/requestForm/pettyCashRequestForm";

const actions = { closeModal };

const pettyCashRequestModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Petty Cash Request" onClose={closeModal}>
      <PettyCashRequestForm />
    </Modal>
  );
};

export default connect(null, actions)(pettyCashRequestModal);
