import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AmendDepositForm from "../financials/DepositForm/AmendDepositForm";

const actions = { closeModal };

const amendDepositModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Amend Merchant Deposit" onClose={closeModal}>
      <AmendDepositForm />
    </Modal>
  );
};

export default connect(null, actions)(amendDepositModal);
