import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";

import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";
import styles from "./DisableOutletUser.module.css";
import { disableOutletUser } from "../../../../../merchantActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  password: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    }),
    hasLengthBetween(
      4,
      4
    )({
      message: "Must be 4 numbers",
    })
  )(),
});

const disableUser = (props) => {
  const { user, disableOutletUser, handleSubmit, loading, closePanel } = props;

  const onFormSubmit = (values) => {
    disableOutletUser(values, user.id);
    closePanel();
  };

  return (
    <div label="Deposits">
      <header className={styles.TabHeader}>
        <div className={styles.PageHeader}>
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <h4>
              Selected User:
              <span className={"semi-bold"}>{user.userId}</span>
            </h4>
            <br />
            {/* <br /> */}
            <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
              <ul className={"pager wizard no-style"}>
                <li className="next">
                  <button
                    className={styles.btn}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        style={{
                          width: "20px",
                          height: "20px",
                          verticalAlign: "middle",
                        }}
                      />
                    ) : (
                      <span>Disable User</span>
                    )}
                  </button>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </header>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
});
const actions = {
  disableOutletUser,
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "disableUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(disableUser)
);
