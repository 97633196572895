import { createReducer } from "../../app/common/util/reducerUtil";
import {
  FETCH_TODAYS_TRANSACTIONS,
  FETCH_UNALLOCATED_TRANSACTIONS,
} from "./depositsConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.transactions;
};
export const getUnallocatedTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.transactions;
};

export default createReducer(initialState, {
  [FETCH_TODAYS_TRANSACTIONS]: getTransactionsForDashboard,
  [FETCH_UNALLOCATED_TRANSACTIONS]: getUnallocatedTransactionsForDashboard,
});
