import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { actionPettyCash } from "../../../pettycash/pettyCashActions";

import PettyCashListItem from "./details";

import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

const index = ({
  handleSubmit,
  actionPettyCash,
  clearTodaysDeposits,
  pettyCashRequests,
  profile,
  // requesting,
  initialValues,
}) => {
  let [expanded, setExpanded] = useState(false);
  let [loadingInitial, setLoadingInitial] = useState(true);

  console.log("pettyCashRequests", pettyCashRequests);
  console.log("profile", profile);

  // const loading = requesting[`credit?where=status:==:"Credit Application"`];

  let transactionList = null;

  let transactionsItems = pettyCashRequests;

  if (!transactionsItems)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  transactionsItems.length > 0
    ? (transactionList = (
        <table className={classes.MerchantTable}>
          <thead>
            <tr>
              <th scope="col">Date Applied</th>
              <th scope="col">Amount Requested</th>
              <th scope="col">Name</th>
              <th scope="col">Reason</th>

              {/* <th scope="col">Reason</th> */}
              <th scope="col">Request Status</th>
            </tr>
          </thead>

          <tbody>
            {transactionsItems.map((transaction, index) => {
              return (
                <PettyCashListItem key={index} transaction={transaction} />
              );
            })}
          </tbody>
        </table>
      ))
    : (transactionList = <h4>There are no petty cash transactions.</h4>);

  return (
    <div label={`Transactions`}>
      <div className={classes.MerchantTableWrapper}>
        <div
          className={classes.TableWrapper}
          style={{ flexDirection: "column" }}
        >
          {transactionList}
        </div>
      </div>
    </div>
  );
};

let query = [
  {
    collection: "pettyCash",
    storeAs: "pettyCashRequests",
    where: [
      ["status", "in", ["Application", "Approved", "Authorised", "Returned"]],
    ],
  },
];

const mapStateToProps = (state) => ({
  pettyCashRequests: state.firestore.ordered.pettyCashRequests,
  requesting: state.firestore.status.requesting,
  profile: state.firebase.profile,
  initialValues: {
    // transactionStartDate: moment().startOf("day"),
    // transactionEndDate: moment().endOf("day"),
  },
});

const actions = {
  actionPettyCash,
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect(query)(index));
