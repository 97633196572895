import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../modals/modalActions";
import Tabs from "../../../app/UI/Tabs/Tabs";
import classes from "./index.module.css";
import Requests from "./requests";
import Upload from "./upload";
import { firestoreConnect } from "react-redux-firebase";

const index = ({ openModal, auth, profile }) => {
  const userRole = profile.role;
  const userType = profile.userType;
  return (
    // <Tabs>

    // </Tabs>
    <div label={`Transactions`}>
      <header className={classes.TabHeader}>
        <div className={classes.PageHeader}>
          <h4>Requests</h4>
        </div>
        {(userRole === "Administrator" || userRole === "Supervisor") &&
          userType === "PrepaidPlus" && (
            <div className={classes.AddUser}>
              <button
                className={classes.AddUserBtn}
                onClick={() =>
                  openModal("PettyCashRequestModal", {
                    auth: auth,
                    profile: profile,
                  })
                }
              >
                Make an Application
              </button>
            </div>
          )}
      </header>
      {/* <div label={`Un-Allocated`}>
        <UnAllocatedTransactions />
      </div> */}
      {/* <div label={`Manual Upload`}>
        <Upload />
      </div> */}
      <Requests />
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  openModal,
};

export default connect(
  mapStateToProps,
  actions
)(firestoreConnect([{ collection: "pettyCash" }])(index));

// export default index;
