import React from "react";
import { connect } from "react-redux";
import classes from "./detail.module.css";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";
import format from "date-fns/format";

import { updatePettyCash } from "../../pettyCashActions";

const escalated = (props) => {
  let { transaction, profile, auth, loading, openModal } = props;

  return (
    <table className={classes.FuneralSchemeTable}>
      <thead>
        <tr>
          <th className={"hide-s"} scope="col">
            Amount
          </th>

          <th className={"hide-s"} scope="col">
            Status
          </th>
          <th className={"hide-s"} scope="col">
            Actioned By
          </th>
          <th className={"hide-s"} scope="col">
            Date Actioned{" "}
          </th>
          <th className={"hide-s"} scope="col">
            Note(s)
          </th>
          <th className={"hide-s"} scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr className={classes.MemberRow}>
          <td>{transaction.amount}</td>
          <td>{transaction.status}</td>
          <td>{transaction.approvedBy}</td>
          <td>{transaction.actionedBy}</td>
          <td>{transaction.notes}</td>
        </tr>

        <tr>
          <td colSpan="5">
            <b>History</b>
          </td>
        </tr>

        {
          <tr>
            <td>
              <b>Application</b>
            </td>
            <td>Approved</td>
            <td>{transaction.createdBy}</td>{" "}
            <td>
              {format(transaction.dateCreated.toDate(), "YYYY-MM-DD HH:mm:ss")}
            </td>
            <td>{transaction.reason}</td>
          </tr>
        }

        {transaction.isPettyCashApproved ? (
          <tr>
            <td>
              <b>Approval</b>
            </td>
            <td>Approved</td>
            <td>{transaction.approvedBy}</td>{" "}
            <td>
              {format(transaction.dateApproved.toDate(), "YYYY-MM-DD HH:mm:ss")}
            </td>
            <td>{transaction.approvalNotes}</td>
          </tr>
        ) : (
          <tr>
            <td>
              <b>Approval</b>
            </td>
            <td>Rejected</td>
            <td>{transaction.approvedBy}</td>{" "}
            <td>
              {format(transaction.dateApproved.toDate(), "YYYY-MM-DD HH:mm:ss")}
            </td>
            <td>{transaction.approvalNotes}</td>
          </tr>
        )}
        {transaction.isPettyCashDocumentationSubmited ? (
          <tr>
            <td>
              <b>Documentation</b>
            </td>
            <td>
              <b>Submission</b>
            </td>
            <td>{transaction.approvedBy}</td>{" "}
            <td>
              {format(transaction.dateApproved.toDate(), "YYYY-MM-DD HH:mm:ss")}
            </td>
            {/* <td>Notes:{transaction.approvalNotes}</td> */}
            <td>{transaction.approvalNotes}</td>
          </tr>
        ) : (
          <tr>
            <td>
              <b>Documentation</b>
            </td>
            <td colSpan={4}>Petty cash documentation not submitted</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

// const actions = {
//   redeemWinningPurchase,
// };

const actions = {
  updatePettyCash,
  openModal,
};

// export default connect(mapStateToProps, actions)(detail);
export default connect(mapStateToProps, actions)(escalated);
