import React from 'react';
import {connect} from 'react-redux';
import {Route,Redirect} from 'react-router-dom';

const PrepaidPlusRoute = ({profile,isAuthenticated,
component:Component,
...rest}) => (
<Route {...rest} component={(props) => (
   (isAuthenticated && profile.account === "PrepaidPlus") ? (
<Component {...props} />
    ): (
        <Redirect to="/" />
    )
)}/>
);

const mapStateToProps = (state) => ({
    isAuthenticated: !!state.firebase.auth.uid,
    profile: state.firebase.profile
});

export default connect(mapStateToProps)(PrepaidPlusRoute)


// const PrivateRoute({ children, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           fakeAuth.isAuthenticated ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }