import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import moment from "moment";

import { formatMoney } from "../../../app/common/util/helpers";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
} from "revalidate";
import format from "date-fns/format";

import SelectInput from "../../../app/common/form/SelectInput";

import { amendDeposit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  deposit_method: composeValidators(customIsRequired)(),
  deposit_bank: composeValidators(customIsRequired)(),
  deposit_amount: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    })
  )(),
  deposit_date: composeValidators(customIsRequired)(),
});

const $ = window.$;

const depositBank = [
  {
    label: "First National Bank",
    value: "First National Bank",
  },
  {
    label: "Stanbic Bank",
    value: "Stanbic Bank",
  },
  ,
  {
    label: "ABSA",
    value: "ABSA",
  },
];

const depositType = [
  {
    label: "EFT",
    value: "EFT",
  },
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Cheque",
    value: "Cheque",
  },
];

class AmendDepositForm extends Component {
  state = {
    depositDate: null,
  };

  componentDidMount() {
    $(findDOMNode(this.depositDate)).datepicker({
      format: "yyyy-mm-dd",
      autoclose: true,
      startDate: "-365d",
      endDate: "+4d",
    });
    var _this = this;
    $(findDOMNode(this.depositDate)).on("changeDate", function (e) {
      _this.props.change("deposit_date", format(e.date, "YYYY-MM-DD"));
    });
  }

  componentWillUnmount() {
    $(findDOMNode(this.depositDate)).datepicker("destroy");
  }

  render() {
    const { initialValues, handleSubmit, amendDeposit, error } = this.props;

    console.log(" this.props", this.props);
    const {
      account,
      deposit_date,
      transactionAmount,
      reportStartDate,
      reportEndDate,
    } = initialValues;

    this.depositDate = format(deposit_date, "YYYY-MM-DD");

    console.log(" this.depositDate", this.depositDate);
    // this.depositDate.value = dateOfDeposit;

    const onFormSubmit = (values) => {
      amendDeposit(
        values,
        account,
        moment(reportStartDate).toDate(),
        moment(reportEndDate).endOf("day").toDate()
      );
    };

    console.log(
      "this.props.deposit_method",
      this.props.initialValues.deposit_method
    );
    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              name="deposit_bank"
              options={depositBank}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              placeholder="Bank"
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
          <div className={"col-sm-6"}>
            <Field
              name="deposit_method"
              options={depositType}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "neutral0",
                },
              })}
              component={SelectInput}
              className={"form-control"}
              required="true"
              aria-required="true"
            />
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <h5>Amount: {formatMoney(transactionAmount)}</h5>
          </div>
          <div className={"col-sm-6"}>
            <div className={"input-group date col-md-12 p-l-0"}>
              <Field
                type="text"
                className={"form-control"}
                component="input"
                placeholder="Pick Deposit date"
                name="deposit_date"
                ref={(input) => {
                  this.depositDate = input;
                }}
              />

              <div className={"input-group-append"}>
                <span className={"input-group-text"}>
                  <i className={"fa fa-calendar"} />
                </span>
              </div>
            </div>
          </div>
        </div>

        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={"btn btn-primary btn-cons m-t-10"}>
          Amend Deposit
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  initialValues: {
    reportStartDate: state.modals.modalProps.reportStartDate,
    reportEndDate: state.modals.modalProps.reportEndDate,
    deposit_bank: state.modals.modalProps.deposit.bank,
    account: state.modals.modalProps.deposit.account,
    merchantName: state.modals.modalProps.deposit.merchantName,
    bank: state.modals.modalProps.deposit.bank,
    deposit_date: format(
      state.modals.modalProps.deposit.dateOfDeposit,
      "YYYY-MM-DD"
    ),
    deposit_method: state.modals.modalProps.deposit.depositMethod,
    transactionAmount: state.modals.modalProps.deposit.transactionAmount,
    transactionCategory: "Credit",
    transactionType: "Deposit",
    depositType: "Bank Deposit",
    isTransactionCharged: false,
    id: state.modals.modalProps.deposit.id,
    reference: state.modals.modalProps.deposit.reference,
    createdBy: state.modals.modalProps.deposit.createdBy,

    // bank(pin):"First National Bank"
    // account(pin):"2727"
    // merchantName(pin):"Shell Metsimotlhabe"
    // dateOfDeposit(pin):"2022-07-12T22:00:00.000Z"
    // depositMethod(pin):"ELECTRONIC RECEIPT"
    // transactionAmount(pin):30000
    // transactionDateTime(pin):"2022-07-13T09:11:00.921Z"
  },
  enableReinitialize: true,
});

const actions = {
  amendDeposit,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "amendDepositForm", validate })(AmendDepositForm));
