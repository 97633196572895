import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import TextInput from "../../../app/common/form/TextInput";

import { updateNames } from "../../auth/authActions";
// import styles from "./CreateOutlet.module.css";

const validate = combineValidators({
  firstName: isRequired("displayName")
});

const changeUserName = ({
  updateNames,
  handleSubmit,
  error,
  invalid,
  submitting,
  merchantId,
  onClose
}) => {
  // let { users, loading } = this.props;

  const onFormSubmit = values => {
    updateNames(values);
    // props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <p>
        <strong>User Details</strong>
      </p>

      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="Display Name"
            name="displayName"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Display Name"
            required="required"
          />
        </div>
      </div>

      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}
      <br />
      <button className={"btn btn-primary btn-cons m-t-10"}>
        Amend User Name
      </button>
    </form>
  );
};

const actions = {
  updateNames
};

export default connect(
  null,
  actions
)(reduxForm({ form: "changeUserPasswordForm", validate })(changeUserName));
