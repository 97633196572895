import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
} from "revalidate";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
import styles from "./CreditForm.module.css";
import TextInput from "../../../app/common/form/TextInput";
import { createCredit } from "../financialActions";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  credit_limit: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    })
  )(),
});

class CreditForm extends Component {
  state = {
    expiryDate: null,
    creditExpiryDate: moment(),
    endDate: moment(),
    today: moment(),
  };

  render() {
    const { handleSubmit, loading, createCredit, error } = this.props;

    const onFormSubmit = (values) => {
      createCredit(values, this.state.creditExpiryDate);
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={"row clearfix"}>
          <div className={"col-sm-6"}>
            <Field
              label="Credit Limit"
              name="credit_limit"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Credit Limit Amount"
              required="required"
            />
          </div>
          <div className={"col-sm-6"}>
            <div className={"row"}>
              <SingleDatePicker
                date={this.state.creditExpiryDate} // momentPropTypes.momentObj or null
                onDateChange={(creditExpiryDate) =>
                  this.setState({ creditExpiryDate })
                } // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                id="creditExpiryDate" // PropTypes.string.isRequired
                numberOfMonths={1}
                isOutsideRange={(date) => date.isBefore(this.state.today)}
                displayFormat={"YYYY-MM-DD"}
                required={true}
                small={true}
                readOnly={true}
              />

              <button
                className={styles.FilterReportBtn}
                style={{ marginLeft: "1rem" }}
                type="submit"
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                      fill="#FFFFFF"
                      d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <br />

        {error && <label className={"error semi-bold"}>{error}</label>}

        <button className={styles.btn} type="submit" disabled={loading}>
          {loading ? (
            <Spinner
              style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
            />
          ) : (
            <span>Create Credit</span>
          )}
        </button>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  initialValues: {
    account: state.modals.modalProps.account,
    name: state.modals.modalProps.name,
  },
  enableReinitialize: true,
});

const actions = {
  createCredit,
  change,
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "creditForm", validate })(CreditForm));
