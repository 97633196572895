import axios from "axios";

let url = "";
console.log(
  "window.location.pathname, ",
  extractFirstPart(window.location.href)
);
if (extractFirstPart(window.location.href) == "localhost") {
  url = "https://us-central1-develop-prepaidplus.cloudfunctions.net";
} else if (extractFirstPart(window.location.href) == "portal-tps") {
  url = "https://us-central1-portal-tps.cloudfunctions.net";
} else if (extractFirstPart(window.location.href) == "develop-prepaidplus") {
  url = "https://us-central1-develop-prepaidplus.cloudfunctions.net";
} else if (extractFirstPart(window.location.href) == "portal-uat" || extractFirstPart(window.location.href) == "portal-uat-412914") {
  url = "https://us-central1-portal-uat-412914.cloudfunctions.net";
} else if (extractFirstPart(window.location.href) == "portal") {
  url = "https://us-central1-prod-prepaidplus.cloudfunctions.net";
} else {
  url = "http://localhost:9999/develop-prepaidplus/us-central1";
}

const instance = axios.create({
  baseURL: url,
});

function extractFirstPart(url) {
  const withoutProtocol = url.split("://")[1];
  const domainPart = withoutProtocol.split("/")[0];
  const firstPart = domainPart.split(".")[0];
  return firstPart;
}

export default instance;
