import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import MerchantTransferForm from "../financials/TransferForm/MerchantTransferForm";

const actions = { closeModal };

const intraMerchantTransferModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Intra Merchant Transfer" onClose={closeModal}>
      <MerchantTransferForm />
    </Modal>
  );
};

export default connect(null, actions)(intraMerchantTransferModal);
