import React, { Fragment, Component } from "react";
import LoadingComponent from "../../../../../../../app/layout/LoadingComponent";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";
import { firestoreConnect } from "react-redux-firebase";

import styles from "./GeneratePairingCode.module.css";
import {
  generatePairingCode,
  updatePairingCode,
} from "../../../../../merchantActions";

class PairingCode extends Component {
  render() {
    const {
      asyncLoading,
      device,
      outlet,
      merchantName,
      generatePairingCode,
      updatePairingCode,
      pairing,
      requesting,

      auth,
      profile,
    } = this.props;
    const loading =
      requesting[`inventory_pairing?where=registrationId:==:${device.id}`];
    let pairingCode;

    const authenticated = auth.isLoaded && !auth.isEmpty;
    const userRole = profile.role;
    const userType = profile.userType;

    console.log("outlet", outlet);

    const generatePairingCodeClick = () => {
      generatePairingCode(merchantName, outlet.name, device);
      // closePanel();
    };

    const updatePairingCodeClick = () => {
      updatePairingCode(merchantName, outlet.name, device, pairingCode);
    };

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (pairing) {
      pairingCode = pairing.length > 0 && pairing[0].pairingCode;
    }

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <h3>
              Code:
              <span className={"semi-bold"}>
                {pairingCode ? pairingCode : "None"}
              </span>
            </h3>

            {!pairingCode ? (
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={asyncLoading}
                      onClick={() => generatePairingCodeClick()}
                    >
                      {asyncLoading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Generate Code</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              //  {userRole === "Administrator" &&
              //                                     userType === "PrepaidPlus" && (

              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={asyncLoading}
                      onClick={() => updatePairingCodeClick()}
                    >
                      {asyncLoading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Update Code</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            // )}
            )}
          </div>
        </header>
      </div>
    );
  }
}

const query = (props) => {
  return [
    {
      collection: "inventory_pairing",
      where: ["registrationId", "==", props.device.id],
    },
  ];
};

const actions = {
  generatePairingCode,
  updatePairingCode,
};

const mapStateToProps = (state) => ({
  asyncLoading: state.async.loading,
  pairing: state.firestore.ordered.inventory_pairing,
  requesting: state.firestore.status.requesting,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "pairingCodeForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(firestoreConnect(query)(PairingCode))
);

// const mapStateToProps = state => ({
//   inventory: state.firestore.ordered.outlet_inventory,
//   requesting: state.firestore.status.requesting
// });

// const actions = {
//   fetchOutletInventory,
//   resetOutletInventory
// };

// export default connect(
//   mapStateToProps,
//   actions
// )(firestoreConnect(query)(OutletInventory));
