import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import WithdrawalForm from "../financials/WithdrawalForm/WithdrawalForm";

const actions = { closeModal };

const withdrawalModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Merchant Withdrawal" onClose={closeModal}>
      <WithdrawalForm />
    </Modal>
  );
};

export default connect(null, actions)(withdrawalModal);
