import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Field, reduxForm, change } from "redux-form";
import {
  combineValidators,
  composeValidators,
  isRequired,
  isNumeric,
  hasLengthBetween,
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";
import TextArea from "../../../app/common/form/TextArea";
import { amendPettyCashRequest } from "../pettyCashActions";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
// import { asyncValidateAndGetMerchantName } from "../../../app/common/validation/asyncValidate";

const customIsRequired = isRequired({ message: "Required" });

const validate = combineValidators({
  transfer_to_account: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    }),
    hasLengthBetween(
      4,
      8
    )({
      message: "Must be 4 digits",
    })
  )(),
  transfer_amount: composeValidators(
    customIsRequired,
    isNumeric({
      message: "Must be a number",
    })
  )(),
});

const pettyCashAmendRequestForm = (props) => {
  const {
    initialValues,
    handleSubmit,
    amendPettyCashRequest,
    error,
    approvers,
    loading,
  } = props;

  console.log("initialValues!!!!!", initialValues);
  console.log("approvers", approvers);

  const onFormSubmit = async (values) => {
    const approver = approvers.map((approver) => {
      return approver;
    });

    await amendPettyCashRequest(values, approver);
  };

  const clearFormSubmit = async () => {
    // console.log("clearFormSubmit");
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <>
        <div className={"row clearfix"}>
          <div className={"col-sm-4"}>
            <div
              className={"all-caps no-margin hint-text"}
              style={{ fontSize: "10px" }}
            >
              Approver
            </div>
            <strong>
              {approvers &&
                approvers.map((approver, index) => {
                  return <div>{approver}</div>;
                })}
            </strong>
          </div>
          <div className={"col-sm-4"}>
            <Field
              label="Amount Requested"
              name="amount"
              component={TextInput}
              className={"form-control"}
              placeholder="Amount"
              required="required"
            />
          </div>
        </div>

        <br />
        <div className={"row"} style={{ lineHeight: "3.8rem" }}>
          <div className={"col-md-12"}>
            <Field
              label="Reason for Request"
              name="reason"
              component={TextArea}
              type="text"
              className={"form-control"}
              placeholder="Reason for Request"
              required="required"
            />
          </div>
        </div>
      </>

      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      <button className={"btn btn-primary btn-cons m-t-10"}>
        {loading ? (
          <Spinner
            style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
          />
        ) : (
          <span>Submit Application</span>
        )}
      </button>
      <button
        onClick={() => clearFormSubmit()}
        className={"btn btn-danger btn-cons m-t-10"}
      >
        Cancel
      </button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    uid: state.firebase.auth.uid,
    createdBy: state.firebase.auth.displayName,
    approvers: state.modals.modalProps.transaction.approvers,
    amount: state.modals.modalProps.transaction.amount,
    reason: state.modals.modalProps.transaction.reason,
    id: state.modals.modalProps.transaction.id,
  },
  enableReinitialize: true,
  approvers: state.modals.modalProps.transaction.approvers,
  loading: state.async.loading,
  profile: state.modals.modalProps.profile,
  auth: state.modals.modalProps.auth,
});

const actions = {
  amendPettyCashRequest,
  change,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({ form: "pettyCashAmendRequestForm", validate })
)(pettyCashAmendRequestForm);
