import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import { reduxForm, Field } from "redux-form";

import { openModal } from "../../modals/modalActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import { createReportExcelFile } from "../../../app/excel/excel";
import {
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
} from "../competitonsActions";

import classes from "./index.module.css";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import CompetitionListItem from "./details";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let meterSearchText = "";

const transactions = ({
  handleSubmit,
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
  transactions,
  openModal,
  createReportExcelFile,
  requesting,
  outletId,
  initialValues,
}) => {
  let [expanded, setExpanded] = useState(false);
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());
  let [transactionsFiltered, setTransactionsFiltered] = useState([]);
  let [selectedTransaction, setSelectedTransaction] = useState(null);
  let [selectedRow, setSelectedRow] = useState(-1);

  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getTransactionsForDashboard({
      reportStartDate: new Date(initialValues.reportStartDate),
      reportEndDate: new Date(initialValues.reportEndDate),
    }).then(() => {
      setLoadingInitial(false);
    });

    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    return () => {
      clearTransactionsForDashboard();
    };
  }, [expanded]);

  let transactionRowClasses = null;
  let transactionDetailPanelOpenClasses = [classes.RevealMore];

  const loading = requesting[`merchants`];

  !selectedRow
    ? (transactionRowClasses = [classes.MerchantRow])
    : (transactionRowClasses = [
        classes.MerchantRow,
        classes.SelectedTransaction,
      ]);

  // const exportToExcel = async () => {
  //   openModal("SpinnerModal");

  //   await createReportExcelFile(
  //     "Transactions Report",
  //     transactions,
  //     transactionsSheet,
  //     "Prepaid Plus",
  //     startDate.toDate(),
  //     endDate.toDate()
  //   );
  // };

  const onFormSubmit = async (values) => {
    const outletId = values.outletId;
    let transactionStartDate = new Date(startDate);
    transactionStartDate.setHours(0, 0, 0, 0);
    let transactionEndDate = new Date(endDate);
    transactionEndDate.setHours(23, 59, 59, 999);

    setLoadingInitial(true);
    await getTransactionsForDashboard({
      transactionStartDate: transactionStartDate,
      transactionEndDate: transactionEndDate,
      outletId: outletId,
    });
    setLoadingInitial(false);
  };

  const filterByMeterNumber = (meterNumber) => {
    let keyword = meterNumber.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      return transaction.meterNumber.indexOf(keyword) > -1;
    });

    setTransactionsFiltered(filtered);

    meterSearchText = keyword;
  };

  const filterWinningPaid = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.transactionType === "Deposit";
    });

    setTransactionsFiltered(filtered);
  };

  const filterWinning = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.transactionType === "Commission";
    });

    setTransactionsFiltered(filtered);
  };

  const filterWinningPending = () => {
    let filtered = transactions.filter((transaction) => {
      return transaction.transactionType === "Reversal";
    });

    setTransactionsFiltered(filtered);
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  let transactionList = (
    <Fragment>
      <LoadingComponent inverted={true} style={{ width: "100%" }} />
    </Fragment>
  );

  let salesTotal;
  let winningTotal;
  let winningPaidTotal;
  let winningPendingTotal;
  let withdrawalsTotal;

  if (loading || loadingInitial)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    winningTotal = transactions.reduce(function (total, doc) {
      total += parseFloat(doc.transactionAmount)
        ? parseFloat(doc.transactionAmount)
        : 0;

      return total;
    }, 0);
    winningPaidTotal = transactions
      .filter((item) => item.isRedeemed === true)
      .reduce(function (total, doc) {
        total += parseFloat(doc.transactionAmount)
          ? parseFloat(doc.transactionAmount)
          : 0;

        return total;
      }, 0);

    winningPendingTotal = transactions
      .filter((item) => item.isRedeemed === false)
      .reduce(function (total, doc) {
        total += parseFloat(doc.transactionAmount)
          ? parseFloat(doc.transactionAmount)
          : 0;

        return total;
      }, 0);

    let transactionsItems =
      transactionsFiltered.length === 0 ? transactions : transactionsFiltered;

    meterSearchText.length > 0 && transactionsFiltered.length === 0
      ? (transactionList = (
          <div>
            <div style={{ paddingBottom: "30px" }}>
              <h5>
                There are no transactions found for query {meterSearchText}
              </h5>
            </div>
          </div>
        ))
      : transactionsItems.length > 0
      ? (transactionList = (
          <table className={classes.MerchantTable}>
            <thead>
              <tr>
                <th scope="col">Date Time</th>
                <th scope="col">Outlet</th>
                <th scope="col">Meter Number</th>
                <th scope="col">Winning Amount</th>
                <th scope="col">Is Paid Out</th>
              </tr>
            </thead>
            <tbody>
              {transactionsItems.map((transaction, index) => {
                return (
                  <CompetitionListItem key={index} transaction={transaction} />
                );
              })}
            </tbody>
          </table>
        ))
      : (transactionList = (
          <h4>There are no winnings to redeem at this time.</h4>
        ));
  }

  return (
    <div className={classes.Container}>
      <div className={classes.ToolsWrapper}>
        <div className={classes.Tools}>
          <div className={classes.PageFilterTools}>
            <div className={classes.PageFilter}>
              {/* <h4 className="m-r-20"> Search:</h4> */}

              {/* <!-- START Form Control--> */}
              <div className={attachedClasses.join(" ")}>
                <label>Meter Number</label>
                <div className={"controls"}>
                  <input
                    type="text"
                    className={classes.FormControl}
                    placeholder="Filter by Meter Number"
                    value={meterSearchText}
                    onChange={filterByMeterNumber}
                  />
                </div>
              </div>
            </div>

            {/* <!-- END Form Control--> */}
          </div>
          <div className={classes.PrintToolsWrapper}>
            <div className={classes.ToolsDataExportIconWrapper}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.MerchantTableWrapper}>
        <div
          className={classes.TableWrapper}
          style={{ flexDirection: "column" }}
        >
          <div>
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Winning Transactions</th>
                  <th scope="col">Paid Out</th>
                  <th scope="col">Pending Pay-out</th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.Totals}>
                  <td onClick={() => filterWinning()}>
                    {moneyFormat(winningTotal)}
                  </td>
                  <td onClick={() => filterWinningPaid()}>
                    {moneyFormat(winningPaidTotal)}
                  </td>
                  <td onClick={() => filterWinningPending()}>
                    {moneyFormat(winningPendingTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", marginTop: "3rem" }}>
            {transactionList}
          </div>
        </div>
      </div>
    </div>
  );
};

const actions = {
  getTransactionsForDashboard,
  clearTransactionsForDashboard,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state) => ({
  transactions: state.transactions,
  requesting: state.firestore.status.requesting,
  initialValues: {
    outletId: "",
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(transactions)
);
