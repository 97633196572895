import React, { Component } from "react";
import classes from "./MerchantForm.module.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withFirestore } from "react-redux-firebase";
import { Field, reduxForm, formValueSelector } from "redux-form";
import firebase from "../../../app/config/firebase";

import { createMerchant, updateMerchant} from "../merchantActions";
import MerchantDetails from "./FormSections/MerchantDetails";
import MerchantOutlet from "./FormSections/MerchantOutlet";
import ReviewMerchant from "./FormSections/ReviewMerchant";
import Commissions from "./FormSections/Commissions";

import initialState from "./initialState";

class MerchantForm extends Component {
  attachedFormGroupClasses = [classes.FormGroup, classes.FormGroupDefault];
  attachedCardClasses = ["card", "card-transparent"];
  attachedColClasses = "col-md-6";
  attachedWizardListClasses = ["cf"];
  attachedSplit1 = [classes.Split, classes.One];
  attachedSplit2 = [classes.Split, classes.Two];

  //currentStep = null;

  state = {
    files: [],
    fileName: "",
    omang: [],
    omangFileName: "",
    affidavit: [],
    affidavitFileName: "",
    merchantName: "",
    merchantId: "",
    merchantPass: "",
    merchantType: "",
    merchantCity: "",
    merchantSuburb: "",
    merchant_referral_code: "",
    merchant_contact_name: "",
    merchant_contact_email: "",
    merchant_contact_telephone: "",
    attachementError: false,
    idPassError: false,
    invalidIdError: false,
  };

  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.state = {
      page: 1,
    };
  }

  nextPage() {
    this.setState({ idPassError: false });
    if (
      (this.state.merchantId != "" && this.state.merchantId != undefined) ||
      (this.state.merchantPass != "" && this.state.merchantPass != undefined)
    ) {
    } else {
      this.setState({ idPassError: true });
    }
    if (
      this.state.omangFileName != "" &&
      this.state.omangFileName != undefined &&
      this.state.fileName != "" &&
      this.state.fileName != undefined &&
      this.state.affidavitFileName != "" &&
      this.state.affidavitFileName != undefined
    ) {
      if (
        (this.state.merchantId != "" && this.state.merchantId != undefined) ||
        (this.state.merchantPass != "" && this.state.merchantPass != undefined)
      ) {
        if (this.state.invalidIdError != true && this.state.invalidreferralcodeError != true) {
          this.setState({ idPassError: false });
          this.setState({ attachementError: false });
          this.setState({ page: this.state.page + 1 });
        }
      } else {
        this.setState({ idPassError: true });
      }
    } else {
      this.setState({ attachementError: true });
    }
  }

  onDrop = (files) => {
    this.setState({
      files,
      fileName: files[0].name,
    });
  };

  onDrop1 = (omang) => {
    this.setState({
      omang,
      omangFileName: omang[0].name,
    });
  };

  onDrop2 = (affidavit) => {
    this.setState({
      affidavit,
      affidavitFileName: affidavit[0].name,
    });
  };

  onMerchantName = (event) => {
    this.setState({
      merchantName: event.target.value,
    });
  };

  onMerchantId = (event) => {
    if (event.target.value != "") {
      const regex = /^\d{4}[12]\d{4}$/;
      if (regex.test(event.target.value)) {
        this.setState({
          merchantId: event.target.value,
        });
        this.setState({
          invalidIdError: false,
        });
      } else {
        this.setState({
          invalidIdError: true,
        });
      }
    } else {
      this.setState({
        invalidIdError: false,
      });
      this.setState({
        merchantId: event.target.value,
      });
    }
  };

  onmerchant_referral_code = async (event) => {
    const value = event.target.value;
    if (value !== "") {
      const regexLessThanThree = /^\d{3,}$/; // for 3 digits
      const regexGreaterThanFour = /^\d{4,}$/; //for 4 or more digits

      if (regexLessThanThree.test(value)) {
        this.setState({
          merchant_referral_code: value,
          invalidreferralcodeError: false,
        });
        if (regexGreaterThanFour.test(value)) {
          // TODO: Validate against merchants collection
          const referralCodeValidate = async (value) => {
            const firestore = firebase.firestore();
            return await firestore
              .collection("merchants").doc(value).get().then((doc)=>{
                if(doc.exists){
                  return true;
                }else{
                  return false;
                }
              })
          };

          const isValidMerchant = await referralCodeValidate(value);
  
          if (isValidMerchant) {
            this.setState({
              merchant_referral_code: value,
              invalidreferralcodeError: false,
            });
          } else {
            this.setState({
              invalidreferralcodeError: true,
            });
          }
        }
      } else {
        this.setState({
          invalidreferralcodeError: true,
        });
      }
      
    } else {
      this.setState({
        invalidreferralcodeError: false,
        merchant_referral_code: value,
      });
    }
  };

  onmerchantPass = (event) => {
    this.setState({
      merchantPass: event.target.value,
    });
  };

  onmerchantType = (event) => {
    this.setState({
      merchantType: event.target.value,
    });
  };

  onmerchantCity = (event) => {
    this.setState({
      merchantCity: event.target.value,
    });
  };

  onmerchantSuburb = (event) => {
    this.setState({
      merchantSuburb: event.target.value,
    });
  };

  onmerchant_contact_email = (event) => {
    this.setState({
      merchant_contact_email: event.target.value,
    });
  };

  onmerchant_contact_telephone = (event) => {
    this.setState({
      merchant_contact_telephone: event.target.value,
    });
  };

  onmerchant_contact_name = (event) => {
    this.setState({
      merchant_contact_name: event.target.value,
    });
  };

  previousPage() {
    this.setState({ page: this.state.page - 1 });
  }

  onFormSubmit = (values) => {
    let merchantAgreement = this.state.files ? this.state.files[0] : null;
    let merchantOmang = this.state.omang ? this.state.omang[0] : null;
    let merchantAffidavit = this.state.affidavit
      ? this.state.affidavit[0]
      : null;
     this.props.createMerchant(
      values,
      merchantAgreement,
      merchantOmang,
      merchantAffidavit
    );
  };

  render() {
    const { onSubmit } = this.props;
    const page = this.state.page;
    return (
      <div className={this.attachedCardClasses.join(" ")}>
        <div className={classes.CardBody}>
          <div id="rootwizard" className={classes.Wizard}>
            <div className={classes.TabContent}>
              {page === 1 && (
                <MerchantDetails
                  onDrop={this.onDrop}
                  onDrop1={this.onDrop1}
                  onDrop2={this.onDrop2}
                  files={this.state.files}
                  fileName={this.state.fileName}
                  omang={this.state.omang}
                  omangFileName={this.state.omangFileName}
                  affidavit={this.state.affidavit}
                  affidavitFileName={this.state.affidavitFileName}
                  merchantName={this.state.merchantName}
                  onMerchantName={this.onMerchantName}
                  merchantId={this.state.merchantId}
                  onMerchantId={this.onMerchantId}
                  merchantPass={this.state.merchantPass}
                  onmerchantPass={this.onmerchantPass}
                  merchantType={this.state.merchantType}
                  onmerchantType={this.onmerchantType}
                  merchantCity={this.state.merchantCity}
                  onmerchantCity={this.onmerchantCity}
                  merchantSuburb={this.state.merchantSuburb}
                  onmerchantSuburb={this.onmerchantSuburb}
                  merchant_referral_code={this.state.merchant_referral_code}
                  onmerchant_referral_code={this.onmerchant_referral_code}
                  merchant_contact_name={this.state.merchant_contact_name}
                  onmerchant_contact_name={this.onmerchant_contact_name}
                  merchant_contact_email={this.state.merchant_contact_email}
                  onmerchant_contact_email={this.onmerchant_contact_email}
                  merchant_contact_telephone={
                    this.state.merchant_contact_telephone
                  }
                  onmerchant_contact_telephone={
                    this.onmerchant_contact_telephone
                  }
                  onSubmit={this.nextPage}
                  attachementError={this.state.attachementError}
                  idPassError={this.state.idPassError}
                  invalidIdError={this.state.invalidIdError}
                  invalidreferralcodeError={this.state.invalidreferralcodeError}
                />
              )}
              {/* {page === 2 && (
                <Commissions
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )} */}
              {/* {page === 2 && (
                <MerchantOutlet
                  previousPage={this.previousPage}
                  onSubmit={this.nextPage}
                />
              )} */}
              {page === 2 && (
                <ReviewMerchant
                  previousPage={this.previousPage}
                  fileName={this.state.fileName}
                  omangFileName={this.state.omangFileName}
                  onSubmit={onSubmit}
                  onFormSubmit={this.onFormSubmit}
                  affidavit={onSubmit}
                  affidavitFileName={this.state.affidavitFileName}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default connect(mapState)(
//   reduxForm({ form: "merchantForm" })(MerchantForm)
// );
const actions = {
  createMerchant,
  updateMerchant,
};

export default withFirestore(
  connect(null, actions)(reduxForm({ form: "merchantForm" })(MerchantForm))
);
