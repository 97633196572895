import { closeModal } from "../modals/modalActions";
import { toastr } from "react-redux-toastr";
import Papa from "papaparse";
import moment from "moment";

import axios from "../../axios-firebase";
import firebase from "../../app/config/firebase";


import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";

import { SET_SUMMARY } from "./airtimeConstants";

const FieldValue = firebase.firestore.FieldValue;
let airtimeForUload;
let airtimeUploadSummary;
let total = 0;
const dateCreated = FieldValue.serverTimestamp();

export const uploadDecryptedAirtimeFile = (file) => async (dispatch) => {
  console.log("file", file);

  const config = {
    quotes: false, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: true,
    newline: "\r\n",
    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null, //or array of strings,
    complete: function (results, file) {
      console.log("Parsing complete:", results.data);
      validateTotals(results.data, dispatch);
    },
  };

  // const fileName = `airtime.csv`;



  try {
    dispatch(asyncActionStart());
    Papa.parse(file, config);

    dispatch(asyncActionFinish());
    dispatch(closeModal());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};

export const decryptFile = (file) => async (dispatch) => {
  console.log("file", file);

  

  // const fileName = `airtime.csv`;



  try {
    dispatch(asyncActionStart());
    
    const formData = new FormData();
    formData.append('file',file)
    const config = {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
  }

    let response = await axios
      .post("api/decrypt", formData,config)
      .then(response => {
        return response;
      });


    console.log('response.data',response.data)

    dispatch(asyncActionFinish());
    dispatch(closeModal());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};




// index.js

// import FormData from 'form-data';

// const ENDPOINT_URL = XXXXXXXXXXXXXXXXX;

// const postFile = async () => {

//     try {
//         const form_data = new FormData();
//         form_data.append('userName', 'Fred');
//         form_data.append('password', 'Flintstone');
//         const response = await axios.post(`${ENDPOINT_URL}/upload`, form_data);
//         console.log(response.data);
//     } catch (error) {
//         console.error(`Error: ${error}`);
//     }
// }

// postFile();

export const uploadAirtimeFile = () => {
  return async (dispatch) => {
    const firestore = firebase.firestore();

    try {
      dispatch(asyncActionStart());

      await loadAirtime(firestore, dispatch);
      console.log("airtimeForUload", airtimeForUload);

      toastr.success(`Airtime tokens worth ${total} uploaded`);
      total = 0;
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const getSummaryForDashboard = () => async (dispatch) => {
  try {
    dispatch(asyncActionStart());

    let response = await axios
      .post("/api/airtimeSummary", {})
      .then((response) => {
        return response;
      });

    const summary = response.data;

    console.log("response.data", summary);
    dispatch({ type: SET_SUMMARY, payload: { summary } });
    dispatch(asyncActionFinish());
  } catch (error) {
    if (error) {
      console.log(error);
    }
    dispatch(asyncActionError());
  }
};

const validateTotals = async (data, dispatch) => {
  const firestore = firebase.firestore();
  let filteredData = data.filter((item) => item.OrderNumber !== "");
  



  const summary = filteredData
    .map((doc) => {
      return {
        denomination: parseFloat(doc.Denomination),
        total: 0,
        orderNumberProviderDenomination:
          doc.OrderNumber + "-" + doc.Provider + "-" + doc.Denomination,
        orderNumber: doc.OrderNumber,
        batchNo: doc.BatchNoDateAndTimeLoaded,
        provider: doc.Provider,
        status: "Successful",
      };
    })
    .reduce(function (arr, doc) {
      if (!(doc.orderNumberProviderDenomination in arr)) {
        arr.push((arr[doc.orderNumberProviderDenomination] = doc));

        arr[doc.orderNumberProviderDenomination].total += doc.denomination ? doc.denomination : 0;

        total += doc.denomination ? doc.denomination : 0;
        return arr;
      }

      arr[doc.orderNumberProviderDenomination].total += doc.denomination
        ? doc.denomination
        : 0;

      total += doc.denomination ? doc.denomination : 0;
      console.log("total", total);
      return arr;
    }, []);


    let uploadId = `${summary[0].orderNumber}_${summary[0].provider}_${summary[0].denomination}`;
    
    console.log("uploadId", uploadId);

  
   let uploadExists = await firestore
    .collection("airtimeUpload")
    .where("uploadId", "==", uploadId)
    .limit(1)
    .get()
    .then((querySnapshot) => {

      console.log("querySnapshot.length", querySnapshot.size);
      if(querySnapshot.size > 0) {
        return true;
      } else {
        return false;
      }
    });

    console.log("uploadIdExists", uploadExists);



  airtimeForUload = filteredData;
  airtimeUploadSummary = summary;

  airtimeUploadSummary.uploadExists = uploadExists;

  console.log("airtimeUploadSummary", airtimeUploadSummary);
  dispatch({ type: SET_SUMMARY, payload: { summary } });
};

const loadAirtime = async (firestore, dispatch) => {
  let ref = firestore.collection("airtime");

  let airtimeUploadsRef = firestore.collection("airtimeUpload");
  // const dateCreated = new Date();

  const activeAirtimeBalanceRef = firestore.collection(`airtimeBalance`);

  let activeBalances = [];

  for (const summary of airtimeUploadSummary) {

    console.log("summary",summary)
    let activeBalance = await firestore
      .collection("airtimeBalance")
      .where("provider", "==", summary.provider)
      .where("denomination", "==", summary.denomination)
      .where("isActiveBalance", "==", true)
      .limit(1)
      .get()
      .then((querySnapshot) => {
        let balance;
        console.log("querySnapshot.size",querySnapshot.size)

        // {

        //   batchNo: summary.batchNo,
        //   transactionAmount: summary.total,
        //   balance: 0,
        //   isActiveBalance: true,
        //   denomination: parseFloat(summary.denomination),
        //   transactionType: "Deposit",
        //   provider: summary.provider,
        //   dateCreated: new Date(),
        //   recordId: moment(dateCreated).valueOf(),
        //   createdBy: "Prepaid Administrator",
        // };

        if (querySnapshot.size > 0) {
          let doc = querySnapshot.docs[0].data();

          doc.transactionId = querySnapshot.docs[0].id;
          balance = doc;
        }
        return balance;
      });
      
    
      console.log("activeBalance",activeBalance)

      if(activeBalance) {
         activeBalances.push(activeBalance);
      }
   

  }


  // ! register the purchase

  let counter = 0;
  let commitCounter = 0;
  let batches = [];
  batches[commitCounter] = firestore.batch();
  let runningBalance = [];

  airtimeForUload.forEach((row) => {
    let item = {
      orderNumber: row.OrderNumber,
      airtimeID: row.AirtimeID,
      provider: row.Provider,
      denomination: parseFloat(row.Denomination),
      serialNumber: row.SerialNumber,
      pinNumber: row.PINNumber,
      saleStatus: "Available",
      isAvailableForSale: true,
      batchNoDateAndTime: row.BatchNoDateAndTimeLoaded,
      dateLoaded: new Date(),
      transactionDateTime: new Date(),
    };

    if (counter <= 498) {
      let thisRef = ref.doc();
      batches[commitCounter].set(thisRef, item);
      counter = counter + 1;
    } else {
      counter = 0;
      commitCounter = commitCounter + 1;
      batches[commitCounter] = firestore.batch();
    }
  });

  airtimeUploadSummary.forEach((row) => {
    console.log("row", row);
    let item = {
      orderNumber: row.orderNumber,
      provider: row.provider,
      total: parseFloat(row.total),
      denomination: parseFloat(row.denomination),
      batchNo: row.batchNo,
      status: row.status,
      dateCreated: dateCreated,
      recordId: moment().valueOf(),
      createdBy: "Prepaid Administrator",
      uploadId: `${row.orderNumber}_${row.provider}_${row.denomination}`,
    };

    console.log("item", item);
    if (counter <= 498) {
      let thisRef = airtimeUploadsRef.doc();
      batches[commitCounter].set(thisRef, item);
      counter = counter + 1;
    } else {
      counter = 0;
      commitCounter = commitCounter + 1;
      batches[commitCounter] = firestore.batch();
    }
  });

  airtimeUploadSummary.forEach((row) => {
    let activeRecord = runningBalance.find((balance) => {
      return (
        balance.provider === row.provider &&
        balance.denomination === row.denomination &&
        balance.isActiveBalance === true
      );
    })
      ? runningBalance.find((balance) => {
          return (
            balance.provider === row.provider &&
            balance.denomination === row.denomination &&
            balance.isActiveBalance === true
          );
        })
      : activeBalances.find((balance) => {
          return (
            balance.provider === row.provider &&
            balance.denomination === row.denomination &&
            balance.isActiveBalance === true
          );
        }) ? activeBalances.find((balance) => {
          return (
            balance.provider === row.provider &&
            balance.denomination === row.denomination &&
            balance.isActiveBalance === true
          );
        }) : {balance:0};

    let activeBalance = activeRecord.balance;

    console.log("activeRecord", activeRecord);

    let item = {
      batchNo: row.batchNo,
      transactionAmount: parseFloat(row.total),
      balance: parseFloat(activeBalance) + parseFloat(row.total),
      isActiveBalance: true,
      denomination: parseFloat(row.denomination),
      transactionType: "Deposit",
      provider: row.provider,
      dateCreated: dateCreated,
      recordId: moment().valueOf(),
      createdBy: "Prepaid Administrator",
    };

    // console.log('item',item)
    if (counter <= 498) {
      let thisRef = activeAirtimeBalanceRef.doc();
      batches[commitCounter].set(thisRef, item);
      counter = counter + 1;
    } else {
      counter = 0;
      commitCounter = commitCounter + 1;
      batches[commitCounter] = firestore.batch();
    }
  });

  activeBalances.forEach((balance) => {
    console.log("balance", balance);

    console.log("balance.transactionId", balance.transactionId);

   
    if (counter <= 498) {
     let airtimeBalanceRef = firestore.collection("airtimeBalance").doc(balance.transactionId);

      batches[commitCounter].update(airtimeBalanceRef, {
        isActiveBalance: false
      });
      counter = counter + 1;
    } else {
      counter = 0;
      commitCounter = commitCounter + 1;
      batches[commitCounter] = firestore.batch();
    }



  });



  for (const batch of batches) {
    await batch.commit();

    console.count("wrote  batch");
  }

  let summary = [];

  dispatch({ type: SET_SUMMARY, payload: { summary } });
  airtimeForUload = null;
  airtimeUploadSummary = null;
};

export const clearSummary = () => async (dispatch) => {
  try {
    dispatch(asyncActionStart());

    let summary = [];

    dispatch({ type: SET_SUMMARY, payload: { summary } });

    dispatch(asyncActionFinish());
  } catch (error) {
    if (error) {
      console.log(error);
    }

    dispatch(asyncActionError());
  }
};
