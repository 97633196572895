import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
import { formatMoney } from "../../../../app/common/util/helpers";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const smsListItem = (props) => {
  let { transaction, index } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format( 
            transaction.dateCreated.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>{transaction.cellphoneNumber}</td>

        <td>{transaction.service}</td>
        <td style={{ textAlign: "left" }}>
        {transaction.status }

          {/* {transaction.status === "DeliveredToTerminal" ? "Delivered": transaction.status.trim() }
           */}
        </td>
      </tr>

      {/* <th scope="col">Date Time</th>
                <th scope="col">Account</th>
                <th scope="col">Channel</th>
                <th scope="col">Amount</th>
                <th scope="col">Is Credited</th> */}

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="6">
            <Detail transaction={transaction} closeExpanded={setExpanded} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default smsListItem;
