import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

import classes from "./OutletComputers.module.css";
import EnableComputer from "./Computers/EnableComputer";
import DisableComputer from "./Computers/DisableComputer";
import ChangeMACAddress from "./Computers/ChangeMACAddress";
import LoadingComponent from "../../../../../../app/layout/LoadingComponent";
import GeneratePairingCode from "./Computers/GeneratePairingCode";

class OutletComputers extends Component {
  state = {
    openManageComputerPanel: false,
    selectedComputer: null
  };

  activateDevicePanelOpenClasses = [classes.RevealMore];

  handleDeviceClick = device => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    this.setState({
      openManageComputerPanel: true,
      selectedComputer: device
    });
  };

  handleCloseClick = () => {
    if (this.props.profile.userType === "Merchant") {
      return;
    }
    this.setState({
      openManageComputerPanel: false,
      selectedComputer: null
    });
  };

  render() {
    const { computers, account, outletId, requesting } = this.props;
    const { selectedComputer, openManageComputerPanel } = this.state;

    const loading =
      requesting[`outlet_computers?where=outletId:==:${outletId}`];

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    if (loading)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    let outletComputersItems =
      computers &&
      Object.values(computers).map((computer, index) => {
        return (
          <tr
            className={classes.MerchantRow}
            key={index}
            onClick={() => this.handleDeviceClick(computer)}
          >
            <td>{computer.name}</td>
            <td>{computer.macAddress}</td>
            <td>{computer.type}</td>
            <td>
              {format(
                computer.dateCreated && computer.dateCreated.toDate(),
                "YYYY-MM-DD"
              )}
            </td>
            <td>{computer.createdBy}</td>
          </tr>
        );
      });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {computers && computers.length === 0 ? (
            <div style={{ paddingBottom: "30px" }}>
              <h3>Outlet has no computers registered.</h3>
            </div>
          ) : (
            <Fragment>
              <div className={classes.TableWrapper}>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Mac Address</th>
                      <th scope="col">Device Type</th>
                      <th className={"hide-s"} scope="col">
                        Date Allocated
                      </th>
                      <th className={"hide-s"} scope="col">
                        Allocated By
                      </th>
                    </tr>
                  </thead>
                  <tbody>{outletComputersItems}</tbody>
                </table>

                {openManageComputerPanel ? (
                  <div className={classes.EnableComputer}>
                    <div className={classes.EnableComputer}>
                      <div className={"card card-transparent"}>
                        <div
                          className={"card-header "}
                          style={{
                            padding: "0",
                            minHeight: "4.1rem",
                            padding: "1rem",
                            color: "#666",
                            fontSize: "1.4rem",
                            fontFamily: "Roboto",
                            fontWeight: "500"
                          }}
                        >
                          <div
                            className={"card-title semi-bold"}
                            style={{
                              color: "#666",
                              fontSize: "1.4rem",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              textTransform: "none",
                              marginLeft: "0.7rem"
                            }}
                          >
                            Manage Computer
                          </div>
                          <div className={"card-controls"}>
                            <ul>
                              <li>
                                <a
                                  data-toggle="close"
                                  className={"card-close"}
                                  href="#"
                                  onClick={this.handleCloseClick}
                                >
                                  <i className={"card-icon card-icon-close"} />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className={"card-body"}>
                          <div className={"panel"}>
                            <Fragment>
                              <ul
                                className={
                                  "nav nav-tabs nav-tabs-simple d-none d-md-flex d-lg-flex d-xl-flex"
                                }
                              >
                                <li className={"nav-item active"}>
                                  <a
                                    data-toggle="tab"
                                    href="#enabledisable"
                                    className={"active show"}
                                  >
                                    {selectedComputer.isEnabled
                                      ? "Disable"
                                      : "Enable"}
                                  </a>
                                </li>
                                <li className={"nav-item active"}>
                                  <a data-toggle="tab" href="#pairingcode">
                                    Pairing
                                  </a>
                                </li>
                                <li className={"nav-item active"}>
                                  <a data-toggle="tab" href="#changeMacAddress">
                                    Edit MAC Address
                                  </a>
                                </li>
                              </ul>
                              <div className={"tab-content"}>
                                <div
                                  className={"tab-pane active"}
                                  id="enabledisable"
                                >
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      {selectedComputer.isEnabled ? (
                                        <DisableComputer
                                          computer={selectedComputer}
                                          closePanel={this.handleCloseClick}
                                        />
                                      ) : (
                                        <EnableComputer
                                          computer={selectedComputer}
                                          closePanel={this.handleCloseClick}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className={"tab-pane"} id="pairingcode">
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <GeneratePairingCode
                                        computer={selectedComputer}
                                        outletName={selectedComputer.outletName}
                                        merchantName={
                                          selectedComputer.outletName
                                        }
                                        account={account}
                                        closePanel={this.handleCloseClick}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={"tab-pane"}
                                  id="changeMacAddress"
                                >
                                  <div className={"row column-seperation"}>
                                    <div className={"col-md-12"}>
                                      <div>
                                        <ChangeMACAddress
                                          computer={selectedComputer}
                                          outletName={selectedComputer.outletId}
                                          account={account}
                                          closePanel={this.handleCloseClick}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className={classes.Row}>
                <div>
                  <div
                    className={attachedPaginateSimpleNumbersClasses.join(" ")}
                  >
                    <ul>
                      <li>
                        <a href="">
                          <i className={classes.pg_arrow_left} />
                        </a>
                      </li>
                      <li className={attachedPaginateActiveClasses.join(" ")}>
                        <a href="">1</a>
                      </li>
                      <li
                        className={attachedPaginateNextClasses.join(" ")}
                        id="tableWithExportOptions_next"
                      >
                        <a href="">
                          <i className={classes.pg_arrow_right} />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={classes.dataTables_info}
                    id="tableWithExportOptions_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing <b>1 to {computers && computers.length}</b> of {}
                    entries
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

const query = props => {
  console.log("props", props);
  return [
    {
      collection: "outlet_computers",
      where: ["outletId", "==", props.outletId]
    }
  ];
};

const mapStateToProps = state => ({
  computers: state.firestore.ordered.outlet_computers,
   profile:state.firebase.profile,
  requesting: state.firestore.status.requesting
});

// const actions = {
//   fetchOutletInventory,
//   resetOutletInventory
// };

export default connect(mapStateToProps)(
  firestoreConnect(query)(OutletComputers)
);
