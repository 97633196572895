import React, { Fragment } from "react";
import { connect } from "react-redux";
import { uploadAirtimeFile } from "../airtimeActions";
import { moneyFormat } from "../../../app/common/util/moneyFormat";

import classes from "./index.module.css";

const index = ({ airtimeSummary, uploadAirtimeFile }) => {
  const summary =
    airtimeSummary.length > 0 ? (
      <Fragment>
        <table className={classes.MerchantTable}>
          <thead>
            <tr>
              <th scope="col">OrderNumber</th>
              <th scope="col">Provider</th>
              <th scope="col">Batch Number</th>
              <th scope="col">Denomination</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {airtimeSummary.map((item, index) => {
              return (
                <tr key={index} className={classes.MerchantRow}>
                  <td>{item.orderNumber}</td>
                  <td>{item.provider}</td>
                  <td>{item.batchNo}</td>
                  <td>{item.denomination}</td>
                  <td>{moneyFormat(item.total)}</td>
                </tr>
              );
            })} 
          </tbody>
        </table>
        <br />
      
        <div className={classes.AddUser}>
        {
          airtimeSummary.uploadExists === true ? <h4>Airtime already uploaded</h4> :
          <button className={classes.btn} onClick={() => uploadAirtimeFile()}>
            Upload Airtime
          </button>
        } 
        </div>
      </Fragment>
    ) : (
      <h4>Upload Airtime file to decrypt and upload.</h4>
    );

  return (
    <div className={classes.MerchantTableWrapper}>
      <div className={classes.TableWrapper} style={{ flexDirection: "column" }}>
        {summary}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  airtimeSummary: state.airtimeSummary,
});

const actions = {
    uploadAirtimeFile,
};

export default connect(mapStateToProps, actions)(index);
