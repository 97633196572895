import React, { Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { formatMoney } from "../../../app/common/util/helpers";

import classes from "./index.module.css";

const index = ({ transfers, transfersTotal }) => {
  return (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>
          {transfers && transfers.length > 0 ? (
            <Fragment>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col"> Transfer From</th> <th scope="col"> Transfer To</th>
                    <th scope="col"> Reason</th>
                    <th scope="col">Captured By</th>
                    <th scope="col">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(transfers).map((transfer, index) => {
                    return (
                      <tr className={classes.MerchantRow} key={index}>
                        <td>
                          {format(
                            transfer.transactionDateTime,
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </td>
                        <td>
                          {
                            transfer.transferFromAccount}
                        </td>
                        <td>
                          {
                            transfer.transferToAccount}
                        </td>
                        <td>
                          {
                            transfer.transferReason}
                        </td>
                        <td>{transfer.createdBy}</td>
                        <td>{formatMoney(transfer.transactionAmount)}</td>
                      </tr>
                    );
                  })}
                  <tr className={classes.Totals}>
                    <td colSpan="5">
                      <div className={classes.MoneyTotalLabel}> Total:</div>
                    </td>
                    <td className={classes.MoneyTotal}>
                      {formatMoney(transfersTotal)}
                    </td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </Fragment>
          ) : (
            <h4>
              There are no <strong>transfers</strong> for the selected period.
            </h4>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  transfers: state.financials.transfersList,
  transfersTotal: state.financials.transfersTotal,
});

export default connect(mapStateToProps)(index);
