import moment from "moment";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";

import firebase from "../../app/config/firebase";
import { createReversalTransaction } from "../../app/common/util/helpers";
import { FETCH_TRANSACTIONS } from "./competitionsConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
// import { fetchMerchantTransactionsList } from '../../app/data/firebaseAPI';
//import {fetchMerchantTransactionsList} from '../../app/data/firebaseAPI'

export const getTransactionsForDashboard = () => async (dispatch) => {
  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("competitionWinners");

  try {
    dispatch(asyncActionStart());

    let query;

    query = transactionsRef
      .where("competitionId", "==", "Choppies2")
      .orderBy("transactionDateTime", "desc");

    let querySnap = await query.get();

    let transactions = [];

    if (querySnap.docs.length === 0) {
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id,
      };
      transactions.push(transaction);
    }

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const redeemWinningPurchase = (values) => async (dispatch, getState) => {
  const firestore = firebase.firestore();
  const competitionWinnersRef = firestore.collection("competitionWinners");

  try {
    dispatch(asyncActionStart());

    await competitionWinnersRef
      .doc(values.id)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          return doc.data().isRedeemed ? true : false;
        } else {
          dispatch(asyncActionFinish());
          toastr.error("Filed", "Winning was not found.");
        }
      })
      .then(async (redeemed) => {
        console.log("redeemed", redeemed);
        if (!redeemed) {
          await axios
            .post("api/bpcSaleCompetition", {
              id: values.id,
              meterNumber: values.meterNumber,
              transactionAmount: values.transactionAmount,
              clientSaleId: values.clientSaleId,
              createdBy: values.createdBy,
            })
            .then((response) => {
              return response;
            });
        }
      })
      .then(() => {
        // console.log("refreshing...");

        dispatch(getTransactionsForDashboard());
      });

    // console.log("Finished refresh");
    // dispatch(getTransactionsForDashboard());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Winning has been reedeemed.");
  } catch (error) {
    dispatch(asyncActionError());

    toastr.error("Oops", "Something went wrong");
  }
};

export const clearTransactionsForDashboard =
  () => async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());

      let transactions = [];
      dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const reportByCategory = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            saleType: doc.data().saleType,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.saleType in arr))
              arr.__array.push((arr[doc.saleType] = doc));
            else {
              arr[doc.saleType].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    // // console.log("querySnap.docs.length", querySnap.docs.length);
    // let transactions = [];

    // if (querySnap.docs.length === 0) {
    //   dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    //   dispatch(asyncActionFinish());
    //   return querySnap;
    // }

    // for (let i = 0; i < querySnap.docs.length; i++) {
    //   let transaction = {
    //     ...querySnap.docs[i].data(),
    //     id: querySnap.docs[i].id
    //   };
    //   transactions.push(transaction);
    // }

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByDate = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  console.log("outletId is ", values);

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  console.log("transactionsEndDateYear is ", transactionsEndDateYear);
  console.log("transactionsEndDateMonth is ", transactionsEndDateMonth);
  console.log("transactionsEndDateDay is ", transactionsEndDateDay);

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            transactionDateTime: doc
              .data()
              .dateCreated.toDate()
              .toISOString()
              .slice(0, 10),
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.transactionDateTime in arr))
              arr.__array.push((arr[doc.transactionDateTime] = doc));
            else {
              arr[doc.transactionDateTime].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const reportByUser = (values) => async (dispatch, getState) => {
  let today = new Date(Date.now());

  const outletId = values.outletId;
  const transactionsStartDate = values.transactionsStartDate;
  let transactionsEndDate = values.transactionsEndDate;

  // console.log("transactionsEndDate is ", transactionsEndDate);

  let transactionsEndDateYear = transactionsEndDate.getFullYear();
  let transactionsEndDateMonth = transactionsEndDate.getMonth();
  let transactionsEndDateDay = transactionsEndDate.getDate();

  transactionsEndDate = new Date(
    transactionsEndDateYear,
    transactionsEndDateMonth,
    transactionsEndDateDay,
    "23",
    "59",
    "59"
  );

  const firestore = firebase.firestore();

  const transactionsRef = firestore.collection("transactions");

  try {
    dispatch(asyncActionStart());

    let query = transactionsRef
      .where("dateCreated", ">=", transactionsStartDate)
      .where("dateCreated", "<=", transactionsEndDate)
      .where("outletId", "==", outletId)
      .where("transactionStatus", "==", "Successful")
      .orderBy("dateCreated");

    let transactions = await query.get().then(function (querySnapshot) {
      const result = querySnapshot.docs
        .map((doc) => {
          return {
            userId: doc.data().createdBy,
            transactionAmount: doc.data().transactionAmount,
          };
        })
        .reduce(
          function (arr, doc) {
            if (!(doc.userId in arr)) arr.__array.push((arr[doc.userId] = doc));
            else {
              arr[doc.userId].transactionAmount += parseFloat(
                doc.transactionAmount
              )
                ? parseFloat(doc.transactionAmount)
                : 0;
            }
            return arr;
          },
          {
            __array: [],
          }
        );

      return result.__array;
    });

    dispatch({ type: FETCH_TRANSACTIONS, payload: { transactions } });
    dispatch(asyncActionFinish());
    //return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

// export const reverseTransaction = transaction => async (
//   dispatch,
//   getState
// ) => {};

export const reverseTransaction =
  (transaction) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    let dateNow = new Date();
    let transactionCategory =
      transaction.transactionCategory === "Debit" ? "Credit" : "Debit";

    // let isTransactionCharged = transaction.isTransactionCharged ? false : true;

    let reverseTransactionObject = createReversalTransaction(
      transaction,
      transactionCategory,
      user
    );

    try {
      dispatch(asyncActionStart());

      await firestore.add(`transactions`, reverseTransactionObject);

      dispatch(asyncActionFinish());
      toastr.success("Success", "Transaction has been reversed");
    } catch (error) {
      console.log(error);

      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
