import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";

// import {fetchMerchantTransactionsList} from '../../../../app/data/firebaseAPI'
import classes from "./index.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import ReportBody from "./ReportBody";

// {merchant,merchantTransactions}

class Index extends Component {
  render() {
    const { merchant, outlets, profile, match } = this.props;

    const userRole = profile.role;
    const userType = profile.userType;

    const merchantsLink = `/merchants`;
    const selectedMerchantLink = `/merchants/${match.params.account}`;
    const itemisedReportLink = `/merchants/${match.params.account}/reports`;

    let reportBody;

    if (!outlets || !merchant) return <LoadingComponent inverted={true} />;



    let resolvedOutlets =
      userRole === "User"
        ? outlets.filter((outlet) => profile.outlets.includes(outlet.id)) 
        : outlets;

  reportBody = <ReportBody outlets={resolvedOutlets} />;

    return (
      <Fragment>
        <div className={classes.Container}>
          <div className={classes.BreadcrumbWrapper}>
            <div className={classes.Breadcrumb}>
              <span className={classes.Header}>
                <Link to={merchantsLink}>Home</Link>
                {" - "}
                <Link to={selectedMerchantLink}>{merchant[0].name}</Link>
                {" - "}
                <Link to={itemisedReportLink}>Reports</Link> {" - "} Category
              </span>
              <br />
              <span className={classes.ByLine}>
                {/* You are in the {merchant.name} Reports panel */}
                You are in the Reports panel
              </span>
            </div>
          </div>
          {/* <MerchantFinancials transactions={merchantTransactions}/> */}
          <div className={classes.Content}>
            <div className={classes.WelcomeContainer}>
              <div className={classes.MerchantProfile}>
                <div className={"card card-default"}>
                  {/* <div className={"card-header"}>
                    <div className={"card-title semi-bold"}>Inventory</div>
                  </div> */}
                  <div className={"card-body"}>
                    <header className={classes.TabHeader}>
                      <div className={classes.PageHeader}>
                        <h3>Sales by Category</h3>
                      </div>
                    </header>

                    <div className={"row"}>
                      <div className={"card card-transparent"}>
                        <div className={"card-body"}>
                          {/* {console.log("{merchant.outlets", merchant.outlets)} */}
                          {reportBody}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  merchant: state.firestore.ordered.merchant,
  outlets: state.firestore.ordered.outlets,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const query = ownProps => {
  return [
    {
      collection: "merchants",
      storeAs: "merchant",
      doc: ownProps.match.params.account
    },
    {
      collection: "merchants",
      storeAs: "outlets",
      doc: ownProps.match.params.account,
      subcollections: [{ collection: "outlets" }]
    }
  ];
};

export default compose(
  firestoreConnect(query),
  connect(mapStateToProps)
)(Index);
