import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import BalanceForm from "../triggers/triggers/forms/balance";

const actions = { closeModal };

const balanceTriggerValueModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Update Balance Trigger" onClose={closeModal}>
      <BalanceForm />
    </Modal>
  );
};

export default connect(null, actions)(balanceTriggerValueModal);
