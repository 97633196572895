// console.log(
//   "window.location.pathname, ",
//   extractFirstPart(window.location.href)
// );

// function extractFirstPart(url) {
//   const withoutProtocol = url.split("://")[1];
//   const domainPart = withoutProtocol.split("/")[0];
//   const firstPart = domainPart.split(".")[0];
//   return firstPart;
// }

import { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
const firebaseConfig = require("./key.json");

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage().ref();
export const firestore = firebase.firestore();

export const useCollection = (path, orderBy, where = []) => {
  const [docs, setDocs] = useState([]);

  const [queryField, queryOperator, queryValue] = where;

  useEffect(() => {
    let collection = firestore.collection(path);

    if (orderBy) {
      collection = collection.orderBy(orderBy);
    }

    if (queryField) {
      collection = collection.where(queryField, queryOperator, queryValue);
    }

    return firestore.collection(path).onSnapshot((snapshot) => {
      const docs = [];
      snapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setDocs(docs);
    });
  }, [path, orderBy, queryField, queryOperator, queryValue]);

  return docs;
};

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  // Get a reference to the place in the database where the user is stored
  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();
    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData,
      });
    } catch (error) {
      console.error("error creating user", error.message);
    }
  }

  return getUserDocumentRef(userAuth.uid);
};

export const getUserDocumentRef = async (uid) => {
  if (!uid) return null;

  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();

    return { uid, ...userDocument.data() };
  } catch (error) {
    console.error("error fetching user", error.message);
  }
};

export default firebase;
