import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { unAssignSIMFromDevice } from "../inventoryActions";

const unassignSIMFromDeviceForm = ({
  unAssignSIMFromDevice,
  error,
  handleSubmit,
  device,
}) => {
  const onFormSubmit = (values) => {
    unAssignSIMFromDevice(values);
  };

  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        {device && (
          <form className={"p-t-15"} onSubmit={handleSubmit(onFormSubmit)}>
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label>Device </label>
                <div>
                  <strong>{device.deviceModel}</strong>
                </div>
              </div>
              <div className={"col-md-6"}>
                <label>Mac Address</label>
                <div>
                  <strong>{device.deviceMACAddress}</strong>
                </div>
              </div>
            </div>
            <p />
            <div className={"row"}>
              <div className={"col-md-6"}>
                <label>Assigned Cell Number </label>
                <div>
                  <strong>{device.assignedCellNumber}</strong>
                </div>
              </div>
            </div>

            <p />


            {error && <label className={"error semi-bold"}>{error}</label>}

            <button className={"btn btn-primary btn-cons m-t-10"}>
              Remove Assigned SIM Card
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  device: state.modals.modalProps,
  initialValues: {
    device: state.modals.modalProps,
  },
  enableReinitialize: true,
});

const actions = {
  unAssignSIMFromDevice,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "unAssignSIMToDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })
)(unassignSIMFromDeviceForm);
