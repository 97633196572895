import React, { useState, useEffect, Fragment } from "react";
import format from "date-fns/format";

import classes from "./index.module.css";
import { formatMoney } from "../../../../app/common/util/helpers";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const requestListItem = (props) => {
  let { request, initialValues, index } = props;
  // console.log("request!!!!!", request);
  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <>
        <tr
          className={classes.MerchantRow}
          key={index}
          onClick={() => handleClick()}
        >
          <td>{format(request.dateCreated.toDate(), "YYYY-MM-DD HH:mm:ss")}</td>
          <td>{formatMoney(request.amount)}</td>
          <td>{request.reason}</td>
          <td>
            {request.approvers &&
              request.approvers.map((approver, index) => {
                return <div>{approver}</div>;
              })}
          </td>

          <td>{request.status}</td>
        </tr>
        {/* {expanded ? (
                          <tr className={classes.Expandabled}>
                            <td colSpan="6">
                              <Detail
                                transaction={request}
                                // initialValues={initialValues}
                                closeExpanded={setExpanded}
                              />
                            </td>
                          </tr>
                        ) : null} */}
      </>

      {/* <th scope="col">Date Time</th>
                <th scope="col">Account</th>
                <th scope="col">Channel</th>
                <th scope="col">Amount</th>
                <th scope="col">Is Credited</th> */}

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="5">
            <Detail
              transaction={request}
              initialValues={initialValues}
              closeExpanded={setExpanded}
            />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default requestListItem;
