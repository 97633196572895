import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
import { moneyFormat } from "../../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const pettyCashListItem = (props) => {
  let { transaction, initialValues, index } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <>
      <tr
        key={index}
        onClick={() => handleClick(index)}
        className={classes.MemberRow}
      >
        <td>
          {format(
            transaction.dateCreated && transaction.dateCreated.toDate(),
            "YYYY-MM-DD"
          )}
        </td>
        <td>{moneyFormat(transaction.amount)}</td>
        <td>{transaction.createdBy}</td>
        <td>{transaction.reason}</td>
        {/* <td>{transaction.reason}</td> */}
        <td>{transaction.status}</td>
      </tr>
      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="6">
            <Detail
              transaction={transaction}
              initialValues={initialValues}
              closeExpanded={setExpanded}
            />
          </td>
        </tr>
      ) : null}
    </>
  );
};

export default pettyCashListItem;
