import moment from "moment";
import { toastr } from "react-redux-toastr";
import axios from "../../axios-firebase";
import { closeModal } from "../modals/modalActions";

import firebase, { auth } from "../../app/config/firebase";
import { FETCH_PETTY_CASH_TRANSACTIONS } from "./pettyCashConstants";
import { FETCH_UNALLOCATED_TRANSACTIONS } from "./pettyCashConstants";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";

export const createPettyCashRequest = (values, approvers) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    const pettyCash = { ...values };
    pettyCash.dateCreated = new Date();
    pettyCash.approvers = approvers;
    pettyCash.status = "Application";
    pettyCash.createdBy = user;
    console.log("pettycash", pettyCash);

    try {
      dispatch(asyncActionStart());

      let pettyCashRef = await firestore.collection("pettyCash").doc();
      console.log("doc ref id", pettyCashRef);
      let batch = firestore.batch();

      await batch.set(pettyCashRef, { ...pettyCash, id: pettyCashRef.id });

      await batch.commit();

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Petty cash order has been placed");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const amendPettyCashRequest = (values, approvers) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const user = firebase.auth().currentUser.displayName;

    const pettyCash = { ...values };
    pettyCash.dateCreated = new Date();
    pettyCash.approvers = approvers;
    pettyCash.status = "Application";
    // console.log("values !!!222", values);

    // console.log("pettycash !!!222", pettyCash);

    try {
      dispatch(asyncActionStart());

      await firestore.collection("pettyCash").doc(values.id).set(
        {
          amount: values.amount,
          status: pettyCash.status,
          reason: values.reason,
          dateUpdated: new Date(),
        },
        { merge: true }
      );

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Petty cash order has been placed");
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };
};

export const getPettyCashTransactions =
  (values) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      const transactionsRef = firestore.collection("pettyCash");

      dispatch(asyncActionStart());

      let query;

      query = transactionsRef;

      let querySnap = await query.get();

      let transactions = [];

      console.log("Water Utilities Corporation", querySnap.docs.length);

      if (querySnap.docs.length === 0) {
        dispatch({
          type: FETCH_PETTY_CASH_TRANSACTIONS,
          payload: { transactions },
        });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      console.log("transactions", transactions);

      dispatch({
        type: FETCH_PETTY_CASH_TRANSACTIONS,
        payload: { transactions },
      });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const clearUsersPettyCashRequests = () => async (dispatch) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({
      type: FETCH_PETTY_CASH_TRANSACTIONS,
      payload: { transactions },
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getTransactionsForDashboard = (values) => async (dispatch) => {
  console.log("values", values);
  const firestore = firebase.firestore();

  // const transactionsStartDate = values.startDate; // set to 12:00 am today
  // const transactionsEndDate = values.endDate;

  const transactionsRef = firestore.collection("pettyCash");

  try {
    dispatch(asyncActionStart());

    let query;

    // console.log("transactionsStartDate", transactionsStartDate);
    // console.log("transactionsEndDate", transactionsEndDate);

    query = transactionsRef.orderBy("dateCreated");

    let querySnap = await query.get();

    let transactions = [];

    console.log("querySnap.docs.length", querySnap.docs.length);

    if (querySnap.docs.length === 0) {
      dispatch({
        type: FETCH_PETTY_CASH_TRANSACTIONS,
        payload: { transactions },
      });
      dispatch(asyncActionFinish());
      return querySnap;
    }

    for (let i = 0; i < querySnap.docs.length; i++) {
      let transaction = {
        ...querySnap.docs[i].data(),
        id: querySnap.docs[i].id,
      };
      transactions.push(transaction);
    }
    console.log("transactions", transactions);
    dispatch({
      type: FETCH_PETTY_CASH_TRANSACTIONS,
      payload: { transactions },
    });
    dispatch(asyncActionFinish());
    // return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const actionPettyCash =
  (values, transactionStatus) =>
  async (dispatch, { getFirebase, getFirestore }) => {
    const user = auth.currentUser.displayName;

    const firestore = firebase.firestore();
    try {
      dispatch(asyncActionStart());
      if (transactionStatus === "Application") {
        console.log("actionPettyCash application", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            isPettyCashApproved: true,
            status: "Approved",
            approverNotes: values.approver_notes,
            dateUpdated: new Date(),
            approvedBy: user,
            dateActioned: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash application has been approved");
      }
      if (transactionStatus === "Approved") {
        console.log("actionPettyCash approved", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            status: "Complete",
            authoriserNotes: values.authoriser_notes,
            dateUpdated: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash has been completed");
      }
      if (transactionStatus === "Returned") {
        console.log("actionPettyCash Returned", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            status: "Complete",
            dateUpdated: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash has been completed");
      }
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const rejectPettyCash =
  (values, transactionStatus) =>
  async (dispatch, { getFirebase, getFirestore }) => {
    const user = auth.currentUser.displayName;

    const firestore = firebase.firestore();
    try {
      dispatch(asyncActionStart());
      if (transactionStatus === "Application") {
        console.log("actionPettyCash application", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            isPettyCashApproved: true,
            status: "Rejected",
            rejectionNote: values.approver_notes,
            dateUpdated: new Date(),
            rejectedBy: user,
            dateActioned: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash has been rejected");
      }
      if (transactionStatus === "Approved") {
        console.log("actionPettyCash approved", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            status: "Rejected",
            rejectionNote: values.authoriser_notes,
            rejectedBy: user,
            dateUpdated: new Date(),
            dateActioned: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash has been rejected");
      }
      if (transactionStatus === "Returned") {
        console.log("actionPettyCash Returned", values);

        await firestore.collection("pettyCash").doc(values.id).set(
          {
            status: "Rejected",
            dateUpdated: new Date(),
          },
          { merge: true }
        );
        toastr.success("Success", "Petty cash has been rejected");
      }
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };
export const uploadPettyCashDocumentation = (values) => async (dispatch) => {
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  console.log("values!!!!!!!!!!!!!", values);
  try {
    dispatch(asyncActionStart());

    const storageRef = storage.ref();
    const fileRef = storageRef.child(
      `pettyCash/${values.id}/${values.proof[0].name}`
    );
    await fileRef.put(values.proof[0]);
    const url = await fileRef.getDownloadURL();
    await firestore.collection("pettyCash").doc(values.id).set(
      {
        status: "Returned",
        documentationUrl: url,
        dateUpdated: new Date(),
      },
      { merge: true }
    );

    dispatch(getTransactionsForDashboard());
    dispatch(asyncActionFinish());
    toastr.success("Success", "Record has been sent for authorisation.");
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Oops", "Something went wrong");
  }
};
export const authorisePettyCash =
  (values) =>
  async (dispatch, { getFirebase, getFirestore }) => {
    console.log("authorisePettyCash", values);

    const firestore = firebase.firestore();
    try {
      dispatch(asyncActionStart());
      await firestore.collection("pettyCash").doc(values.id).set(
        {
          status: "Authorised",
          authoriserNotes: values.authoriser_notes,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const getUnallocatedTransactionsForDashboard =
  (values) => async (dispatch) => {
    const firestore = firebase.firestore();

    const transactionsRef = firestore.collection("receiptIT");

    try {
      dispatch(asyncActionStart());

      let query;

      query = transactionsRef
        .where("txnIdCatName", "in", ["RECEIPTS", "TRANSFERS IN"])
        .where("isCredited", "==", false)
        .orderBy("acceptanceCalendarDateTime");

      let querySnap = await query.get();

      let transactions = [];

      if (querySnap.docs.length === 0) {
        dispatch({
          type: FETCH_UNALLOCATED_TRANSACTIONS,
          payload: { transactions },
        });
        dispatch(asyncActionFinish());
        return querySnap;
      }

      console.log("transactions", transactions);

      for (let i = 0; i < querySnap.docs.length; i++) {
        let transaction = {
          ...querySnap.docs[i].data(),
          id: querySnap.docs[i].id,
        };
        transactions.push(transaction);
      }

      dispatch({
        type: FETCH_UNALLOCATED_TRANSACTIONS,
        payload: { transactions },
      });
      dispatch(asyncActionFinish());
      // return querySnap;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };

export const updatePettyCash =
  (values, supportDocument, initialValues) =>
  async (dispatch, { getFirebase, getFirestore }) => {
    const firestore = firebase.firestore();
    console.log("actionPettyCash", values);

    const account = values.account;
    const msgId = "values.msgId";

    try {
      dispatch(asyncActionStart());

      if (supportDocument) {
        await uploadPettyCashSupportDocument(
          supportDocument,
          msgId,
          account,
          dispatch
        );
      }

      await firestore.collection("receiptIT").doc(msgId).set(
        {
          reconReference: values.account,
          updatedBy: values.currentUser,
          correctionRequired: "Yes",
          dateUpdated: new Date(),
        },
        { merge: true }
      );

      dispatch(
        // console.log("initialValues", initialValues);
        getTransactionsForDashboard({
          startDate: initialValues.startDate,
          endDate: initialValues.endDate,
        })
      );
      dispatch(asyncActionFinish());
      toastr.success("Success", "Record has been updated.");
    } catch (error) {
      console.log("error", error);
      dispatch(asyncActionError());
      toastr.error("Oops", "Something went wrong");
    }
  };

export const uploadPettyCashSupportDocument = async (
  file,
  msgId,
  merchantAccount,
  dispatch
) => {
  console.log("In supportDocument", file, msgId);

  const supportDocument = `${msgId}-SupportDocument`;
  const path = `${merchantAccount}/pettyCash`; //MerchantID
  const options = {
    name: supportDocument,
  };
  try {
    dispatch(asyncActionStart());
    // upload the file to fb storage
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    dispatch(asyncActionFinish());
    console.log("uploadedFile", uploadedFile);
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error("Problem uploading merchant contract");
  }
};

export const clearTodaysPettyCash = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({
      type: FETCH_PETTY_CASH_TRANSACTIONS,
      payload: { transactions },
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const clearUnallocatedPettyCash = () => async (dispatch, getState) => {
  try {
    dispatch(asyncActionStart());

    let transactions = [];
    dispatch({
      type: FETCH_UNALLOCATED_TRANSACTIONS,
      payload: { transactions },
    });
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
