import { SubmissionError, reset } from "redux-form";
import { closeModal } from "../modals/modalActions";
import { toastr } from "react-redux-toastr";

import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";

import { hashPassword } from "../../app/common/util/helpers";

import { history } from "../../app/common/util/history";

export const login = (creds) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      dispatch(asyncActionStart());

      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);

      history.push("/merchants");
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };
};

export const registerUser =
  (user) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser.displayName;
    const dateCreated = new Date();
    console.log("user", user);

    try {
      dispatch(asyncActionStart());

      const displayName = `${user.firstName} ${user.lastName}`;
      let newUser;

      let outlets = [];
      let accounts = [];

      if (user.role.value === "User") {
        for (let i = 0; i < user.outletId.length; i++) {
          let outlet = user.outletId[i].value;
          outlets.push(outlet);
        }
      } else {
        outlets.push("All Outlets");
      }

      if (user.role.value === "User" || user.role.value === "Administrator") {
        accounts.push(user.account);
      } else {
        accounts.push("All Accounts");
      }

      if (user.userType === "Merchant") {
        newUser = {
          firstName: user.firstName,
          lastName: user.lastName,
          displayName: displayName,
          email: user.email,
          account: user.account,
          group: user.group,
          outlets: outlets,
          accounts: accounts,
          password: hashPassword(user.password),
          role: user.role.value,
          userType: user.userType,
          dateCreated: firestore.FieldValue.serverTimestamp(),
        };
      } else {
        newUser = {
          firstName: user.firstName,
          lastName: user.lastName,
          displayName: displayName,
          email: user.email,
          account: user.account,
          outletId: user.outletId,
          password: hashPassword(user.password),
          role: user.role.value,
          userType: user.userType,
          dateCreated: firestore.FieldValue.serverTimestamp(),
        };
      }

      let batch = firestore.batch();

      // create the user in firebase auth
      let createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      createdUser = firebase.auth().currentUser;

      // createdUser.sendEmailVerification();

      // update the auth profile
      await createdUser.updateProfile({
        displayName: displayName,
      });

      newUser.uid = createdUser.uid;

      let userRef = await firestore.collection("users").doc(createdUser.uid);
      let merchantEmailRef;
      let outletEmailRef;

      if (
        user.role.value === "Group Administrator" ||
        user.role.value === "Administrator"
      ) {
        merchantEmailRef = firestore.collection("scheduleEmails").doc();

        let email = {
          account: user.account,
          merchantName: user.merchantName,
          createdBy: currentUser,
          dateCreated: dateCreated,
          email: user.email,
          name: displayName,
          type: "Merchant",
        };

        await batch.set(merchantEmailRef, email);
      } else {
        user.outletId.forEach(async (outlet) => {
          outletEmailRef = firestore.collection("scheduleEmails").doc();

          let email = {
            account: user.account,
            merchantName: user.merchantName,
            createdBy: currentUser,
            dateCreated: dateCreated,
            email: user.email,
            name: displayName,
            outletId: outlet.value,
            outletName: outlet.label,
            type: "Outlet",
          };

          await batch.set(outletEmailRef, email);
        });
      }

      await batch.set(userRef, { ...newUser });

      // await firestore.set(`users/${createdUser.uid}`, { ...newUser });

      await batch.commit();
      dispatch(asyncActionFinish());
      dispatch(closeModal());
    } catch (error) {
      dispatch(asyncActionError());
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const registerSystemUser =
  (user) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser.displayName;
    const dateCreated = new Date();

    try {
      dispatch(asyncActionStart());

      const displayName = `${user.firstName} ${user.lastName}`;
      let newUser;

      newUser = {
        account: user.account,
        group: user.account,
        dateCreated: dateCreated,
        displayName: displayName,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: hashPassword(user.password),
        role: user.role.value,
        userType: user.userType,
        createdBy: currentUser,
      };

      let batch = firestore.batch();

      // create the user in firebase auth
      let createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      createdUser = firebase.auth().currentUser;

      // createdUser.sendEmailVerification()

      // update the auth profile
      await createdUser.updateProfile({
        displayName: displayName,
      });

      newUser.uid = createdUser.uid;

      let userRef = firestore.collection("users").doc(createdUser.uid);

      await batch.set(userRef, { ...newUser });

      // await firestore.set(`users/${createdUser.uid}`, { ...newUser });

      await batch.commit();
      dispatch(asyncActionFinish());
      dispatch(closeModal());
    } catch (error) {
      dispatch(asyncActionError());
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const registerWUCUser =
  (user) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser.displayName;
    const dateCreated = new Date();

    try {
      dispatch(asyncActionStart());

      const displayName = `${user.firstName} ${user.lastName}`;
      let newUser;

      newUser = {
        account: user.account,
        group: user.account,
        dateCreated: dateCreated,
        displayName: displayName,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: hashPassword(user.password),
        role: user.role.value,
        userType: user.userType,
        createdBy: currentUser,
      };

      let batch = firestore.batch();

      // create the user in firebase auth
      let createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      createdUser = firebase.auth().currentUser;

      // createdUser.sendEmailVerification()

      // update the auth profile
      await createdUser.updateProfile({
        displayName: displayName,
      });

      newUser.uid = createdUser.uid;

      let userRef = firestore.collection("users").doc(createdUser.uid);

      await batch.set(userRef, { ...newUser });

      // await firestore.set(`users/${createdUser.uid}`, { ...newUser });

      await batch.commit();
      dispatch(asyncActionFinish());
      dispatch(closeModal());
    } catch (error) {
      dispatch(asyncActionError());
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const updatePassword =
  (values) =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    console.log("calues.newPassword", values.newPassword);
    try {
      dispatch(asyncActionStart());

      await user.updatePassword(values.newPassword);
      await dispatch(reset("account"));

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Your password has been updated");
    } catch (error) {
      dispatch(asyncActionError());
      // toastr.error("Oops", "Something went wrong");
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const updateNames =
  (values) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser;
    const now = new Date();

    try {
      dispatch(asyncActionStart());

      currentUser
        .updateProfile({
          displayName: values.displayName,
        })
        .then(async (user) => {
          await firestore.collection("users").doc(currentUser.uid).set(
            {
              displayName: values.displayName,
              updatedBy: currentUser.displayName,
              dateUpdated: now,
            },
            {
              merge: true,
            }
          );
        });

      await dispatch(reset("account"));

      dispatch(closeModal());
      dispatch(asyncActionFinish());
      toastr.success("Success", "Your names have been updated");
    } catch (error) {
      dispatch(asyncActionError());
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };
