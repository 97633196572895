import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.css";
import { formatMoney } from "../../../../app/common/util/helpers";

import Transactions from "./Transactions";

const merchantListItem = (props) => {
  let { merchant, showCommission } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <tr
        className={classes.MemberRow}
        onClick={() => handleClick()}
        style={{ textAlign: "right", verticalAlign: "middle" }}
      >
        <td className={classes.MemberName} style={{ textAlign: "left" }}>
          <Link to={`/merchants/${merchant.account}`}>{merchant.name}</Link>
        </td>
        <td>
          {merchant.closingBalance
            ? formatMoney(merchant.openingBalance)
            : formatMoney(0)}
        </td>
        <td>
          {merchant.deposits ? formatMoney(merchant.deposits) : formatMoney(0)}
        </td>

        {showCommission && (
          <td>
            {merchant.commission
              ? formatMoney(merchant.commission)
              : formatMoney(0)}
          </td>
        )}
        <td>{merchant.sales ? formatMoney(merchant.sales) : formatMoney(0)}</td>
        <td>
          {merchant.closingBalance
            ? formatMoney(merchant.closingBalance)
            : formatMoney(0)}
        </td>
        <td>
          <span className={classes.MemberName}>
            <Link to={`/merchants/${merchant.account}/reports`}>Reports</Link>
          </span>
        </td>
      </tr>
      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan={showCommission ? 7 : 6}>
            <Transactions transactions={merchant.transactions} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default merchantListItem;
