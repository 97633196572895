import React, { Fragment } from "react";
import { connect } from "react-redux";

import { formatMoney } from "../../../../app/common/util/helpers";
import classes from "./index.module.css";

const index = ({
  sales,
  deviceFees,
  salesTotal,
  providerBalances,
  merchantCommissionTotal,
  prepaiPlusCommissionTotal,
  deposits,
  commissionCredited,
  reversalsTotal,
  withdrawalsTotal,
  startingBalance,
  closingBalance,
}) => {
  let financialRecords = (
    <Fragment>
      <div className={classes.BalanceSheet}>
        <table className={classes.MerchantTable}>
          <thead>
            <tr style={{ backgroundColor: "#eee" }}>
              <th style={{ padding: "1rem" }} scope="col">
                Type
              </th>
              <th style={{ padding: "1rem" }} scope="col">
                Amount
              </th>

              {/* <th scope="col">Sales Value2</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>
                <strong>Starting Balance</strong>
              </td>
              <td>
                <strong>{formatMoney(startingBalance)}</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td>Deposits </td>
              <td>{formatMoney(deposits)}</td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td>Commission Credited</td>
              <td>{formatMoney(commissionCredited)}</td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td>Reversals</td>
              <td>{formatMoney(reversalsTotal)}</td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}>
                <strong>Sales</strong>
              </td>
            </tr>

            {sales.reduce(function (arr, doc) {
                let transactionType = doc.saleType + " - " + doc.provider;

                console.log("doc", doc);

                if (!(transactionType in arr)) {
                  arr.push((arr[transactionType] = doc));
                  return arr;
                }

                arr[transactionType].transactionAmount += parseFloat(
                  doc.transactionAmount
                )
                  ? parseFloat(doc.transactionAmount)
                  : 0;
                arr[transactionType].commissionAmount += parseFloat(
                  doc.commissionAmount
                )
                  ? parseFloat(doc.commissionAmount)
                  : 0;

                arr[transactionType].vendorCommissionAmount += parseFloat(
                  doc.vendorCommissionAmount
                )
                  ? parseFloat(doc.vendorCommissionAmount)
                  : 0;

                return arr;
              }, []).map((report, index) => {
              return (
                <tr className={classes.MerchantRow} key={index}>
                  <td>
                    {report.saleType} - {report.provider}
                  </td>
                  <td>{formatMoney(report.transactionAmount)}</td>
                </tr>
              );
            })}
            <tr className={classes.MerchantRow}>
              <td>
                <strong>Total Sales</strong>
              </td>
              <td>
                <strong>{formatMoney(salesTotal)}</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td>Withdrawals</td>
              <td>{formatMoney(withdrawalsTotal)}</td>
            </tr>

            {deviceFees &&
              deviceFees.map((item, index) => {
                return (
                  <tr className={classes.MerchantRow} key={index}>
                    <td>{item.transactionType}</td>
                    <td>{`(${formatMoney(item.transactionAmount)})`}</td>
                  </tr>
                );
              })}

            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td style={{ padding: "1rem", background: "#efefef" }}>
                <strong>Closing Balance</strong>
              </td>
              <td style={{ padding: "1rem", background: "#efefef" }}>
                <strong>{formatMoney(closingBalance)}</strong>
              </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}>
                <strong>Commissions</strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Commission earned by PrepaidPlus</td>
              <td>{formatMoney(prepaiPlusCommissionTotal)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>Commission earned by Merchants</td>
              <td>{formatMoney(merchantCommissionTotal)}</td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td>
                <strong>Net Commission earned by PrepaidPlus</strong>
              </td>
              <td>
                <strong>
                  {formatMoney(
                    prepaiPlusCommissionTotal - merchantCommissionTotal
                  )}
                </strong>
              </td>
            </tr>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={classes.VendorBalances}>
        <table className={classes.MerchantTable}>
          <thead>
            <tr style={{ backgroundColor: "#eee" }}>
              <th style={{ padding: "1rem" }} scope="col">
                Type
              </th>
              <th style={{ padding: "1rem" }} scope="col">
                Amount
              </th>

              {/* <th scope="col">Sales Value2</th> */}
            </tr>
          </thead>
          <tbody>
            <tr className={classes.MerchantRow}>
              <td colSpan={2}> </td>
            </tr>

            <tr className={classes.MerchantRow}>
              <td colSpan={2}>
                <strong>Vendor Balances</strong>
              </td>
            </tr>

            {providerBalances.map((report, index) => {
              return (
                <Fragment>
                  <tr className={classes.MerchantRow} key={index}>
                    <td>
                      {report.saleType} - {report.provider}
                    </td>
                    <td>{formatMoney(report.balance)}</td>
                  </tr>
                  <tr className={classes.MerchantRow}>
                    <td colSpan={2}> </td>
                  </tr>
                </Fragment>
              );
            })}

            {/* <tr className={classes.MerchantRow}>
            <td colSpan={2}> </td>
          </tr>
          <tr className={classes.MerchantRow}>
            <td colSpan={2}> </td>
          </tr>

          <tr className={classes.MerchantRow}>
            <td colSpan={2}>
              <strong>Commissions</strong>
            </td>
          </tr>
          <tr className={classes.MerchantRow}>
            <td>Commission earned by PrepaidPlus</td>
            <td>{formatMoney(prepaiPlusCommissionTotal)}</td>
          </tr>
          <tr className={classes.MerchantRow}>
            <td>Commission earned by Merchants</td>
            <td>{formatMoney(merchantCommissionTotal)}</td>
          </tr>
          <tr className={classes.MerchantRow}>
            <td>
              <strong>Net Commission earned by PrepaidPlus</strong>
            </td>
            <td>
              <strong>
                {formatMoney(
                  prepaiPlusCommissionTotal - merchantCommissionTotal
                )}
              </strong>
            </td>
          </tr>
          <tr className={classes.MerchantRow}>
            <td colSpan={2}> </td>
          </tr> */}
          </tbody>
        </table>
      </div>
    </Fragment>
  );

  return (
    <div className={classes.MerchantTableWrapper}>
      <div className={classes.TableWrapper}>{financialRecords}</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startingBalance: state.financials.startingBalance,
  closingBalance: state.financials.closingBalance,
  deposits: state.financials.depositsTotal,
  reversalsTotal: state.financials.reversalsTotal,
  withdrawals: state.financials.withdrawalsList,
  withdrawalsTotal: state.financials.withdrawalsTotal,
  sales: state.financials.totalsByProvider,
  salesTotal: state.financials.salesTotal,
  financials: state.financials.summary,
  deviceFees: state.financials.deviceFees,
  providerBalances: state.financials.providerBalances,
  vendorBalances: state.financials.vendorBalances,
  commissionCredited: state.financials.creditedCommissionListTotal,
  merchantCommissionTotal: state.financials.earnedCommissionTotal,
  prepaiPlusCommissionTotal: state.financials.vendorCommissionEarnedTotal,
});

export default connect(mapStateToProps)(index);
