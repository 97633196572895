import { createReducer } from "../../app/common/util/reducerUtil";
import {
  ADD_COMMISSION_RATE,
  FETCH_COMMISSION_RATES
} from "./settingsConstants";

const initialState = [];

export const addCommissionRate = (state, payload) => {
  return [...state, Object.assign({}, payload.commission)];
};

export const fetchCommissionRates = (state, payload) => {
  return payload.commissionRates;
};

// export const fetchMerchantsTransactions = (state, payload) => {
//   // console.log('payload',payload)
//   return payload;
// };

export default createReducer(initialState, {
  [ADD_COMMISSION_RATE]: addCommissionRate,
  [FETCH_COMMISSION_RATES]: fetchCommissionRates
});
