import React from "react";

import Tabs from "../../../app/UI/Tabs/Tabs";

import Balance from "./balance";
import Failure from "./failures";
import Payments from "./payments";
// import UnAllocatedTransactions from "./unallocated";

const index = () => {
  return (
    <Tabs>
      <div label={`Provider Payments`}>
        <Payments />
      </div>
      <div label={`Balance Triggers`}>
        <Balance />
      </div>
      <div label={`Failure Triggers`}>
        <Failure />
      </div>
    </Tabs>
  );
};

export default index;
