import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import { registerUser } from "../authActions";

import TextInput from "../../../app/common/form/TextInput";
import AsyncTextInput from "../../../app/common/form/AsyncTextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import validate from "./validate";
import asyncValidate from "./asyncValidate";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
import styles from "./RegisterForm.module.css"

// const validate = combineValidators({
//   firstName: isRequired("firstName"),
//   lastName: isRequired("lastName"),
//   role: isRequired("role"),
//   email: isRequired("email"),
//   password: isRequired("password")
// });

const RegisterForm = props => {
  let { registerUser,registerForm, userRole, outlets, handleSubmit,loading, error } = props;

  let userRoles;
  let merchantOutlets;

  merchantOutlets =
    outlets &&
    outlets.map(outlet => {
      return {
        label: outlet.name,
        value: outlet.id
      };
    });

  userRoles =
    userRole &&
    userRole.map(role => {
      return {
        label: role.role,
        value: role.role
      };
    });

  const onFormSubmit = user => {

    registerUser({
      user
    });
  };

  // <form className={"p-t-15"} onSubmit={handleSubmit(registerUser)}>

  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        <form className={"p-t-15"} onSubmit={handleSubmit(registerUser)}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>First Name</label>
              <Field
                type="text"
                name="firstName"
                component={TextInput}
                placeholder="First Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
            <div className={"col-md-6"}>
              <label>Last Names</label>
              <Field
                type="text"
                name="lastName"
                component={TextInput}
                placeholder="Last Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>User Role</label>
              <Field
                name="role"
                options={userRoles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0"
                  }
                })}
                component={SelectInput}
                placeholder="Select Role..."
                className={"form-control"}
                required="true"
                aria-required="true"
              />
            </div>
                  <div className={"col-md-6"}>
              <label>Outlet </label>

       {registerForm && ( registerForm.values.role &&
         registerForm.values.role.value === "User" ? (

              <Field
                name="outletId"
                options={merchantOutlets}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0"
                  }
                })}
                multiple="true"
                component={SelectInput}
                placeholder="Select Outlet..."
                className={"form-control"}
                required="true"
                aria-required="true"
              />
           
         ) : <h4>All Outlets</h4> ) 
       }</div>
            
          </div>
          <p />
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Email</label>
              <Field
                type="email"
                name="email"
                component={AsyncTextInput}
                placeholder="E-mail"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Password</label>
              <Field
                type="password"
                name="password"
                component={TextInput}
                placeholder="Minimum of 4 Charactors"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <br />

          {error && <label className={"error semi-bold"}>{error}</label>}

          <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span >Create User</span>} 
                
              </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loading: state.async.loading,
  registerForm: state.form.registerForm,
  userRole: state.firestore.ordered.userRoles,
  outlets: state.firestore.ordered.outlets,
  initialValues: {
    account: state.modals.modalProps.account,
    group: state.modals.modalProps.group,
    merchantName: state.modals.modalProps.merchantName,
    userType: "Merchant"
  },
  enableReinitialize: true
});

const actions = {
  registerUser
};

const query = (props, store) => {
  let storeState = store.getState();
  let acc = storeState.modals.modalProps.account;

  return [
    {
      collection: "userRoles",
      where: ["roleScope", "==", "Merchant"]
    },
    {
      collection: "merchants",
      storeAs: "outlets",
      doc: acc,
      subcollections: [{ collection: "outlets" }]
    }
  ];
};

export default compose(
  firestoreConnect(query),
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["email"],
    validate
  })
)(RegisterForm);
