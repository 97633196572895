import React, { Fragment } from "react";
import Tabs from "../../../app/UI/Tabs/Tabs";
import { connect } from "react-redux";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";

const index = ({
  commissionList,
  totalsByProvider,
  salesTotal,

  earnedCommissionTotal,
  creditedCommissionListTotal,

  commissionSalesTotal,
}) => {
  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.MerchantTableWrapper}>
          <Fragment>
            <Tabs>
              <div label={`Credited`}>
                <div className={classes.TableWrapper}>
                  {commissionList.length > 0 ? (
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Provider</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Merchant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(commissionList).map(
                          (commission, index) => {
                            return (
                              <tr className={classes.MerchantRow} key={index}>
                                <td>{commission.commissionType}</td>
                                <td>{commission.provider}</td>
                                <td>{formatMoney(commission.sales)}</td>
                                <td>
                                  {formatMoney(commission.transactionAmount)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr className={classes.Totals}>
                          <td colSpan="2">
                            <div className={classes.MoneyTotalLabel}>
                              Totals
                            </div>
                          </td>
                          <td className={classes.MoneyTotal}>
                            {formatMoney(commissionSalesTotal)}
                          </td>

                          <td className={classes.MoneyTotal}>
                            {formatMoney(creditedCommissionListTotal)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <h4>
                      There is no <strong>credited commission</strong> for the
                      selected period.
                    </h4>
                  )}
                </div>
              </div>
              <div label={`Earned`}>
                <div className={classes.TableWrapper}>
                  {totalsByProvider.length > 0 ? (
                    <table className={classes.MerchantTable}>
                      <thead>
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Provider</th>
                          <th scope="col">Rate</th>
                          <th scope="col">Sales</th>
                          <th scope="col">Merchant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.values(totalsByProvider).map(
                          (commission, index) => {
                            return (
                              <tr className={classes.MerchantRow} key={index}>
                                <td>{commission.saleType}</td>
                                <td>{commission.provider}</td>
                                <td>{commission.commissionRate}</td>
                                <td>
                                  {formatMoney(commission.transactionAmount)}
                                </td>
                                <td>
                                  {formatMoney(commission.commissionAmount)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                        <tr className={classes.Totals}>
                          <td colSpan="3">
                            <div className={classes.MoneyTotalLabel}>
                              Totals
                            </div>
                          </td>
                          <td className={classes.MoneyTotal}>
                            {formatMoney(salesTotal)}
                          </td>

                          <td className={classes.MoneyTotal}>
                            {formatMoney(earnedCommissionTotal)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ) : (
                    <h4>
                      There is no <strong>earned commission</strong> for the
                      selected period.
                    </h4>
                  )}
                </div>
              </div>
            </Tabs>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  commissionList: state.financials.commissionList,
  creditedCommissionListTotal: state.financials.creditedCommissionListTotal,
  salesTotal: state.financials.salesTotal,
  totalsByProvider: state.financials.totalsByProvider,
  commissionSalesTotal: state.financials.commissionSalesTotal,
  earnedCommissionTotal: state.financials.earnedCommissionTotal,
});

export default connect(mapStateToProps)(index);
