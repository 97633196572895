import { createReducer } from "../../app/common/util/reducerUtil";
import {
  FETCH_FNB_RECON_TRANSACTIONS,
  FETCH_KAZANG_RECON_TRANSACTIONS,
} from "./reconsConstants";

const initialState = [];

export const getTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.recons;
};
export const getUnallocatedTransactionsForDashboard = (state, payload) => {
  console.log("payload", payload);

  return payload.recons;
};

export default createReducer(initialState, {
  [FETCH_FNB_RECON_TRANSACTIONS]: getTransactionsForDashboard,
});


