import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import SelectInput from "../../../../../app/common/form/SelectInput";
import TextInput from "../../../../../app/common/form/TextInput";
import { ReactComponent as Spinner } from "../../../../../assets/spinner.svg";

import { addSelfServiceAccountToExisting } from "../../../merchantActions";
import styles from "./CreateSelfServiceOutlet.module.css";

const validate = combineValidators({
  merchant_outlet_name: isRequired("Outlet Name"),
  merchant_outlet_type: isRequired("Outlet Type"),
  merchant_outlet_city: isRequired("Outlet City"),
  merchant_outlet_suburb: isRequired("Outlet Suburb"),
  merchant_outlet_contact_name: isRequired("Contact Name"),
  merchant_outlet_contact_email: isRequired("Contact Email"),
  merchant_outlet_contact_telephone: isRequired("Contact Telephone"),
});

const outlets = [
  {
    label: "Central Government",
    value: "Central Government",
  },
  {
    label: "Local Government",
    value: "Local Government",
  },
  {
    label: "Parastatal",
    value: "Parastatal",
  },
  {
    label: "Private Company",
    value: "Private Company",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const createOutlet = ({
  merchant,
  addSelfServiceAccountToExisting,
  handleSubmit,
  loading,
  error,
  invalid,
  submitting,
  merchantId,
  onClose,
}) => {
  // let { users, loading } = this.props;

  const onFormSubmit = async (values) => {
    values.merchantId = merchant.id;
    values.merchantName = merchant.name;
    values.merchant_outlet_name = "Self-Service";

    console.log("merchant", merchant);

    await addSelfServiceAccountToExisting(merchant, values);

    // props.onClose();
  };

  // onCreateSelfServiceClick = async (merchant) => {
  //   this.setState({ loadingInitial: true });
  //   console.log("merchant", merchant);

  //   // const account = merchant.account;
  //   await this.props.addSelfServiceAccount(merchant);
  //   this.setState({ loadingInitial: false });
  // };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <p>
        <strong>Outlet Details</strong>
      </p>

      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <strong>Name: Self-Service</strong>
        </div>
        <div className={"col-sm-6"}>
          <Field
            name="merchant_outlet_type"
            options={outlets}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: "neutral0",
              },
            })}
            component={SelectInput}
            placeholder="Select Industry..."
            className={"form-control"}
            required="true"
            aria-required="true"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="City"
            name="merchant_outlet_city"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="City"
            required="required"
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            label="Suburb"
            name="merchant_outlet_suburb"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Suburb"
            required="required"
          />
        </div>
      </div>

      <br />
      <p>
        <strong>Contact and Address</strong>
      </p>
      <div className={"row clearfix"}>
        <div className={"col-sm-12"}>
          <Field
            label="Contact Name"
            name="merchant_outlet_contact_name"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Name"
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-6"}>
          <Field
            label="Contact Email"
            name="merchant_outlet_contact_email"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Email"
            required="required"
          />
        </div>
        <div className={"col-sm-6"}>
          <Field
            label="Contact Telephone"
            name="merchant_outlet_contact_telephone"
            component={TextInput}
            type="text"
            className={"form-control"}
            placeholder="Telephone"
            required="required"
          />
        </div>
      </div>
      <br />

      {error && <label className={"error semi-bold"}>{error}</label>}
      <br />
      <button className={styles.btn} type="submit" disabled={loading}>
        {loading ? (
          <Spinner
            style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
          />
        ) : (
          <span>Create Outlet</span>
        )}
      </button>
    </form>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  initialValues: {
    merchant: state.modals.modalProps.merchant,
  },
  enableReinitialize: true,
  merchant: state.modals.modalProps.merchant,
});

const actions = {
  addSelfServiceAccountToExisting,
};

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "createOutletForm", validate })(createOutlet));
