import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import TextInput from "../../../app/common/form/TextInput";
import AsyncTextInput from "../../../app/common/form/AsyncTextInput";
import SelectInput from "../../../app/common/form/SelectInput";

import validate from "./validate";
import asyncValidate from "./asyncValidate";
import { registerWUCUser } from "../authActions";
import { ReactComponent as Spinner } from "../../../assets/spinner.svg";
import styles from "./RegisterWUCUserForm.module.css";

const registerWUCUserForm = ({
  registerWUCUser,
  loading,
  error,
  handleSubmit,
  userRole,
  merchant,
}) => {
  let userRoles;
  let outlets;

  outlets =
    merchant &&
    Object.values(merchant.outlets).map((outlet) => {
      return {
        label: outlet.name,
        value: outlet.id,
      };
    });

  userRoles =
    userRole &&
    userRole.map((role) => {
      return {
        label: role.role,
        value: role.role,
      };
    });

  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        <form className={"p-t-15"} onSubmit={handleSubmit(registerWUCUser)}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>First Name</label>
              <Field
                type="text"
                name="firstName"
                component={TextInput}
                placeholder="First Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
            <div className={"col-md-6"}>
              <label>Last Names</label>
              <Field
                type="text"
                name="lastName"
                component={TextInput}
                placeholder="Last Name"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>User Role</label>
              <Field
                name="role"
                options={userRoles}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0",
                  },
                })}
                component={SelectInput}
                placeholder="Select Role..."
                className={"form-control"}
                required="true"
                aria-required="true"
              />
            </div>
          </div>
          <p />
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Email</label>
              <Field
                type="email"
                name="email"
                component={AsyncTextInput}
                placeholder="E-mail"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <label>Password</label>
              <Field
                type="password"
                name="password"
                component={TextInput}
                placeholder="Minimum of 6 Characters"
                className={"form-control"}
                required=""
                aria-required="true"
              />
            </div>
          </div>
          <br />

          {error && <label className={"error semi-bold"}>{error}</label>}

          <button className={styles.btn} type="submit" disabled={loading}>
            {loading ? (
              <Spinner
                style={{
                  width: "20px",
                  height: "20px",
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <span>Create User</span>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.firestore.ordered.userRoles,
  loading: state.async.loading,
  initialValues: {
    userType: "WUC",
    outletId: "WUC",
    account: "WUC",
  },
  enableReinitialize: true,
});

let query = [
  {
    collection: "userRoles",
    where: ["roleScope", "==", "WUC"],
  },
];

const actions = {
  registerWUCUser,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "registerWUCUserForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["email"],
    validate,
  }),
  firestoreConnect(query)
)(registerWUCUserForm);

// where: ["roleScope", "==", "Merchant"]
