import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../react_dates_overrides.css";
import { reduxForm, Field } from "redux-form";

import { openModal } from "../../../../modals/modalActions";
import { moneyFormat } from "../../../../../app/common/util/moneyFormat";
import { createReportExcelFile } from "../../../../../app/excel/excel";
import {
  getTransactionsDetailsForDashboard,
  getTransactionsForDashboard,
} from "../transactionsActions";
import classes from "./index.module.css";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import SelectedTransaction from "../selectedTransaction";

import TransactionListItem from "./details";
import TextInput from "../../../../../app/common/form/TextInput";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let meterSearchText = "";

class Transactions extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day"),
    today: moment().endOf("day"),
    csvData: [],
    transactionDetailPanelOpen: false,
    selectedTransaction: null,
    selectedRow: -1,
    transactionsFiltered: [],
  };

  transactionsSheet = async (
    transactions,
    workbook,
    logo,
    reportStartDate,
    reportEndDate,
    merchantName
  ) => {
    let startDate = format(reportStartDate, "YYYY-MM-DD");
    let endDate = format(reportEndDate, "YYYY-MM-DD");

    let worksheet = workbook.addWorksheet("All Sales", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 7,
          activeCell: "G1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "G2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A3", "F3");

    worksheet.mergeCells("A4", "F4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "For ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${merchantName}`,
        },
      ],
    };

    worksheet.mergeCells("A5", "F5");

    worksheet.mergeCells("A6", "F6");
    worksheet.getCell("A6").value = `Transactions For Period`;
    worksheet.getRow(6).font = {
      name: "Calibri",
      family: 4,
      size: 16,
      bold: true,
    };

    worksheet.mergeCells("A7", "F7");

    worksheet.mergeCells("A8", "F8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Date From ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${startDate}`,
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: " to ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${endDate}`,
        },
      ],
    };

    worksheet.mergeCells("A9", "F9");

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(10);
    headers.height = 25;
    headers.values = [
      "Date Time",
      "Outlet Id",
      "Type",
      "Transaction Detail",
      "Amount",
      "Status",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    headers.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "dateTime",
        width: 20,
      },
      {
        key: "outlet",
        width: 16,
      },
      {
        key: "transactionType",
        width: 18,
      },
      {
        key: "transactionDetail",
        width: 30,
      },
      {
        key: "transactionAmount",
        width: 18,
      },
      {
        key: "transactionStatus",
        width: 16,
      },
    ];

    worksheet.getColumn(2).numFmt = "0000";
    worksheet.getColumn(5).numFmt = "0.00";

    ["A10", "B10", "C10", "D10", "E10", "F10"].map((key) => {
      worksheet.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });

    transactions.forEach((doc, index) => {
      worksheet
        .addRow({
          dateTime: format(
            doc.transactionDateTime.toDate(),
            "YYYY-MM-DD HH:mm:ss"
          ),
          outlet: doc.outletId ? doc.outletId : doc.account,
          transactionType: doc.transactionType,
          transactionDetail: this.transactionDetail(doc),
          transactionAmount: parseFloat(doc.transactionAmount),
          transactionStatus:
            doc.transactionStatus === "Successful"
              ? doc.transactionStatus
              : "Failed",
        })
        .commit();
      let rowNum = index + 11;
      let row = worksheet.getRow(rowNum);
      row.outlineLevel = 2;
      // row.alignment = {
      //     vertical: "middle",
      //     horizontal: "center"
      // };
    });

    let column8 = worksheet.getColumn(8);
    // column7.alignment = {
    //     vertical: 'middle',
    //     horizontal: 'right'
    // };
    column8.width = 15;

    let total = generateSalesTotalsCell(worksheet, "E", transactions.length);

    const totalsRow = worksheet.addRow(["Sales Totals", "", "", "", total, ""]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });
  };

  async componentDidMount() {
    await this.props.getTransactionsForDashboard({
      group: this.props.profile.group,
      account: this.props.account,
      role: this.props.profile.role,
      transactionStartDate: new Date(
        this.props.initialValues.transactionStartDate
      ),
      transactionEndDate: new Date(this.props.initialValues.transactionEndDate),
      outletId: this.props.initialValues.outletId,
    });

    this.setState({ loadingInitial: false });
  }

  componentWillUnmount() {}

  transactionRowClasses = null;
  transactionDetailPanelOpenClasses = [classes.RevealMore];

  handleDeviceClick = (transaction, index) => {
    console.log("transaction", transaction);
    this.setState({
      transactionDetailPanelOpen: true,
      selectedTransaction: transaction,
      selectedRow: index,
    });
  };

  handleCloseClick = () => {
    this.setState({
      transactionDetailPanelOpen: false,
      selectedTransaction: null,
      selectedRow: -1,
    });
  };

  transactionDetail = (transaction) => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.transactionDetail
            ? transaction.provider + " - " + transaction.transactionDetail
            : transaction.saleType + " - " + transaction.provider
          : transaction.saleType + " - " + transaction.transactionDetail;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank + " - " + transaction.depositMethod
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType + " - " + transaction.reversalReference;
        break;
      case "Withdrawal":
        return (
          transaction.withdrawalType + " - " + transaction.withdrawalDetail
        );
        break;
      case "Commission":
        return transaction.commissionType + " - " + transaction.provider;
        break;
      default:
        return transaction.saleType + " - " + transaction.transactionDetail;
    }
  };

  render() {
    const {
      handleSubmit,
      getTransactionsForDashboard,
      getTransactionsDetailsForDashboard,
      transactions,
      openModal,
      createReportExcelFile,
      requesting,
      profile,
    } = this.props;
    const loading = requesting[`merchants`];

    let group = profile && profile.group;
    let accounts = profile && profile.accounts;
    let role = profile && profile.role;

    let {
      transactionsFiltered,
      transactionDetailPanelOpen,
      selectedTransaction,
      selectedRow,
    } = this.state;

    !selectedRow
      ? (this.transactionRowClasses = [classes.MerchantRow])
      : (this.transactionRowClasses = [
          classes.MerchantRow,
          classes.SelectedTransaction,
        ]);

    const exportToExcel = async () => {
      openModal("SpinnerModal");

      await createReportExcelFile(
        "Transactions Report",
        transactions,
        this.transactionsSheet,
        "Prepaid Plus",
        this.state.startDate.toDate(),
        this.state.endDate.toDate()
      );
    };

    const onFormSubmit = async (values) => {
      let transactionStartDate = this.state.startDate.startOf("day").toDate();
      // transactionStartDate.setHours(0, 0, 0, 0);
      let transactionEndDate = this.state.endDate.endOf("day").toDate();
      // transactionEndDate.setHours(23, 59, 59, 999);
      console.log("transactionStartDate", transactionStartDate);
      console.log("grtransactionEndDateoup", transactionEndDate);

      console.log("group", group);
      console.log("accounts", accounts);
      console.log("role", role);

      this.setState({ loadingInitial: true });
      console.log("values", values);

      const transactionsFiltered = await getTransactionsDetailsForDashboard({
        group: group,
        account: this.props.account,
        role: role,
        transactionStartDate: transactionStartDate,
        transactionEndDate: transactionEndDate,
        transactionDetail: values.transactionDetail,
      });
      console.log("values", values);
      this.setState({ loadingInitial: false });
      this.setState({
        transactions: transactionsFiltered,
      });
    };

    const filterByMeterNumber = (meterNumber) => {
      let keyword = meterNumber.target.value.toLowerCase();
      let filtered = transactions.filter((transaction) => {
        if (transaction.transactionType === "Sale") {
          return transaction.transactionDetail.indexOf(keyword) > -1;
        } else return false;
      });

      this.setState({
        transactionsFiltered: filtered,
      });

      meterSearchText = keyword;
    };

    const filterSales = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "Sale";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    const filterFailed = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionStatus !== "Successful";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    const filterDeposits = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "Deposit";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    const filterCommissions = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "Commission";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    const filterWithdrawals = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "Withdrawal";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    const filterContracts = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "DeviceContract";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    // const filterContracts = () => {
    //   let filtered = transactions.filter((transaction) => {
    //     return transaction.transactionType === "DeviceContract";
    //   });

    const filterReversals = () => {
      let filtered = transactions.filter((transaction) => {
        return transaction.transactionType === "Reversal";
      });

      this.setState({
        transactionsFiltered: filtered,
      });
    };

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault,
    ];

    let transactionList = (
      <Fragment>
        <LoadingComponent inverted={true} style={{ width: "100%" }} />
      </Fragment>
    );

    let salesTotal;
    let commissionTotal;
    let depositsTotal;
    let reversalsTotal;
    let withdrawalsTotal;
    let contractsTotal;
    let failedCount;

    if (loading || this.state.loadingInitial)
      return (
        <Fragment>
          <LoadingComponent inverted={true} />
        </Fragment>
      );

    if (!this.state.loadingInitial) {
      salesTotal = transactions
        .filter(
          (item) =>
            item.transactionType === "Sale" &&
            item.transactionStatus === "Successful"
        )
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);
      failedCount = transactions
        .filter((item) => item.transactionStatus !== "Successful")
        .reduce(function (total, doc) {
          total += 1;

          return total;
        }, 0);
      commissionTotal = transactions
        .filter((item) => item.transactionType === "Commission")
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);
      depositsTotal = transactions
        .filter((item) => item.transactionType === "Deposit")
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);

      reversalsTotal = transactions
        .filter((item) => item.transactionType === "Reversal")
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);

      withdrawalsTotal = transactions
        .filter((item) => item.transactionType === "Withdrawal")
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);

      contractsTotal = transactions
        .filter((item) => item.transactionType === "DeviceContract")
        .reduce(function (total, doc) {
          total += parseFloat(doc.transactionAmount)
            ? parseFloat(doc.transactionAmount)
            : 0;

          return total;
        }, 0);

      let transactionsItems =
        transactionsFiltered.length === 0 ? transactions : transactionsFiltered;

      meterSearchText.length > 0 && transactionsFiltered.length === 0
        ? (transactionList = (
            <div>
              <div style={{ paddingBottom: "30px" }}>
                <h5>
                  There are no transactions found for query {meterSearchText}
                </h5>
              </div>
            </div>
          ))
        : transactionsItems.length > 0
        ? (transactionList = (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Time</th>
                  <th scope="col">Outlet ID</th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Transaction Detail</th>
                  <th scope="col">Transaction Value</th>
                  <th scope="col" style={{ margin: "0 auto" }}>
                    Status
                  </th>
                  {/* <th scope="col">Vendor Balance</th> */}
                </tr>
              </thead>
              <tbody>
                {/* {transactionsItems.map((transaction, index) => {
                  return (
                    <tr
                      key={index}
                      onClick={() => this.handleDeviceClick(transaction, index)}
                      className={
                        this.state.selectedRow === index
                          ? classes.SelectedTransaction
                          : classes.MerchantRow
                      }
                    >
                      <td>
                        {format(
                          transaction.transactionDateTime.toDate(),
                          "YYYY-MM-DD  HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {transaction.outletId
                          ? transaction.outletId
                          : transaction.account}
                      </td>
                      <td>{transaction.transactionType}</td>
                      <td>{this.transactionDetail(transaction)}</td>
                      <td>{moneyFormat(transaction.transactionAmount)}</td>
                      <td>
                        {transaction.vendorBalance
                          ? moneyFormat(transaction.vendorBalance)
                          : "N/A"}
                      </td>
                    </tr>
                  );
                })} */}

                {transactionsItems.map((transaction, index) => {
                  return (
                    <TransactionListItem
                      key={index}
                      transaction={transaction}
                    />
                  );
                })}
              </tbody>
            </table>
          ))
        : (transactionList = (
            <h4>Selected Outlet has no transactions for selected period.</h4>
          ));
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilterTools}>
              <form
                style={{
                  borderRight: "solid 3px #707070",
                  paddingRight: "2rem",
                }}
                onSubmit={handleSubmit(onFormSubmit)}
              >
                <div className={"row"}>
                  {/* <div className={"col-md-3"}>
                    <Field
                      type="text"
                      className={"form-control"}
                      style={{ marginBottom: 0 }}
                      component={TextInput}
                      placeholder="Outlet Id (ie 1000-01)"
                      name="outletId"
                      id="outletId"
                      required="required"
                      autoComplete="off"
                    />
                  </div> */}

                  <div className={"col-md-12"}>
                    <div className={"row"}>
                      <DateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={(date) =>
                          date.isAfter(this.state.today)
                        }
                        displayFormat={"YYYY-MM-DD"}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />
                      <div className={classes.PageFilter}>
                        {/* <h4 className="m-r-20"> Search:</h4> */}

                        {/* <!-- START Form Control--> */}
                        <div className={attachedClasses.join(" ")}>
                          <div className={"controls"}>
                            {/* <input
                              type="text"
                              className={classes.FormControl}
                              placeholder="Filter by Transaction Detail"
                              name="transactionDetail"
                            /> */}
                            <Field
                              label="Transaction Detail"
                              name="transactionDetail"
                              component={TextInput}
                              type="text"
                              className={classes.FormControl}
                              placeholder="Filter by Transaction Detail"
                              required="required"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
              <div
                className={classes.ToolsDataExportIconWrapper}
                onClick={() => exportToExcel()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div style={{ flexDirection: "column" }}>
            <div>
              <table className={classes.SummaryTable}>
                <thead>
                  <tr>
                    <th scope="col">Sales Total</th>
                    <th scope="col">Failed Count</th>
                    <th scope="col">Commissions</th>
                    <th scope="col">Deposits</th>
                    <th scope="col">Reversals</th>
                    {/* <th scope="col">Withdrawals</th> */}
                    {/* <th scope="col">Contracts</th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr className={classes.Totals}>
                    <td onClick={() => filterSales()}>
                      {moneyFormat(salesTotal)}
                    </td>
                    <td onClick={() => filterFailed()}>{failedCount}</td>

                    <td onClick={() => filterCommissions()}>
                      {moneyFormat(commissionTotal)}
                    </td>
                    <td onClick={() => filterDeposits()}>
                      {moneyFormat(depositsTotal)}
                    </td>
                    <td onClick={() => filterReversals()}>
                      {moneyFormat(reversalsTotal)}
                    </td>
                    {/* <td onClick={() => filterWithdrawals()}>
                      {moneyFormat(withdrawalsTotal)}
                    </td>
                    <td onClick={() => filterContracts()}>
                      {moneyFormat(contractsTotal)}
                    </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ display: "flex", marginTop: "3rem" }}>
              {transactionList}
              {transactionDetailPanelOpen ? (
                <div className={classes.TransactionDetail}>
                  <div
                    className={"card card-transparent"}
                    style={{ paddingLeft: "1.5rem" }}
                  >
                    <div
                      className={"card-header "}
                      style={{
                        minHeight: "3.7rem",
                        padding: "0.8rem",
                        color: "#666",
                        fontSize: "1.4rem",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                      }}
                    >
                      <div
                        className={"card-title semi-bold"}
                        style={{
                          color: "#666",
                          fontSize: "1.4rem",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          textTransform: "none",
                          marginLeft: "0.7rem",
                        }}
                      >
                        Transaction Detail
                      </div>
                      <div className={"card-controls"}>
                        <ul>
                          <li>
                            <a
                              data-toggle="close"
                              className={"card-close"}
                              href="#"
                              onClick={this.handleCloseClick}
                            >
                              <i className={"card-icon card-icon-close"} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className={"card-body"}
                      style={{ padding: "0 1.5rem" }}
                    >
                      <Fragment>
                        <div className={"row column-seperation"}>
                          <div className={"col-md-12"}>
                            <SelectedTransaction
                              transaction={selectedTransaction}
                              closePanel={this.handleCloseClick}
                            />
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  getTransactionsDetailsForDashboard,
  getTransactionsForDashboard,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state, match) => ({
  match: match,
  transactions: state.merchantTransactions,
  requesting: state.firestore.status.requesting,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  initialValues: {
    outletId: "",
    transactionStartDate: moment().startOf("day"),
    transactionEndDate: moment().endOf("day"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterTransactionsForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(Transactions)
);
