import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
import { moneyFormat } from "../../../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const index = (props) => {
  let { transaction, index } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };


  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  const transactionDetail = (transaction) => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.saleType + " - " + transaction.provider
          : transaction.saleType + " - " + transaction.transactionDetail;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank + " - " + transaction.depositMethod
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType + " - " + transaction.reversalReference;
        break;
      case "Withdrawal":
        return (
          transaction.withdrawalType + " - " + transaction.withdrawalDetail
        );
        break;
      case "Commission":
        return transaction.commissionType + " - " + transaction.provider;
        break;
      case "DeviceContract":
        return transaction.transactionType + " - " + transaction.contract;
        break;
      default:
        return transaction.saleType + " - " + transaction.transactionDetail;
    }
  };

  return (
    <Fragment>
      {/* <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>{transaction.outletId}</td>

        <td>{transaction.meterNumber}</td>
        <td>{moneyFormat(transaction.transactionAmount)}</td>
        <td>{transaction.isRedeemed ? "Yes" : "No"}</td>
      </tr> */}
      {/* 
      <tr
                      key={index}
                      onClick={() => this.handleClick()}
                      className={
                        this.state.selectedRow === index
                          ? classes.SelectedTransaction
                          : classes.MerchantRow
                      }
                    > */}
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>
          {transaction.outletId ? transaction.outletId : transaction.account}
        </td>
        <td>
          {transaction.transactionType === "DeviceContract"
            ? transaction.deviceContractTransactionType
            : transaction.transactionType}
        </td>
        <td>{transactionDetail(transaction)}</td>
        <td>{moneyFormat(transaction.transactionAmount)}</td>
        <td>
          <div style={{height:"24px",width:"24px"}}>
{transaction.transactionStatus === "Successful" ? <span><svg focusable="false" fill="#7AB317" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleOutlineIcon">
              <path d="M16.59 7.58 10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg></span>  : <svg  focusable="false" fill="#FC100D" aria-hidden="true" viewBox="0 0 24 24" data-testid="CancelOutlinedIcon">
              <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z">
                </path></svg>
              }
          </div>
          
        </td>
        {/* <td>
          {transaction.vendorBalance
            ? moneyFormat(transaction.vendorBalance)
            : "N/A"}
        </td> */}
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="7">
            <Detail transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default index;
