import firebase from "../../config/firebase";

export const asyncMerchantNameValidate = async (values) => {
  const firestore = firebase.firestore();

  console.log("asyncname", values);

  return await firestore
    .collection("merchants")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (
          doc.data().name.toLowerCase() === values.merchantName.toLowerCase()
        ) {
          throw { merchantName: "Merchant name is taken.." };
        }
      })
    );
};

export const asyncValidateAndGetMerchantName = async (values) => {
  const firestore = firebase.firestore();

  return await firestore
    .collection("merchants")
    .doc(values.transfer_to_account)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        return {
          isValidAccount: true,
          merchantName: doc.data().name,
          merchantAccount: doc.data().account,
        };
      } else {
        return {
          isValidAccount: false,
          merchantName: null,
          merchantAccount: null,
        };
      }
    });
};

export const asyncOutletNameValidate = async (values) => {
  const firestore = firebase.firestore();

  console.log("asyncname", values);

  return await firestore
    .collection("merchants")
    .doc(values.account)
    .get()
    .then((doc) => {
      Object.values(doc.outlets).forEach((doc) => {
        if (doc.name.toLowerCase() === values.outletName.toLowerCase()) {
          throw { merchantName: "Outlet name is taken" };
        }
      });
    });
};

export const asyncValidateImei = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("imei", "==", values.imei)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};
export const asyncValidateImei2 = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("imei2", "==", values.imei2)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};
export const asyncValidateMacAddress = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("macAddress", "==", values.macAddress)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};
export const asyncValidateSerialNumber = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("serialNumber", "==", values.serialNumber)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};
export const asyncValidatePiNumber = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().piNumber === values.piNumber) {
          throw { piNumber: "Purchase Invoice Id already exists" };
        }
      })
    );
};

export const asyncValidateSimNumber = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("simNumber", "==", values.simNumber)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};

export const asyncValidatePhoneNumber = async (values) => {
  const firestore = firebase.firestore();
  return await firestore
    .collection("inventory")
    .where("cellNumber", "==", values.cellNumber)
    .get()
    .then((doc) => {
      if (doc.empty) {
        return false;
      } else {
        return true;
      }
    });
};
export const asyncValidateAccountNumber = async (account) => {
  const firestore = firebase.firestore();

  return await firestore
    .collection("merchants")
    .doc(account)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return true;
      } else {
        return false;
      }
    });
};
export const asyncValidateReceiptITCompositeKey = async (values) => {
  const firestore = firebase.firestore();
  console.log("validating", values.compositeKey);
  return await firestore
    .collection("receiptITMock1")
    .where("compositeKey", "==", values.compositeKey)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        return false;
      } else {
        return true;
      }
    });
};

// const asyncValidateAccountNumber = async (account) => {
//   return await admin.db
//     .collection("merchants")
//     .doc(account)
//     .get()
//     .then((doc) => {
//       if (doc.exists) {
//         console.log("doc.exists", doc.exists);
//         return true;
//       } else {
//         console.log("doc.exists", doc.exists);
//         return false;
//       }
//     });
// };

// export const asyncMACAddressValidate = async values => {
//   const firestore = firebase.firestore();

//   if (values.macAddress) {
//     return await firestore
//       .collection("inventory")
//       .get()
//       .then(querySnapshot =>
//         querySnapshot.forEach(function(doc) {
//           if (doc.data().macAddress === values.macAddress) {
//             throw { macAddress: "MAC Addreess name is registered" };
//           }
//         })
//       );
//   }

//   if (values.serialNumber) {
//     return await firestore
//       .collection("inventory")
//       .get()
//       .then(querySnapshot =>
//         querySnapshot.forEach(function(doc) {
//           if (doc.data().serialNumber === values.serialNumber) {
//             throw { serialNumber: "Serial Number is already registered" };
//           }
//         })
//       );
//   }

// };

export const asyncInventoryDevicesValidate = async (values) => {
  const firestore = firebase.firestore();
  await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().macAddress === values.macAddress) {
          throw { macAddress: "MAC Addreess name is registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().serialNumber === values.serialNumber) {
          throw { serialNumber: "Serial is already registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().imei === values.imei) {
          throw { imei: "IMEI Number is already registered" };
        }
      })
    );
};

export const asyncInventoryComputersValidate = async (values) => {
  const firestore = firebase.firestore();
  await firestore
    .collection("outlet_computers")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().macAddress === values.macAddress) {
          throw { macAddress: "MAC Addreess name is registered" };
        }
      })
    );
};

export const asyncInventorySIMValidate = async (values) => {
  const firestore = firebase.firestore();
  await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().simNumber === values.simNumber) {
          throw { simNumber: "SIM Number name is registered" };
        }
      })
    );

  await firestore
    .collection("inventory")
    .get()
    .then((querySnapshot) =>
      querySnapshot.forEach(function (doc) {
        if (doc.data().cellNumber === values.cellNumber) {
          throw { cellNumber: "Cell Number is already registered" };
        }
      })
    );
};

// export const asyncSerialNumberValidate = async values => {
//   const firestore = firebase.firestore();

//   return await firestore
//     .collection("inventory")
//     .get()
//     .then(querySnapshot =>
//       querySnapshot.forEach(function(doc) {
//         if (doc.data().serialNumber === values.serialNumber) {
//           throw { serialNumber: "Serial Number is already registered" };
//         }
//       })
//     );
// };
