import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import AmendDeviceContractForm from "../inventory/Devices/Contract/AmendDeviceContractForm";

const actions = { closeModal };

const amendDeviceContractModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Amend Device Contract" onClose={closeModal}>
      <AmendDeviceContractForm />
    </Modal>
  );
};

export default connect(null, actions)(amendDeviceContractModal);
