import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { format, parse } from "date-fns/format";
import { moneyFormat } from "../../../app/common/util/moneyFormat";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { asyncValidateReceiptITCompositeKey } from "../../../app/common/validation/asyncValidate.jsx";
import { useDropzone } from "react-dropzone";

import classes from "./Deposits.module.css";
import { batchWriteDeposits } from "./../depositsActions";
import LoadingComponent from "../../../app/layout/LoadingComponent.jsx";
const Joi = require("joi");

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};
const actions = {};

function compositeKeyBuilder(record) {
  return `${record.Date}-${record.Amount}-${record.REFERENCE}-${record.Balance}`;
}

const depositRecord = Joi.object({
  Amount: Joi.number().required(),
  Balance: Joi.number().required(),
  "CHEQUE NUMBER": Joi.number().required(),
  DESCRIPTION: Joi.string().required(),
  Date: Joi.string().required(),
  REFERENCE: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
  "SERVICE FEE": Joi.number().required(),
}).messages({
  "number.base": "line {{#line}}, {{#label}} is required",
});

const listOfDepositRecords = Joi.array().items(depositRecord);

const ManualUpload = ({ handleSubmit, error }) => {
  async function submitDeposits(values) {
    // console.log(parsedRecords);
    await batchWriteDeposits([...validRecords, ...conflictingRecords]);
    setDepositUploadDone(true);
    setParsedRecords([]);
    setConflictingRecords([]);
  }
  const [parsedRecords, setParsedRecords] = useState([]);
  const [validRecords, setValidRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(null);

  const [conflictingRecords, setConflictingRecords] = useState([]);
  const [localConflictingRecords, setLocalConflictingRecords] = useState([]);
  const [depositUploadDone, setDepositUploadDone] = useState(false);

  useEffect(() => {
    validateLocalConflicts();
    validateConflicts();
  }, [parsedRecords]);

  function getTotalDepositAmount() {
    let validRecordSum = 0;
    let conflictingRecordSum = 0;

    validRecords.map((value) => {
      validRecordSum += value.Amount;
    });

    conflictingRecords.map((value) => {
      conflictingRecordSum += value.Amount;
    });
    return validRecordSum + conflictingRecordSum;
  }
  function getTotalValidDepositAmount() {
    let validRecordSum = 0;

    validRecords.map((value) => {
      validRecordSum += value.Amount;
    });

    return validRecordSum;
  }
  function getTotalConflictingDepositAmount() {
    let sum = 0;

    conflictingRecords.map((value) => {
      sum += value.Amount;
    });

    return sum;
  }
  function getTotalDepositCount() {
    return parsedRecords.length;
  }

  function validateLocalConflicts() {
    const localConflictsSet = new Set();
    let allLocalConflicts = [];

    parsedRecords.forEach((record, index) => {
      const filteredObjects = parsedRecords.filter(
        (obj) => obj.compositeKey === record.compositeKey
      );

      if (filteredObjects.length > 1) {
        console.log(
          `index ${index} conflicting local records`,
          filteredObjects
        );

        filteredObjects.forEach((object) => {
          object.objectisduplicate = true;

          localConflictsSet.add(object);
        });
      }
    });

    allLocalConflicts = [...localConflictsSet];
    console.log("All local conflicts", allLocalConflicts);
    setLocalConflictingRecords((prevRecords) => [
      ...prevRecords,
      ...allLocalConflicts,
    ]);
  }

  async function validateConflicts() {
    let records = [];
    let conflictingRecords = [];
    let validRecords = [];

    for (const [index, value] of parsedRecords.entries()) {
      setLoading(true);
      if (
        value["REFERENCE"] != undefined &&
        value.upload === true &&
        value.Amount > 0
      ) {
        let objectisduplicate = parsedRecords.find((obj) => {
          return obj.compositeKey === value.compositeKey;
        });

        records.push({
          ...value,
          objectExists: objectisduplicate ? true : false,
        });
      }
      const validateCompositeKey = await asyncValidateReceiptITCompositeKey(
        value
      );

      if (validateCompositeKey) {
        conflictingRecords.push(value);
      } else {
        validRecords.push(value);
      }
      console.log("conflicting records", conflictingRecords);
    }
    setValidRecords(validRecords);
    setConflictingRecords((prevLocalCOnflicts) => [
      ...conflictingRecords,
      ...prevLocalCOnflicts,
      ...localConflictingRecords,
    ]);
    setLoading(false);
  }
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = (e) => {
        setValidationError(null);
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // callback(XLSX.utils.sheet_to_json(worksheet));

        const records = XLSX.utils.sheet_to_json(worksheet);
        // console.log("records!!!", records);
        const validateRecords = listOfDepositRecords.validate(records);

        if (validateRecords.error) {
          setValidationError(validateRecords.error.message);
        } else {
          //for of records - validate vs online repository (composite key matches online - record.conflict = true;)
          let depositRecords = [];
          records.forEach((record, index) => {
            //conficlt test

            // record.conflict = awat conflictTest;
            record.id = index;
            record.upload = true; //toggling
            record.compositeKey = compositeKeyBuilder(record);
            record.uniqueId = uuidv4();
            if (
              !record.DESCRIPTION.includes("TRF") ||
              !record.DESCRIPTION.includes("FEE")
            ) {
              depositRecords.push(record);
            }
          });

          console.log("records", records);

          setParsedRecords(depositRecords);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const toggleUpload = (index) => {
    console.log("index", index);
    console.log("parsed records", parsedRecords);

    const deposit = conflictingRecords[index];

    deposit.upload = !deposit.upload;

    console.log("deposit", deposit);

    setConflictingRecords([...conflictingRecords]);
  };

  return (
    <div label={`Transactions`}>
      <div className={classes.Container}>
        <div className={classes.MerchantTableWrapper}>
          {parsedRecords.length === 0 ? (
            <div className={"row clearfix"}>
              <div className={"col-sm-4"}>
                <div className={classes.Dropzone}>
                  <div {...getRootProps()}>
                    <input
                      {...getInputProps()}
                      name="excelDropzone"
                      // required
                    />
                    <div className={classes.CenteredDiv}>
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  </div>
                </div>
                {!!validationError ? validationError : null}
              </div>
            </div>
          ) : null}
          {loading ? <LoadingComponent inverted={true} /> : null}
          {conflictingRecords.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <div>
                  <h5>
                    Total Deposit Amount:{" "}
                    <strong>{getTotalDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Valid Deposit Amount:{" "}
                    <strong>{getTotalValidDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Conflicting Deposit Amount:{" "}
                    <strong>{getTotalConflictingDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Deposit Count:{" "}
                    <strong>{getTotalDepositCount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5> </h5>
                </div>
              </div>

              <div>
                <div style={{ paddingBottom: "30px" }}>
                  <table className={classes.MerchantTable}>
                    <thead>
                      <tr>
                        <th scope="col">Composite Id</th>
                        <th scope="col">Date</th>
                        <th scope="col">ServiceFee</th>
                        <th style={{ textAlign: "right" }} scope="col">
                          Amount
                        </th>
                        <th scope="col">Description</th>
                        <th scope="col">Reference</th>
                        <th style={{ textAlign: "right" }} scope="col">
                          Balance
                        </th>
                        <th scope="col">ChequeNumber</th>
                        <th scope="col">Conflicting locally</th>
                        <th scope="col">Upload Record</th>
                      </tr>
                    </thead>
                    <tbody>
                      {conflictingRecords.map((transaction, index) => {
                        return (
                          <tr
                            className={
                              transaction.objectExists
                                ? classes.Totals
                                : classes.MemberRow
                            }
                            key={transaction.uniqueId}
                          >
                            <td>{transaction.compositeKey}</td>

                            <td>{transaction.Date}</td>
                            <td>{moneyFormat(transaction["SERVICE FEE"])}</td>

                            <td style={{ textAlign: "right" }}>
                              {moneyFormat(transaction.Amount)}
                            </td>
                            <td>{transaction["DESCRIPTION"]}</td>
                            <td>{transaction["REFERENCE"]}</td>
                            <td style={{ textAlign: "right" }}>
                              {moneyFormat(transaction.Balance)}
                            </td>
                            <td>{transaction["CHEQUE NUMBER"]}</td>
                            <td>
                              {transaction.objectisduplicate ? `Yes` : `No`}
                            </td>
                            <td onClick={() => toggleUpload(index)}>
                              {transaction.upload ? "Yes" : "No"}
                            </td>
                          </tr>
                        );
                      })}
                      {conflictingRecords.length > 0
                        ? console.log("allRecords", conflictingRecords)
                        : null}
                    </tbody>
                  </table>
                </div>
              </div>
              <form onSubmit={handleSubmit(submitDeposits)}>
                <button className={"btn btn-primary btn-cons m-t-10"}>
                  Submit deposits
                </button>
              </form>
            </>
          ) : null}
          {conflictingRecords.length == 0 && validRecords.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <div>
                  <h5>
                    Total Deposit Amount:{" "}
                    <strong>{getTotalDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Valid Deposit Amount:{" "}
                    <strong>{getTotalValidDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Conflicting Deposit Amount:{" "}
                    <strong>{getTotalConflictingDepositAmount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5>
                    Total Deposit Count:{" "}
                    <strong>{getTotalDepositCount()}</strong>
                  </h5>
                </div>
                <div>
                  <h5> </h5>
                </div>
              </div>
              <form onSubmit={handleSubmit(submitDeposits)}>
                <button className={"btn btn-primary btn-cons m-t-10"}>
                  Submit deposits
                </button>
              </form>
            </>
          ) : null}

          {depositUploadDone ? <div>Transaction upload done</div> : null}
        </div>
      </div>
    </div>
  );
};
// export default ManualUpload
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "bb",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(ManualUpload)
);
