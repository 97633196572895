import React, { Component } from "react";
import { connect } from "react-redux";

import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";

import { combineValidators, isRequired } from "revalidate";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { makeAdhocPayment } from "../../inventoryActions";
import TextInput from "../../../../app/common/form/TextArea";

import styles from "./AdhocDevicePaymentForm.module.css";

const validate = combineValidators({
  device: isRequired("device"),
  devicePlan: isRequired("devicePlan"),
  sim: isRequired("sim"),
});

class AdhocDevicePaymentForm extends Component {
  render() {
    const { makeAdhocPayment, initialValues, loading, handleSubmit, error } =
      this.props;

    const { deviceContract } = initialValues;

    console.log("deviceContract", deviceContract);
    const onFormSubmit = (values) => {
      makeAdhocPayment(values);
    };

    return (
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <br />

        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Current Contract: ${deviceContract.plan}`}
            </h5>
          </div>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Remaining Period: ${deviceContract.plan}`}
            </h5>
          </div>
        </div>
        <div className={"row clearfix"}>
          <div className={"col-md-6"}>
            <h5 style={{ marginTop: 0 }}>
              {`Amount Outstanding: ${deviceContract.amountOutstanding}`}
            </h5>
          </div>
          <div className={"col-md-6"}>
            <Field
              label="Payment Amount"
              name="paymentAmount"
              component={TextInput}
              type="text"
              className={"form-control"}
              placeholder="Amount"
              required="required"
            />
          </div>
        </div>

        <br />
        {error && <label className={"error semi-bold"}>{error}</label>}
        <button className={styles.btn} type="submit" disabled={loading}>
          {loading ? (
            <Spinner
              style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
            />
          ) : (
            <span>Make Payment</span>
          )}
        </button>
      </form>
    );
  }
}

const query = () => {
  return [
    {
      collection: "devicePaymentPlans",
      storeAs: "devicePaymentPlans",
      orderBy: ["orderingNumber", "asc"],
    },
  ];
};

const mapStateToProps = (state) => ({
  devicePaymentPlans:
    state.firestore.ordered.devicePaymentPlans &&
    state.firestore.ordered.devicePaymentPlans.map((item) => {
      return {
        label: `${item.plan}`,
        value: item,
      };
    }),

  initialValues: {
    deviceContract: state.modals.modalProps.deviceContract,
    outletName: state.modals.modalProps.outletName,
    group: state.modals.modalProps.group,
    merchantName: state.modals.modalProps.merchantName,
  },
  loading: state.async.loading,
});

const actions = {
  makeAdhocPayment,
};

export default compose(
  connect(mapStateToProps, actions),
  reduxForm({
    form: "AdhocDevicePaymentForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  }),
  firestoreConnect(query)
)(AdhocDevicePaymentForm);
