//import liraries
import React, { Fragment, useState } from "react";

import classes from "./MerchantList.module.css";
import MerchantListItem from "./MerchantListItem/MerchantListItem";

// create a component
const merchantsList = props => {
  // const [test, setTest] = useState({ test: "Test" });

  console.log("props", props);

  const merchants = props.merchants.map(merchant => {
    return <MerchantListItem key={merchant.account} merchant={merchant} />;
  });

  let numberOfRecords = merchants.length;
  let attachedPaginateActiveClasses = [classes.paginate_button, classes.active];

  let attachedPaginateSimpleNumbersClasses = [
    classes.dataTables_paginate,
    classes.paging_simple_numbers
  ];

  let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

  return (
    <Fragment>
      <table className={classes.MerchantTable}>
        <thead>
          <tr>
            <th scope="col">Merchant</th>
            <th className={"hide-s"} scope="col">
              Account Number
            </th>
            <th className={"hide-s"} scope="col">
              Contact Number
            </th>
            <th className={"hide-s"} scope="col">
              Group
            </th>
          </tr>
        </thead>
        <tbody>{merchants}</tbody>
      </table>

      <div className={classes.Row}>
        <div>
          <div className={attachedPaginateSimpleNumbersClasses.join(" ")}>
            <ul>
              <li>
                <a href="">
                  <i className={classes.pg_arrow_left} />
                </a>
              </li>
              <li className={attachedPaginateActiveClasses.join(" ")}>
                <a href="">1</a>
              </li>
              <li
                className={attachedPaginateNextClasses.join(" ")}
                id="tableWithExportOptions_next"
                onClick={props.getNextMerchants}
              >
                <span>
                  <i className={classes.pg_arrow_right} />
                </span>
              </li>
            </ul>
          </div>
          <div
            className={classes.dataTables_info}
            id="tableWithExportOptions_info"
            role="status"
            aria-live="polite"
          >
            Showing <b>1 to {merchants.length}</b> of {numberOfRecords} entries
          </div>
        </div>
      </div>
    </Fragment>
  );
};

//make this component available to the app
export default merchantsList;
