//import liraries
import React, { useState, Fragment } from "react";

import { connect } from "react-redux";

import format from "date-fns/format";
import moment from "moment";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";

import "react-dates/lib/css/_datepicker.css";

import { getOozeDailySalesForExport } from "../../reportActions";

import LoadingComponent from "../../../../app/layout/LoadingComponent";
// import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import { openModal } from "../../../modals/modalActions";
import classes from "./index.module.css";

const index = ({ initialValues, openModal, getOozeDailySalesForExport }) => {
  let [loading, setLoading] = useState(false);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());
  let [today, setToday] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  //  async function fetchComment(commentId) {
  //   // You can await here
  // }

  // useEffect(() => {
  //   fetchComment(commentId);
  // }, [commentId]);

  // useEffect(() => {
  //   getOozeDailySalesForExport({
  //     reportStartDate: moment(initialValues.reportStartDate)
  //       .startOf("day")
  //       .toDate(),
  //     reportEndDate: moment(initialValues.reportEndDate).endOf("day").toDate(),
  //   }).then(() => {
  //     setLoadingInitial(false);
  //   });

  //   return () => {
  //     clearFinancialTransactions();
  //   };
  // }, []);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  // var ts = moment("10/15/2014 9:00", "M/D/YYYY H:mm").valueOf();

  // const handleCustomDatesClick = async () => {
  //   console.log("dates", startDate, endDate);

  //   let reportStartDate = startDate
  //     ? startDate.startOf("day").toDate()
  //     : endDate.startOf("day").toDate();
  //   let reportEndDate = endDate
  //     ? endDate.endOf("day").toDate()
  //     : startDate.endOf("day").toDate();

  //   // setLoading(true);

  //   // await getOozeDailySalesForExport({
  //   //   reportStartDate: reportStartDate,
  //   //   reportEndDate: reportEndDate,
  //   // });

  //   // setLoading(false);
  // };

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Reports - Ooze Daily Sales</span>
            <br />
            <span className={classes.ByLine}>
              You are in the PrepaidPlus Reports panel
            </span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <h5>
                        <strong>{format(startDate, "YYYY-MM-DD")}</strong>
                        {` to `}
                        <strong>{format(endDate, "YYYY-MM-DD")}</strong>
                      </h5>
                    </div>
                    <div
                      className={"row"}
                      style={{ alignItems: "center", flex: "1 1 auto" }}
                    >
                      <div className={"col-md-6"}>
                        <div className={classes.FilterWrapper}></div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className={classes.PageFilter}>
                          <div className={"row"}>
                            <DateRangePicker
                              startDate={startDate} // momentPropTypes.momentObj or null,
                              startDateId="startDate" // PropTypes.string.isRequired,
                              endDate={endDate} // momentPropTypes.momentObj or null,
                              endDateId="endDate" // PropTypes.string.isRequired,
                              onDatesChange={({ startDate, endDate }) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                              }} // PropTypes.func.isRequired,
                              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                              onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                              numberOfMonths={1}
                              isOutsideRange={(date) => date.isAfter(today)}
                              displayFormat={"YYYY-MM-DD"}
                              minimumNights={0}
                              small={true}
                              readOnly={true}
                            />

                            {/* <button
                              className={classes.FilterReportBtn}
                              style={{ marginLeft: "1rem" }}
                              onClick={() => handleCustomDatesClick()}
                            >
                              <svg
                                height="18"
                                width="18"
                                viewBox="0 0 18 18"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    id="path1"
                                    transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                    fill="#FFFFFF"
                                    d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                                  />
                                </g>
                              </svg>
                            </button> */}
                          </div>

                          {/* <!-- END Form Control--> */}
                        </div>
                      </div>
                    </div>
                  </header>

                  {loading ? (
                    <LoadingComponent inverted={true} />
                  ) : (
                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              <div className={"col-lg-3"}>
                                <button
                                  className={classes.SalesBtn}
                                  onClick={() =>
                                    openModal(
                                      "OozeDailySalesReportSpinnerModal",
                                      {
                                        reportStartDate: moment(
                                          startDate.toDate()
                                        ).format("YYYY-MM-DD"),
                                        reportEndDate:
                                          endDate.format("YYYY-MM-DD"),
                                      }
                                    )
                                  }
                                >
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        height: "24px",
                                        width: "24px",
                                        verticalAlign: "bottom",
                                      }}
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-6 h-6"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
                                      />
                                    </svg>
                                  </span>{" "}
                                  Download Ooze Daily Report
                                </button>
                              </div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                              <div className={"col-lg-3"}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

const actions = {
  getOozeDailySalesForExport,
  openModal,
};

export default connect(mapStateToProps, actions)(index);

// const mapStateToProps = (state) => ({
//   startingBalance: state.financials.startingBalance,
//   closingBalance: state.financials.closingBalance,
//   deposits: state.financials.depositsTotal,
//   reversalsTotal: state.financials.reversalsTotal,
//   withdrawals: state.financials.withdrawalsList,
//   withdrawalsTotal: state.financials.withdrawalsTotal,
//   sales: state.financials.totalsByProvider,
//   salesTotal: state.financials.salesTotal,
//   financials: state.financials.summary,
//   deviceFees: state.financials.deviceFees,
//   providerBalances: state.financials.providerBalances,
//   commissionCredited: state.financials.creditedCommissionListTotal,
//   merchantCommissionTotal: state.financials.earnedCommissionTotal,
//   prepaiPlusCommissionTotal: state.financials.vendorCommissionEarnedTotal,
// });

// export default connect(mapStateToProps)(index);
