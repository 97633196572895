import React from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import CreateSelfServiceOutlet from "../merchants/Merchant/MerchantProfile/Outlets/CreateSelfServiceOutlet";

const actions = { closeModal };

const createSelfServiceOutletModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create Self-Service: Outlet" onClose={closeModal}>
      <CreateSelfServiceOutlet />
    </Modal>
  );
};

export default connect(null, actions)(createSelfServiceOutletModal);
