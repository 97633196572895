import React, { Component, Fragment } from "react";
import { connect } from "react-redux";


import format from "date-fns/format";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

import moment from "moment";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";
import { openModal } from "../../../modals/modalActions";
import { createReportExcelFile } from "../../../../app/excel/excel";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

import { getPrepaidPlusTransactionsForDashboard } from "../../reportActions";

import classes from "./Itemised.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId")
});

class ItemisedReport extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    startDate: moment(),
    endDate: moment(),
    today: moment()
  };

  async componentDidMount() {
    await this.props.getPrepaidPlusTransactionsForDashboard({
      reportStartDate: new Date(this.props.initialValues.reportStartDate),
      reportEndDate: new Date(this.props.initialValues.reportEndDate)
    });

    this.setState({ loadingInitial: false });
  }

  // getGovenmentLevyReport = (
  //   report,
  //   reportStartDate,
  //   reportEndDate,
  //   merchantName
  // ) => {
  //   // Create a new instance of a Workbook class
  //   let wb = new xl.Workbook();
  //   // Add Worksheets to the workbook
  //   let wsTransactions = wb.addWorksheet("Itemised Sales");

  //   // Create a reusable style
  //   var style = wb.createStyle({
  //     font: {
  //       name: "Verdana",
  //       color: "#000000",
  //       size: 9
  //     },
  //     alignment: {
  //       vertical: "center",
  //       horizontal: "center"
  //     }
  //   });

  //   var headerStyle = wb.createStyle({
  //     font: {
  //       name: "Verdana",
  //       color: "#FFFFFF",
  //       size: 10,
  //       bold: true
  //     },
  //     fill: {
  //       // §18.8.20 fill (Fill)
  //       type: "pattern", // Currently only 'pattern' is implemented. Non-implemented option is 'gradient'
  //       patternType: "solid", //§18.18.55 ST_PatternType (Pattern Type)
  //       bgColor: "#002060", // HTML style hex value. defaults to black
  //       fgColor: "#002060"
  //     },
  //     alignment: {
  //       vertical: "center"
  //     }
  //   });

  //   var dateStyle = wb.createStyle({
  //     font: {
  //       name: "Verdana",
  //       color: "#FFFFFF",
  //       size: 10,
  //       bold: true
  //     },
  //     fill: {
  //       // §18.8.20 fill (Fill)
  //       type: "pattern", // Currently only 'pattern' is implemented. Non-implemented option is 'gradient'
  //       patternType: "solid", //§18.18.55 ST_PatternType (Pattern Type)
  //       bgColor: "#003060", // HTML style hex value. defaults to black
  //       fgColor: "#003060"
  //     },
  //     alignment: {
  //       vertical: "center"
  //     }
  //   });

  //   var reportHeaderStyle = wb.createStyle({
  //     font: {
  //       name: "Calibri",
  //       color: "#000000",
  //       size: 20,
  //       bold: true
  //     },
  //     alignment: {
  //       vertical: "center"
  //     }
  //   });

  //   let startDate = new Date(reportStartDate).toDateString();
  //   let endDate = new Date(reportEndDate).toDateString();

  //   wsTransactions.cell(1, 1, 2, 7, true);
  //   wsTransactions.cell(3, 1, 3, 7, true).string(`${merchantName}`);
  //   wsTransactions.cell(4, 1, 4, 7, true);
  //   wsTransactions
  //     .cell(5, 1, 5, 7, true)
  //     .string("Sales for Period")
  //     .style(reportHeaderStyle);
  //   wsTransactions.cell(6, 1, 6, 7, true);
  //   wsTransactions
  //     .cell(7, 1, 7, 7, true)
  //     .string(`Date From ${startDate} to ${endDate}`);
  //   wsTransactions.cell(8, 1, 8, 7, true);

  //   // wsTransactions.addImage({
  //   //   path: "../../../../assets/logo.png",
  //   //   name: "logo", // name is not required param
  //   //   type: "picture",
  //   //   position: {
  //   //     type: "absoluteAnchor",
  //   //     x: "0.5mm",
  //   //     y: "0.5mm"
  //   //   }
  //   // });

  //   // Set value of cell A1 to 100 as a number type styled with paramaters of style
  //   wsTransactions
  //     .cell(9, 1)
  //     .string("Date Time")
  //     .style(headerStyle);

  //   wsTransactions
  //     .cell(9, 2)
  //     .string("Outlet ID")
  //     .style(headerStyle);

  //   wsTransactions
  //     .cell(9, 3)
  //     .string("Sale Type")
  //     .style(headerStyle);

  //   wsTransactions
  //     .cell(9, 4)
  //     .string("Transaction Detail")
  //     .style(headerStyle);

  //   wsTransactions
  //     .cell(9, 5)
  //     .string("Government Levy")
  //     .style(headerStyle);

  //   wsTransactions
  //     .cell(9, 5)
  //     .string("Sale Value")
  //     .style(headerStyle);

  //   wsTransactions.row(9).setHeight(25);
  //   wsTransactions.column(1).setWidth(20);
  //   wsTransactions.column(2).setWidth(10);
  //   wsTransactions.column(3).setWidth(10);
  //   wsTransactions.column(4).setWidth(20);
  //   wsTransactions.column(5).setWidth(25);
  //   wsTransactions.column(6).setWidth(20);
  //   // wsTransactions.column(7).setWidth(12);

  //   let index = 10;
  //   let total = 0;

  //   report.forEach(sale => {
  //     // let transactionDateTime = new Date(sale.transactionDateTime).toISOString().slice(0, 19);

  //     // format(
  //     //   report.transactionDateTime.toDate(),
  //     //   "YYYY-MM-DD  HH:mm:ss"
  //     // )
  //     // .date(new Date(sale.transactionDateTime))

  //     // console.log("sale", sale);

  //     let transactionDateTime = format(
  //       sale.transactionDateTime.toDate(),
  //       "YYYY-MM-DD  HH:mm:ss"
  //     );

  //     wsTransactions
  //       .cell(index, 1)
  //       .date(transactionDateTime)
  //       .style(dateStyle);

  //     wsTransactions
  //       .cell(index, 2)
  //       .string(sale.outletId ? sale.outletId : sale.account)
  //       .style(style);

  //     wsTransactions
  //       .cell(index, 3)
  //       .string(sale.saleType)
  //       .style(style);

  //     wsTransactions
  //       .cell(index, 4)
  //       .string(
  //         sale.saleType === "Airtime" ? sale.provider : sale.transactionDetail
  //       )
  //       .style(style);

  //     wsTransactions
  //       .cell(index, 5)
  //       .string(
  //         sale.response
  //           ? sale.response.governmentLevy
  //             ? moneyFormat(parseFloat(sale.response.governmentLevy))
  //             : moneyFormat(0)
  //           : moneyFormat(0)
  //       )
  //       .style(style);

  //     wsTransactions
  //       .cell(index, 6)
  //       .string(moneyFormat(sale.transactionAmount))
  //       .style(style);

  //     total = total + sale.transactionAmount;
  //     index++;
  //   });

  //   wsTransactions
  //     .cell(index, 6)
  //     .string("Totals")
  //     .style(style);

  //   wsTransactions
  //     .cell(index, 7)
  //     .number(total)
  //     .style({
  //       numberFormat: "#,##0.00",
  //       name: "Verdana",
  //       color: "#000000",
  //       size: 9,
  //       bold: true
  //     });

  //   console.log("wsTransactions", wsTransactions);

  //   wb.write("excel.xlsx");
  // };

  render() {
    const {
      handleSubmit,openModal,
      getPrepaidPlusTransactionsForDashboard,
      transactions,
      requesting
    } = this.props;


    const onFormSubmit = async values => {
      let reportStartDate = new Date(this.state.startDate);
      reportStartDate.setHours(0, 0, 0, 0);
      let reportEndDate = new Date(this.state.endDate);
      reportEndDate.setHours(23, 59, 59, 999);

      this.setState({ loadingInitial: true });
      await getPrepaidPlusTransactionsForDashboard({
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate
      });
      this.setState({ loadingInitial: false });
    };

    const exportToExcel = async () => {
      openModal("SpinnerModal");

   await  createReportExcelFile(
       "Transactions Report",
       transactions,
       this.transactionsSheet,
       "Prepaid Plus",
       this.state.startDate.toDate(),
       this.state.endDate.toDate()
     );
   };


    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    let reportDataSet;

    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    let group;
    let outletName;
    let reportDate;

    if (!this.state.loadingInitial) {
      let levyTotal = transactions.reduce(function(accumulator, transaction) {
        return (
          accumulator +
          (transaction.response
            ? transaction.response.governmentLevy
              ? parseFloat(transaction.response.governmentLevy)
              : 0
            : 0)
        );
      }, 0);

      let salesTotal = transactions.reduce(function(accumulator, transaction) {
        return accumulator + transaction.transactionAmount;
      }, 0);

      let firstrow =
        transactions.length > 0
          ? transactions[0]
          : { group: "None", outletName: "None" };

      group = firstrow.group;
      outletName = firstrow.outletName;

      // let salesTotal = 0;

      transactions.length > 0
        ? (itemisedReport = (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Time</th>
                  <th scope="col">Outlet ID</th>
                  <th scope="col">Sale Type</th>
                  <th scope="col">Transaction Detail</th>
                  <th scope="col">Government Levy</th>
                  <th scope="col">Sale Value</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((report, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>
                        {format(
                          report.transactionDateTime.toDate(),
                          "YYYY-MM-DD  HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {report.outletId ? report.outletId : report.account}
                      </td>
                      <td>{report.saleType}</td>
                      <td>
                        {report.saleType === "Airtime"
                          ? report.provider
                          : report.transactionDetail}
                        {/* {report.saleType + " - " + report.transactionDetail} */}
                      </td>
                      <td>
                        {report.response
                          ? report.response.governmentLevy
                            ? moneyFormat(
                                parseFloat(report.response.governmentLevy)
                              )
                            : moneyFormat(0)
                          : moneyFormat(0)}
                      </td>
                      <td>{moneyFormat(report.transactionAmount)}</td>
                      {/* <td>{report.transactionValue2}</td> */}
                    </tr>
                  );
                })}
                <tr className={classes.Totals}>
                  <td colSpan="4">
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>

                  <td className={classes.MoneyTotal}>
                    {moneyFormat(levyTotal)}
                  </td>

                  <td className={classes.MoneyTotal}>
                    {moneyFormat(salesTotal)}
                  </td>
                  {/* <td className={classes.MoneyTotal}>{salesTotal2}</td> */}
                </tr>
              </tbody>
            </table>
          ))
        : (itemisedReport = (
            <h4>Selected Group has no transactions for selected period.</h4>
          ));

      let reportData = transactions.map(report => {
        return [
          {
            value: format(
              report.transactionDateTime.toDate(),
              "YYYY-MM-DD  HH:mm:ss"
            ),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: report.outletId ? report.outletId : report.account,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: report.saleType,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: report.response
              ? report.response.governmentLevy
                ? moneyFormat(parseFloat(report.response.governmentLevy))
                : moneyFormat(0)
              : moneyFormat(0),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          },
          {
            value: moneyFormat(report.transactionAmount),
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "f8f8f8" } }
            }
          }
        ];
      });

      reportData.push([
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: "",
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } }
          }
        },
        {
          value: moneyFormat(levyTotal),
          style: {
            fill: { patternType: "solid", fgColor: { rgb: "920202" } },
            font: { sz: "12", bold: true }
          }
        }
      ]);

      // 920202;
      //4e0000

      // Itemised Sales Report
      //

      reportDate = `${this.props.initialValues.reportStartDate} to ${this.props.initialValues.reportStartDate}`;
      let reportStartDate = `Start Date: ${this.props.initialValues.reportStartDate}`;
      let reportEndDate = `End Date: ${this.props.initialValues.reportEndDate}`;
      // Period: 01 Nov 2018 To 30 Nov 2018

      // {value: "Bold", style: {font: {bold: true}}},

      reportDataSet = [
        {
          columns: [
            { title: "Headings", width: { wpx: 80 } }, //pixels width
            { title: "Text Style", width: { wch: 40 } }, //char width
            { title: "Colors", width: { wpx: 90 } }
          ],
          data: [
            [
              { value: "H1", style: { font: { sz: "24", bold: true } } },
              { value: "Bold", style: { font: { bold: true } } },
              {
                value: "Red",
                style: {
                  fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
                }
              }
            ]
          ]
        },
        {
          ySteps: 1,
          columns: [
            {
              title: "",
              width: { wpx: 890 },
              style: {
                font: { sz: "18", bold: true }
              }
            },
            { title: "", width: { wpx: 10 } }
          ],
          data: [[group, outletName]]
        },
        {
          ySteps: 1,

          columns: [
            { title: "", width: { wpx: 10 } },
            { title: "", width: { wpx: 10 } }
          ],
          data: [
            [
              {
                value: reportStartDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              },
              {
                value: reportEndDate,
                width: { wpx: 150 },
                style: {
                  font: { sz: "12", bold: true }
                }
              }
            ]
          ]
        },

        {
          // xSteps: 1, // Will start putting cell with 1 empty cell on left most
          ySteps: 1,
          columns: [
            {
              title: "Store Name",
              width: { wpx: 150 },
              style: {
                font: { sz: "16", bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "User Name",
              width: { wpx: 150 },
              style: { font: { bold: true } }
            },
            {
              title: "Date Time",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Sale Type",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Transaction Detail",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            },
            {
              title: "Sale Value",
              width: { wpx: 150 },
              style: {
                font: { bold: true },
                fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } }
              }
            }
          ],
          data: reportData
        }
      ];
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <DateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={date => date.isAfter(this.state.today)}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />

                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
               <div className={classes.ToolsDataExportIconWrapper}>
                {/* <button
                  onClick={() =>
                    this.getGovenmentLevyReport(
                      transactions,
                      this.props.initialValues.reportStartDate,
                      this.props.initialValues.reportEndDate
                    )
                  }
                  type="button"
                > */}

<button type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        id="path1"
                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                        fill="#707070"
                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                      />
                    </g>
                  </svg>
                </button>


              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>{itemisedReport}</div>
          <div className={classes.Row}>
            <div>
              <div className={attachedPaginateSimpleNumbersClasses.join(" ")}>
                <ul>
                  <li>
                    <a href="">
                      <i className={classes.pg_arrow_left} />
                    </a>
                  </li>
                  <li className={attachedPaginateActiveClasses.join(" ")}>
                    <a href="">1</a>
                  </li>
                  <li
                    className={attachedPaginateNextClasses.join(" ")}
                    id="tableWithExportOptions_next"
                  >
                    <a href="">
                      <i className={classes.pg_arrow_right} />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={classes.dataTables_info}
                id="tableWithExportOptions_info"
                role="status"
                aria-live="polite"
              >
                {/* Showing <b>1 to {numberOfRecords}</b> of {numberOfRecords}
                entries */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  getPrepaidPlusTransactionsForDashboard,createReportExcelFile,openModal
};

const mapStateToProps = state => ({
  transactions: state.transactions,
  requesting: state.firestore.status.requesting,
  initialValues: {
    reportStartDate: format(new Date(), "YYYY-MM-DD"),
    reportEndDate: format(new Date(), "YYYY-MM-DD")
  },
  enableReinitialize: true
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
  })(ItemisedReport)
);

// export default connect(
//   mapStateToProps,
//   actions
// )(reduxForm({ form: "depositForm", validate })(DepositForm));
