import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import CreateOutlet from "../merchants/Merchant/MerchantProfile/Outlets/CreateOutlet";

const actions = { closeModal };

const createOutletModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create New Outlet" onClose={closeModal}>
      <CreateOutlet />
    </Modal>
  );
};

export default connect(
  null,
  actions
)(createOutletModal);
