import React, { Component } from "react";
import Modal from "../../app/UI/Modal/Modal";
import { connect } from "react-redux";

import { closeModal } from "./modalActions";
import MerchantForm from "../merchants/MerchantForm/MerchantForm";

const actions = { closeModal };

const createMerchantModal = ({ closeModal }) => {
  return (
    <Modal modalHeader="Create Individual Merchant" onClose={closeModal}>
      <MerchantForm />
    </Modal>
  );
};

export default connect(null, actions)(createMerchantModal);
