import React from "react";
import { connect } from "react-redux";

import { formatMoney } from "../../../app/common/util/helpers";
import classes from "./index.module.css";

  const index = ({salesTotal, totalsByProvider }) => {

    return (
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>
          {totalsByProvider.length > 0 ? (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Sale Type</th>
                  <th scope="col">Provider</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {totalsByProvider.map((sale, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>{sale.saleType}</td>
                      <td>{sale.provider}</td>
                      <td>{formatMoney(sale.transactionAmount)}</td>
                    </tr>
                  );
                })}

                <tr className={classes.Totals}>
                  <td className={classes.MoneyTotal}>&nbsp;</td>{" "}
                  <td>
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {formatMoney(salesTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <h4>
              There are no <strong>sales</strong> for the selected period.
            </h4>
          )}
        </div>
      </div>
    );
  }

const mapStateToProps = (state) => ({
  salesTotal: state.financials.salesTotal,
  totalsByProvider: state.financials.totalsByProvider,
});

export default connect(mapStateToProps)(index);
