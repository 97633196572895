import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({
  isAuthenticated,
  profile,
  component: Component,
  ...rest
}) => (
  //   <Route
  //     {...rest}
  //     component={(props) =>
  //       isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
  //     }
  //   />

  <Route
    {...rest}
    component={(props) =>
      !isAuthenticated ? (
        <Redirect to="/" />
      ) : profile.userType === "WUC" ? (
        <Redirect to="/wuc" />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.firebase.auth.uid,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(PrivateRoute);

// const PrivateRoute({ children, ...rest }) {
//     return (
//       <Route
//         {...rest}
//         render={({ location }) =>
//           fakeAuth.isAuthenticated ? (
//             children
//           ) : (
//             <Redirect
//               to={{
//                 pathname: "/",
//                 state: { from: location }
//               }}
//             />
//           )
//         }
//       />
//     );
//   }
