const validate = (values) => {
  const errors = {};
  if (!values.merchantName) {
    errors.merchantName = "Required";
  }
  if (!values.merchantGroup) {
    errors.merchantGroup = "Required";
  }
  // if (!values.merchantId) {
  //   errors.merchantId = "Required";
  // }

  if (!values.merchantType) {
    errors.merchantType = "Required";
  }

  if (!values.merchantSellerId) {
    errors.merchantSellerId = "Required";
  }

  if (!values.merchantCity) {
    errors.merchantCity = "Required";
  }
  if (!values.merchantSuburb) {
    errors.merchantSuburb = "Required";
  }
  if (!values.merchant_contact_name) {
    errors.merchant_contact_name = "Required";
  }
  if (!values.merchant_contact_email) {
    errors.merchant_contact_email = "Required";
  }
  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.merchant_contact_email
    )
  ) {
    errors.merchant_contact_email = "Invalid email address";
  }

  if (!values.merchant_contact_telephone) {
    errors.merchant_contact_telephone = "Required";
  }

  if (!values.merchant_outlet_name) {
    errors.merchant_outlet_name = "Required";
  }

  if (!values.merchant_outlet_type) {
    errors.merchant_outlet_type = "Required";
  }

  if (!values.merchant_outlet_city) {
    errors.merchant_outlet_city = "Required";
  }

  if (!values.merchant_outlet_suburb) {
    errors.merchant_outlet_suburb = "Required";
  }

  if (!values.merchant_outlet_contact_name) {
    errors.merchant_outlet_contact_name = "Required";
  }

  if (!values.merchant_outlet_contact_email) {
    errors.merchant_outlet_contact_email = "Required";
  }

  if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.merchant_outlet_contact_email
    )
  ) {
    errors.merchant_outlet_contact_email = "Invalid email address";
  }

  if (!values.merchant_outlet_contact_telephone) {
    errors.merchant_outlet_contact_telephone = "Required";
  }

  if (!values.commission_frequency) {
    errors.commission_frequency = "Required";
  }

  return errors;
};

export default validate;
