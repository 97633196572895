import React, { Component, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import format from "date-fns/format";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react_dates_overrides.css";

import { cachetReportByDate } from "../../reportActions";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import classes from "./ReportBody.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { openModal } from "../../../modals/modalActions";

import { createReportExcelFile } from "../../../../app/excel/excel";

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId"),
});

class ReportBody extends Component {
  state = {
    loadingInitial: true,
    loadedEmerchants: [],
    contextRef: {},
    startDate: moment(),
    endDate: moment(),
    today: moment(),
  };

  salesByDateSheet = async (
    transactions,
    workbook,
    logo,
    reportStartDate,
    reportEndDate,
    merchantName
  ) => {
    let startDate = format(reportStartDate, "YYYY-MM-DD");
    let endDate = format(reportEndDate, "YYYY-MM-DD");

    let worksheet = workbook.addWorksheet("Sales By Date", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 2,
          activeCell: "F1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "B2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A3", "B3");

    worksheet.mergeCells("A4", "B4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "For ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${merchantName}`,
        },
      ],
    };

    worksheet.mergeCells("A5", "B5");

    worksheet.mergeCells("A6", "B6");
    worksheet.getCell("A6").value = `Sales By Date`;
    worksheet.getRow(6).font = {
      name: "Calibri",
      family: 4,
      size: 16,
      bold: true,
    };

    worksheet.mergeCells("A7", "B7");

    worksheet.mergeCells("A8", "B8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Date From ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${startDate}`,
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: " to ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: `${endDate}`,
        },
      ],
    };

    worksheet.mergeCells("A9", "B9");

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(10);
    headers.height = 25;
    headers.values = ["Sale Date", "Amount"];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    headers.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "dateTime",
        width: 30,
      },
      {
        key: "transactionAmount",
        width: 10,
      },
    ];

    worksheet.getColumn(2).numFmt = "0000";
    worksheet.getColumn(7).numFmt = "0.00";

    ["A10", "B10"].map((key) => {
      worksheet.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });

    transactions.forEach((doc, index) => {
      worksheet
        .addRow({
          dateTime: doc.transactionDateTime,
          transactionAmount: parseFloat(doc.transactionAmount),
        })
        .commit();
      let rowNum = index + 11;
      let row = worksheet.getRow(rowNum);
      row.outlineLevel = 2;
    });

    worksheet.getColumn(2).numFmt = "#,##0.00;[Red]-#,##0.00";

    let total = generateSalesTotalsCell(worksheet, "B", transactions.length);

    const totalsRow = worksheet.addRow(["Sales Totals", total]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Calibri",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });
  };

  async componentDidMount() {
    await this.props.cachetReportByDate({
      reportStartDate: new Date(this.props.initialValues.reportStartDate),
      reportEndDate: new Date(this.props.initialValues.reportEndDate),
    });

    this.setState({ loadingInitial: false });
  }

  render() {
    const {
      handleSubmit,
      openModal,
      cachetReportByDate,
      createReportExcelFile,
      transactions,
    } = this.props;

    const onFormSubmit = async (values) => {
      let reportStartDate = new Date(this.state.startDate);
      reportStartDate.setHours(0, 0, 0, 0);
      let reportEndDate = new Date(this.state.endDate);
      reportEndDate.setHours(23, 59, 59, 999);

      this.setState({ loadingInitial: true });
      await cachetReportByDate({
        reportStartDate: reportStartDate,
        reportEndDate: reportEndDate,
      });
      this.setState({ loadingInitial: false });
    };

    const exportToExcel = async () => {
      openModal("SpinnerModal");

      await createReportExcelFile(
        "Sales By Date Report",
        transactions,
        this.salesByDateSheet,
        "Prepaid Plus",
        this.state.startDate.toDate(),
        this.state.endDate.toDate()
      );
    };

    let attachedClasses = [
      classes.FilterInput,
      classes.FormGroup,
      classes.FormGroupDefault,
    ];

    let attachedPaginateActiveClasses = [
      classes.paginate_button,
      classes.active,
    ];

    let attachedPaginateSimpleNumbersClasses = [
      classes.dataTables_paginate,
      classes.paging_simple_numbers,
    ];

    let attachedPaginateNextClasses = [classes.paginate_button, classes.next];

    // let itemisedReport = "Spinner.....";
    let itemisedReport = (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

    if (!this.state.loadingInitial) {
      let salesTotal = transactions.reduce(function (accumulator, transaction) {
        return accumulator + transaction.transactionAmount;
      }, 0);

      transactions.length > 0
        ? (itemisedReport = (
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Of Sale</th>
                  <th scope="col">Sale Value</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((report, index) => {
                  return (
                    <tr className={classes.MerchantRow} key={index}>
                      <td>{report.transactionDateTime}</td>
                      <td>{moneyFormat(report.transactionAmount)}</td>
                    </tr>
                  );
                })}
                <tr className={classes.Totals}>
                  <td>
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {moneyFormat(salesTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          ))
        : (itemisedReport = (
            <h4>Selected Group has no transactions for selected period.</h4>
          ));
    }

    return (
      <div className={classes.Container}>
        <div className={classes.ToolsWrapper}>
          <div className={classes.Tools}>
            <div className={classes.PageFilter}>
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className={"row"}>
                  <div className={"col-md-9"}>
                    <div className={"row"}>
                      <DateRangePicker
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="startDate" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="endDate" // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) =>
                          this.setState({ startDate, endDate })
                        } // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={(focusedInput) =>
                          this.setState({ focusedInput })
                        } // PropTypes.func.isRequired,
                        numberOfMonths={1}
                        isOutsideRange={(date) =>
                          date.isAfter(this.state.today)
                        }
                        displayFormat={"YYYY-MM-DD"}
                        minimumNights={0}
                        small={true}
                        readOnly={true}
                      />

                      <button
                        className={classes.FilterReportBtn}
                        style={{ marginLeft: "1rem" }}
                        type="submit"
                      >
                        <svg
                          height="20"
                          width="20"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,10,10) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                              fill="#FFFFFF"
                              d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {/* <!-- END Form Control--> */}
            </div>
            <div className={classes.PrintToolsWrapper}>
                <div
                className={classes.ToolsDataExportIconWrapper}
                onClick={() => exportToExcel()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <g>
                    <path
                      id="path1"
                      transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                      fill="#707070"
                      d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.MerchantTableWrapper}>
          <div className={classes.TableWrapper}>{itemisedReport}</div>
        </div>
      </div>
    );
  }
}

const actions = {
  cachetReportByDate,
  createReportExcelFile,
  openModal,
};

const mapStateToProps = (state, props) => ({
  transactions: state.transactions,
  initialValues: {
    reportStartDate: format(new Date(), "YYYY-MM-DD"),
    reportEndDate: format(new Date(), "YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterReportForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(ReportBody)
);
