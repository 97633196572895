import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { asyncInventoryDevicesValidate as asyncValidate } from "../../../../app/common/validation/asyncValidate.jsx";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../../app/common/form/TextInput";
import AsyncTextInput from "../../../../app/common/form/AsyncTextInput";
import validate from "./validate";
import { createDeviceInventoryItem } from "../../inventoryActions";

const RegisterDevice = ({
  createDeviceInventoryItem,
  handleSubmit,
  error,
  invalid,
  submitting,
  merchantId
}) => {
  const onFormSubmit = values => {
    createDeviceInventoryItem(values);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="Manufacturer"
            name="manufacturer"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Manufacturer"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="Model"
            name="model"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Model"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="IMEI"
            name="imei"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="IMEI"
            required="required"
          />
        </div>
      </div>
      <div className={"row clearfix"}>
        <div className={"col-sm-4"}>
          <Field
            label="MAC Address"
            name="macAddress"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="MAC Address"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="WIFI Address"
            name="wifiAddress"
            component={TextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="WIFI Address"
            required="required"
          />
        </div>
        <div className={"col-sm-4"}>
          <Field
            label="Serial Number"
            name="serialNumber"
            component={AsyncTextInput}
            type="text"
            className={"form-control input-sm"}
            placeholder="Serial Number"
            required="required"
          />
        </div>
      </div>
      <br />
      {error && <label className={"error semi-bold"}>{error}</label>}
      <button className={"btn btn-primary btn-cons m-t-10"}>
        Register A Device
      </button>
    </form>
  );
};

const mapStateToProps = state => {
  let devices = {};

  devices = state.firestore.ordered.inventory.filter(
    item => item.isAllocated === false && item.deviceType === "Device"
  );
  return {
    devices
  };
};

const actions = {
  createDeviceInventoryItem
};

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "registerDevice",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["macAddress", "serialNumber"],
    validate
  })
)(RegisterDevice);
