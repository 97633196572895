import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import firebase from "../../../../app/config/firebase";
import { ReactComponent as Spinner } from "../../../../assets/spinner.svg";
import styles from "./EnableMerchant.module.css";
import {
  approveWaybill,
  rejectDeposit,
} from "../../../deposits/depositsActions";

class enableMerchant extends Component {
  render() {
    const {
      merchant,
      approveWaybill,
      rejectDeposit,
      loading,
      profile,
      handleSubmit,
      closePanel,
    } = this.props;

    const onFormSubmit = (values) => {
      approveWaybill(merchant, profile.displayName);
      closePanel();
    };

    const onFormSubmitReject = (values) => {
      rejectDeposit(merchant, profile.displayName);
      closePanel();
    };

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                <a href={merchant.deliveryAttachment} target="_blank">
                  View waybill
                </a>
              </h5>
              <br />
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Approve</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmitReject)}>
              <div className={"p-t-20 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={loading}
                      style={{
                        backgroundColor: "red",
                      }}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Reject</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  approveWaybill,
  rejectDeposit,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
  profile: state.firebase.profile,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableMerchantForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(enableMerchant)
);
