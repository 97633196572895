//import liraries
import React, { useState, useEffect, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createNewExcelFile } from "../../app/excel/excel";
import { reduxForm } from "redux-form";
import { combineValidators, isRequired } from "revalidate";

import format from "date-fns/format";
import moment from "moment";

import { DatePicker } from "@material-ui/pickers";

import {
  getMerchantTransactionsForDashboard,
  clearFinancialTransactions,
} from "./dashboardActions";

import LoadingComponent from "../../app/layout/LoadingComponent";
import { openModal } from "../modals/modalActions";
import classes from "./index.module.css";

import MerchantList from "./Merchants";

import { formatMoney } from "../../app/common/util/helpers";

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId"),
});

const index = ({
  groupStartingBalance,
  groupClosingBalance,
  accountStartingBalances,
  accountClosingBalances,
  summary,
  salesTotal,
  sales,
  commissionCredited,
  merchants,
  depositsTotal,
  initialValues,
  profile,
  getMerchantTransactionsForDashboard,
  clearFinancialTransactions,
}) => {
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment().endOf("day"));
  let [today, setToday] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);

  let group = profile && profile.group;
  let accounts = profile && profile.accounts;
  let role = profile && profile.role;

  // const [selectedDate, handleDateChange] = useState(new Date());

  let transactions = {
    summary: summary,
    sales: sales,
    startingBalances: accountStartingBalances,
    closingBalances: accountClosingBalances,
  };

  //  async function fetchComment(commentId) {
  //   // You can await here
  // }

  // useEffect(() => {
  //   fetchComment(commentId);
  // }, [commentId]);

  useEffect(() => {
    let isMounted = true;

    getMerchantTransactionsForDashboard({
      reportStartDate: moment(initialValues.reportStartDate).startOf("day"),
      reportEndDate: moment(initialValues.reportEndDate).endOf("day"),
      group: group,
      accounts: accounts,
      role: role,
    }).then(() => {
      console.log("isMounted", isMounted);
      if (isMounted) {
        console.log("commissionCredited", commissionCredited);
        group && setLoadingInitial(false);
      }
    });

    return () => {
      isMounted = false;
      console.log("isMounted", isMounted);
      clearFinancialTransactions();
    };
  }, [group]);

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  const handleStartDateChange = (date) => {
    setStartDate(moment(date).startOf("day"));

    if (date > endDate) setEndDate(moment(date).endOf("day"));
  };

  const handleEndDateChange = (date) => {
    setEndDate(moment(date).endOf("day"));

    if (date < startDate) {
      setStartDate(moment(date).startOf("day"));
    }
  };

  const handleCustomDatesClick = async () => {
    let reportStartDate = startDate;
    let reportEndDate = endDate;

    setLoadingInitial(true);

    await getMerchantTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      group: group,
      accounts: accounts,
      role: role,
    });

    setLoadingInitial(false);
  };

  const handleTodayClick = async () => {
    let reportStartDate = moment().startOf("day");
    let reportEndDate = moment().endOf("day");

    setStartDate(reportStartDate);
    setEndDate(reportEndDate);
    setLoadingInitial(true);

    await getMerchantTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      group: group,
      accounts: accounts,
      role: role,
    });

    setLoadingInitial(false);
  };

  const handleLast7DaysClick = async () => {
    let reportStartDate = moment().subtract(7, "days").startOf("day");
    let reportEndDate = moment().endOf("day");

    setStartDate(moment(reportStartDate));
    setEndDate(moment(reportEndDate));
    setLoadingInitial(true);

    await getMerchantTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      group: group,
      accounts: accounts,
      role: role,
    });

    // commissionCredited > 0 && setShowCommission(true);
    setLoadingInitial(false);
  };

  const handleMonthToDateClick = async () => {
    let reportStartDate = moment().startOf("month");
    let reportEndDate = moment().endOf("day");

    setStartDate(moment(reportStartDate));
    setEndDate(moment(reportEndDate));
    setLoadingInitial(true);

    await getMerchantTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      group: group,
      accounts: accounts,
      role: role,
    });

    setLoadingInitial(false);
  };

  let list =
    merchants &&
    transactions &&
    merchants.map((merchant) => {
      return {
        account: merchant.account,
        name: merchant.merchantName,
        openingBalance: transactions.startingBalances
          .filter((item) => item.account === merchant.account)
          .map((doc) => {
            return doc.startingBalance;
          })[0],
        deposits: transactions.summary
          .filter(
            (item) =>
              item.account === merchant.account &&
              item.transactionType === "Deposit"
          )
          .map((doc) => {
            return doc.transactionAmount;
          })[0],
        sales: transactions.summary
          .filter(
            (item) =>
              item.account === merchant.account &&
              item.transactionType === "Sale"
          )
          .map((doc) => {
            return doc.transactionAmount;
          })[0],
        closingBalance: transactions.closingBalances
          .filter((item) => item.account === merchant.account)
          .map((doc) => {
            return doc.closingBalance;
          })[0],
        transactions: transactions.sales
          .filter((item) => item.account === merchant.account)
          .map((doc) => {
            return {
              type: doc.saleType,
              vendor: doc.provider,
              sales: formatMoney(doc.transactionAmount),
              commission: formatMoney(doc.commissionAmount),
            };
          }),
      };
    });

  const merchantListSheet = async (merchants, workbook, logo) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("Merchant List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 7,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "G2");

    worksheet.mergeCells("A3", "G3");

    worksheet.mergeCells("A4", "G4");
    worksheet.getCell("A4").value = `Merchant List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "G5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "G6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = ["", "Opening", "Deposits", "Sales", "Closing"];
    headers.alignment = {
      vertical: "middle",
      horizontal: "right",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "name",
        width: 48,
      },
      {
        key: "openingBalance",
        width: 16,
      },
      {
        key: "deposits",
        width: 16,
      },
      {
        key: "sales",
        width: 16,
      },
      {
        key: "closingBalance",
        width: 16,
      },
    ];

    list.forEach((doc, index) => {
      worksheet
        .addRow({
          name: doc.name,
          openingBalance: doc.openingBalance ? doc.openingBalance : 0,
          deposits: doc.deposits ? doc.deposits : 0,
          sales: doc.sales ? doc.sales : 0,
          closingBalance: doc.closingBalance ? doc.closingBalance : 0,
        })
        .commit();
    });

    worksheet.getColumn(2).numFmt = "#,##0.00;[Red]-#,##0.00";
    worksheet.getColumn(3).numFmt = "#,##0.00;[Red]-#,##0.00";
    worksheet.getColumn(4).numFmt = "#,##0.00;[Red]-#,##0.00";
    worksheet.getColumn(5).numFmt = "#,##0.00;[Red]-#,##0.00";

    let openingTotal = generateSalesTotalsCell(worksheet, "B", list.length);
    let depositsTotal = generateSalesTotalsCell(worksheet, "C", list.length);
    let salesTotal = generateSalesTotalsCell(worksheet, "D", list.length);
    let closing = generateSalesTotalsCell(worksheet, "E", list.length);

    const totalsRow = worksheet.addRow([
      "Totals",
      openingTotal,
      depositsTotal,
      salesTotal,
      closing,
    ]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
      horizontal: "right",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Verdana",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };

      cell.protection = {
        locked: true,
        hidden: true,
      };

      cell.numFmt = "#,##0.00;[Red]-#,##0.00";
    });

    worksheet.unprotect();
  };

  function generateSalesTotalsCell(worksheet, columnLetter, totalDataRows) {
    const firstDataRow = 9;
    const lastDataRow = firstDataRow + totalDataRows - 1;

    const firstCellReference = `${columnLetter}${firstDataRow}`;
    const lastCellReference = `${columnLetter}${lastDataRow}`;
    const sumRange = `${firstCellReference}:${lastCellReference}`;

    return {
      formula: `SUM(${sumRange})`,
    };
  }

  return (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Home</span>
            <br />
            <span className={classes.ByLine}>You are in the Home panel</span>
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div
                      className={"row"}
                      style={{
                        alignItems: "start",
                        justifyContent: "center",
                        flex: "1 0 auto",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "solid #707070",
                          paddingRight: "3rem",
                        }}
                      >
                        <div className={classes.PageFilter}>
                          <div className={"row"}>
                            <span>
                              <DatePicker
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                inputProps={{
                                  style: {
                                    padding: 2,
                                  },
                                }}
                                margin="none"
                                id="start-date"
                                label="Start Date"
                                value={startDate}
                                disableFuture={true}
                                onChange={(date) => handleStartDateChange(date)}
                              />
                            </span>
                            <span> &nbsp;&nbsp;&nbsp; </span>

                            <span>
                              <DatePicker
                                autoOk={true}
                                disableToolbar
                                variant="inline"
                                inputProps={{
                                  style: {
                                    padding: 2,
                                  },
                                }}
                                margin="none"
                                id="end-date"
                                label="End Date"
                                value={endDate}
                                disableFuture={true}
                                onChange={(date) => handleEndDateChange(date)}
                              />
                            </span>

                            <button
                              className={classes.FilterReportBtn}
                              style={{ marginLeft: "1rem" }}
                              onClick={() => handleCustomDatesClick()}
                            >
                              <svg
                                height="18"
                                width="18"
                                viewBox="0 0 18 18"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    id="path1"
                                    transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                    fill="#FFFFFF"
                                    d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                                  />
                                </g>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className={"col-md-6"}>
                        <div className={classes.FilterWrapper}>
                          <ul
                            className={classes.FilterPeriods}
                            style={{ paddingLeft: 0 }}
                          >
                            <li style={{ paddingLeft: 0 }}>
                              <span onClick={() => handleTodayClick()}>
                                Today
                              </span>
                            </li>
                            <li>
                              <span onClick={() => handleLast7DaysClick()}>
                                Last 7 Days
                              </span>
                            </li>
                            <li>
                              <span onClick={() => handleMonthToDateClick()}>
                                Month To Date
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className={classes.PageHeader}>
                      <div
                        className={classes.ToolsDataExportIconWrapper}
                        onClick={() =>
                          createNewExcelFile(
                            "Merchants",
                            list,
                            merchantListSheet
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          width="24"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <path
                              id="path1"
                              transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                              fill="#707070"
                              d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </header>

                  {loadingInitial ? (
                    <LoadingComponent inverted={true} />
                  ) : (
                    <div className={"card card-transparent"}>
                      <div className={classes.CardBody}>
                        <div className={classes.ToolsWrapper}>
                          <div className={classes.Tools}>
                            <div className={"row"}>
                              {commissionCredited > 0 ? (
                                <>
                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Opening
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {groupStartingBalance &&
                                          formatMoney(groupStartingBalance)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className={"col-lg-2"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Deposits
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {depositsTotal &&
                                          formatMoney(depositsTotal)}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className={"col-lg-2"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Commission
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {formatMoney(commissionCredited)}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className={"col-lg-2"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Sales
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {salesTotal && formatMoney(salesTotal)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Closing
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {groupClosingBalance &&
                                          formatMoney(groupClosingBalance)}
                                      </h4>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Opening
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {groupStartingBalance &&
                                          formatMoney(groupStartingBalance)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Deposits
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {depositsTotal &&
                                          formatMoney(depositsTotal)}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Sales
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {salesTotal && formatMoney(salesTotal)}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className={"col-lg-3"}>
                                    <div>
                                      <h5
                                        className={
                                          "all-caps small no-margin hint-text semi-bold"
                                        }
                                      >
                                        Closing
                                      </h5>
                                      <h4 className={"no-margin bold"}>
                                        {groupClosingBalance &&
                                          formatMoney(groupClosingBalance)}
                                      </h4>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div label={`Summary`}>
                          {merchants && (
                            <MerchantList
                              transactions={transactions}
                              merchants={merchants}
                              showCommission={
                                commissionCredited > 0 ? true : false
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  groupStartingBalance: state.dashboard.startingBalance,
  groupClosingBalance: state.dashboard.closingBalance,
  accountStartingBalances: state.dashboard.accountStartingBalances,
  accountClosingBalances: state.dashboard.accountClosingBalances,
  depositsTotal: state.dashboard.depositsTotal,
  reversalsTotal: state.dashboard.reversalsTotal,
  salesTotal: state.dashboard.salesTotal,
  sales: state.dashboard.totalsByProvider,
  commissionList: state.dashboard.commissionList,
  commissionListTotal: state.dashboard.commissionListTotal,
  commissionTotal: state.dashboard.commissionTotal,
  withdrawalsList: state.dashboard.withdrawalsList,
  withdrawalsTotal: state.dashboard.withdrawalsTotal,
  summary: state.dashboard.summary,
  merchants: state.dashboard.merchants,
  commissionCredited: state.dashboard.creditedCommissionListTotal,
  earnedCommissionTotal: state.dashboard.earnedCommissionTotal,
  prepaidPlusCommissionTotal: state.dashboard.vendorCommissionEarnedTotal,
  requesting: state.firestore.status.requesting,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  initialValues: {
    reportStartDate: moment().format("YYYY-MM-DD"),
    reportEndDate: moment().format("YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

const actions = {
  openModal,
  getMerchantTransactionsForDashboard,
  clearFinancialTransactions,
};

export default compose(connect(mapStateToProps, actions))(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate,
  })(index)
);
