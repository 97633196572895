import React, { Fragment, useState, useEffect } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";

import { formatMoney } from "../../../app/common/util/helpers";
import {
  getPettyCashTransactions,
  clearUsersPettyCashRequests,
} from "../pettyCashActions";
import classes from "./index.module.css";
// import Detail from "./details/detail";
import RequestListItem from "./details";

const index = ({
  requests,
  requestsTotal,
  getPettyCashTransactions,
  clearUsersPettyCashRequests,
}) => {
  console.log("requests!!!", requests);
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  useEffect(() => {
    getPettyCashTransactions().then(() => {
      setLoadingInitial(false);
    });

    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);

    return () => {
      clearUsersPettyCashRequests();
    };
  }, [expanded]);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Fragment>
      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>
          {requests && requests.length > 0 ? (
            <Fragment>
              <table className={classes.MerchantTable}>
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Amount Requested</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Approver(s)</th>
                    <th scope="col">Request Status</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(requests).map((request, index) => {
                    return <RequestListItem key={index} request={request} />;
                  })}
                </tbody>
              </table>
            </Fragment>
          ) : (
            <h4>
              There are no <strong>requests</strong> for the selected period.
            </h4>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  requests: state.firestore.ordered.pettyCash,
  requestsTotal: 0,
});

const actions = {
  getPettyCashTransactions,
  clearUsersPettyCashRequests,
};

export default connect(mapStateToProps, actions)(index);
