import React, { Component, Fragment } from "react";
import format from "date-fns/format";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import { formatMoney } from "../../../app/common/util/helpers";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import classes from "./index.module.css";
import DepositListItem from "./details";

class Index extends Component {
  render() {
    let { depositsList, depositsTotal, reportStartDate, reportEndDate } =
      this.props;

    const deposits =
      depositsList &&
      depositsList.map((deposit, index) => {
        return (
          <DepositListItem
            key={index}
            deposit={deposit}
            reportStartDate={reportStartDate}
            reportEndDate={reportEndDate}
          />
        );
      });

    // Object.values(depositsList).map((deposit, index) => {
    //   return (
    //     <tr className={classes.MerchantRow} key={index}>
    //       <td>
    //         {format(deposit.transactionDateTime, "YYYY-MM-DD HH:mm:ss")}
    //       </td>
    //       <td>{`${deposit.account} - ${deposit.merchantName}`}</td>
    //       <td>{deposit.bank}</td>
    //       <td>{format(deposit.dateOfDeposit, "YYYY-MM-DD")}</td>
    //       <td>{formatMoney(deposit.transactionAmount)}</td>
    //     </tr>
    //   );
    // });

    return (
      <Fragment>
        <div className={classes.MerchantTableWrapper}>
          {" "}
          <div className={classes.TableWrapper}>
            {deposits && deposits.length === 0 ? (
              <h4>
                There are no <strong>deposits</strong> for the selected period.
              </h4>
            ) : (
              <Fragment>
                <table className={classes.MerchantTable}>
                  <thead>
                    <tr>
                      <th scope="col">Transaction Date</th>
                      <th scope="col">Merchant Account </th>
                      <th scope="col">Bank </th>
                      <th scope="col">Deposit Date</th>
                      <th scope="col" style={{ textAligh: "end" }}>
                        Amount Deposited
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deposits}
                    <tr className={classes.Totals}>
                      <td colSpan="4">
                        <div className={classes.MoneyTotalLabel}> Total:</div>
                      </td>
                      <td
                        className={classes.MoneyTotal}
                        style={{ textAligh: "end" }}
                      >
                        {formatMoney(depositsTotal)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Fragment>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  depositsList: state.financials.depositsList,
  depositsTotal: state.financials.depositsTotal,
});

export default connect(mapStateToProps)(Index);
