import React from "react";

import Tabs from "../../../app/UI/Tabs/Tabs";

import FnbRecons from "./recons";
import KazangRecons from "./kazangRecons";
import BpcRecons from "./bpcRecons";

// import UnAllocatedTransactions from "./unallocated";

const index = () => {
  return (
    <Tabs>
      <div label={`FNB Recon`}>
        <FnbRecons />
      </div>
      <div label={`Kazang Recon`}>
        <KazangRecons />
      </div>
      <div label={`BPC Recon`}>
        <BpcRecons/>
      </div>
    </Tabs>
  );
};

export default index;
