import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { DOMParser } from "xmldom";
import classes from "./details.module.css";

const getVoucher = (xmlString) => {
  const domparser = new DOMParser();

  let xmlDoc = domparser.parseFromString(xmlString, "text/xml");
  // const doc = domparser.parseFromString(string, mimeType)

  let creditVendResp =
    xmlDoc.getElementsByTagName("creditVendResp")[0] !== undefined
      ? xmlDoc.getElementsByTagName("creditVendResp")[0]
      : null;

  let creditVendReceipt =
    creditVendResp.getElementsByTagName("creditVendReceipt")[0];

  let transactions = creditVendReceipt.getElementsByTagName("transactions")[0];
  let txs = transactions.getElementsByTagName("tx");
  let creditVendTx;
  let creditVendTxIndex;

  for (var i = 0; i < txs.length; i++) {
    let tx = txs[i];

    if (tx.getAttribute("xsi:type") === "CreditVendTx") {
      if (tx.hasAttribute("receiptNo")) {
        creditVendTx = tx;
        creditVendTxIndex = i + 1;
      }
    }
  }

  let stsCipherElement;

  if (creditVendTxIndex === 1) {
    stsCipherElement = creditVendTx.getElementsByTagName(`q1:stsCipher`)[0];
  } else if (creditVendTxIndex === 2) {
    stsCipherElement = creditVendTx.getElementsByTagName(`q2:stsCipher`)[0];
  } else {
    stsCipherElement = creditVendTx.getElementsByTagName(`q3:stsCipher`)[0];
  }

  let stsCipher = stsCipherElement.textContent;

  return stsCipher;
};

let chunkNumber = (number) => {
  let pinArray = number.match(/.{1,4}/g);

  let chunkedNmber = "";

  pinArray.forEach((element) => {
    chunkedNmber = chunkedNmber + ` ${element}`;
  });

  return chunkedNmber;
};

let tableRow = (transaction, userType) => {
  switch (transaction.saleType) {
    case "Electricity":
      return (
        <table className={classes.FuneralSchemeTable}>
          <thead>
            <tr>
              <th scope="col">Meter</th>

              <th className={"hide-s"} scope="col">
                Amount
              </th>
              <th className={"hide-s"} scope="col">
                Recharge Code
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.MemberRow}>
              <td>{transaction.transactionDetail}</td>
              <td>{transaction.transactionAmount}</td>
              <td>
                <span>{chunkNumber(getVoucher(transaction.response))}</span>
              </td>
            </tr>
          </tbody>
        </table>
      );
    case "Airtime":
      return (
        <table className={classes.FuneralSchemeTable}>
          <thead>
            <tr>
              <th scope="col">Provider</th>

              <th className={"hide-s"} scope="col">
                Amount
              </th>
              <th className={"hide-s"} scope="col">
                Recharge Code/Number
              </th>
              <th className={"hide-s"} scope="col">
                Serial Number
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.MemberRow}>
              <td>{transaction.provider}</td>
              <td>{transaction.transactionAmount}</td>
              <td>
                <span>
                  {transaction.response.voucherPinNumber
                    ? transaction.response.voucherPinNumber
                    : transaction.response.rechargeCellNumber}
                </span>
              </td>
              <td>
                <span>
                  {transaction.response.voucherSerialNumber
                    ? transaction.response.voucherSerialNumber
                    : transaction.response.vendor_transaction_reference}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      ) ;
      // return userType === "PrepaidPlus" ? (
      //   <table className={classes.FuneralSchemeTable}>
      //     <thead>
      //       <tr>
      //         <th scope="col">Provider</th>

      //         <th className={"hide-s"} scope="col">
      //           Amount
      //         </th>
      //         <th className={"hide-s"} scope="col">
      //           Recharge Code/Number
      //         </th>
      //         <th className={"hide-s"} scope="col">
      //           Serial Number
      //         </th>
      //       </tr>
      //     </thead>
      //     <tbody>
      //       <tr className={classes.MemberRow}>
      //         <td>{transaction.provider}</td>
      //         <td>{transaction.transactionAmount}</td>
      //         <td>
      //           <span>
      //             {transaction.response.voucherPinNumber
      //               ? transaction.response.voucherPinNumber
      //               : transaction.response.rechargeCellNumber}
      //           </span>
      //         </td>
      //         <td>
      //           <span>
      //             {transaction.response.voucherSerialNumber
      //               ? transaction.response.voucherSerialNumber
      //               : transaction.response.vendor_transaction_reference}
      //           </span>
      //         </td>
      //       </tr>
      //     </tbody>
      //   </table>
      // ) : (
      //   <span>Transaction has no additional details</span>
      // );

    default:
      return <span>Transaction has no additional details</span>;
  }
};

// {userRole === "Administrator" &&
//                                 userType === "PrepaidPlus" && (
//                                   <div className={classes.AddUser}>
//                                     <button
//                                       className={classes.AddUserBtn}
//                                       onClick={() =>
//                                         openModal("UploadAirtimeModal")
//                                       }
//                                     >
//                                       Parse Airtime File
//                                     </button>
//                                   </div>
//                                 )}

const detail = (props) => {
  let { transaction, profile } = props;

  const userRole = profile.role;
  const userType = profile.userType;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let attachedMemberContractDetailAreaClasses = [
    classes.MemberContractDetailArea,
    classes.clearfix,
  ];

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  return (
    <Fragment>
      <div className={classes.ExpandableView}>
        <div className={attachedMemberContractDetailAreaClasses.join(" ")}>
          <header>
            <h4>Additional Detail</h4>
          </header>

          <div className={classes.FuneralSchemeWrapper}>
            {tableRow(transaction, userType)}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

export default connect(mapStateToProps)(detail);
