import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { openModal } from "../../modals/modalActions";
import classes from "./index.module.css";
import firebase from "../../../app/config/firebase";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import {
  createReportExcelFile,
  createNewExcelFile,
} from "../../../app/excel/excel";

const Failure = ({ openModal }) => {
  const firestore = firebase.firestore();
  const triggersRef = firestore.collection("triggers");

  let [triggerList, setTriggerList] = useState([]);
  let [focusedInput, setFocusedInput] = useState(null);
  let [startDate, setStartDate] = useState(moment());
  let [endDate, setEndDate] = useState(moment());

  const onFocusChangeRangeHandler = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  let failuresreportSheet = async (
    triggerList,
    workbook,
    logo,
    accountName
  ) => {
    let failuresheet = workbook.addWorksheet("Failure Triggers", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 15,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 20,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    failuresheet.mergeCells("A1", "G2");
    failuresheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    failuresheet.mergeCells("A4", "G4");
    failuresheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 14,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Failure Triggers Report",
        },
      ],
    };

    failuresheet.mergeCells("A6", "B6");
    failuresheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "Start date: ",
        },
      ],
    };

    failuresheet.mergeCells("A7", "B7");
    failuresheet.getCell("A7").value = {
      richText: [
        {
          font: {
            size: 13,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: "End date: ",
        },
      ],
    };

    failuresheet.mergeCells("C6", "E6");
    failuresheet.getCell("C6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(startDate.toDate())
            .startOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    failuresheet.mergeCells("C7", "E7");
    failuresheet.getCell("C7").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: false,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },
          text: moment(endDate.toDate())
            .endOf("day")
            .toDate()
            .toString()
            .split("GMT")[0],
        },
      ],
    };

    failuresheet.mergeCells("A10", "C10");
    failuresheet.mergeCells("D10", "F10");
    failuresheet.mergeCells("G10", "I10");
    failuresheet.mergeCells("J10", "L10");
    failuresheet.mergeCells("M10", "O10");
    failuresheet.getCell("A10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Type",
        },
      ],
    };
    failuresheet.getCell("D10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Product",
        },
      ],
    };
    failuresheet.getCell("G10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Date time",
        },
      ],
    };

    failuresheet.getCell("J10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Total transactions",
        },
      ],
    };

    failuresheet.getCell("M10").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Calibri",
            family: 4,
            scheme: "minor",
          },

          text: "Total failures",
        },
      ],
    };

    let _index_failuresheet = 0;
    triggerList.forEach((trigger) => {
      failuresheet.mergeCells(
        `A${11 + _index_failuresheet}`,
        `C${11 + _index_failuresheet}`
      );
      failuresheet.mergeCells(
        `D${11 + _index_failuresheet}`,
        `F${11 + _index_failuresheet}`
      );
      failuresheet.mergeCells(
        `G${11 + _index_failuresheet}`,
        `I${11 + _index_failuresheet}`
      );
      failuresheet.mergeCells(
        `J${11 + _index_failuresheet}`,
        `L${11 + _index_failuresheet}`
      );
      failuresheet.mergeCells(
        `M${11 + _index_failuresheet}`,
        `O${11 + _index_failuresheet}`
      );

      failuresheet.getCell(`A${11 + _index_failuresheet}`).value = `${
        trigger.type == "notrans"
          ? "No Transaction"
          : trigger.type == "erroroccured"
          ? "Database error"
          : trigger.type == "failtrans"
          ? "High failures"
          : null
      }`;

      failuresheet.getCell(
        `D${11 + _index_failuresheet}`
      ).value = `${trigger.provider}`;

      failuresheet.getCell(`G${11 + _index_failuresheet}`).value = `${new Date(
        trigger.date.seconds * 1000
      ).toLocaleString()}`;

      failuresheet.getColumn(10).numFmt = "0000";
      failuresheet.getCell(`J${11 + _index_failuresheet}`).value = `${
        trigger.total ? trigger.total : ""
      }`;

      failuresheet.getColumn(13).numFmt = "0000";
      failuresheet.getCell(`M${11 + _index_failuresheet}`).value = `${
        trigger.failures ? trigger.failures : ""
      }`;

      _index_failuresheet++;
    });
  };

  let fetchData = async () => {
    triggersRef
      .doc("no_transaction_and_failures")
      .collection("no_transaction_and_failures_list")
      .where("date", ">", moment(startDate.toDate()).startOf("day").toDate())
      .where("date", "<", moment(endDate.toDate()).endOf("day").toDate())
      .orderBy("date", "desc")
      .onSnapshot((snapshot) => {
        let data = [];
        snapshot.forEach((element) => {
          data.push(element.data());
        });
        console.log(data);
        setTriggerList(data);
      });
  };
  useEffect(() => {
    fetchData();
    return () => {
      setTriggerList({});
    };
  }, []);

  return (
    <>
      <Fragment>
        <div label={`Transactions`}>
          <div className={classes.Container}>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <div
                  style={{
                    marginTop: "55px",
                    marginBottom: "25px",
                    marginLeft: "20px",
                    float: "right",
                    cursor: "pointer",
                  }}
                  className={classes.ToolsExportIconWrapper}
                  onClick={() =>
                    createNewExcelFile(
                      "Failure Triggers Report",
                      triggerList,
                      failuresreportSheet,
                      "Failure Triggers Report"
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        id="path1"
                        transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                        fill="#707070"
                        d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                      />
                    </g>
                  </svg>
                </div>
                <div
                  style={{
                    marginTop: "50px",
                    marginBottom: "25px",
                    float: "right",
                  }}
                  className={classes.PageFilter}
                >
                  <DateRangePicker
                    startDate={startDate} // momentPropTypes.momentObj or null,
                    startDateId="startDate" // PropTypes.string.isRequired,
                    endDate={endDate} // momentPropTypes.momentObj or null,
                    endDateId="endDate" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                      // console.log(startDate, endDate);
                      setStartDate(startDate);
                      setEndDate(endDate);
                    }} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={onFocusChangeRangeHandler} // PropTypes.func.isRequired,
                    numberOfMonths={1}
                    isOutsideRange={(date) => date.isAfter(moment())}
                    displayFormat={"YYYY-MM-DD"}
                    minimumNights={0}
                    small={true}
                    readOnly={true}
                  />

                  <button
                    className={classes.FilterReportBtn}
                    style={{ marginLeft: "1rem" }}
                    onClick={() => fetchData()}
                  >
                    <svg
                      height="18"
                      width="18"
                      viewBox="0 0 18 18"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <path
                          id="path1"
                          transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                          fill="#FFFFFF"
                          d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                        />
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className={"row"}>
              <div className={"col-md-12"}>
                <h5>Recorded failure and no transactions triggers</h5>
                <div
                  className={classes.TableWrapper}
                  style={{ flexDirection: "column" }}
                >
                  <table className={classes.MerchantTable}>
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Product</th>
                        <th scope="col">Date Time</th>
                        <th scope="col">Total transactions</th>
                        <th scope="col">Total failed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {triggerList && triggerList.length > 0 ? (
                        triggerList.map((trigger, index) => (
                          <tr key={index} className={classes.Totals}>
                            <td>
                              {trigger.type == "notrans"
                                ? "No Transaction"
                                : null}
                              {trigger.type == "erroroccured"
                                ? "Database error"
                                : null}
                              {trigger.type == "failtrans"
                                ? "High failures"
                                : null}
                            </td>
                            <td>{trigger.provider}</td>
                            <td>
                              {new Date(
                                trigger.date.seconds * 1000
                              ).toLocaleString()}
                            </td>
                            <td>{trigger.total ? trigger.total : null}</td>
                            <td>
                              {trigger.failures ? trigger.failures : null}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5">No triggers found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

const mapStateToProps = (state) => ({});

const actions = {
  openModal,
};

export default connect(mapStateToProps, actions)(Failure);
