import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { firestore } from "../../app/config/firebase";

import format from "date-fns/format";

// import { useCollection } from "../../app/config/firebase";
import { fetchMerchants, clearMerchants } from "./merchantActions";
import { openModal } from "../modals/modalActions";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { createNewExcelFile } from "../../app/excel/excel";
import classes from "./Merchants.module.css";
import MerchantList from "./MerchantList/MerchantList";

let searchBykeyword = "";

const index = ({
  fetchMerchants,
  clearMerchants,
  openModal,
  merchants,
  auth,
  profile,
}) => {
  // const merchants = useCollection("merchants", "name");

  const [filtered, setFiltered] = useState([]);

  // const [merchantList, setMerchantList] = useState(null);

  let merchantsFiltered = filtered;
  // const userRole = profile.role;
  const userType = profile.userType;
  // const userAccount = profile.account;
  // const userGroup = profile.group;

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  const merchantListSheet = async (merchants, workbook, logo) => {
    // let merchants = [];

    let worksheet = workbook.addWorksheet("Merchant List", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 9,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "I2");

    worksheet.mergeCells("A3", "H3");

    worksheet.mergeCells("A4", "H4");
    worksheet.getCell("A4").value = `Merchant List`;
    worksheet.getRow(4).font = {
      name: "Calibri",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A5", "I5");

    let now = format(new Date(), "YYYY-MM-DD HH:mm:ss");

    worksheet.mergeCells("A6", "I6");

    worksheet.getCell("A6").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date generated: ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${now}`,
        },
      ],
    };

    // insert an image over B2:D6
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(8);
    headers.height = 25;
    headers.values = [
      "Name",
      "Account",
      "Telephone",
      "Location",
      "Suburb",
      "Group",
      "Is Active?",
      "Date Created",
      "Created By",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };
    headers.eachCell((cell) => {
      cell.font = {
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "name",
        width: 20,
      },
      {
        key: "account",
        width: 10,
      },
      {
        key: "telephone",
        width: 15,
      },
      {
        key: "city",
        width: 24,
      },
      {
        key: "suburb",
        width: 24,
      },
      {
        key: "group",
        width: 20,
      },
      {
        key: "isMerchantActive",
        width: 10,
      },
      {
        key: "dateCreated",
        width: 20,
      },
      {
        key: "createdBy",
        width: 30,
      },
    ];

    merchants.forEach((doc, index) => {
      worksheet
        .addRow({
          name: doc.name,
          account: doc.account,
          group: doc.group,
          telephone: doc.contact.telephone,
          city: doc.city,
          suburb: doc.suburb,
          isMerchantActive: doc.isMerchantActive === true ? "Yes" : "No",
          dateCreated: format(doc.dateCreated.toDate(), "YYYY-MM-DD HH:mm:ss"),
          createdBy: doc.createdBy,
        })
        .commit();
      // let rowNum = index + 9;
      // let row = worksheet.getRow(rowNum);
      // row.outlineLevel = 2;
    });
  };

  const filterByMerchantName = (merchant) => {
    let keyword = merchant.target.value.toLowerCase();
    let filtered = merchants.filter((merchant) => {
      return merchant.name.toLowerCase().indexOf(keyword) > -1;
    });
    setFiltered(filtered);

    searchBykeyword = keyword;
  };

  const filterByAccountNumber = (merchant) => {
    let keyword = merchant.target.value;
    let filtered = merchants.filter((merchant) => {
      return merchant.account.indexOf(keyword) > -1;
    });
    setFiltered(filtered);

    searchBykeyword = keyword;
  };

  useEffect(() => {
    profile.isLoaded && fetchMerchants();
    searchBykeyword = "";
    setFiltered([]);

    return clearMerchants;
  }, [profile]);

  return merchants.length ? (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Merchants</span>
            <br />
            <span className={classes.ByLine}>
              You are in the <span className={"bold"}>Merchants</span> panel
            </span>{" "}
          </div>
        </div>

        <div className={classes.Content}>
          <div className={classes.WelcomeContainer}>
            <div className={classes.MerchantProfile}>
              <div className={"card card-default"}>
                <div className={"card-body"}>
                  <header className={classes.TabHeader}>
                    <div className={classes.PageHeader}>
                      <div className={classes.Tools}>
                        <div className={classes.PageFilter}>
                          <h3 className="m-r-20"> Search:</h3>

                          {/* <!-- START Form Control--> */}
                          <div className={attachedClasses.join(" ")}>
                            <label>Merchant Account</label>
                            <div className={"controls"}>
                              <input
                                id="merchant-id"
                                type="search"
                                className={classes.FormControl}
                                placeholder="Account Number"
                                onChange={filterByAccountNumber}
                              />
                            </div>
                          </div>
                          {/* <!-- END Form Control--> */}

                          {/* <!-- START Form Control--> */}
                          <div className={attachedClasses.join(" ")}>
                            <label>Merchant Name</label>
                            <div className={"controls"}>
                              <input
                                id="merchant-name"
                                type="search"
                                className={classes.FormControl}
                                placeholder="Merchant Name"
                                onChange={filterByMerchantName}
                              />
                            </div>
                          </div>
                          {/* <!-- END Form Control--> */}
                        </div>
                        <div className={classes.PrintToolsWrapper}>
                          {userType === "PrepaidPlus" && (
                            <>
                              <div
                                className={classes.AddNewMerchant}
                                style={{ marginRight: "10px" }}
                              >
                                <button
                                  className={classes.AddNewMerchantBtn}
                                  onClick={() =>
                                    openModal("CreateMerchantModal")
                                  }
                                >
                                  Add New Individual Merchant
                                </button>
                              </div>
                              <div className={classes.AddNewMerchant}>
                                <button
                                  className={classes.AddNewMerchantBtn}
                                  onClick={() =>
                                    openModal("CreateCorporateMerchantModal")
                                  }
                                >
                                  Add New Corporate Merchant
                                </button>
                              </div>
                            </>
                          )}

                          <div
                            className={classes.ToolsDataExportIconWrapper}
                            onClick={() => {
                              console.log("merchants", merchants);
                              createNewExcelFile(
                                "Merchants",
                                merchants,
                                merchantListSheet
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              width="24"
                              viewBox="0 0 24 24"
                            >
                              <g>
                                <path
                                  id="path1"
                                  transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                                  fill="#707070"
                                  d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </header>

                  <div className={"card card-transparent"}>
                    <div className={classes.CardBody}>
                      {merchants && merchants.length === 0 ? (
                        <div style={{ paddingBottom: "30px" }}>
                          <h3>No merchants. Add a merchant to proceed</h3>
                        </div>
                      ) : // merchantList

                      searchBykeyword.length > 0 &&
                        merchantsFiltered.length === 0 ? (
                        <div>
                          <div style={{ paddingBottom: "30px" }}>
                            <h3>
                              {" "}
                              No merchants returned for query "{searchBykeyword}
                              "{" "}
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {console.log("merchants", merchants && merchants)}

                          <MerchantList
                            merchants={
                              merchantsFiltered.length === 0
                                ? merchants
                                : merchantsFiltered
                            }
                            filterByMerchantName={filterByMerchantName}
                            filterByAccountNumber={filterByAccountNumber}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className={classes.Container}>
        <div className={classes.BreadcrumbWrapper}>
          <div className={classes.Breadcrumb}>
            <span className={classes.Header}>Merchants</span>
            <br />
            <span className={classes.ByLine}>
              You are in the <span className={"bold"}>Merchants</span> panel
            </span>
          </div>
        </div>
        <LoadingComponent inverted={true} />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  merchants: state.merchants,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});

const actions = {
  fetchMerchants,
  clearMerchants,
  openModal,
};

export default connect(mapStateToProps, actions)(index);
