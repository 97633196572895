import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import BPCReversalsReportSpinner from "../reports/Cachet/BPCReversals/BPCReversalsReportSpinner";

const actions = {
  closeModal,
};

const bpcSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <BPCReversalsReportSpinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(bpcSpinnerModal);
