

import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Field, reduxForm } from "redux-form";
import { assignSIMToDevice } from "../inventoryActions";

import SelectInput from "../../../app/common/form/SelectInput";

const assignSIMToDeviceForm = ({
    assignSIMToDevice,
    unallocatedSIM,
    initialValues,
  error,
  handleSubmit,
  deviceId
}) => {
  let unallocatedSIMs;

  unallocatedSIMs =
  unallocatedSIM &&
  unallocatedSIM.map(sim => {
      return {
        label: `${sim.network} - ${sim.cellNumber}`,
        value: sim
      };
    });

    const onFormSubmit = values => {
        assignSIMToDevice(values);
      };

  return (
    <div className={"full-height sm-p-t-30"}>
      <div className={"d-flex justify-content-center flex-column full-height"}>
        <form className={"p-t-15"} onSubmit={handleSubmit(onFormSubmit)}>
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>Device </label>
              <div><strong>{initialValues.device.deviceModel}</strong></div>
            </div>
            <div className={"col-md-6"}>
              <label>Mac Address</label>
              <div><strong>{initialValues.device.macAddress}</strong></div>
            </div>
          </div>
          <p />
          <div className={"row"}>
            <div className={"col-md-6"}>
              <label>SIM Card</label>
              <Field
                name="sim"
                options={unallocatedSIMs}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0"
                  }
                })}
                component={SelectInput}
                placeholder="Select SIM..."
                className={"form-control"}
                required="true"
                aria-required="true"
              />
            </div>
          </div>
          <p />

          {error && <label className={"error semi-bold"}>{error}</label>}

          <button className={"btn btn-primary btn-cons m-t-10"}>
            Assign SIM Card
          </button>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
    unallocatedSIM: state.firestore.ordered.unallocatedSIM,
  initialValues: {
    device: state.modals.modalProps
  },
  enableReinitialize: true
});

let query = [
  {
    collection: "inventory",
    storeAs: "unallocatedSIM",
    where: [["deviceType", "==", "SIM"],["isAssigned", "==", false]],
  }
];


const actions = {
    assignSIMToDevice
};

const validate = values => {
    const errors = {};
    if (!values.sim) {
      errors.sim = "Required";
    }
    return errors;
  };
  

export default compose(
  connect(
    mapStateToProps,
    actions
  ),
  reduxForm({
    form: "assignSIMToDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    validate
  }),
  firestoreConnect(query)
)(assignSIMToDeviceForm);

// where: ["roleScope", "==", "Merchant"]
