import React from "react";
import TransparentModal from "../../app/UI/Modal/TransparentModal";
import { closeModal } from "./modalActions";
import { connect } from "react-redux";

import MerchantStatementspinner from "../reports/Merchant/Statement/MerchantStatementpinner";

const actions = {
  closeModal,
};

const merchantStatementSpinnerModal = ({ closeModal }) => {
  return (
    <TransparentModal modalHeader="Please Wait...." onClose={closeModal}>
      <MerchantStatementspinner />
    </TransparentModal>
  );
};

export default connect(null, actions)(merchantStatementSpinnerModal);
