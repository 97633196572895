import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import AsyncTextInput from "../../../../../app/common/form/AsyncTextInput";
import { asyncMerchantNameValidate as asyncValidate } from "../../../../../app/common/validation/asyncValidate";
import validate from "./validate";
import { ReactComponent as Spinner } from "../../../../../assets/spinner.svg";
import styles from "./Edit.module.css";

import { updateMerchantField } from "../../../merchantActions";

const editName = (props) => {
  const {
    name,
    account,
    handleSubmit,
    loading,
    closePanel,
    updateMerchantField,
  } = props;
  const onFormSubmit = (values) => {
    let type = "Name";
    updateMerchantField(values, account, type);
    closePanel();
  };

  console.log("name", name);

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={"row clearfix"}>
        <div className={"col-sm-8"}>
          <Field
            name="merchantName"
            component={AsyncTextInput}
            type="text"
            className={"form-control"}
            placeholder="Name"
            value={name}
            required="required"
          />
        </div>
      </div>
      <div className={"p-t-20 sm-m-t-20 clearfix"}>
        <ul className={"pager wizard no-style"}>
          <li className="next">

            <button
                className={styles.btn} type="submit" disabled={loading}>
               {loading ? <Spinner style={{width:'20px',height:'20px',verticalAlign:'middle'}}/> : <span>Update Name</span>} 
                
              </button>
          </li>
        </ul>
      </div>
    </form>
  );
};

const actions = {
  updateMerchantField,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "editMerchantFieldForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    asyncValidate,
    asyncBlurFields: ["merchantName"],
  })(editName)
);
