import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import format from "date-fns/format";
import { reduxForm, Field } from "redux-form";
import { combineValidators, isRequired } from "revalidate";
import { DateRangePicker } from "react-dates";
import { openModal } from "../../../modals/modalActions";
import { createReportExcelFile } from "../../../../app/excel/excel";

import { getTransactionsForDashboard } from "../../reportActions";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";

import SelectInput from "../../../../app/common/form/SelectInput";
import classes from "./Itemised.module.css";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import Detail from "./details/detail";
import ItemisedReportItem from "./details";

import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const validate = combineValidators({
  reportStartDate: isRequired("reportStartDate"),
  reportEndDate: isRequired("reportEndDate"),
  outletId: isRequired("outletId"),
});

let generateSalesTotalsCell = (worksheet, columnLetter, totalDataRows) => {
  const firstDataRow = 11;
  const lastDataRow = firstDataRow + totalDataRows - 1;

  const firstCellReference = `${columnLetter}${firstDataRow}`;
  const lastCellReference = `${columnLetter}${lastDataRow}`;
  const sumRange = `${firstCellReference}:${lastCellReference}`;

  return {
    formula: `SUM(${sumRange})`,
  };
};

let outletList;

const ItemisedReport = ({
  handleSubmit,
  openModal,
  createReportExcelFile,
  merchant,
  getTransactionsForDashboard,
  transactions,
  requesting,
  initialValues,
  outlets,
}) => {
  let [loadingInitial, setLoadingInitial] = useState(true);
  let [s_Date, setSDate] = useState(moment().startOf("day"));
  let [e_Date, setEDate] = useState(moment().endOf("day"));
  let [startDate, setStartDate] = useState(moment().startOf("day"));
  let [endDate, setEndDate] = useState(moment().endOf("day"));
  let [startTime, setStartTime] = useState("00:00");
  let [endTime, setEndTime] = useState("23:59");
  let [filterText, setFilterText] = useState("");
  let [filteredTransactions, setFilteredTransactions] = useState([]);

  let [outletName, setOutletName] = useState("");

  const filterByMeterNumber = (meterNumber) => {
    let keyword = meterNumber.target.value.toLowerCase();
    let filtered = transactions.filter((transaction) => {
      if (transaction.transactionType === "Sale") {
        return transaction.transactionDetail.indexOf(keyword) > -1;
      } else return false;
    });

    setFilteredTransactions(filtered);

    setFilterText(keyword);
  };

  let itemisedSalesSheet = async (
    transactions,
    workbook,
    logo,
    reportStartDate,
    reportEndDate,
    outletName
  ) => {
    let startDate = format(reportStartDate, "YYYY-MM-DD");
    let endDate = format(reportEndDate, "YYYY-MM-DD");

    let worksheet = workbook.addWorksheet("All Sales", {
      properties: {
        showGridLines: false,
      },
      pageSetup: {
        paperSize: 9,
        fitToPage: true,
        orientation: "portrait",
      },
      views: [
        {
          state: "frozen",
          xSplit: 6,
          activeCell: "A1",
          showGridLines: false,
        },
      ],
    });

    worksheet.mergeCells("A1", "F2");
    worksheet.addImage(logo, {
      tl: {
        col: 0.25,
        row: 0.25,
      },
      ext: {
        width: 150,
        height: 32,
      },
    });

    worksheet.mergeCells("A3", "F3");

    worksheet.mergeCells("A4", "F4");
    worksheet.getCell("A4").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "For ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${outletName}`,
        },
      ],
    };

    worksheet.mergeCells("A5", "F5");

    worksheet.mergeCells("A6", "F6");
    worksheet.getCell("A6").value = `Itemised Sales For Period`;
    worksheet.getRow(6).font = {
      name: "Verdana",
      family: 4,
      size: 20,
      bold: true,
    };

    worksheet.mergeCells("A7", "F7");

    worksheet.mergeCells("A8", "F8");
    worksheet.getCell("A8").value = {
      richText: [
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: "Date From ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${startDate}`,
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: " to ",
        },
        {
          font: {
            size: 12,
            color: {
              argb: "FF000000",
            },
            bold: true,
            name: "Verdana",
            family: 4,
            scheme: "minor",
          },
          text: `${endDate}`,
        },
      ],
    };

    worksheet.mergeCells("A9", "F9");

    // adjust pageSetup settings afterwards
    worksheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    let headers = worksheet.getRow(10);
    headers.height = 25;
    headers.values = [
      "Date Time",
      "User",
      "Sale Type",
      "Provider",
      "Meter Number",
      "Amount",
    ];
    headers.alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    headers.eachCell((cell) => {
      cell.font = {
        name: "Verdana",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };
      cell.border = {
        bottom: {
          style: "thick",
        },
      };
    });

    worksheet.columns = [
      {
        key: "dateTime",
        width: 20,
      },
      {
        key: "user",
        width: 24,
      },
      {
        key: "saleType",
        width: 18,
      },
      {
        key: "provider",
        width: 30,
      },
      {
        key: "meterNumber",
        width: 18,
      },
      {
        key: "transactionAmount",
        width: 10,
      },
    ];

    ["A10", "B10", "C10", "D10", "E10", "F10"].map((key) => {
      worksheet.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });

    transactions.forEach((doc, index) => {
      worksheet
        .addRow({
          dateTime: format(
            doc.transactionDateTime.toDate(),
            "YYYY-MM-DD HH:mm:ss"
          ),
          user: `${doc.createdBy} - ${doc.name}`,
          saleType: doc.saleType,
          provider: doc.provider,
          meterNumber: doc.transactionDetail ? doc.transactionDetail : "n/a",
          transactionAmount: parseFloat(doc.transactionAmount),
        })
        .commit();
      let rowNum = index + 11;
      let row = worksheet.getRow(rowNum);
      row.outlineLevel = 2;
    });

    let column7 = worksheet.getColumn(7);

    column7.width = 15;

    worksheet.getColumn(6).numFmt = "0.00";

    let total = generateSalesTotalsCell(worksheet, "F", transactions.length);

    const totalsRow = worksheet.addRow(["Sales Totals", "", "", "", "", total]);

    totalsRow.height = 25;
    totalsRow.alignment = {
      vertical: "middle",
      horizontal: "right",
    };

    totalsRow.eachCell((cell) => {
      cell.font = {
        name: "Verdana",
        family: 4,
        size: 10,
        color: {
          argb: "FFFFFFFF",
        },
        bold: true,
      };

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "FF002060",
        },
      };
    });
  };

  const handleStartDateChange = (date) => {
    let dateTimeString = date.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${startTime}`);

    setStartDate(dateTime);
    setSDate(date);

    if (date > e_Date) {
      let endDateTime = moment(`${dateTimeString} 23:59:59`);

      setEndDate(endDateTime);
      setEDate(date);
      setEndTime("23:59");
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);

    if (date < startDate) setStartDate(date);

    let dateTimeString = date.format("YYYY-MM-DD");

    console.log("dateTimeString", dateTimeString);
    console.log("endTime", endTime);

    let dateTime = moment(`${dateTimeString} ${endTime}`);

    console.log("dateTime", dateTime);

    setEndDate(dateTime);
    setEDate(date);

    if (date < s_Date) {
      console.log("dateTimeString", dateTimeString);

      let startDateTime = moment(`${dateTimeString} 00:00`);

      console.log("startDateTime", startDateTime);

      setStartDate(startDateTime);
      setSDate(date);
      setStartTime("00:00");
    }
  };

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);

    let dateTimeString = startDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    setStartDate(dateTime);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);

    let dateTimeString = endDate.format("YYYY-MM-DD");

    let dateTime = moment(`${dateTimeString} ${event.target.value}`);

    setEndDate(dateTime);
  };

  let attachedClasses = [
    classes.FilterInput,
    classes.FormGroup,
    classes.FormGroupDefault,
  ];

  useEffect(() => {
    getTransactionsForDashboard({
      reportStartDate: moment(initialValues.reportStartDate)
        .startOf("day")
        .toDate(),
      reportEndDate: moment(initialValues.reportEndDate).endOf("day").toDate(),
      outletId: initialValues.outletId,
    }).then(() => {
      setOutletName(initialValues.outletName);

      setLoadingInitial(false);
      outletList = {
        label: initialValues.outletName,
        value: initialValues.outletId,
      };
    });

    // return () => {
    //   clearFinancialTransactions();
    // };
  }, []);

  // const {
  //   handleSubmit, openModal, createReportExcelFile, merchant,
  //   getTransactionsForDashboard,
  //   transactions,
  //   requesting
  // } = this.props;
  const loading = requesting[`merchants`];

  outletList = Object.values(outlets).map((outlet) => {
    return {
      label: `${outlet.id} - ${outlet.name}`,
      value: outlet.id,
    };
  });

  const onFormSubmit = async (values) => {
    const outletId = values.outletId;

    const reportStartDate = new Date(startDate);
    const reportEndDate = new Date(endDate);

    // outletId: "2165-01"
    // outletName: "Shell G/West"
    // reportEndDate: "2022-07-16"
    // reportStartDate: "2022-07-16"

    console.log("outletId", outletId);
    console.log("reportStartDate", reportStartDate);
    console.log("reportEndDate", reportEndDate);

    setLoadingInitial(true);
    await getTransactionsForDashboard({
      reportStartDate: reportStartDate,
      reportEndDate: reportEndDate,
      outletId: outletId,
    });

    setLoadingInitial(false);
    setOutletName(values.outletId.label);
  };

  const exportToExcel = async () => {
    openModal("SpinnerModal");

    await createReportExcelFile(
      "Itemised Sales",
      transactions,
      itemisedSalesSheet,
      outletName,
      startDate.toDate(),
      endDate.toDate()
    );
  };

  let itemisedReport = (
    <Fragment>
      <LoadingComponent inverted={true} />
    </Fragment>
  );

  if (loading)
    return (
      <Fragment>
        <LoadingComponent inverted={true} />
      </Fragment>
    );

  if (!loadingInitial) {
    let transactionsItems =
      filteredTransactions.length === 0 ? transactions : filteredTransactions;

    let salesTotal = transactionsItems.reduce(function (
      accumulator,
      transaction
    ) {
      return accumulator + transaction.transactionAmount;
    },
    0);

    filterText.length > 0 && filteredTransactions.length === 0
      ? (itemisedReport = (
          <Fragment>
            {outletName && (
              <div
                className={classes.PageFilterTools}
                style={{ marginTop: "3rem", marginBottom: "2rem" }}
              >
                <div
                  style={{
                    paddingRight: "1.5rem",
                    borderRight: "3px solid rgb(112, 112, 112)",
                  }}
                >
                  <h4>Outlet: {outletName}</h4>
                </div>

                <div
                  className={classes.PageFilter}
                  style={{ paddingLeft: "1.5rem" }}
                >
                  {/* <h4 className="m-r-20"> Search:</h4> */}

                  {/* <!-- START Form Control--> */}
                  <div className={attachedClasses.join(" ")}>
                    <label>Meter Number</label>
                    <div className={"controls"}>
                      <input
                        type="text"
                        className={classes.FormControl}
                        placeholder="Filter by Meter Number"
                        value={filterText}
                        onChange={filterByMeterNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div style={{ paddingBottom: "30px" }}>
                <h5>There are no transactions found for query {filterText}</h5>
              </div>
            </div>
          </Fragment>
        ))
      : transactionsItems.length > 0
      ? (itemisedReport = (
          <Fragment>
            {outletName && (
              <div
                className={classes.PageFilterTools}
                style={{ marginTop: "3rem", marginBottom: "2rem" }}
              >
                <div
                  style={{
                    paddingRight: "1.5rem",
                    borderRight: "3px solid rgb(112, 112, 112)",
                  }}
                >
                  <h4>Outlet: {outletName}</h4>
                </div>

                <div
                  className={classes.PageFilter}
                  style={{ paddingLeft: "1.5rem" }}
                >
                  {/* <h4 className="m-r-20"> Search:</h4> */}

                  {/* <!-- START Form Control--> */}
                  <div className={attachedClasses.join(" ")}>
                    <label>Meter Number</label>
                    <div className={"controls"}>
                      <input
                        type="text"
                        className={classes.FormControl}
                        placeholder="Filter by Meter Number"
                        value={filterText}
                        onChange={filterByMeterNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <table className={classes.MerchantTable}>
              <thead>
                <tr>
                  <th scope="col">Date Time</th>
                  <th scope="col">UseId - Name</th>
                  <th scope="col">Sale Type</th>
                  <th scope="col">Transaction Detail</th>
                  <th scope="col">Sale-Value</th>
                </tr>
              </thead>
              <tbody>
                {transactionsItems.map((transaction, index) => {
                  return (
                    <ItemisedReportItem
                      transaction={transaction}
                      index={index}
                      key={index}
                    />
                  );
                })}
                <tr className={classes.Totals}>
                  <td colSpan="4">
                    <div className={classes.MoneyTotalLabel}> Total:</div>
                  </td>
                  <td className={classes.MoneyTotal}>
                    {moneyFormat(salesTotal)}
                  </td>
                </tr>
              </tbody>
            </table>
          </Fragment>
        ))
      : (itemisedReport = (
          <h4>Selected Outlet has no transactions for selected period.</h4>
        ));
  }

  return (
    <div className={classes.Container}>
      <div className={classes.ToolsWrapper}>
        <div className={classes.Tools}>
          <div className={classes.PageFilter}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className={"row"}>
                <div className={"col-md-3"}>
                  <Field
                    name="outletId"
                    options={outletList}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: "neutral0",
                      },
                    })}
                    component={SelectInput}
                    placeholder="Select Outlet..."
                    className={"form-control"}
                    required="true"
                    aria-required="true"
                  />
                </div>

                <div className={"col-md-9"}>
                  <div className={"row"}>
                    <div className={"offset-1"}>
                      <div className={"row"}>
                        <span>
                          <InputLabel id="start-date-label">
                            Start Date
                          </InputLabel>
                          <DatePicker
                            labelid="start-date-label"
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            margin="none"
                            id="start-date"
                            value={s_Date}
                            disableFuture={true}
                            onChange={(date) => handleStartDateChange(date)}
                          />
                        </span>
                        <span>
                          <InputLabel id="start-time-label">Time</InputLabel>
                          <Select
                            labelid="start-time-label"
                            id="start-time"
                            value={startTime}
                            onChange={handleStartTimeChange}
                          >
                            <MenuItem value="00:00">00:00</MenuItem>
                            <MenuItem value="00:30">00:30</MenuItem>
                            <MenuItem value="01:00">01:00</MenuItem>
                            <MenuItem value="01:30">01:30</MenuItem>
                            <MenuItem value="02:00">02:00</MenuItem>
                            <MenuItem value="02:30">02:30</MenuItem>
                            <MenuItem value="03:00">03:00</MenuItem>
                            <MenuItem value="03:30">03:30</MenuItem>
                            <MenuItem value="04:00">04:00</MenuItem>
                            <MenuItem value="04:30">04:30</MenuItem>
                            <MenuItem value="05:00">05:00</MenuItem>
                            <MenuItem value="05:30">05:30</MenuItem>
                            <MenuItem value="06:00">06:00</MenuItem>
                            <MenuItem value="06:30">06:30</MenuItem>
                            <MenuItem value="07:00">07:00</MenuItem>
                            <MenuItem value="07:30">07:30</MenuItem>
                            <MenuItem value="08:00">08:00</MenuItem>
                            <MenuItem value="08:30">08:30</MenuItem>
                            <MenuItem value="09:00">09:00</MenuItem>
                            <MenuItem value="09:30">09:30</MenuItem>
                            <MenuItem value="10:00">10:00</MenuItem>
                            <MenuItem value="10:30">10:30</MenuItem>
                            <MenuItem value="11:00">11:00</MenuItem>
                            <MenuItem value="11:30">11:30</MenuItem>
                            <MenuItem value="12:00">12:00</MenuItem>
                            <MenuItem value="12:30">12:30</MenuItem>
                            <MenuItem value="13:00">13:00</MenuItem>
                            <MenuItem value="13:30">13:30</MenuItem>
                            <MenuItem value="14:00">14:00</MenuItem>
                            <MenuItem value="14:30">14:30</MenuItem>
                            <MenuItem value="15:00">15:00</MenuItem>
                            <MenuItem value="15:30">15:30</MenuItem>
                            <MenuItem value="16:00">16:00</MenuItem>
                            <MenuItem value="16:30">16:30</MenuItem>
                            <MenuItem value="17:00">17:00</MenuItem>
                            <MenuItem value="17:30">17:30</MenuItem>
                            <MenuItem value="18:00">18:00</MenuItem>
                            <MenuItem value="18:30">18:30</MenuItem>
                            <MenuItem value="19:00">19:00</MenuItem>
                            <MenuItem value="19:30">19:30</MenuItem>
                            <MenuItem value="20:00">20:00</MenuItem>
                            <MenuItem value="20:30">20:30</MenuItem>
                            <MenuItem value="21:00">21:00</MenuItem>
                            <MenuItem value="21:30">21:30</MenuItem>
                            <MenuItem value="22:00">22:00</MenuItem>
                            <MenuItem value="22:30">22:30</MenuItem>
                            <MenuItem value="23:00">23:00</MenuItem>
                            <MenuItem value="23:30">23:30</MenuItem>
                          </Select>
                        </span>
                        <span style={{ marginRight: "30px" }}> </span>

                        <span>
                          <InputLabel id="end-date-label">End Date</InputLabel>
                          <DatePicker
                            labelid="end-date-label"
                            id="end-date"
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            margin="none"
                            value={e_Date}
                            disableFuture={true}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </span>
                        <span>
                          <InputLabel id="end-time-label">Time</InputLabel>
                          <Select
                            labelid="end-time-label"
                            id="end-time"
                            value={endTime}
                            onChange={(time) => handleEndTimeChange(time)}
                          >
                            <MenuItem value="00:00">00:00</MenuItem>
                            <MenuItem value="00:30">00:30</MenuItem>
                            <MenuItem value="01:00">01:00</MenuItem>
                            <MenuItem value="01:30">01:30</MenuItem>
                            <MenuItem value="02:00">02:00</MenuItem>
                            <MenuItem value="02:30">02:30</MenuItem>
                            <MenuItem value="03:00">03:00</MenuItem>
                            <MenuItem value="03:30">03:30</MenuItem>
                            <MenuItem value="04:00">04:00</MenuItem>
                            <MenuItem value="04:30">04:30</MenuItem>
                            <MenuItem value="05:00">05:00</MenuItem>
                            <MenuItem value="05:30">05:30</MenuItem>
                            <MenuItem value="06:00">06:00</MenuItem>
                            <MenuItem value="06:30">06:30</MenuItem>
                            <MenuItem value="07:00">07:00</MenuItem>
                            <MenuItem value="07:30">07:30</MenuItem>
                            <MenuItem value="08:00">08:00</MenuItem>
                            <MenuItem value="08:30">08:30</MenuItem>
                            <MenuItem value="09:00">09:00</MenuItem>
                            <MenuItem value="09:30">09:30</MenuItem>
                            <MenuItem value="10:00">10:00</MenuItem>
                            <MenuItem value="10:30">10:30</MenuItem>
                            <MenuItem value="11:00">11:00</MenuItem>
                            <MenuItem value="11:30">11:30</MenuItem>
                            <MenuItem value="12:00">12:00</MenuItem>
                            <MenuItem value="12:30">12:30</MenuItem>
                            <MenuItem value="13:00">13:00</MenuItem>
                            <MenuItem value="13:30">13:30</MenuItem>
                            <MenuItem value="14:00">14:00</MenuItem>
                            <MenuItem value="14:30">14:30</MenuItem>
                            <MenuItem value="15:00">15:00</MenuItem>
                            <MenuItem value="15:30">15:30</MenuItem>
                            <MenuItem value="16:00">16:00</MenuItem>
                            <MenuItem value="16:30">16:30</MenuItem>
                            <MenuItem value="17:00">17:00</MenuItem>
                            <MenuItem value="17:30">17:30</MenuItem>
                            <MenuItem value="18:00">18:00</MenuItem>
                            <MenuItem value="18:30">18:30</MenuItem>
                            <MenuItem value="19:00">19:00</MenuItem>
                            <MenuItem value="19:30">19:30</MenuItem>
                            <MenuItem value="20:00">20:00</MenuItem>
                            <MenuItem value="20:30">20:30</MenuItem>
                            <MenuItem value="21:00">21:00</MenuItem>
                            <MenuItem value="21:30">21:30</MenuItem>
                            <MenuItem value="22:00">22:00</MenuItem>
                            <MenuItem value="22:30">22:30</MenuItem>
                            <MenuItem value="23:00">23:00</MenuItem>
                            <MenuItem value="23:30">23:30</MenuItem>
                            <MenuItem value="23:59">23:59</MenuItem>
                          </Select>
                        </span>

                        <button
                          className={classes.FilterReportBtn}
                          style={{ marginLeft: "1rem" }}
                          type="submit"
                        >
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 18 18"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g>
                              <path
                                id="path1"
                                transform="rotate(0,9,9) translate(0,0.00844358491989894) scale(0.562499396503619,0.562499396503619)  "
                                fill="#FFFFFF"
                                d="M12.833076,4.5889625C9.0420458,4.5889625 5.7500408,7.1819653 5.3510525,10.673919 4.9520643,14.364907 7.9450567,17.756886 12.134098,18.055895 16.324117,18.45488 20.015109,15.760895 20.414098,11.970939 20.813148,8.2799505 17.821132,4.8879718 13.631113,4.5889625z M12.733099,5.7343641E-10C13.132088,-2.0158268E-07 13.631113,-2.0158268E-07 14.030102,0.10000526 21.013099,0.6979938 26.100154,6.2849674 25.402154,12.568927 25.103141,14.763922 24.205129,16.758906 22.808151,18.45488L31.288163,27.53283C32.385152,28.629839 32.18514,30.425818 30.889175,31.322817 29.592172,32.320795 27.69617,32.120818 26.599118,31.023808L18.020107,21.746853C16.025104,22.544852 13.830089,22.943867 11.436097,22.743857 4.4530389,22.145869 -0.63395572,16.559901 0.064045019,10.274934 0.76204573,4.3889822 6.3490422,-2.0158268E-07 12.733099,5.7343641E-10z"
                              />
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            {/* <!-- END Form Control--> */}
          </div>
          <div className={classes.PrintToolsWrapper}>
            <div
              className={classes.ToolsDataExportIconWrapper}
              onClick={() => exportToExcel()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                width="24"
                viewBox="0 0 24 24"
              >
                <g>
                  <path
                    id="path1"
                    transform="rotate(0,12,12) translate(0,2.3996250629425) scale(0.75,0.75)  "
                    fill="#707070"
                    d="M23.471008,4.2230202L32,12.800012 23.471008,20.360005 23.471008,16.322012C23.471008,16.322012 8.7430115,12.294 5.0610046,20.360005 5.0610046,18.343985 6.3990173,8.2560073 23.471008,8.2560073z M0,0L21.334015,0 21.334015,2.1340014 21.334015,4.2690102 19.200012,4.2690102 19.200012,2.1340014 2.1340027,2.1340014 2.1340027,23.466997 19.200012,23.466997 19.200012,21.331989 21.334015,21.331989 21.334015,23.466997 21.334015,25.601 19.200012,25.601 0,25.601 0,23.466997 0,2.1340014z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.MerchantTableWrapper}>
        <div className={classes.TableWrapper}>{itemisedReport}</div>
      </div>
    </div>
  );
};

const actions = {
  getTransactionsForDashboard,
  openModal,
  createReportExcelFile,
};

const mapStateToProps = (state, props) => ({
  transactions: state.reports,
  requesting: state.firestore.status.requesting,
  merchant: state.firestore.ordered.merchant[0],
  initialValues: {
    outletId: Object.values(props.outlets)[0].id,
    outletName: Object.values(props.outlets)[0].name,
    reportStartDate: format(new Date(), "YYYY-MM-DD"),
    reportEndDate: format(new Date(), "YYYY-MM-DD"),
  },
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "filterItemisedForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(ItemisedReport)
);
