import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

import { ReactComponent as Spinner } from "../../../../../../../assets/spinner.svg";

import styles from "./DisableDevice.module.css";
import { deactivateDevice } from "../../../../../merchantActions";

class DisableDevice extends Component {
  render() {
    const {
      deactivateDevice,
      handleSubmit,
      loading,
      // closePanel,
      device,
      // outletId,
      // account,
    } = this.props;

    const onFormSubmit = (values) => {
      deactivateDevice(device.id);
      // closePanel();
    };

    return (
      <div label="Deposits">
        <header className={styles.TabHeader}>
          <div className={styles.PageHeader}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <h5>
                Serial:
                <span className={"semi-bold"}>{device.serialNumber}</span>
              </h5>
              <h5>
                Cell Number:
                <span className={"semi-bold"}>{device.cellNumber}</span>
              </h5>

              <div className={"p-t-10 sm-m-t-20 bg-white clearfix"}>
                <ul className={"pager wizard no-style"}>
                  <li className="next">
                    <button
                      className={styles.btn}
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          style={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      ) : (
                        <span>Disable Device</span>
                      )}
                    </button>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </header>
      </div>
    );
  }
}

const actions = {
  deactivateDevice,
};

const mapStateToProps = (state) => ({
  loading: state.async.loading,
  enableReinitialize: true,
});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "enableDeviceForm",
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
  })(DisableDevice)
);
